import { FETCH_CURRENT_APP_VERSION_INFO } from './types'
const initialState = {
  currentAppVersionInfo: []
}

const appVersionReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURRENT_APP_VERSION_INFO:
      return { ...state, currentAppVersionInfo: action.payload }
    default:
      return state
  }
}

export default appVersionReducer