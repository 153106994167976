export const ACTIVITY_IMPACT_UNITS = [
    {
        label: 'kg',
        value: 'kg'
    }, {
        label: 'hrs',
        value: 'hrs'
    },
    {
        label: 'litres',
        value: 'litres'
    },
    {
        label: 'km',
        value: 'km'
    }
]

/**
 * #48854 - Web Activity Management
 */
export const ACTIVITY_TYPE = {
    APP: { value: "APP", label: "App (for Challenges)" },
    WEB: { value: "WEB", label: "Web (for Quests)" }
}

export const ACTIVITY_VALIDATION_METHOD = {
    QR_CODE: {
        label: 'Scan QR',
        value: 'QRCODE'
    },
    PIN: {
        label: 'Enter Pin',
        value: 'PIN'
    },
    GPS: {
        label: 'Validate GPS',
        value: 'GPS'
    },
    PHOTO_UPLOAD: {
        label: 'Upload Picture',
        value: 'PHOTOUPLOAD'
    },
    ZOHO_FORM: {
        label: 'Complete Quiz (Zoho Form)',
        value: 'ZOHOFORM'
    },
    ZOHO_SURVEY: {
        label: 'Complete Quiz (Zoho Survey)',
        value: 'ZOHOSURVEY'
    },
    TRACK_STEPS: {
        label: 'Track Steps (Daily)',
        value: 'TRACK_STEPS'
    },
    SHARE_WITH_COMMUNITY: {
        label: 'Share with Community',
        value: 'SHARE_WITH_COMMUNITY'
    }
}

/**
 * #48854 - Web Activity Management
 */
export const ACTIVITY_VALIDATION_METHOD_FOR_WEB = {
    QR_CODE: {
        label: 'Scan QR',
        value: 'QRCODE'
    },
    PIN: {
        label: 'Enter Pin',
        value: 'PIN'
    },
    PHOTO_UPLOAD: {
        label: 'Upload Picture',
        value: 'PHOTOUPLOAD'
    },
    ZOHO_FORM: {
        label: 'Complete Quiz (Zoho Form)',
        value: 'ZOHOFORM'
    },
    ZOHO_SURVEY: {
        label: 'Complete Quiz (Zoho Survey)',
        value: 'ZOHOSURVEY'
    }
}