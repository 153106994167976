export const ILightTableColumns = [
    {
      title: 'No',
      dataIndex: 'srno',
      key: 'srno',
      notAllowToSearch: true
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      notAllowToSearch: true
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id'
      // notAllowToSearch: true
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message'
    },
    {
      title: 'Be a Zero Hero',
      dataIndex: 'zeroHero',
      key: 'zeroHero',
      notAllowToSearch: true
    },
    {
      title: 'Switch Off & Turn Up',
      dataIndex: 'switchOff',
      key: 'switchOff',
      notAllowToSearch: true
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      notAllowToSearch: true
    },
    // {
    //   title: "Is Email Sent",
    //   dataIndex: 'isSentEmail',
    //   key: 'isSentEmail',
    //   notAllowToSearch: true
    // },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      notAllowToSearch: true
    }
  ]