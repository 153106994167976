export const EngagementByCommunityOverviewTableColumns = [
  {
    title: 'Community',
    dataIndex: 'kpi',
    key: 'kpi',
    notAllowToSearch: true
  },
  {
    title: 'Number of Teams',
    dataIndex: 'number_of_teams',
    key: 'number_of_teams'
  },
  {
    title: 'Number of Individuals',
    dataIndex: 'number_of_members',
    key: 'number_of_members'
  }
]
