export const CommunityChallengesTransactionsTableColumns = [
  {
    title: 'No',
    dataIndex: 'srno',
    key: 'srno',
    notAllowToSearch: true
  },
  // {
  //   title: 'Community Id',
  //   dataIndex: 'communityId',
  //   key: 'communityId'
  // },
  {
    title: 'Broadcasting Id',
    dataIndex: 'broadcastingId',
    key: 'broadcastingId'
  },
  {
    title: 'Challenge Name',
    dataIndex: 'challengeName',
    key: 'challengeName'
  },
  {
    title: 'Type',
    dataIndex: 'challengeType',
    key: 'challengeType'
  },
  {
    title: 'Points/Target',
    dataIndex: 'progress',
    key: 'progress'
  },
  {
    title: 'Completion Mode',
    dataIndex: 'completionMode',
    key: 'completionMode'
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt'
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    key: 'updatedAt'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  }
]

export const CHALLENGES_ACTION_STATUS = {
  ACTIVE: { value: "ACTIVE", label: "Active" },
  COMPLETED: { value: "COMPLETED", label: "Completed" },
  CLOSED: { value: "CLOSED", label: "Closed" }
}