import React, { useState, useEffect } from 'react'
import { SearchOutlined, EditOutlined, DeleteOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Input, Table, Tooltip, Popconfirm, Tag, Select } from 'antd'
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllBanners, deleteBannerById } from '../../../store/banner/action'
import PreviewImage from '../../../components/image/PreviewImage'
import { DEFAULT_PAGE_SIZE } from '../../../const/utils'

export const Banners = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const banners = useSelector(state => state.banners?.banners)

    useEffect(() => {
        dispatch(fetchAllBanners())
    }, [])

    const [bannersData, setBannersData] = useState(banners)
    const [wordEntered, setWordEntered] = useState()

    useEffect(() => {
        setBannersData(banners)
    }, [banners])

    const handleSearch = (event) => {
        const searchWord = event?.target?.value || ""
        setWordEntered(searchWord)
        const newFilter = banners.filter((values) => {
            return values?.activityId?.name?.toLowerCase().includes(searchWord.toLowerCase())
            // || values.challengeId.name.toLowerCase().includes(searchWord.toLowerCase()) || values.link.toLowerCase().includes(searchWord.toLowerCase())
        })

        if (searchWord === "") {
            setBannersData(banners)
        } else {
            setBannersData(newFilter)
        }
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'srno',
            key: 'srno'
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            width: '20%'
        },
        {
            title: 'To open',
            dataIndex: 'toopen',
            key: 'toopen'
            // ...getColumnSearchProps('To open')
        },
        {
            title: 'Community',
            dataIndex: 'community',
            key: 'community'
            // ...getColumnSearchProps('community')
        },
        {
            title: 'Display Order',
            dataIndex: 'displayOrder',
            key: 'displayOrder'
            // ...getColumnSearchProps('community')
        },
        {
            title: 'Cities',
            dataIndex: 'city',
            key: 'city'
            // ...getColumnSearchProps('community')
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '5%'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: '120px'
        }
    ]

    const deleteBanner = (id) => {
        dispatch(deleteBannerById(id))
        return new Promise(resolve => {
            setTimeout(() => resolve(), 2000)
        })
    }

    return (
        <div className='bg-white shadow'>
            <PageHeader
                ghost={false}
                title="Banners"
                extra={[<Button icon={<PlusOutlined />} key="btn" type="primary" className="bg-[#207868] flex items-center" onClick={() => navigate('/banners/add')} >New Banner</Button>]}
            ></PageHeader>

            {/* <div className='flex justify-between'>
                <Input
                    className='w-[220px] h-[40px] ml-4 mb-5'
                    placeholder='Search'
                    value={wordEntered}
                    onChange={handleSearch}
                    prefix={<SearchOutlined />}
                />
                <Select
                    // bordered={false}
                    className='w-[180px] h-[40px] mx-4'
                    placeholder='Filter by Type'
                >

                </Select>
            </div> */}

            <Table
                className='z-[0] relative'
                columns={columns}
                dataSource={bannersData?.map((i, it) => ({
                    key: it,
                    srno: ++it,
                    image: <PreviewImage src={i.image} />,
                    toopen: i.activityId?.name || i.link || i.challengeId?.name,
                    community: i.communityIds?.map(i => <Tag className='my-0.5' color='blue' key={i?._id}>{i.name}</Tag>),
                    displayOrder: i?.displayOrder,
                    city: i.cities?.map(k => k.name).join(", "),
                    status: <Tag color={i.publish === true ? 'green' : 'red'}>{i.publish ? 'Published' : 'Not published'}</Tag>,
                    action:
                        <div style={{ minWidth: '65px' }}>
                            <Tooltip title="Edit">
                                <Button style={{ marginRight: "8px" }} onClick={() => navigate((`/banners/edit/${i._id}`))} size="small" type="ghost" shape="circle" icon={<EditOutlined />} />
                            </Tooltip>
                            <Popconfirm
                                title="Are you sure？"
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                onConfirm={() => deleteBanner(i._id)}
                                onCancel={() => null}
                                okText="Yes"
                                okType="danger"
                                placement="topLeft"
                                cancelText="Cancel"
                            >
                                <Button size="small" type="ghost" danger shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </div>
                }))}
                pagination={{
                    showSizeChanger: true,
                    hideOnSinglePage: true,
                    position: ["none", "bottomRight"],
                    defaultPageSize: DEFAULT_PAGE_SIZE
                }}
            />
        </div>
    )
}