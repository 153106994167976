import { FETCH_PNS_QUEUE_LOGS_TRANSACTIONS } from "./types"

const initialState = {
    pnsQueueLogs: []
}

const pnsQueueLogsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PNS_QUEUE_LOGS_TRANSACTIONS:
            return { ...state, pnsQueueLogs: action.payload }
        default:
            return state
    }
}

export default pnsQueueLogsReducer