import React, { useEffect, useState, useRef } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Table, Space, Input, Button, Select, message, DatePicker, Flex } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { QUEST_ACTION_STATUS, QuestTransactionsTableColumns } from './columns'
import { CgArrowsV } from 'react-icons/cg'
import { DEFAULT_PAGE_SIZE, PAGINATION, currentUserRole, showTotalRecords } from '../../../../const/utils'
import moment from 'moment'
import { TRANSACTION_DATE_FORMAT, transformUserIdFormat } from '../../Transactions/Transactions.const'
import { fetchAllQuestsTransactions } from '../../../../store/quests/actions'
import { fetchCommunityQuestStatusTransactionsExportCsvAll, fetchCommunityReportsCommunitiesList, fetchCommunityReportsQuestsList } from '../../../../store/communityReport/action'
import { SUSGAIN_ROLES } from '../../../../const/roles'

/**
 * #50429
 * @returns 
 */
export const QuestsDetailTransactions = () => {
    const [currentPagination, setCurrentPagination] = useState(PAGINATION)

    const dispatch = useDispatch()
    const [userRole, setUserRole] = useState('')
    const myCorporateProfile = useSelector(state => state?.community?.myCorporateProfile)
    const questsTransactions = useSelector(state => state?.quests?.questsTransactions)
    const [selectedCommunity, setSelectedCommunity] = useState(null)
    // const [selectedTeam, setSelectedTeam] = useState(null)
    const [communitiesData, setCommunitiesData] = useState([])
    const filterStatuses = Object.values(QUEST_ACTION_STATUS)
    const communities = useSelector(state => state.communityReport?.communities)
    const [filterDateRange, setFilterDateRange] = useState(null)
    const [filterStatus, setFilterStatus] = useState(null)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [prepairLeaderBoardColumns, setPrepairLeaderBoardColumns] = useState(null)
    const [selectedCommunityObject, setSelectedCommunityObject] = useState(null)
    const [isReadyToGetDefaultData, setIsReadyToGetDefaultData] = useState(false)
    const [defaultCommunity, setDefaultCommunity] = useState(null)
    const quests = useSelector(state => state.communityReport?.quests)
    const [questsData, setQuestsData] = useState([])
    const [selectedQuest, setSelectedQuest] = useState(null)
    const [defaultQuest, setDefaultQuest] = useState(null)
    const hasSelected = selectedRowKeys.length > 0

    useEffect(() => {
        setUserRole(currentUserRole())
        dispatch(fetchCommunityReportsCommunitiesList())
        dispatch(fetchCommunityReportsQuestsList())
        setPrepairLeaderBoardColumns(QuestTransactionsTableColumns)
    }, [])

    const handleStatusFilter = (value) => {
        setFilterStatus(value)
    }

    const _handleSearchAndFilter = (pagination = PAGINATION) => {
        if (selectedCommunity) {
            const newSelectedCommunityObject = communities.filter(i => {
                return i.communityId === selectedCommunity
            })
            setSelectedCommunityObject(newSelectedCommunityObject[0])
        } else {
            setSelectedCommunityObject(null)
        }
        const params = {
            ...pagination
        }
        const _current = JSON.parse(JSON.stringify(currentPagination))
        _current.current = pagination?.current || PAGINATION.current
        _current.pageSize = pagination?.pageSize || PAGINATION.pageSize
        setCurrentPagination(_current)
        if (filterDateRange) {
            params['fromDate'] = moment(new Date(filterDateRange[0])).format(`DD/MM/YYYY`)
            params['toDate'] = moment(new Date(filterDateRange[1])).format(`DD/MM/YYYY`)
        }
        if (filterStatus) {
            params['status'] = filterStatus
        }
        if (selectedCommunity) {
            params['communityId'] = selectedCommunity
        }
        if (selectedQuest) {
            params['questId'] = selectedQuest
        }
        // if (selectedTeam) {
        //     params['teamId'] = selectedTeam
        // }
        dispatch(fetchAllQuestsTransactions(params))
    }

    useEffect(() => {
        if (userRole && communities) {
            if (userRole === SUSGAIN_ROLES.QUEST_LEADER.role) {
                const currentCommunityData = communities.filter((item) => { return item?.communityId === myCorporateProfile?._id })
                setCommunitiesData(currentCommunityData)
                if (currentCommunityData.length > 0) {
                    setDefaultCommunity({ label: currentCommunityData[0]?.communityName, value: currentCommunityData[0]?.communityId })
                } else {
                    setDefaultCommunity({ label: myCorporateProfile?.name, value: myCorporateProfile?._id })
                }
            } else {
                setCommunitiesData(communities)
                setDefaultCommunity(null)
            }
        }
    }, [communities, userRole])

    useEffect(() => {
        if (userRole && quests) {
            setQuestsData(quests)
            if (userRole === SUSGAIN_ROLES.QUEST_LEADER.role) {
                setDefaultQuest({ label: quests[0]?.questName, value: quests[0]?.questId })
            } else {
                setDefaultQuest(null)
            }
        }
    }, [quests, userRole])

    useEffect(() => {
        if (defaultCommunity) {
            setSelectedCommunity(defaultCommunity?.value)
        }
        setIsReadyToGetDefaultData(true)
    }, [defaultCommunity])

    useEffect(() => {
        if (defaultQuest) {
            setSelectedQuest(defaultQuest?.value)
            // setIsReadyToGetDefaultData(true)
        } else {
            setSelectedQuest(null)
            // setIsReadyToGetDefaultData(true)
        }
    }, [defaultQuest])

    useEffect(() => {
        if (isReadyToGetDefaultData) {
            _handleSearchAndFilter()
            setIsReadyToGetDefaultData(false)
        }
    }, [isReadyToGetDefaultData])

    const handleTableChange = (pagination) => {
        _handleSearchAndFilter(pagination)
    }

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const rowSelection = {
        ...selectedRowKeys,
        onChange: onSelectChange
    }

    const _handleExportCsvAll = () => {
        const params = {

        }
        if (selectedCommunity) {
            params['communityId'] = selectedCommunity
        }
        if (filterDateRange) {
            params['fromDate'] = moment(new Date(filterDateRange[0])).format(`DD/MM/YYYY`)
            params['toDate'] = moment(new Date(filterDateRange[1])).format(`DD/MM/YYYY`)
        }
        if (filterStatus) {
            params['status'] = filterStatus
        }
        if (selectedQuest) {
            params['questId'] = selectedQuest
        }
        // if (selectedTeam) {
        //     params['teamId'] = selectedTeam
        // }
        dispatch(fetchCommunityQuestStatusTransactionsExportCsvAll(params))
    }

    const _handleExportCsvSelected = () => {
        // if (selectedRowKeys.length > 0) {
        //     dispatch(fetchCommunityquestsTransactionsExportCsvAll({}, selectedRowKeys))
        // }
    }

    const _handleFromToSelected = (v) => {
        // console.log(`_handleFromToSelected `, v)
        setFilterDateRange(v)
    }


    return (
        <div className='bg-white shadow pb-3'>
            <PageHeader
                ghost={false}
                title={`Quest Status`}
            ></PageHeader>
            <Flex gap="middle">
                <Flex gap="middle" vertical>
                    <Flex gap="middle" className='ml-4 h-[40px]'>
                        {userRole == 'admin' &&
                        <Select
                            showSearch
                            allowClear
                            value={selectedCommunity}
                            disabled={defaultCommunity && userRole != 'admin'}
                            className='w-[300px] h-[40px]'
                            placeholder="Select community"
                            optionFilterProp='label'
                            options={communitiesData?.map(i => ({ label: i.communityName, value: i.communityId }))}
                            suffixIcon={<CgArrowsV />}
                            onChange={(h) => { setSelectedCommunity(h) }}
                        >
                        </Select>}                        
                        <DatePicker.RangePicker onChange={(v) => _handleFromToSelected(v)} />
                    </Flex>
                    <Flex gap="middle" className='mb-4'>
                        <Select
                            showSearch
                            allowClear
                            // bordered={false}
                            value={selectedQuest}
                            className='w-[300px] h-[40px] ml-4'
                            placeholder="Select quest"
                            optionFilterProp='label'
                            options={questsData?.map(i => ({ label: i.questName, value: i.questId }))}
                            suffixIcon={<CgArrowsV />}
                            // disabled={userRole != 'admin'}
                            onChange={(h) => { setSelectedQuest(h) }}
                        />
                        <Select
                            // bordered={false}
                            className='w-[200px] h-[40px]'
                            placeholder='Status'
                            name='status'
                            allowClear
                            onChange={handleStatusFilter}
                            options={filterStatuses.map(i => ({ label: i.label, value: i.value }))}>
                        </Select>
                    </Flex>
                </Flex>
                <Flex gap="middle" className='mb-4'>
                    <Button className='w-[200px] h-[40px]'
                        // disabled={userRole != 'admin'} 
                        onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
                    <Button className='w-[200px] h-[40px] bg-[#207868]' type='primary' disabled={!(questsTransactions)} onClick={_handleExportCsvAll}>
                        {`Export by Current Filter`}
                    </Button>
                </Flex>
            </Flex>
            <Table
                className='z-[0] relative overflow-auto'
                // rowSelection={rowSelection}
                columns={prepairLeaderBoardColumns}
                dataSource={questsTransactions?.result?.map((i, it) => ({
                    key: i?._id,
                    srno: (++it + ((currentPagination?.current - 1) * currentPagination?.pageSize)),
                    userName: `${i?.userData?.name} (${transformUserIdFormat(i?.userData)})`,
                    questName: `${i?.questData?.name}`,
                    email: i?.userData?.email,
                    // communityId: i?.communityId,
                    communityName: i?.communityData?.length > 0 ? i?.communityData[0]['name'] : '-',
                    status: i?.status,
                    createdAt: i?.createdAt && (moment(i?.createdAt).format(TRANSACTION_DATE_FORMAT)),
                    updatedAt: i?.updatedAt && (moment(i?.updatedAt).format(TRANSACTION_DATE_FORMAT)),
                    progress: `${i?.points}/${i?.targetPoints}`,
                    completionMode: i?.windowMode,
                    challengeType: i?.type
                }))}
                pagination={{
                    defaultPageSize: DEFAULT_PAGE_SIZE,
                    showSizeChanger: true,
                    // hideOnSinglePage: true,
                    position: ["none", "bottomRight"],
                    total: questsTransactions?.totalCount,
                    showTotal: showTotalRecords
                }}
                onChange={handleTableChange}
            />
        </div>
    )
}