import React, { useEffect, useState } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Table, Tooltip, Tag } from 'antd'
import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import PreviewImage from '../../../components/image/PreviewImage'
import { fetchAllLocations } from '../../../store/locations/actions'
import { DEFAULT_PAGE_SIZE } from '../../../const/utils'

const Locations = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()


  const locations = useSelector(state => state.locations.locations)

  useEffect(() => {
    dispatch(fetchAllLocations())
  }, [])

  const [locationsData, setLocationsData] = useState(locations)


  useEffect(() => {
    setLocationsData(locations)
  }, [locations])

  const columns = [
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
      // ...getColumnSearchProps('name')
    },
    {
      title: 'Display Order',
      dataIndex: 'display_order',
      key: 'display_order'
      // ...getColumnSearchProps('display_order')
    },
    {
      title: 'Timezone',
      dataIndex: 'timezone',
      key: 'timezone'
      // ...getColumnSearchProps('timezone')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.length - b.status.length,
      sortDirections: ['descend', 'ascend']
      // ...getColumnSearchProps('status')
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: '100px'
    }
  ]

  return (
    <div className='bg-white'>
      <PageHeader
        ghost={false}
        title="Manage Locations"
        extra={[<Button icon={<PlusOutlined />} type='primary' className='bg-[#207868] flex items-center' onClick={() => navigate('/locations/add')} >New Location</Button>]}
      ></PageHeader>
      {/* <Input
        className='w-[220px] h-[40px] ml-4 mb-5'
        placeholder='Search'
        value={wordEntered}
        onChange={handleSearch}
        prefix={<SearchOutlined />}
      /> */}
      <Table
        className='z-[0] relative overflow-auto'
        columns={columns}
        dataSource={
          locationsData.map(i => ({
            key: i._id,
            icon: <PreviewImage key={`${i._id}_prev`} src={i?.icon} size='w-20' />,
            name: i.name,
            timezone: i?.timezone,
            display_order: i.display_order,
            status: <Tag key={`${i._id}_tag`} color={i.publish === true ? 'green' : 'red'}>{i.publish ? 'Published' : 'Not published'}</Tag>,
            action:
              <div key={`${i._id}_ctam`} style={{ minWidth: '65px', textAlign: 'center' }}><Tooltip title="Edit">
                <Button key={`${i._id}_cta`} style={{ marginRight: "8px" }} onClick={() => { navigate((`/locations/edit/${i._id}`)) }} size="small" type="ghost" shape="circle" icon={<EditOutlined />} />
              </Tooltip>
              </div>
          }))}
        pagination={{
          showSizeChanger: true,
          hideOnSinglePage: true,
          position: ["none", "bottomRight"],
          defaultPageSize: DEFAULT_PAGE_SIZE
        }}
      />
    </div>
  )
}

export default Locations

