import { FETCH_BROADCASTINGS_CHALLENGES_LIST, FETCH_BROADCASTINGS_COMMUNITIES_LIST, FETCH_BROADCASTINGS_DATA_LIST } from "./types"
import { toggleNetworkLoading } from "../common/actions"
import { message } from 'antd'
import ServerApi from "../../utility/ServerApi"

/**
 * 
 * @returns 
 */
export const fetchBroadcastingsCommunitiesList = () => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/broadcastings/communities-list`)
        .then(res => {
            dispatch({
                type: FETCH_BROADCASTINGS_COMMUNITIES_LIST,
                payload: res.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fectching Data", e)
            console.error(e)
        })
}

/**
 * 
 * @returns 
 */
export const fetchBroadcastingsChallengesList = () => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/broadcastings/challenges-list`)
        .then(res => {
            dispatch({
                type: FETCH_BROADCASTINGS_CHALLENGES_LIST,
                payload: res.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fectching Data", e)
            console.error(e)
        })
}

export const createBroadcasting = (rawData, successCallback) => dispatch => {
    ServerApi().post(`/broadcastings/add-broadcasting`, rawData)
        .then(res => {
            if (res.status === 200 || res.status === 201) {
                message.success("New item created")
                successCallback(res.data)
            } else {
                message.error("Error creating new item!")
                successCallback(null)
            }
        })
        .catch(e => {
            message.error("Error creating new item", e)
            console.log(e)
        })
}

/**
 * 
 * @param {*} rawData 
 * @param {*} successCallback 
 * @returns 
 */
export const createBroadcastingIndividual = (rawData, successCallback) => dispatch => {
    ServerApi().post(`/broadcastings/add-broadcasting-individual`, rawData)
        .then(res => {
            if (res.status === 200 || res.status === 201) {
                message.success("New item created")
                successCallback(res.data)
            } else {
                message.error("Error creating new item!")
                successCallback(null)
            }
        })
        .catch(e => {
            message.error("Error creating new item", e)
            console.log(e)
        })
}

/**
 * 
 * @returns 
 */
export const fetchBroadcastingDataList = () => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/broadcastings/all`)
        .then(res => {
            dispatch({
                type: FETCH_BROADCASTINGS_DATA_LIST,
                payload: res.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            message.error("Error fetching data!")
            dispatch(toggleNetworkLoading(false))
            console.log(e)
        })
}

// export const updateBanner = (_id, rawData, successCallback) => dispatch => {
//     ServerApi().patch(`banners/${_id}`, rawData)
//         .then(res => {
//             if (res.status === 200 || res.status === 201) {
//                 message.success("Banner updated.")
//             } else {
//                 message.warning("Unable to update Banner.")
//             }
//             dispatch(fetchAllBanners(false))
//             successCallback()
//         })
//         .catch(e => {
//             message.error(e)
//         })
// }

export const deleteBroadcastingItem = (_id, successCallback) => dispatch => {
    ServerApi().delete(`/broadcastings/remove-broadcasting/${_id}`)
        .then(res => {
            message.success("Deleted successfully!")
            successCallback(res.data)
        })
        .catch(e => {
            message.error("Error Deleting")
            console.log(e)
        })
}