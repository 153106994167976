import { FETCH_ALL_MESSAGES, FETCH_MESSAGES_BY_ID } from "./types"

const initialState = {
    messages: [],
    message: {}
}

const messageReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_MESSAGES:
            return { ...state, messages: action.payload }

        case FETCH_MESSAGES_BY_ID:
            return { ...state, message: action.payload }

        default:
            return state
    }
}

export default messageReducer