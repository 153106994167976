import { FETCH_ALL_BANNERS, FETCH_BANNER_BY_ID } from "./types"
import { toggleNetworkLoading } from "../common/actions"
import { message } from 'antd'
import ServerApi from "../../utility/ServerApi"

export const fetchAllBanners = () => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/banners/admin-fetch-all`)
        .then(res => {
            dispatch({
                type: FETCH_ALL_BANNERS,
                payload: res.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fectching Banners", e)
            console.log(e)
        })
}

export const createBanner = (rawData, successCallback) => dispatch => {
    ServerApi().post(`/banners`, rawData)
        .then(res => {
            if (res.status === 200 || res.status === 201) {
                message.success("New Banner Created")
                successCallback(res.data)
            } else {
                message.error("Error Creating Banner!")
            }
            successCallback()
        })
        .catch(e => {
            message.error("Error Creating New Banner", e)
            console.log(e)
        })
}

export const fetchBannerById = (id) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/banners/admin-fetch-detail/${id}`)
        .then(res => {
            const data = res.data
            dispatch({
                type: FETCH_BANNER_BY_ID,
                payload: data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            message.error("Error fetching data!")
            dispatch(toggleNetworkLoading(false))
            console.log(e)
        })
}

export const updateBanner = (_id, rawData, successCallback) => dispatch => {
    ServerApi().patch(`banners/${_id}`, rawData)
        .then(res => {
            if (res.status === 200 || res.status === 201) {
                message.success("Banner updated.")
            } else {
                message.warning("Unable to update Banner.")
            }
            dispatch(fetchAllBanners())
            successCallback()
        })
        .catch(e => {
            message.error(e)
        })
}

export const deleteBannerById = (id) => dispatch => {
    ServerApi().delete(`banners/${id}`)
        .then(res => {
            message.success("Banner Deleted successfully!")
            dispatch(fetchAllBanners())
        })
        .catch(e => {
            message.error("Error Deleting")
            console.log(e)
        })
}