import { FETCH_COMMON_CONFIGURATION, NETWORK_LOADING } from "./types"
import ServerApi from "../../utility/ServerApi"

/**
 * 
 * @param {Boolean} data - The state of loading 
 */
export const toggleNetworkLoading = (data) => dispatch => {
  dispatch({
    type: NETWORK_LOADING,
    payload: data
  })
}

/**
 * 
 * @returns 
 */
export const fetchCommonConfiguration = () => dispatch => {  
  ServerApi().get(
    `/common-configuration`)
    .then(res => {
      dispatch({
        type: FETCH_COMMON_CONFIGURATION,
        payload: res.data
      })
    })
    .catch(e => {
      console.error(e)
    })
}