import { FETCH_ALL_MESSAGES, FETCH_MESSAGES_BY_ID } from "./types"
import { toggleNetworkLoading } from "../common/actions"
import { message } from 'antd'
import ServerApi from "../../utility/ServerApi"

export const fetchAllMessagings = () => dispatch => {
  dispatch(toggleNetworkLoading(true))
  ServerApi().get(`/messagings`)
    .then(res => {
      dispatch({
        type: FETCH_ALL_MESSAGES,
        payload: res.data
      })
      dispatch(toggleNetworkLoading(false))
    })
    .catch(e => {
      dispatch(toggleNetworkLoading(false))
      message.error("Error in Fecthing Messagings Details", e)
      console.log(e)
    })
}

export const createMessaging = (rawData, successCallback) => dispatch => {
  ServerApi().post(`/messagings`, rawData)
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        message.success("New Message Created")
        successCallback(res.data)
      } else {
        message.error("Error Creating Message!")
      }
      successCallback()
    })
    .catch(e => {
      message.error("Error Creating New Message", e)
      console.log(e)
    })
}

export const fetchMessagingById = (id) => dispatch => {
  dispatch(toggleNetworkLoading(true))
  ServerApi().get(`/messagings/${id}`)
    .then(res => {
      const data = res.data
      dispatch({
        type: FETCH_MESSAGES_BY_ID,
        payload: data
      })
      dispatch(toggleNetworkLoading(false))
    })
    .catch(e => {
      message.error("Error fetching data!")
      dispatch(toggleNetworkLoading(false))
      console.log(e)
    })
}

export const updateMessaging = (_id, rawData, successCallback) => dispatch => {
  ServerApi().patch(`messagings/${_id}`, rawData)
    .then(res => {

      if (res.status === 200 || res.status === 201) {
        message.success("Message updated.")
      } else {
        message.warning("Unable to update Message.")
      }
      dispatch(fetchAllMessagings())
      successCallback()
    })
    .catch(e => {
      message.error(e)
    })
}

export const deleteMessagingById = (id) => dispatch => {
  ServerApi().delete(`messagings/${id}`)
    .then(res => {
      message.success("Message Deleted Succesfully!")
      dispatch(fetchAllMessagings())
    })
    .catch(e => {
      message.error("Error Deleting")
      console.log(e)
    })
}

