import { FETCH_PNS_QUEUE_LOGS_TRANSACTIONS } from "./types"
import { toggleNetworkLoading } from "../common/actions"
import { message } from 'antd'
import ServerApi from "../../utility/ServerApi"
import { PAGINATION } from "../../const/utils"

export const fetchPNSQueueLogsTransactions = (filters = PAGINATION, callback = null) => dispatch => {
    console.log(`fetchPNSQueueLogsTransactions filters `, filters)
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/pns-queue/queue-logs?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`)
        .then(res => {
            dispatch({
                type: FETCH_PNS_QUEUE_LOGS_TRANSACTIONS,
                payload: res.data
            })
            if (callback) callback(res.data)
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in  fetchPNSQueueLogsTransactions", e)
            console.log(e)
        })
} 