import { FETCH_ALL_REWARDS, FETCH_ALL_REWARDS_FOR_QUEST } from "./types"

const initialState = {
    rewards: []
}

const rewardReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_REWARDS:
            return { ...state, rewards: action.payload }
        case FETCH_ALL_REWARDS_FOR_QUEST:
            return { ...state, rewards: action.payload }
        default:
            return state
    }
}

export default rewardReducer