import React, { useRef, useState, useEffect } from 'react'
import { Card, Form, Input, Button, Space, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { forgotPassward, validateResetPasswordToken } from '../../../store/auth/actions'
const { Text, Link, Title } = Typography

export const ResetPassword = () => {

    // const { userId } = useParams()
    const { token } = useParams()
    const [viewMode, setViewMode] = useState('') //reset, error, success

    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const navigate = useNavigate() 

    const successCallback = () => {
        // navigate('/')
        setViewMode('success')
    }

    const successValidateTokenCallback = () => {
        setViewMode('reset')
    }

    const _errorResetpasswordCallback = () => {
        setViewMode('error')
    }

    const _validateResetPasswordToken = (_token) => {
        const data = {
            token: { token }.token
        }
        // console.log(`validateResetPasswordToken `, data)
        dispatch(validateResetPasswordToken(data, successValidateTokenCallback, _errorResetpasswordCallback))
    }

    const resetThisPassword = (values) => {
        const data = {
            token: { token }.token,
            // userId: { userId }.userId,
            newPassword: values.newPassword,
            confirmPassword: values.confirmPassword
        }
        // console.log(data)
        dispatch(forgotPassward(data, successCallback, _errorResetpasswordCallback))
    }

    const _renderErrorView = () => {
        return (
            <Space direction="vertical">
                <Title level={4}>Token Expired</Title>
                <Text type="danger">The request token is no longer valid. We can not reset your password from here.</Text>
            </Space>
        )
    }
    const _renderSuccess = () => {
        return (
            <Space direction="vertical">
                <Title level={4}>Password Reset</Title>
                <Text>Your password has been reset successfully. Please try <a href="https://zva00.app.link/nSgNsCGozub">log in</a> again.</Text>
            </Space>
        )
    }

    useEffect(() => { 
        _validateResetPasswordToken(token)
    }, [token])

    const _renderResetForm = () => {
        return (
            <Card title='Reset Password' style={{ width: 550 }}>
                <Form
                    name='ChangePassword'
                    form={form}
                    labelCol={{
                        span: 4
                    }}
                    onFinish={(values) => resetThisPassword(values)}
                    autoComplete='off'
                >
                    <Form.Item
                        label='New Password'
                        labelCol={{
                            span: 7
                        }}
                        labelAlign='left'
                        autoComplete='off'
                        name='newPassword'
                        rules={[
                            { required: true, message: 'Please enter your New Password!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword').length > 5) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(new Error('Password too SHORT!'))
                                }
                            })
                        ]}
                    >
                        <Input type='password' />
                    </Form.Item>
                    <Form.Item
                        label='Confirm Password'
                        labelCol={{
                            span: 7
                        }}
                        labelAlign='left'
                        name='confirmPassword'
                        rules={[
                            { required: true, message: 'Please confirm your password!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve()
                                    }

                                    return Promise.reject(new Error('The two passwords that you entered do not match!'))
                                }
                            })
                        ]}
                    >
                        <Input type='password' />
                    </Form.Item>

                    <div className="text-center pt-2 pb-4">
                        <Button className="bg-[#207868] px-8" size="large" type="primary" htmlType="submit">
                            Save
                        </Button>
                    </div>

                </Form>
            </Card>
        )
    }

    return (
        <div className='flex justify-center items-center'>
            {viewMode == 'reset' && _renderResetForm()}
            {viewMode == 'error' && _renderErrorView()}
            {viewMode == 'success' && _renderSuccess()}
        </div>
    )
}
