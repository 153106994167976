import React, { useRef, useState, useEffect } from 'react'
import { SearchOutlined, EyeOutlined, DeleteOutlined, PlusOutlined, QuestionCircleOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Input, Space, Table, Tooltip, Popconfirm, Tag, Checkbox, DatePicker, Select, Flex } from 'antd'
import { CgArrowsV } from 'react-icons/cg'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { CommunityScoringTableColumns } from './Transactions.columns'
import { COMMUNITY_TRANSACTION_STATUS, exportFormat, TRANSACTION_DATE_FORMAT, transformUserIdFormat } from './Transactions.const'
import { exportCommunityScoringTransactionsAsCSV, fetchCommunityScoringTransactions, handleRejectThisScoringTransaction } from '../../../store/transactions/scoringTransactions/actions'
import { DEFAULT_PAGE_SIZE, PAGINATION, showTotalRecords } from '../../../const/utils'
import { fetchBroadcastingsCommunitiesList } from '../../../store/broadcasting/action'

export const CommunityScoringTransactions = () => {

  const dispatch = useDispatch()
  const communities = useSelector(state => state.broadcasting?.communities)
  const [currentPagination, setCurrentPagination] = useState(PAGINATION)
  const transactions = useSelector(state => state.scoringTransactions.communityScoringTransactions)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [communitiesData, setCommunitiesData] = useState([])
  const [filterDateRange, setFilterDateRange] = useState(null)
  const [filterStatus, setFilterStatus] = useState(null)
  const [selectedCommunity, setSelectedCommunity] = useState(null)
  const [wordEntered, setWordEntered] = useState()
  const [selectedTeam, setSelectedTeam] = useState(null)
  const [teamsData, setTeamsData] = useState([])
  const [userRole, setUserRole] = useState('')
  const filterStatuses = Object.values(COMMUNITY_TRANSACTION_STATUS).filter(i => i.value !== 'PENDING')
  const [defaultCommunity, setDefaultCommunity] = useState(null)
  const myCorporateProfile = useSelector(state => state?.community?.myCorporateProfile)

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
    // console.log(newSelectedRowKeys)
  }

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("userData"))
    const { role } = userData?.user
    setUserRole(role)
    dispatch(fetchBroadcastingsCommunitiesList())
  }, [])

  useEffect(() => {
    if (userRole === 'community_leader') {
      const currentCommunityData = communities.filter((item) => { return item?.communityId === myCorporateProfile?._id })
      setCommunitiesData(currentCommunityData)
      if (currentCommunityData.length > 0) {
        setDefaultCommunity({ label: currentCommunityData[0]?.communityName, value: currentCommunityData[0]?.communityId })
      } else {
        setDefaultCommunity({ label: myCorporateProfile?.name, value: myCorporateProfile?._id })
      }
    } else {
      setCommunitiesData(communities)
    }
  }, [communities, userRole])
  //This will prevent API call 3 times when page is loaded first time
  // }, [userRole])


  useEffect(() => {
    setSelectedTeam(null)
    if (selectedCommunity) {
      const selectedCommunityData = communitiesData.filter((item) => {
        return item?.communityId === selectedCommunity
      })
      if (selectedCommunityData) {
        setTeamsData(selectedCommunityData[0]?.teams)
      } else {
        setTeamsData([])
      }
    } else {
      setTeamsData([])
    }
  }, [selectedCommunity])

  const rowSelection = {
    ...selectedRowKeys,
    // ...selectedRows,
    onChange: onSelectChange
  }
  // const searchInput = useRef(null)

  const [prepairChallengeRewardsColumns, setPrepairChallengeRewardsColumns] = useState(null)


  useEffect(() => {
    dispatch(fetchCommunityScoringTransactions())
    const newChallengeRewardsTableColumns = CommunityScoringTableColumns.map((item) => {
      if (item.notAllowToSearch === true) {
        return item
      } else {
        return {
          ...item
          // ...getColumnSearchProps(item['dataIndex'])
        }
      }
    })
    setPrepairChallengeRewardsColumns(newChallengeRewardsTableColumns)
  }, [])

  const _handleActivityTransactionsExportCsvAll = () => {
    const params = {}
    if (!!wordEntered) {
      params['search'] = wordEntered.toLowerCase()
    }
    if (filterDateRange) {
      params['fromDate'] = moment(new Date(filterDateRange[0])).format(`DD/MM/YYYY`)
      params['toDate'] = moment(new Date(filterDateRange[1])).format(`DD/MM/YYYY`)
    }
    if (filterStatus) {
      params['status'] = filterStatus
    }
    if (selectedCommunity) {
      params['communityId'] = selectedCommunity?.value ? `${selectedCommunity?.value}` : `${selectedCommunity}`
    }
    if (selectedTeam) {
      params['teamId'] = selectedTeam
    }
    dispatch(exportCommunityScoringTransactionsAsCSV(params))
  }

  /**
   * Export selected records
   */
  const _handleActivityTransactionsExportCsvSelected = () => {
    if (selectedRowKeys.length > 0) {
      dispatch(exportCommunityScoringTransactionsAsCSV({}, selectedRowKeys))
    } else {
      // //export from current result
      // if (transactions?.result.length > 0) {
      //   const filterIds = transactions?.result.map(e => e._id)
      //   dispatch(exportCommunityScoringTransactionsAsCSV(filterIds))
      // }
    }
  }

  const handleStatusFilter = (value) => {
    // console.log(value)
    setFilterStatus(value)
  }

  const _handleSearchAndFilter = (pagination = PAGINATION) => {
    const params = {
      ...pagination
    }
    const _current = JSON.parse(JSON.stringify(currentPagination))
    _current.current = pagination?.current || PAGINATION.current
    _current.pageSize = pagination?.pageSize || PAGINATION.pageSize
    setCurrentPagination(_current)
    if (!!wordEntered) {
      params['search'] = wordEntered.toLowerCase()
    }
    if (filterDateRange) {
      params['fromDate'] = moment(new Date(filterDateRange[0])).format(`DD/MM/YYYY`)
      params['toDate'] = moment(new Date(filterDateRange[1])).format(`DD/MM/YYYY`)
    }
    if (filterStatus) {
      params['status'] = filterStatus
    }
    if (selectedCommunity) {
      params['communityId'] = selectedCommunity?.value ? `${selectedCommunity?.value}` : `${selectedCommunity}`
    }
    if (selectedTeam) {
      params['teamId'] = selectedTeam
    }
    dispatch(fetchCommunityScoringTransactions(params))
  }

  const handleTableChange = (pagination) => {
    // dispatch(fetchActivityTransactions(pagination))
    _handleSearchAndFilter(pagination)
  }
  const _handleFromToSelected = (v) => {
    // console.log(`_handleFromToSelected `, v)
    setFilterDateRange(v)
  }

  const hasSelected = selectedRowKeys.length > 0// || (transactions && transactions?.result && transactions?.result?.length > 0)

  const _successCallback = (e) => {
    _handleSearchAndFilter()
  }

  useEffect(() => {
    if (defaultCommunity) {
      setSelectedCommunity(defaultCommunity?.value)
    }
  }, [defaultCommunity])

  const _successCallbackRejectThisTransaction = (e) => {
    _handleSearchAndFilter()
  }

  /**
   * #44868 - 5 May - URGENT - UBS Challenge duplicated rewards
   */
  const _handleRejectTransactionsSelected = () => {
    if (selectedRowKeys.length > 0) {
      dispatch(handleRejectThisScoringTransaction(selectedRowKeys, _successCallbackRejectThisTransaction))
    } else {

    }
  }

  /**
   * #45721 - Reward Transaction - add option to allow the admin reject the transaction
   * @param {*} _id 
   */
  const _handleRejectThisTransaction = (_id) => {
    dispatch(handleRejectThisScoringTransaction([_id], _successCallbackRejectThisTransaction))
  }

  /**
   * #45721 - Reward Transaction - add option to allow the admin reject the transaction
   * @param {*} item 
   * @returns 
   */
  const _rewardAction = (item) => {
    if (item?.status !== 'REJECTED') {
      return (
        <div style={{ minWidth: '65px' }}>
          <Popconfirm
            title="Are you sure to reject this transaction?"
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm={() => _handleRejectThisTransaction(item._id)}
            onCancel={() => null}
            okText="Yes"
            okType="danger"
            placement="topLeft"
            cancelText="Cancel"
          >
            <Tooltip title="Reject this transaction">
              <Button size="small" type="text" danger icon={<DeleteOutlined danger />}>Reject</Button>
            </Tooltip>
          </Popconfirm>
        </div>
      )
    }
    return <></>
  }

  return (
    <div className='bg-white shadow pb-3'>
      <PageHeader
        ghost={false}
        title="Community Scoring Transaction"
      ></PageHeader>
      <Flex gap="middle">
        <Flex gap="middle" vertical>
          <Flex gap="middle" className='ml-4'>
            <Select
              showSearch
              allowClear
              value={selectedCommunity}
              disabled={defaultCommunity}
              className='w-[300px] h-[40px]'
              // style={{ width: '100%' }}
              placeholder="Select community"
              optionFilterProp='label'
              options={communitiesData?.map(i => ({ label: i.communityName, value: i.communityId }))}
              suffixIcon={<CgArrowsV />}
              onChange={(h) => { setSelectedCommunity(h) }}
            >
            </Select>
            <DatePicker.RangePicker onChange={(v) => _handleFromToSelected(v)} />
          </Flex>
          <Flex gap="middle" className='ml-4'>
            <Select
              showSearch
              value={selectedTeam}
              allowClear
              className='w-[300px] h-[40px]'
              placeholder="Select Teams"
              optionFilterProp='label'
              options={teamsData?.map(i => ({ label: i.teamName, value: i.teamId }))}
              suffixIcon={<CgArrowsV />}
              onChange={(h) => { setSelectedTeam(h) }}
            >
            </Select>
            <Select
              // bordered={false}
              className='w-[200px] h-[40px]'
              placeholder='Status'
              name='status'
              allowClear
              onChange={handleStatusFilter}
              options={filterStatuses.map(i => ({ label: i.label, value: i.value }))}
            />
          </Flex>
        </Flex>
        <Flex gap="middle" vertical className='mb-4'>
          <Flex gap="middle">
            <Button className='w-[200px] h-[40px]' onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
            <Button className='w-[200px] h-[40px] bg-[#207868]' type='primary' disabled={!(transactions?.result)} onClick={_handleActivityTransactionsExportCsvAll}>
              {`Export by Current Filter`}
            </Button>
          </Flex>
          <Flex gap="middle">
            <Button className='w-[200px] h-[40px] border-[#207868] text-[#207868]' disabled={!hasSelected} onClick={_handleActivityTransactionsExportCsvSelected}>
              {`Export Selected`}
            </Button>
            <Popconfirm
              title="Are you sure to reject selected transactions?"
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              onConfirm={_handleRejectTransactionsSelected}
              onCancel={() => null}
              okText="Yes"
              okType="danger"
              placement="topLeft"
              cancelText="Cancel"
            >
              <Button danger className='w-[200px] h-[40px] text-[#207868]' disabled={!hasSelected}>
                {`Reject Selected`}
              </Button>
            </Popconfirm>
          </Flex>
        </Flex>
      </Flex>
      <Table
        className='z-[0] relative overflow-auto'
        rowSelection={rowSelection}
        columns={prepairChallengeRewardsColumns}
        dataSource={transactions?.result?.map((i, it) => ({
          key: i?._id,
          srno: (++it + ((currentPagination?.current - 1) * currentPagination?.pageSize)),
          // transactionId: i?.transactionId,
          submittedData: i?.createdAt && (moment(i?.createdAt).format(TRANSACTION_DATE_FORMAT)),
          userId: transformUserIdFormat(i?.userProfile),
          email: i?.userProfile?.email,
          fullname: i?.userProfile?.name,
          communityName: i?.communityData?.name || '-',
          teamName: i?.teamData?.name || '-',
          points: i?.points,
          // impactType: i?.impactType,
          // rewardSource: i?.rewardSource,
          remarks: i?.remarks,
          status: <Tag color={!i?.status || i?.status === 'DONE' || i?.status === 'SUCCESS' ? 'green' : 'red'}>{i?.status || 'SUCCESS'}</Tag>,
          action: _rewardAction(i)
        }))}
        pagination={{
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          hideOnSinglePage: true,
          position: ["none", "bottomRight"],
          total: transactions?.totalCount,
          showTotal: showTotalRecords
        }}
        onChange={handleTableChange}
      />
    </div >
  )
}