import React, { useEffect, useState, useRef } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Table, Space, Input, Button, Select, Tooltip, Flex } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { fetchLeaderBoardMembers, fetchCommunityReportsCommunitiesList, fetchCommunityDetailTransactionsExportCsvAll } from '../../../../../store/communityReport/action'
import { LeaderBoardMembersTableColumns } from './columns'
import { SearchOutlined } from '@ant-design/icons'
import { CgArrowsV } from 'react-icons/cg'
import ManualAddRemovePointsModal from './ManualAddRemovePointsModal'
import { adminManualUpdatePointsTeamMembers } from '../../../../../store/teams/actions'
import { transformUserIdFormat } from '../../../Transactions/Transactions.const'
import { showTotalRecords } from '../../../../../const/utils'

export const LeaderBoardByMembers = () => {
    const DEFAULT_PAGE_SIZE = 100
    const dispatch = useDispatch()
    const [userRole, setUserRole] = useState('')
    const myCorporateProfile = useSelector(state => state?.community?.myCorporateProfile)
    const membersLeaderBoard = useSelector(state => state?.communityReport?.membersLeaderBoard)
    const [selectedCommunity, setSelectedCommunity] = useState(null)
    const [manualAddRemovePointsMode, setManualAddRemovePointsMode] = useState(null)
    const [visible, setVisible] = useState(false)
    const [selectedTeam, setSelectedTeam] = useState(null)
    const [communitiesData, setCommunitiesData] = useState([])
    const [teamsData, setTeamsData] = useState([])
    const [defaultCommunity, setDefaultCommunity] = useState(null)
    const [isReadyToGetDefaultData, setIsReadyToGetDefaultData] = useState(false)


    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [prepairLeaderBoardColumns, setPrepairLeaderBoardColumns] = useState(null)
    const [prepairMemberDataLeaderBoard, setPrepairMemberDataLeaderBoard] = useState([])
    const communities = useSelector(state => state.communityReport?.communities)

    const [selectedCommunityObject, setSelectedCommunityObject] = useState(null)

    const handleColumnSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
    }

    const handleReset = (clearFilters) => {
        clearFilters()
    }

    const searchInput = useRef(null)

    useEffect(() => {
        if (membersLeaderBoard) {
            let temptCount = 1
            const prepairData = membersLeaderBoard.map((teamItem) => {
                const tableData = {
                    key: teamItem?._id,
                    srno: temptCount,
                    name: teamItem?.userInfo?.name,
                    email: teamItem?.userInfo?.email,
                    points: teamItem?.totalPoints,
                    personalPoints: teamItem?.userInfo?.points,
                    teamName: teamItem?.teamName,
                    communityName: teamItem?.communityInfo?.name,
                    userId: transformUserIdFormat(teamItem?.userInfo),
                    exportKey: teamItem?.userInfo?._id
                }
                temptCount += 1
                return tableData
            })
            setPrepairMemberDataLeaderBoard(prepairData)
        } else {
            setPrepairMemberDataLeaderBoard([])
        }
    }, [membersLeaderBoard])

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block'
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        className='mx-2 bg-[#207868]'
                        onClick={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined
                }}
            />
        ),
        onFilter: (value, record) => {
            if (record[dataIndex]) {
                return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            } else {
                return false
            }

        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        }
    })

    useEffect(() => {
        const userData = JSON.parse(sessionStorage.getItem("userData"))
        const { role } = userData?.user
        setUserRole(role)
        dispatch(fetchCommunityReportsCommunitiesList())

        const newLeaderBoardMembersColumns = LeaderBoardMembersTableColumns.map((item) => {
            if (item.notAllowToSearch === true) {
                return item
            } else {
                return {
                    ...item,
                    ...getColumnSearchProps(item['dataIndex'])
                }
            }
        })
        setPrepairLeaderBoardColumns(newLeaderBoardMembersColumns)

    }, [])

    const _handleSearchAndFilter = () => {
        if (selectedCommunity) {
            const newSelectedCommunityObject = communities.filter(i => {
                return i.communityId === selectedCommunity
            })
            setSelectedCommunityObject(newSelectedCommunityObject[0])
            dispatch(fetchLeaderBoardMembers({ communityId: selectedCommunity, teamId: selectedTeam }))
        } else {
            setSelectedCommunityObject(null)
            dispatch(fetchLeaderBoardMembers())
        }
    }

    useEffect(() => {
        if (defaultCommunity) {
            setSelectedCommunity(defaultCommunity?.value)
            setIsReadyToGetDefaultData(true)
        } else {
            setSelectedCommunity(null)
            // setIsReadyToGetDefaultData(true)
        }
    }, [defaultCommunity])

    useEffect(() => {
        if (isReadyToGetDefaultData) {
            _handleSearchAndFilter()
            setIsReadyToGetDefaultData(false)
        }
    }, [isReadyToGetDefaultData])

    useEffect(() => {
        setSelectedTeam(null)
        if (selectedCommunity) {
            const selectedCommunityData = communitiesData.filter((item) => {
                return item?.communityId === selectedCommunity
            })
            if (selectedCommunityData) {
                setTeamsData(selectedCommunityData[0]?.teams)
            } else {
                setTeamsData([])
            }
        } else {
            setTeamsData([])
        }
    }, [selectedCommunity])


    useEffect(() => {
        if (userRole === 'community_leader') {
            const currentCommunityData = communities.filter((item) => { return item?.communityId === myCorporateProfile?._id })
            setCommunitiesData(currentCommunityData)
            if (currentCommunityData.length > 0) {
                setDefaultCommunity({ label: currentCommunityData[0]?.communityName, value: currentCommunityData[0]?.communityId })
            } else {
                setDefaultCommunity({ label: myCorporateProfile?.name, value: myCorporateProfile?._id })
            }
        } else {
            setCommunitiesData(communities)
            // setDefaultCommunity(null)
            setDefaultCommunity({ label: communities[0]?.communityName, value: communities[0]?.communityId })
        }
    }, [communities, userRole])
    //This will prevent API call 3 times when page is loaded first time
    // }, [userRole])

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }


    const rowSelection = {
        ...selectedRowKeys,
        onChange: onSelectChange
    }

    const handleTableChange = (pagination) => {
        _handleSearchAndFilter()
    }

    const _handleManualAddPointsTeamMembers = () => {
        setManualAddRemovePointsMode('add')
        setVisible(true)
    }

    const _handleDeductPointsTeamMembers = () => {
        setManualAddRemovePointsMode('remove')
        setVisible(true)
    }

    const _successCallback = (e) => {
        _handleSearchAndFilter()
    }

    /**
     * #44934 - We encounter another issue related to Challenge Rewards for UBS - extra pts rewards does not count for each members
     * @param {*} params 
     */
    const _onConfirmAddRemovePoints = (params) => {
        //TODO
        const _params = {
            communityId: selectedCommunity,
            teamId: selectedTeam,
            mode: params.mode,
            points: params.points
        }
        dispatch(adminManualUpdatePointsTeamMembers(_params, _successCallback))
    }


    // const _handleExportCsvAll = () => {
    //     const params = {

    //     }
    //     if (selectedCommunity) {
    //         params['communityId'] = selectedCommunity
    //     }
    //     if (selectedTeam) {
    //         params['teamId'] = selectedTeam
    //     }
    //     dispatch(exportLeaderBoardMember(params))
    // }

    // const _handleExportCsvSelected = () => {
    //     if (selectedRowKeys.length > 0) {
    //         const newSelectedRowKeys = []
    //         for (let i_select = 0; i_select <= selectedRowKeys.length; i_select++) {
    //             for (let i_data = 0; i_data <= prepairMemberDataLeaderBoard.length; i_data++) {
    //                 if (selectedRowKeys[i_select] === prepairMemberDataLeaderBoard[i_data]?.key) {
    //                     if (prepairMemberDataLeaderBoard[i_data]?.exportKey) {
    //                         newSelectedRowKeys.push(prepairMemberDataLeaderBoard[i_data]?.exportKey)
    //                         break
    //                     }
    //                 }
    //             }
    //         }
    //         dispatch(exportLeaderBoardMember(newSelectedRowKeys))
    //     } else {
    //         //export from current result
    //         if (membersLeaderBoard.length > 0) {
    //             const filterIds = membersLeaderBoard.map(e => e._id)
    //             dispatch(exportLeaderBoardMember(filterIds))
    //         }
    //     }
    // }

    const _handleExportAllDetailTransactionsCsvSelected = () => {
        const params = {

        }
        if (selectedCommunity) {
            params['communityId'] = selectedCommunity
            dispatch(fetchCommunityDetailTransactionsExportCsvAll(params))
        } else {

        }
    }

    // const hasSelected = selectedRowKeys.length > 0

    return (
        <div className='bg-white shadow pb-3'>
            <PageHeader
                ghost={false}
                title={`Leaderboard - By Member: ${selectedCommunityObject ? selectedCommunityObject?.communityName : ''}`}
            ></PageHeader>
            <Flex gap="middle">
                <Flex gap="middle" vertical>
                    <Flex gap="middle">
                        <Select
                            showSearch
                            allowClear
                            value={selectedCommunity}
                            disabled={userRole != 'admin'}
                            className='w-[300px] h-[40px] ml-4'
                            placeholder="Select community"
                            optionFilterProp='label'
                            options={communitiesData?.map(i => ({ label: i.communityName, value: i.communityId }))}
                            suffixIcon={<CgArrowsV />}
                            onChange={(h) => { setSelectedCommunity(h) }}
                        >
                        </Select>
                        <Select
                            showSearch
                            value={selectedTeam}
                            allowClear
                            className='w-[300px] h-[40px]'
                            placeholder="Select Teams"
                            optionFilterProp='label'
                            options={teamsData?.map(i => ({ label: i.teamName, value: i.teamId }))}
                            suffixIcon={<CgArrowsV />}
                            onChange={(h) => { setSelectedTeam(h) }}
                        >
                        </Select>
                    </Flex>
                </Flex>
                <Flex gap="middle" className='mb-4' vertical>
                    <Flex gap="middle">
                        <Button className='w-[200px] h-[40px]' onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
                    </Flex>
                    <Flex gap="middle">
                        <Tooltip title={'Add the points for every single member personal profile of the teams. This will also count towards community / team points.'}>
                            <Button className='w-[200px] h-[40px]' onClick={_handleManualAddPointsTeamMembers} disabled={!selectedTeam || !selectedCommunity}>Add Points</Button>
                        </Tooltip>
                        <Button className='w-[200px] h-[40px] bg-[#207868]' type='primary' disabled={!selectedCommunity} onClick={_handleExportAllDetailTransactionsCsvSelected}>
                            {`Export Detail Transactions`}
                        </Button>
                    </Flex>
                </Flex>
            </Flex>
            <Table
                className='z-[0] relative overflow-auto'
                // rowSelection={rowSelection}
                columns={prepairLeaderBoardColumns}
                dataSource={prepairMemberDataLeaderBoard}
                pagination={{
                    defaultPageSize: DEFAULT_PAGE_SIZE,
                    showSizeChanger: true,
                    // hideOnSinglePage: true,
                    position: ["none", "bottomRight"],
                    total: membersLeaderBoard?.totalCount,
                    showTotal: showTotalRecords
                }}
                onChange={handleTableChange}
            />

            <ManualAddRemovePointsModal
                mode={manualAddRemovePointsMode}
                onConfirm={_onConfirmAddRemovePoints}
                visible={visible}
                setVisible={setVisible}
            // refresh={successUpdateCallback}
            />
        </div>
    )
}