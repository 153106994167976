import { FETCH_ALL_CHALLENGES, FETCH_ALL_CHALLENGES_AVAIL_FOR_SETUP_COMMUNITY, FETCH_CHALLENGE_BY_ID, FETCH_ALL_CHALLENGE_FOR_MESSAGING } from "./types"
import { toggleNetworkLoading } from "../common/actions"
import { message } from 'antd'
import ServerApi from "../../utility/ServerApi"
import { PAGINATION } from "../../const/utils"

export const fetchAllChallenges = (filters = PAGINATION) => dispatch => {
  dispatch(toggleNetworkLoading(true))
  ServerApi().get(
    `/challenges/admin-fetch-all?current=${filters.current || 1}&pageSize=${filters.pageSize || 100}
    ${filters?.search ? `&search=${encodeURIComponent(filters?.search)}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.cityId ? `&cityId=${filters?.cityId}` : ''}
    `)
    .then(res => {
      dispatch({
        type: FETCH_ALL_CHALLENGES,
        payload: res.data
      })
      dispatch(toggleNetworkLoading(false))
    })
    .catch(e => {
      dispatch(toggleNetworkLoading(false))
      message.error("Error in Fecthing Challenges Details", e)
      // console.log(e)
    })
}

export const fetchAllChallengesForMessaging = () => dispatch => {
  dispatch(toggleNetworkLoading(true))
  ServerApi().get(
    `/challenges/admin-all-challenges-for-messagings`)
    .then(res => {
      dispatch({
        type: FETCH_ALL_CHALLENGE_FOR_MESSAGING,
        payload: res?.data?.result
      })
      dispatch(toggleNetworkLoading(false))
    })
    .catch(e => {
      dispatch(toggleNetworkLoading(false))
      message.error("Error in Fecthing Challenges Details", e)
      console.log(e)
    })
}

/**
 * 
 * @param {*} filters 
 * @returns 
 */
export const fetchAllChallengesAvailableForSetupCommunity = (filters = PAGINATION) => dispatch => {
  // dispatch(toggleNetworkLoading(true))
  ServerApi().get(
    `/challenges/admin-fetch-all-for-create-edit-community?${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}`)
    .then(res => {
      dispatch({
        type: FETCH_ALL_CHALLENGES_AVAIL_FOR_SETUP_COMMUNITY,
        payload: res.data
      })
      // dispatch(toggleNetworkLoading(false))
    })
    .catch(e => {
      // dispatch(toggleNetworkLoading(false))
      // message.error("Error in Fecthing Challenges Details", e)
      console.log(e)
    })
}

export const createChallenge = (rawData, successCallback) => dispatch => {
  ServerApi().post(`/challenges`, rawData)
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        message.success("New Challenge Created")
        successCallback(res.data)
      } else {
        message.error("Error Creating Challenge!")
      }
      successCallback()
    })
    .catch(e => {
      message.error("Error Creating New Challenge", e)
      console.log(e)
    })
}

export const fetchChallengeById = (id) => dispatch => {
  dispatch(toggleNetworkLoading(true))
  ServerApi().get(`/challenges/${id}`)
    .then(res => {
      const data = res.data
      dispatch({
        type: FETCH_CHALLENGE_BY_ID,
        payload: data
      })
      dispatch(toggleNetworkLoading(false))
    })
    .catch(e => {
      message.error("Error fetching data!")
      dispatch(toggleNetworkLoading(false))
      console.log(e)
    })
}

export const updateChallenge = (_id, rawData, successCallback) => dispatch => {
  ServerApi().patch(`challenges/${_id}`, rawData)
    .then(res => {

      if (res.status === 200 || res.status === 201) {
        message.success("Challenge updated.")
      } else {
        message.warning("Unable to update Challenge.")
      }
      dispatch(fetchAllChallenges(false))
      successCallback()
    })
    .catch(e => {
      message.error(e)
    })
}

export const deleteChallengeById = (id) => dispatch => {
  ServerApi().delete(`challenges/${id}`)
    .then(res => {
      message.success("Challenge Deleted Succesfully!")
      dispatch(fetchAllChallenges())
    })
    .catch(e => {
      message.error(e?.response?.data?.message || "Error Deleting")
      console.log(e)
    })
}

