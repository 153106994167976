export const LeaderBoardTeamsTableColumns = [
    // {
    //     title: 'No',
    //     dataIndex: 'srno',
    //     key: 'srno',
    //     notAllowToSearch: true
    // },
    {
        title: 'Name of Team',
        dataIndex: 'teamName',
        key: 'teamName',
        notAllowToSearch: true
    },
    {
        title: 'Community Name',
        dataIndex: 'communityName',
        key: 'communityName',
        notAllowToSearch: true
    },
    {
        title: 'Points',
        dataIndex: 'totalPoints',
        key: 'totalPoints',
        notAllowToSearch: true
    }
    
]