import moment from 'moment'

export const SDGS = [
    { value: "1", label: "No Poverty" },
    { value: "2", label: "Zero Hunger" },
    { value: "3", label: "Good Health & Wellbeing" },
    { value: "5", label: "Quality Education" },
    { value: "6", label: "Clean Water and Sanitation" },
    { value: "7", label: "Affordable and Clean Energy" },
    { value: "10", label: "Climate Action" },
    { value: "11", label: "Sustainable Cities and Communities" },
    { value: "12", label: "Responsible Consumption & Production" },
    { value: "13", label: "Climate Action" },
    { value: "14", label: "Life Below Water" },
    { value: "15", label: "Life on Land" }
]

export const Events = [
    { value: "Invite", label: "Invite" },
    { value: "Accept", label: "Accept" },
    { value: "Complete first activity", label: "Complete first activity" },
    { value: "Leave for a long time", label: "Leave for a long time" },
    { value: "Complete challenge", label: "Complete challenge" }
]

export const GoalTypes = [
    { value: "ImpactReward", label: "Impact Reward" },
    { value: "Challenge", label: "Challenge" },
    { value: "Activity", label: "Activity" }
]

export const TimeZone = moment.tz.names().map((item) => {
    return {
        value: item,
        label: item
    }
})
export const GoalTypesForCreateNewCommunity = [{ value: "ImpactReward", label: "Impact Reward" }]

export const ImpactTypes = [
    {
        value: "tree", //value set for rewards in impact rewards in Community detail, same as value set for rewards in collection rewardlists
        label: "Plant Trees"
    },
    {
        value: "meal",
        label: "Donate Meal"
    },
    {
        value: "trash_remove",
        label: "Remove Trash"
    }
]