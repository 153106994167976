// import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import SemiCircleProgressBar from "react-progressbar-semicircle"
import { Card, Form, Input, Button, Space, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
const { Text, Link, Title } = Typography

const rx = 85.5
const ry = 83
const strokeWidth = 15
const whSVG = { w: (rx * 2) + strokeWidth, h: ry + strokeWidth - 5 + 10 }
// const cx = strokeWidth + rx - 5
// const circumference = 2 * Math.PI * Math.sqrt(((rx * rx) + (ry * ry)) / 2)

// const inputRangeFrom0To100 = [0, 100]
// const outputRangeFrom0To100 = [circumference, circumference / 2]

/**
 * #43313 - 1. Community Goals
 * @param {*} param0 
 * @returns 
 */
export function CommunityGoalStatisticEllipseComponent({ e, i }) {
    const _color = i == 0 ? '#005725' : i == 1 ? '#5280C7' : '#C55B11'
    const _strokeColor = (i == 0 ? 'rgba(0, 87, 37,0.2)' : (i == 1 ? 'rgba(82, 128, 199,0.2)' : 'rgba(197, 91, 17,0.2)'))
    return (
        <div
            style={{
                paddingTop: 0,
                minWidth: whSVG.w,
                height: whSVG.h,
                position: 'relative'
                // background: 'green'
            }}>
            <div
                style={{
                    // background: 'blue',
                    flex: 1,
                    // paddingTop: 0,
                    // minWidth: whSVG.w,
                    height: whSVG.h,
                    alignSelf: 'center',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                <div style={{
                    position: 'relative'
                }}>
                    <SemiCircleProgressBar
                        strokeLinecap={"round"} 
                        strokeLinejoin={"round"} 
                        background={_strokeColor} 
                        strokeWidth={strokeWidth} 
                        percentage={e.progress_pct} 
                        stroke={_color} 
                        direction={'right'}/>
                    <Text
                        style={{
                            color: _color,
                            fontSize: 40,
                            lineHeight: 1,
                            position: 'absolute',
                            // top: '50%',
                            left: '50%',
                            bottom: 0,
                            // marginTop: 20,
                            transform: 'translate(-50%, 0)',
                            // fontFamily: FONTS.Poppins600,
                            includeFontPadding: false
                        }}>
                        {e?.progress_desc}
                    </Text>
                </div>
                {/* <div style={{
                    // position: 'absolute',
                    bottom: 0,
                    alignSelf: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // flex: 1,
                    display: 'flex',
                    // background: 'red',
                    flexDirection: 'row'
                }}>
                    <Text
                        style={{
                            color: _color,
                            fontSize: 15,
                            // fontFamily: FONTS.Poppins400,
                            includeFontPadding: false,
                            textAlign: 'center'
                        }}>
                        {e?.goalName}
                    </Text>
                </div> */}
            </div>
        </div>
    )
}