import { FETCH_ZOHO_TRANSACTIONS, FETCH_ZOHO_TRANSACTION_BY_ID } from "./types"

const initialState = {
    zohoTransactions: [],
    zohoTransaction: {}
}

const zohoReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ZOHO_TRANSACTIONS:
            return { ...state, zohoTransactions: action.payload }
        case FETCH_ZOHO_TRANSACTION_BY_ID:
            return { ...state, zohoTransaction: action.payload }
        default:
            return state
    }
}

export default zohoReducer