export const ZohoActionsTableColumns = [
  {
    title: 'No',
    dataIndex: 'srno',
    key: 'srno',
    notAllowToSearch: true
  },
  {
    title: 'Activity Name',
    dataIndex: 'activityName',
    key: 'activityName'
  },
  {
    title: 'Type',
    dataIndex: 'zohoType',
    key: 'zohoType'
  },
  
  {
    title: 'Full Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Callback Data',
    dataIndex: 'callbackData',
    key: 'callbackData'
  },
  {
    title: 'Submitted Date',
    dataIndex: 'submittedData',
    key: 'submittedData',
    notAllowToSearch: true
  }
  // {
  //   title: 'Action',
  //   dataIndex: 'action',
  //   key: 'action',
  //   fixed: 'right',
  //   width: '120px',
  //   notAllowToSearch: true
  // }
]