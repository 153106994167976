import React, { useEffect, useState, useRef } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Table, Space, Input, Button, Select, message, Flex } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { fetchLeaderBoardTeams, exportLeaderBoardTeams, fetchCommunityReportsCommunitiesList } from '../../../../../store/communityReport/action'
import { LeaderBoardTeamsTableColumns } from './columns'
import { SearchOutlined } from '@ant-design/icons'
import { CgArrowsV } from 'react-icons/cg'
import { showTotalRecords } from '../../../../../const/utils'


export const LeaderBoardByTeams = () => {
    const DEFAULT_PAGE_SIZE = 100
    const PAGINATION = { current: 1, pageSize: DEFAULT_PAGE_SIZE }
    const [currentPagination, setCurrentPagination] = useState(PAGINATION)

    const dispatch = useDispatch()
    const [userRole, setUserRole] = useState('')
    const myCorporateProfile = useSelector(state => state?.community?.myCorporateProfile)
    const teamsLeaderBoard = useSelector(state => state?.communityReport?.teamsLeaderBoard)
    const [selectedCommunity, setSelectedCommunity] = useState(null)
    const [isReadyToGetDefaultData, setIsReadyToGetDefaultData] = useState(false)
    const [selectedTeam, setSelectedTeam] = useState(null)
    const [communitiesData, setCommunitiesData] = useState([])
    const [teamsData, setTeamsData] = useState([])
    const communities = useSelector(state => state.communityReport?.communities)

    const [defaultCommunity, setDefaultCommunity] = useState(null)

    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [prepairLeaderBoardColumns, setPrepairLeaderBoardColumns] = useState(null)
    const [selectedCommunityObject, setSelectedCommunityObject] = useState(null)


    const handleColumnSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
    }

    const handleReset = (clearFilters) => {
        clearFilters()
    }

    const searchInput = useRef(null)

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block'
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        className='mx-2 bg-[#207868]'
                        onClick={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined
                }}
            />
        ),
        onFilter: (value, record) => {
            if (record[dataIndex]) {
                return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            } else {
                return false
            }

        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        }
    })

    const hasSelected = selectedRowKeys.length > 0

    useEffect(() => {
        const userData = JSON.parse(sessionStorage.getItem("userData"))
        const { role } = userData?.user
        setUserRole(role)
        dispatch(fetchCommunityReportsCommunitiesList())

        const newLeaderBoardTeamsColumns = LeaderBoardTeamsTableColumns.map((item) => {
            if (item.notAllowToSearch === true) {
                return item
            } else {
                return {
                    ...item,
                    ...getColumnSearchProps(item['dataIndex'])
                }
            }
        })
        setPrepairLeaderBoardColumns(newLeaderBoardTeamsColumns)
    }, [])

    useEffect(() => {
        setSelectedTeam(null)
        if (selectedCommunity) {
            const selectedCommunityData = communitiesData.filter((item) => {
                return item?.communityId === selectedCommunity
            })
            if (selectedCommunityData) {
                setTeamsData(selectedCommunityData[0]?.teams)
            } else {
                setTeamsData([])
            }
        } else {
            setTeamsData([])
        }
    }, [selectedCommunity])


    const _handleSearchAndFilter = () => {
        if (selectedCommunity) {
            const newSelectedCommunityObject = communities.filter(i => {
                return i.communityId === selectedCommunity
            })
            setSelectedCommunityObject(newSelectedCommunityObject[0])
            dispatch(fetchLeaderBoardTeams({ communityId: selectedCommunity, teamId: selectedTeam }))
        } else {
            setSelectedCommunityObject(null)
            dispatch(fetchLeaderBoardTeams())
        }
    }


    useEffect(() => {
        if (defaultCommunity) {
            setSelectedCommunity(defaultCommunity?.value)
            setIsReadyToGetDefaultData(true)
        } else {
            setSelectedCommunity(null)
            // setIsReadyToGetDefaultData(true)
        }
    }, [defaultCommunity])


    useEffect(() => {
        if (isReadyToGetDefaultData) {
            _handleSearchAndFilter()
            setIsReadyToGetDefaultData(false)
        }
    }, [isReadyToGetDefaultData])


    useEffect(() => {
        if (userRole && communities) {
            if (userRole === 'community_leader') {
                const currentCommunityData = communities.filter((item) => { return item?.communityId === myCorporateProfile?._id })
                setCommunitiesData(currentCommunityData)
                if (currentCommunityData.length > 0) {
                    setDefaultCommunity({ label: currentCommunityData[0]?.communityName, value: currentCommunityData[0]?.communityId })
                } else {
                    setDefaultCommunity({ label: myCorporateProfile?.name, value: myCorporateProfile?._id })
                }
            } else {
                setCommunitiesData(communities)
                setDefaultCommunity({ label: communities[0]?.communityName, value: communities[0]?.communityId })
            }
        }
        // }, [communities, userRole])
        //This will prevent API call 3 times when page is loaded first time
    }, [userRole])


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const rowSelection = {
        ...selectedRowKeys,
        onChange: onSelectChange
    }

    const _handleExportCsvAll = () => {
        const params = {

        }
        if (selectedCommunity) {
            params['communityId'] = selectedCommunity
        }
        if (selectedTeam) {
            params['teamId'] = selectedTeam
        }
        dispatch(exportLeaderBoardTeams(params))
    }

    const _handleExportCsvSelected = () => {
        if (selectedRowKeys.length > 0) {
            dispatch(exportLeaderBoardTeams(selectedRowKeys))
        } else {
            //export from current result
            if (teamsLeaderBoard.length > 0) {
                const filterIds = teamsLeaderBoard.map(e => e._id)
                dispatch(exportLeaderBoardTeams(filterIds))
            }
        }
    }


    return (
        <div className='bg-white shadow pb-3'>
            <PageHeader
                ghost={false}
                title={`Leaderboard - By Team: ${selectedCommunityObject ? selectedCommunityObject?.communityName : ''}`}
            ></PageHeader>
            <Flex gap="middle">
                <Flex gap="middle" vertical>
                    <Flex gap="middle">
                        <Select
                            showSearch
                            allowClear
                            value={selectedCommunity}
                            disabled={userRole != 'admin'}
                            className='w-[300px] h-[40px] ml-4'
                            placeholder="Select community"
                            optionFilterProp='label'
                            options={communitiesData?.map(i => ({ label: i.communityName, value: i.communityId }))}
                            suffixIcon={<CgArrowsV />}
                            onChange={(h) => { setSelectedCommunity(h) }}
                        >
                        </Select>
                        <Select
                            showSearch
                            value={selectedTeam}
                            allowClear
                            className='w-[300px] h-[40px]'
                            placeholder="Select Teams"
                            optionFilterProp='label'
                            options={teamsData?.map(i => ({ label: i.teamName, value: i.teamId }))}
                            suffixIcon={<CgArrowsV />}
                            onChange={(h) => { setSelectedTeam(h) }}
                        >
                        </Select>
                    </Flex>
                </Flex>
                <Flex gap="middle" className='mb-4'>
                    <Button className='w-[200px] h-[40px]'
                        onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
                    {/* <Button className='mx-2 border-[#207868] text-[#207868]' disabled={!hasSelected} onClick={_handleExportCsvSelected}>
                            {`Export Selected`}
                        </Button> */}
                    <Button className='w-[200px] h-[40px] bg-[#207868]' type='primary' disabled={!(teamsLeaderBoard)} onClick={_handleExportCsvAll}>
                        {`Export by Current Filter`}
                    </Button>
                </Flex>
            </Flex> 
            <Table
                className='z-[0] relative overflow-auto'
                // rowSelection={rowSelection}
                columns={prepairLeaderBoardColumns}
                dataSource={teamsLeaderBoard?.map((i, it) => {
                    return {
                        key: i?.key,
                        // srno: (++it + ((currentPagination?.current - 1) * currentPagination?.pageSize)),
                        teamName: i?.teamName,
                        totalPoints: i?.totalPoints,
                        communityName: i?.community?.name
                    }
                })
                }
                pagination={{
                    defaultPageSize: DEFAULT_PAGE_SIZE,
                    showSizeChanger: true,
                    // hideOnSinglePage: true,
                    position: ["none", "bottomRight"],
                    total: teamsLeaderBoard?.totalCount,
                    showTotal: showTotalRecords
                }}
            />
        </div>
    )
}