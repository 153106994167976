import { Events } from '../../../const/sdgs.const'
export default [
    {
        panel_title: 'Push Content',
        form_item: [
            {
                type: 'input',
                label: 'Push Title',
                name: 'title',
                rules: [{ required: true, message: 'Please enter Push Title' }],
                hasFeedback: true,
                placeholder: 'Enter Push Title'
            },
            {
                type: 'text-area',
                label: 'Push Message',
                name: 'body',
                rules: [{ required: true, message: 'Please enter Push Message' }],
                hasFeedback: true,
                placeholder: 'Enter Push Message'
            },
            {
                type: 'file',
                label: 'Push Image',
                name: 'image',
                rules: [{ required: true, message: 'Please upload Push Image' }],
                hasFeedback: true,
                placeholder: 'Upload Push Image'
                // extra: 'Title, message and push image will be auto populated from Activity name and description and icon.'
            }
        ]
    },
    {
        panel_title: 'Push Target',
        panel_name: "push_target",
        form_item_radio: [
            {
                type: 'radio',
                form_inner_type: 'span',
                inner_span: 'To XX active users',
                label: 'To All User',
                name: 'toAllUser',
                hasFeedback: true,
                placeholder: 'Enter Push Title',
                enum_value: "TO_ALL_USERS"
            },
            {
                type: 'radio',
                form_inner_type: 'select',
                label: 'To Selected Users',
                name: 'selectedUsers',
                hasFeedback: true,
                options: Events,
                placeholder: 'Choose selected users',
                enum_value: "TO_SELECTED_USERS"
            },
            {
                type: 'radio',
                form_inner_type: 'select',
                label: 'To Communities',
                name: 'communities',
                hasFeedback: true,
                options: Events,
                placeholder: 'Choose communities',
                enum_value: "TO_USERS_IN_SELECTED_COMMUNITIES"
            },
            {
                type: 'radio',
                form_inner_type: 'select',
                label: 'To Teams',
                name: 'teams',
                hasFeedback: true,
                options: Events,
                placeholder: 'Choose teams',
                enum_value: "TO_USERS_IN_SELECTED_TEAMS"
            },
            {
                type: 'radio',
                form_inner_type: 'select',
                label: 'To Users by Locations',
                name: 'locations',
                hasFeedback: true,
                options: Events,
                placeholder: 'Choose locations',
                enum_value: "TO_USERS_IN_SELECTED_LOCATIONS"
            },
            {
                type: 'radio',
                form_inner_type: 'file',
                label: 'To Users Via CSV',
                name: 'csv',
                hasFeedback: true,
                options: Events,
                placeholder: 'Upload Push Image',
                enum_value: "TO_CSV_USERS"
            }
        ]
    },
    {
        panel_title: 'Push Action',
        panel_name: "push_action",
        form_item_radio: [
            {
                type: 'radio',
                form_inner_type: 'select',
                label: 'Open Activity',
                name: 'activity',
                hasFeedback: true,
                placeholder: 'Enter Activity',
                enum_value: "Open Activity"
            },
            {
                type: 'radio',
                form_inner_type: 'select',
                label: 'Open Challenge',
                name: 'challenge',
                hasFeedback: true,
                options: Events,
                placeholder: 'Select Challenge',
                enum_value: "Open Challenge"
            },
            {
                type: 'radio',
                form_inner_type: 'select',
                label: 'Open Habit',
                name: 'habit',
                hasFeedback: true,
                options: Events,
                placeholder: 'Select Habit',
                enum_value: "Open Habit"

            },
            
            {
                type: 'input',
                form_inner_type: 'input',
                label: 'Open Link',
                name: 'openLink',
                hasFeedback: true,
                options: Events,
                placeholder: 'Enter Link',
                enum_value: "Open Link"
            },
            {
                type: 'radio',
                form_inner_type: 'select',  
                label: 'Section',
                name: 'section',
                hasFeedback: true,
                options: Events,
                placeholder: 'Select selection',
                enum_value: "Open Section"
            }
        ]
    }
]