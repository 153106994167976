export const PNSQueueLogsTableColumns = [
  {
    title: 'No',
    dataIndex: 'srno',
    key: 'srno',
    notAllowToSearch: true
  },
  {
    title: 'User ID',
    dataIndex: 'userId',
    key: 'userId'
  },
  {
    title: 'Full Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  // {
  //   title: 'Push Type',
  //   dataIndex: 'push_type',
  //   key: 'push_type'
  // },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'Message',
    dataIndex: 'message',
    key: 'message'
  },
  {
    title: 'Timezone',
    dataIndex: 'timezone',
    key: 'timezone'
  },
  {
    title: 'Time',
    dataIndex: 'time_of_message',
    key: 'time_of_message'
  },
  {
    title: 'Payload',
    dataIndex: 'payload',
    key: 'payload',
    notAllowToSearch: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    notAllowToSearch: true
  },
  {
    title: 'Created Date',
    dataIndex: 'createdDate',
    key: 'createdDate',
    notAllowToSearch: true
  },
  {
    title: 'Updated Date',
    dataIndex: 'updatedDate',
    key: 'updatedDate',
    notAllowToSearch: true
  }
]

export const PNS_QUEUE_LOGS_STATUS = {
  PENDING: { value: "PENDING", label: "Pending" },
  SENT: { value: "SENT", label: "Sent" },
  SKIP: { value: "SKIP", label: "Skip" }
}