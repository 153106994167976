import { FETCH_TEAMS_LEADERBOARD, FETCH_MEMBERS_LEADERBOARD, FETCH_ENGAGEMENT_BY_COMMUNITY, FETCH_ENGAGEMENT_BY_TEAM_JOINING_RATE, FETCH_IMPACT_BY_ACTIVITIES, FETCH_IMPACT_BY_GOALS, FETCH_IMPACT_BY_HABITS, FETCH_IMPACT_BY_REWARDS, FETCH_ENGAGEMENT_BY_ACTIVITY_KPIS, FETCH_ENGAGEMENT_BY_PARTICIPATION_KPIS, FETCH_COMMUNITY_REPORTS_COMMUNITIES_LIST, FETCH_COMMUNITY_CHALLENGES_TRANSACTIONS, FETCH_COMMUNITY_REPORTS_QUESTS_LIST } from "./types"

const initialState = {
    teamsLeaderBoard: null,
    membersLeaderBoard: null,
    engagementByCommunity: null,
    engagementByTeamJoiningRate: null,
    engagementByParticipationKPIs: null,
    impactByActivities: null,
    impactByGoals: null,
    impactByHabits: null,
    impactByRewards: null,
    engagementByActivityKpis: null,
    communities: [],
    quests: [],
    challengesTransactions: []
}

const communityReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_COMMUNITY_CHALLENGES_TRANSACTIONS:
            return { ...state, challengesTransactions: action.payload }
        case FETCH_COMMUNITY_REPORTS_COMMUNITIES_LIST:
            return { ...state, communities: action.payload }
        case FETCH_COMMUNITY_REPORTS_QUESTS_LIST:
            return { ...state, quests: action.payload }
        case FETCH_TEAMS_LEADERBOARD:
            return { ...state, teamsLeaderBoard: action.payload }
        case FETCH_MEMBERS_LEADERBOARD:
            return { ...state, membersLeaderBoard: action.payload }
        case FETCH_ENGAGEMENT_BY_COMMUNITY:
            return { ...state, engagementByCommunity: action.payload }
        case FETCH_ENGAGEMENT_BY_TEAM_JOINING_RATE:
            return { ...state, engagementByTeamJoiningRate: action.payload }
        case FETCH_IMPACT_BY_ACTIVITIES:
            return { ...state, impactByActivities: action.payload }
        case FETCH_IMPACT_BY_GOALS:
            return { ...state, impactByGoals: action.payload }
        case FETCH_IMPACT_BY_HABITS:
            return { ...state, impactByHabits: action.payload }
        case FETCH_IMPACT_BY_REWARDS:
            return { ...state, impactByRewards: action.payload }
        case FETCH_ENGAGEMENT_BY_PARTICIPATION_KPIS:
            return { ...state, engagementByParticipationKPIs: action.payload }
        case FETCH_ENGAGEMENT_BY_ACTIVITY_KPIS:
            return { ...state, engagementByActivityKpis: action.payload }
        default:
            return state
    }
}

export default communityReportReducer