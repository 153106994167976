import React, { useState, useEffect } from 'react'
import { Collapse, Button, Form, Input, Typography, Table, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
// import { useNavigate, Link } from 'react-router-dom'
import { inviteMember, SearchUser } from '../../../../store/users/action'
// import { fetchAllCommunity } from '../../../../store/community/actions'
// import { useCSVReader } from 'react-papaparse'

const AddMembers = (props) => {

    // const [form] = Form.useForm()
    const [modalForm] = Form.useForm()
    // const navigate = useNavigate()
    const dispatch = useDispatch()

    const [userExist, setUserExist] = useState(false)
    const [formSubmitState, setFormSubmitState] = useState(false)

    const searchUserCallback = (data) => {
        if (data && data[0]) {
            setUserExist(true)
            modalForm.setFieldsValue({
                name: data[0]['name'] || 'N/A'
            })
        } else {
            setUserExist(false)
        }
    }

    const resetModal = () => {
        setUserExist(false)
        setFormSubmitState(false)
        modalForm.setFieldsValue({ email: null, name: null })
        props.refresh()
        props.setVisible(false)
    }

    const handleNewMemberSubmit = (values) => {
        if (userExist || formSubmitState) {
            dispatch(inviteMember({ teamId: props.teamId, email: values.email, name: values.name }))
            resetModal()
            return
        }

        setFormSubmitState(true)
        dispatch(SearchUser(values.email, searchUserCallback))

    }

    return (
        <Modal
            open={props.visible}
            onCancel={() => resetModal()}
            footer={""}
        >
            <div className='text-center mb-6'>
                <Typography className='text-xl font-bold'>Add Team Member</Typography>
                {userExist && formSubmitState ? <Typography>Search for existing account by email address</Typography> : formSubmitState ? <Typography>Add existing account or enter new email and name to invite new<br />member to the team </Typography> : <></>}
            </div>
            <Form
                form={modalForm}
                name='Add_Team_Member'
                labelCol={{ span: 4 }}
                onFinish={(values) => handleNewMemberSubmit(values)}
                autoComplete='off'
            >
                <Form.Item
                    labelAlign='left'
                    label='Email'
                    name='email'
                    rules={[{ type: 'email', message: 'Invalid Email' }, { required: true, message: 'Please input your E-mail!' }]}
                >
                    <Input disabled={userExist || formSubmitState} />
                </Form.Item>

                {(userExist && formSubmitState) ? (<>
                    <Form.Item
                        labelAlign='left'
                        label='Name'
                        name='name'
                    >
                        <Input disabled />
                    </Form.Item>

                </>) : (formSubmitState ? <>
                    <Form.Item
                        labelAlign='left'
                        label='Name'
                        name='name'
                    >
                        <Input />
                    </Form.Item>
                </> : <></>
                )
                }

                <div className='flex justify-center'>
                    <Button className="bg-[#207868] px-6" type="primary" htmlType="submit">
                        {formSubmitState ? <p>Invite</p> : <p>Search</p>}
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}

export default AddMembers