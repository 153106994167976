import { FETCH_QUEST_TRANSACTIONS, FETCH_QUEST_TRANSACTION_BY_ID } from "./types"

const initialState = {
    transactions: [],
    transaction: {}
}

const questTransactionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_QUEST_TRANSACTIONS:
            return { ...state, transactions: action.payload }
        case FETCH_QUEST_TRANSACTION_BY_ID:
            return { ...state, transaction: action.payload }
        default:
            return state
    }
}

export default questTransactionsReducer