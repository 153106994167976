export const EngagementByTeamJoiningRateTableColumns = [
  {
    title: 'Team',
    dataIndex: 'teamName',
    key: 'teamName',
    notAllowToSearch: true
  },
  {
    title: 'Invited',
    dataIndex: 'number_of_members_invited',
    key: 'number_of_members_invited'
  },
  {
    title: 'Joined',
    dataIndex: 'number_of_members_joined',
    key: 'number_of_members_joined'
  },
  {
    title: '% Joined',
    dataIndex: 'pct_joined',
    key: 'pct_joined'
  }
]
