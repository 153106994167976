import { FETCH_ALL_GOALS, FETCH_GOAL_BY_ID, FETCH_ALL_HABITS_FOR_MESSAGING } from "./types"

const initialState = {
    goals: [],
    goal: {},
    habitsForMessaging: []
}

const goalReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_GOALS:
            return { ...state, goals: action.payload }
        case FETCH_GOAL_BY_ID:
            return { ...state, goal: action.payload }
        case FETCH_ALL_HABITS_FOR_MESSAGING:
            return { ...state, habitsForMessaging: action.payload }
        default:
            return state
    }
}

export default goalReducer