import { SearchOutlined, EditOutlined, DeleteOutlined, PlusOutlined, QuestionCircleOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Input, Space, Table, Tooltip, Popconfirm, Tag, Select, message, Flex } from 'antd'
import { useNavigate } from "react-router-dom"
import React, { useRef, useState, useEffect } from 'react'
import { fetchAllActivities, deleteActivityById } from '../../../store/activity/actions'
import { fetchAllCommunity } from '../../../store/community/actions'
import { useSelector, useDispatch } from 'react-redux'
import CopyToClipboard from 'react-copy-to-clipboard'
// import ServerApi from '../../../utility/ServerApi'
import moment from 'moment'
// import * as dayjs from 'dayjs'
import { ACTIVITY_TYPE, ACTIVITY_VALIDATION_METHOD } from './Activity.const'
import { BE_SHORT_DATE_FORMAT, DEFAULT_PAGE_SIZE } from '../../../const/utils'
import { fetchAllLocations } from '../../../store/locations/actions'

export const Activity = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const activities = useSelector(state => state.activity.activities)
    const communities = useSelector(state => state.community.communities)
    const locations = useSelector(state => state.locations.locations)
    const [activitiesData, setActivitiesData] = useState(activities?.result)
    const [locationsData, setLocationsData] = useState(locations)
    // const [searchText, setSearchText] = useState('')
    // const [searchedColumn, setSearchedColumn] = useState('')
    // const searchInput = useRef(null)
    const [wordEntered, setWordEntered] = useState()
    // const [communities, setCommunitites] = useState([])
    const [communityId, setCommunityId] = useState()
    const [cityId, setCityId] = useState()
    const [activityType, setActivityType] = useState()

    useEffect(() => {
        dispatch(fetchAllActivities({ current: 1, pageSize: 10000 }))
        dispatch(fetchAllLocations())
        dispatch(fetchAllCommunity({ current: 1, pageSize: 10000 }))
    }, [])

    useEffect(() => {
        setActivitiesData(activities?.result)
    }, [activities])

    // useEffect(() => {
    //     const _param = {}
    //     if (wordEntered !== '') {
    //         _param['search'] = wordEntered?.toLowerCase()
    //     }
    //     if (communityId !== null && communityId !== '') {
    //         _param['communityId'] = communityId
    //     }
    //     if (cityId !== null && cityId !== '') {
    //         _param['cityId'] = cityId
    //     }
    //     dispatch(fetchAllActivities(_param))
    // }, [communityId, cityId, wordEntered])

    // const handleColumnSearch = (selectedKeys, confirm, dataIndex) => {
    //     confirm()
    //     setSearchText(selectedKeys[0])
    //     setSearchedColumn(dataIndex)
    // }

    const handleSearch = (event) => {
        const searchWord = event.target.value
        setWordEntered(searchWord)
        // if (searchWord === '') {
        //     dispatch(fetchAllActivities())
        //     return
        // }
        // dispatch(fetchAllActivities({ search: searchWord.toLowerCase() }))
    }

    const handleCommunityFilter = (id) => {
        setCommunityId(id)
    }

    const handleCityFilter = (id) => {
        setCityId(id)
    }

    const handleActivityTypeFilter = (id) => {
        setActivityType(id)
    }

    const handleReset = (clearFilters) => {
        clearFilters()
        setSearchText('')
    }

    const columns = [
        {
            title: 'No.',
            dataIndex: 'srno',
            key: 'srno'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
            // ...getColumnSearchProps('name')
        },
        {
            title: 'Type',
            dataIndex: 'activityType',
            key: 'activityType'
            // ...getColumnSearchProps('name')
        },
        {
            title: 'City',
            dataIndex: 'city',
            key: 'city'
            // ...getColumnSearchProps('city')
        },
        {
            title: 'Community',
            dataIndex: 'community',
            key: 'community'
            // ...getColumnSearchProps('community')
        },
        {
            title: 'Goal/Habit',
            dataIndex: 'goal',
            key: 'goal'
            // ...getColumnSearchProps('goal')
        },
        {
            title: 'Validation Method',
            dataIndex: 'validation',
            key: 'validation'
            // ...getColumnSearchProps('validation')
        },
        {
            title: 'Point',
            dataIndex: 'point',
            key: 'point'
            // ...getColumnSearchProps('point')
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => a.status.length - b.status.length,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Share Link',
            dataIndex: 'link',
            key: 'link'
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            fixed: 'right',
            key: 'action',
            width: '120px'
        }
    ]

    const deleteActivity = (id) => {
        dispatch(deleteActivityById(id))
        return new Promise(resolve => {
            setTimeout(() => resolve(), 2000)
        })
    }


    const _transformValidationMethod = (_method) => {
        try {
            return Object.values(ACTIVITY_VALIDATION_METHOD).filter(i => i.value == _method)[0].label
        } catch (e) {

        }
        return _method
    }

    const _renderActivityName = (i) => {
        if (i.thisIsEvent) {
            return (
                <><Tag color="#87d068">Event</Tag>{i.name}</>
            )
        } else {
            return <>{i.name}</>
        }
    }

    const _handleSearchAndFilter = (pagination = PAGINATION) => {
        const _param = {}
        if (wordEntered !== '') {
            _param['search'] = wordEntered?.toLowerCase()
        }
        if (communityId !== null && communityId !== '') {
            _param['communityId'] = communityId
        }
        if (cityId !== null && cityId !== '') {
            _param['cityId'] = cityId
        }
        if (activityType !== null && activityType !== '') {
            _param['activityType'] = activityType
        }
        dispatch(fetchAllActivities(_param))
    }

    return (
        <div className='bg-white shadow'>
            <PageHeader
                ghost={false}
                title="Manage Activity"
                extra={[<Button icon={<PlusOutlined />} key="btn" type="primary" className="bg-[#207868]" onClick={() => navigate('/activities/add')} >New Activity</Button>]}
            ></PageHeader>
            <Flex gap="middle">
                <Flex gap="middle" vertical className='mb-4 ml-4'>
                    <Flex gap="middle">
                        <Input
                            className='w-[300px] h-[40px]'
                            placeholder='Search by name'
                            value={wordEntered}
                            onChange={handleSearch}
                            prefix={<SearchOutlined />}
                        />
                        <Select
                            className='w-[300px] h-[40px]'
                            placeholder='Filter by Type'
                            optionFilterProp='label'
                            options={Object.values(ACTIVITY_TYPE).map(i => ({ label: i.label, value: i.value }))}
                            onChange={handleActivityTypeFilter}
                            allowClear
                        />
                    </Flex>
                    <Flex gap="middle">
                        <Select
                            className='w-[300px] h-[40px]'
                            placeholder='Filter by City'
                            optionFilterProp='label'
                            options={locationsData?.map(i => ({ label: i.name, value: i._id }))}
                            onChange={handleCityFilter}
                            allowClear
                        />
                        <Select
                            className='w-[300px] h-[40px]'
                            placeholder='Filter by Community'
                            optionFilterProp='label'
                            options={communities.result?.map(i => ({ label: i.name, value: i._id }))}
                            onChange={handleCommunityFilter}
                            allowClear
                        />
                    </Flex>
                </Flex>
                <Flex gap="middle" vertical>
                    <Flex gap="middle">
                        <Button className='w-[200px] h-[40px]' onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
                    </Flex>
                    <Flex gap="middle">

                    </Flex>
                </Flex>
            </Flex>
            <Table
                className='z-[0] relative overflow-auto'
                columns={columns}
                dataSource={activitiesData?.map((i, it) => ({
                    key: i._id,
                    srno: (it + 1),
                    name: _renderActivityName(i),
                    activityType: <Tag color={i?.activityType === ACTIVITY_TYPE.APP.value ? 'green' : 'pink'}>{i?.activityType || ACTIVITY_TYPE.APP.value}</Tag>,
                    city: i?.cities?.map(k => k.name).join(", "),
                    community: i?.communityId?.name,
                    goal: i?.goalId?.name,
                    // habit: i.goalId?.habits?.map(j => <Tag className='my-0.5' color="blue">{j.name}</Tag>),
                    point: i?.points,
                    validation: _transformValidationMethod(i?.validationMethod),
                    createdAt: i?.createdAt && (moment(i?.createdAt).format(BE_SHORT_DATE_FORMAT)),
                    status: <Tag color={i?.publish === true ? 'green' : 'red'}>{i?.publish ? 'Published' : 'Not published'}</Tag>,
                    link: i?.qrCode && (
                        <>
                            {i?.qrCode && i?.qrCode?.qr_code &&
                                <img
                                    className="w-20"
                                    src={`${i?.qrCode?.qr_code}`}
                                />
                            }
                            <CopyToClipboard text={i.qrCode.share_url}>
                                <p className='cursor-pointer text-blue-400 hover:text-blue-800 hover:underline' onClick={() => message.success('Link copied to Clipboard')}>Share Link</p>
                            </CopyToClipboard>
                        </>
                    ),
                    action:
                        <div style={{ minWidth: '65px' }}>
                            <Tooltip title="Edit">
                                <Button style={{ marginRight: "8px" }} onClick={() => navigate((`/activities/edit/${i._id}`))} size="small" type="ghost" shape="circle" icon={<EditOutlined />} />
                            </Tooltip>
                            <Popconfirm
                                title="Are you sure？"
                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                onConfirm={() => deleteActivity(i?._id)}
                                onCancel={() => null}
                                okText="Yes"
                                okType="danger"
                                placement="topLeft"
                                cancelText="Cancel"
                            >
                                <Button size="small" type="ghost" danger shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>

                        </div>
                }
                ))}
                pagination={{
                    showSizeChanger: true,
                    hideOnSinglePage: true,
                    position: ["none", "bottomRight"],
                    total: activities?.totalCount,
                    defaultPageSize: DEFAULT_PAGE_SIZE
                }}
            // onChange={handleTableChange}
            />
        </div>
    )
}