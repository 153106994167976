import React, { useEffect, useState, useRef } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Table, Space, Input, Button, Select, message, Tag, Flex } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { fetchEngagementByTeamJoiningRate, fetchEngagementByTeamJoiningRateExportCsvAll, fetchCommunityReportsCommunitiesList } from '../../../../../store/communityReport/action'
import { EngagementByTeamJoiningRateTableColumns } from './columns'
// import { SearchOutlined } from '@ant-design/icons'
import { CgArrowsV } from 'react-icons/cg'
import { DEFAULT_PAGE_SIZE, PAGINATION } from '../../../../../const/utils'
import ReactApexChart from "react-apexcharts"


/**
 * #44821 - Engagement - by Community Overview
 * @returns 
 */
export const EngagementByTeamJoiningRate = () => {
    const [currentPagination, setCurrentPagination] = useState(PAGINATION)

    const dispatch = useDispatch()
    const [userRole, setUserRole] = useState('')
    const myCorporateProfile = useSelector(state => state?.community?.myCorporateProfile)
    const engagementByTeamJoiningRate = useSelector(state => state?.communityReport?.engagementByTeamJoiningRate)
    const [selectedCommunity, setSelectedCommunity] = useState(null)
    const [selectedTeam, setSelectedTeam] = useState(null)
    const [communitiesData, setCommunitiesData] = useState([])
    const [teamsData, setTeamsData] = useState([])
    const communities = useSelector(state => state.communityReport?.communities)

    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [prepairLeaderBoardColumns, setPrepairLeaderBoardColumns] = useState(null)
    const [selectedCommunityObject, setSelectedCommunityObject] = useState(null)
    const [isReadyToGetDefaultData, setIsReadyToGetDefaultData] = useState(false)
    const [defaultCommunity, setDefaultCommunity] = useState(null)
    const [defaultTeam, setDefaultTeam] = useState(null)

    const [xaxisListData, setXaxisListData] = useState([])
    const [options, setOptions] = useState(
        {
            colors: [
                "#31aa62",
                "#779ddb"
            ],
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            responsive: [
                {
                    breakpoint: 480,
                    options: {
                        legend: {

                        }
                    }
                }
            ],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 10,
                    dataLabels: {
                        total: {
                            enabled: true,
                            style: {
                                fontSize: '13px',
                                fontWeight: 900
                            }
                        }
                    }
                }
            },
            xaxis: {
                categories: xaxisListData
            },
            legend: {

            },
            fill: {
                opacity: 1
            }
        }
    )

    const [series, setSeries] = useState([])

    const [isReady, setIsReady] = useState(false)

    const tooltipFormatter = (val, opts) => {
        const result = xaxisListData.length > 0 ? xaxisListData[val - 1] : val
        return result
    }

    useEffect(() => {
        setOptions(
            {
                ...options,
                xaxis: {
                    ...options.xaxis,
                    categories: xaxisListData,
                    tooltip: {
                        formatter: tooltipFormatter
                    },
                    labels: {
                        show: false
                    }
                }
            }
        )
    }, [xaxisListData])

    useEffect(() => {
        const userData = JSON.parse(sessionStorage.getItem("userData"))
        const { role } = userData?.user
        setUserRole(role)
        dispatch(fetchCommunityReportsCommunitiesList())

        setPrepairLeaderBoardColumns(EngagementByTeamJoiningRateTableColumns)
    }, [])

    useEffect(() => {
        setSelectedTeam(null)
        if (selectedCommunity) {
            setDefaultCommunity(null)
            const selectedCommunityData = communitiesData.filter((item) => {
                return item?.communityId === selectedCommunity
            })
            if (selectedCommunityData) {
                setTeamsData(selectedCommunityData[0]?.teams)
                // setDefaultTeam({label: selectedCommunityData[0]?.teams[0]?.teamName, value: selectedCommunityData[0]?.teams[0]?.teamId})
            } else {
                setTeamsData([])
            }
        } else {
            setTeamsData([])
        }
    }, [selectedCommunity])

    // useEffect(() => {
    //     if (defaultTeam) {
    //         setSelectedTeam(defaultTeam?.value)
    //     } else {
    //         setSelectedTeam(null)
    //     }
    // }, [defaultTeam])


    const _handleSearchAndFilter = (pagination = PAGINATION) => {
        if (selectedCommunity) {
            const newSelectedCommunityObject = communities.filter(i => {
                return i.communityId === selectedCommunity
            })
            setSelectedCommunityObject(newSelectedCommunityObject[0])
        } else {
            setSelectedCommunityObject(null)
        }
        const params = {
            ...pagination
        }
        const _current = JSON.parse(JSON.stringify(currentPagination))
        _current.current = pagination?.current || PAGINATION.current
        _current.pageSize = pagination?.pageSize || PAGINATION.pageSize
        setCurrentPagination(_current)
        // if (filterDateRange) {
        //     params['fromDate'] = moment(new Date(filterDateRange[0])).format(`DD/MM/YYYY`)
        //     params['toDate'] = moment(new Date(filterDateRange[1])).format(`DD/MM/YYYY`)
        // }
        // if (filterStatus) {
        //   params['status'] = filterStatus
        // }
        if (selectedCommunity) {
            params['communityId'] = selectedCommunity
        }
        if (selectedTeam) {
            params['teamId'] = selectedTeam
        }
        dispatch(fetchEngagementByTeamJoiningRate(params))
    }

    const handleTableChange = (pagination) => {
        _handleSearchAndFilter(pagination)
    }

    useEffect(() => {
        if (userRole && communities) {
            if (userRole === 'community_leader') {
                const currentCommunityData = communities.filter((item) => { return item?.communityId === myCorporateProfile?._id })
                setCommunitiesData(currentCommunityData)
                if (currentCommunityData.length > 0) {
                    setDefaultCommunity({ label: currentCommunityData[0]?.communityName, value: currentCommunityData[0]?.communityId })
                } else {
                    setDefaultCommunity({ label: myCorporateProfile?.name, value: myCorporateProfile?._id })
                }
            } else {
                setCommunitiesData(communities)
                setDefaultCommunity({ label: communities[0]?.communityName, value: communities[0]?.communityId })
                // setDefaultCommunity({ label: "Dac Community", value: '642c00859255677b96b0a0e5' })
            }
        }
    }, [communities, userRole])
    //This will prevent API call 3 times when page is loaded first time
    // }, [userRole])

    useEffect(() => {
        if (defaultCommunity) {
            setSelectedCommunity(defaultCommunity?.value)
            setIsReadyToGetDefaultData(true)
        } else {
            setIsReadyToGetDefaultData(false)
        }
    }, [defaultCommunity])

    useEffect(() => {
        if (isReadyToGetDefaultData) {
            _handleSearchAndFilter()
        }
    }, [isReadyToGetDefaultData])

    // useEffect(() => {
    //     if (defaultTeam) {
    //         setIsReadyToGetDefaultData(true)
    //     } else {
    //         setIsReadyToGetDefaultData(false)
    //     }
    // }, [defaultTeam])

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const rowSelection = {
        ...selectedRowKeys,
        onChange: onSelectChange
    }

    const _handleExportCsvAll = () => {
        const params = {

        }
        if (selectedCommunity) {
            params['communityId'] = selectedCommunity
        }
        if (selectedTeam) {
            params['teamId'] = selectedTeam
        }
        dispatch(fetchEngagementByTeamJoiningRateExportCsvAll(params))
    }

    const _handleExportCsvSelected = () => {
        if (selectedRowKeys.length > 0) {
            dispatch(fetchEngagementByTeamJoiningRateExportCsvAll({}, selectedRowKeys))
        }
    }

    const handleLineBreakForLabel = (label) => {
        const splitPosition = label.indexOf(' (')
        const campainName = label.substring(0, splitPosition)
        const campainWeek = label.substring(splitPosition + 1, label.length)
        return [campainName, campainWeek]
    }

    useEffect(() => {
        if (series && series.length > 0 && options) {
            setTimeout(() => {
                setIsReady(true)
            }, 1000)
        }
    }, [series, options])

    useEffect(() => {
        if (engagementByTeamJoiningRate && engagementByTeamJoiningRate?.result.length > 0 && prepairLeaderBoardColumns) {
            //Prepair series
            const seriesList = []
            const xaxisList = []
            let joinListData = []
            for (let i = 0; i < prepairLeaderBoardColumns.length; i++) {
                const dataSeries = engagementByTeamJoiningRate?.result.map((item) => {
                    if (!xaxisList.includes(item?.label)) {
                        // xaxisList.push(handleLineBreakForLabel(item?.label))
                        xaxisList.push(item?.label)
                    }
                    if (prepairLeaderBoardColumns[i]?.dataIndex === "number_of_members_invited") {
                        return item?.number_of_members_invited
                    }
                    if (prepairLeaderBoardColumns[i]?.dataIndex === "number_of_members_joined") {
                        return item?.number_of_members_joined
                    }
                }).filter((item) => {
                    return item !== undefined
                })
                if (prepairLeaderBoardColumns[i]?.dataIndex !== 'teamName' && prepairLeaderBoardColumns[i]?.dataIndex !== 'pct_joined') {
                    if (prepairLeaderBoardColumns[i]?.dataIndex === "number_of_members_joined") {
                        joinListData = dataSeries
                    }
                    seriesList.push({
                        name: prepairLeaderBoardColumns[i]?.title,
                        data: dataSeries,
                        dataIndex: prepairLeaderBoardColumns[i]?.dataIndex
                    })
                }

            }
            const newDataSeries = seriesList.map((item) => {
                if (item?.dataIndex === "number_of_members_invited") {
                    item.dataIndex = 'number_of_members_not_joined'
                    item.name = 'Not Joined'
                    item.data = item.data.map((sub_item, sub_index) => {
                        return sub_item - joinListData[sub_index]
                    })
                }
                return item
            })
            const tempItem = newDataSeries[0]
            newDataSeries[0] = newDataSeries[1]
            newDataSeries[1] = tempItem
            setXaxisListData(xaxisList)
            setSeries(newDataSeries)

        }
    }, [prepairLeaderBoardColumns, engagementByTeamJoiningRate])

    return (
        <div className='bg-white shadow pb-3'>
            <PageHeader
                ghost={false}
                title={`Engagement - By Joining Rate: ${selectedCommunityObject ? selectedCommunityObject?.communityName : ''}`}
            ></PageHeader>
            <Flex gap="middle">
                <Flex gap="middle" vertical>
                    <Flex gap="middle">
                        <Select
                            showSearch
                            allowClear
                            value={selectedCommunity}
                            disabled={userRole != 'admin'}
                            className='w-[300px] h-[40px] ml-4'
                            placeholder="Select community"
                            optionFilterProp='label'
                            options={communitiesData?.map(i => ({ label: i.communityName, value: i.communityId }))}
                            suffixIcon={<CgArrowsV />}
                            onChange={(h) => { setSelectedCommunity(h) }}
                        >
                        </Select>
                        <Select
                            showSearch
                            value={selectedTeam}
                            allowClear
                            className='w-[300px] h-[40px]'
                            placeholder="Select Teams"
                            optionFilterProp='label'
                            options={teamsData?.map(i => ({ label: i.teamName, value: i.teamId }))}
                            suffixIcon={<CgArrowsV />}
                            onChange={(h) => { setSelectedTeam(h) }}
                        >
                        </Select>
                    </Flex>
                </Flex>
                <Flex gap="middle" className='mb-4'>
                    <Button className='w-[200px] h-[40px]'
                        // disabled={userRole != 'admin'} 
                        onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
                    {/* <Button className='mx-2 border-[#207868] text-[#207868]' disabled={!hasSelected} onClick={_handleExportCsvSelected}>
                            {`Export Selected`}
                        </Button> */}
                    <Button className='w-[200px] h-[40px] bg-[#207868]' type='primary' disabled={!(engagementByTeamJoiningRate)} onClick={_handleExportCsvAll}>
                        {`Export by Current Filter`}
                    </Button>
                </Flex>
            </Flex>
            <div className='mr-5 mb-5'>
                {isReady ? <ReactApexChart
                    options={options}
                    series={series}
                    type="bar"
                    width={900}
                /> : ''}
            </div>
            <Table
                className='z-[0] relative overflow-auto'
                // rowSelection={rowSelection}
                columns={prepairLeaderBoardColumns}
                dataSource={engagementByTeamJoiningRate?.result?.map((i, it) => ({
                    key: i.key,
                    srno: (++it + ((currentPagination?.current - 1) * currentPagination?.pageSize)),
                    teamName: i?.label,
                    // teamName: i?.teamName,
                    number_of_members_invited: i?.number_of_members_invited,
                    number_of_members_joined: i?.number_of_members_joined,
                    pct_joined: i?.pct_joined
                }))}
                pagination={{
                    defaultPageSize: DEFAULT_PAGE_SIZE,
                    showSizeChanger: true,
                    hideOnSinglePage: true,
                    position: ["none", "bottomRight"],
                    total: engagementByTeamJoiningRate?.totalCount
                }}
                onChange={handleTableChange}
            />
        </div>
    )
}