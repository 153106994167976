import { FETCH_ALL_ADMIN_USERS, FETCH_ALL_AVAILABLE_COMMUNITY_USERS, FETCH_ALL_AVAILABLE_QUEST_USERS } from "./types"
import { toggleNetworkLoading } from "../../common/actions"
import { message } from 'antd'
import ServerApi from "../../../utility/ServerApi"
import { PAGINATION } from "../../../const/utils"

export const fetchAllAdminUsers = (filters = PAGINATION, callback = null) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(
        `user/admin-fetch-admin-users?current=${filters?.current || 1}
        &pageSize=${filters?.pageSize || 100}
        ${filters?.search ? `&search=${encodeURIComponent(filters?.search)}` : ''}
    `)
        .then(res => {
            dispatch({
                type: FETCH_ALL_ADMIN_USERS,
                payload: res.data
            })
            if (callback) callback(res.data)
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in fetching Admin Users")
            console.log(e)
        })
}


export const fetchAvailCommunityLeaders = (communityId, callback = null) => dispatch => {
    // dispatch(toggleNetworkLoading(true))
    ServerApi().get(
        `user/admin-fetch-avail-community-leaders?communityId=${communityId}`)
        .then(res => {
            dispatch({
                type: FETCH_ALL_AVAILABLE_COMMUNITY_USERS,
                payload: res.data
            })
            if (callback) callback(res.data)
            // dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            // dispatch(toggleNetworkLoading(false))
            // message.error("Error in fetcing Admin Users")
            // console.log(e)
            if (callback) callback([])
        })
}

/**
 * 
 * @param {*} communityId 
 * @param {*} callback 
 * @returns 
 */
export const fetchAvailQuestLeaders = (communityId, callback = null) => dispatch => {
    // dispatch(toggleNetworkLoading(true))
    ServerApi().get(
        `user/admin-fetch-avail-quest-leaders?communityId=${communityId}`)
        .then(res => {
            dispatch({
                type: FETCH_ALL_AVAILABLE_QUEST_USERS,
                payload: res.data
            })
            if (callback) callback(res.data)
            // dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            // dispatch(toggleNetworkLoading(false))
            // message.error("Error in fetcing Admin Users")
            // console.log(e)
            if (callback) callback([])
        })
}