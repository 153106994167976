import React, { useEffect, useState, useRef } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Table, Space, Input, Button, Select, message, Tag, Flex } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { fetchCommunityReportsCommunitiesList, fetchEngagementByActivityKpis, fetchEngagementByActivityKpisExportCsvAll } from '../../../../../store/communityReport/action'
import { EngagementByActivityParticipationKPIsTableColumns } from './columns'
// import { SearchOutlined } from '@ant-design/icons'
import { CgArrowsV } from 'react-icons/cg'
import { DEFAULT_PAGE_SIZE, PAGINATION, showTotalRecords } from '../../../../../const/utils'
import Chart from "react-apexcharts"

/**
 * #44825 - Engagement 5 - By Activity KPIs
 * @returns 
 */
export const EngagementByActivityParticipationKPIs = () => {
    const [currentPagination, setCurrentPagination] = useState(PAGINATION)

    const dispatch = useDispatch()
    const [userRole, setUserRole] = useState('')
    const myCorporateProfile = useSelector(state => state?.community?.myCorporateProfile)
    const engagementByActivityKpis = useSelector(state => state?.communityReport?.engagementByActivityKpis)
    const [selectedCommunity, setSelectedCommunity] = useState(null)
    const [selectedTeam, setSelectedTeam] = useState(null)
    const [communitiesData, setCommunitiesData] = useState([])
    const [teamsData, setTeamsData] = useState([])
    const communities = useSelector(state => state.communityReport?.communities)

    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [prepairLeaderBoardColumns, setPrepairLeaderBoardColumns] = useState(null)
    const [selectedCommunityObject, setSelectedCommunityObject] = useState(null)
    const [isReadyToGetDefaultData, setIsReadyToGetDefaultData] = useState(false)
    const [defaultCommunity, setDefaultCommunity] = useState(null)
    const [xaxisListData, setXaxisListData] = useState([])

    const tooltipFormatter = (val, opts) => {
        const result = xaxisListData.length > 0 ? xaxisListData[val - 1] : val
        return result
    }

    const [optionsActivities, setOptionsActivities] = useState(
        {
            colors: ["#31aa62"],
            chart: {
                id: "basic-activities-bar",
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: "50%"
                }
            },
            markers: {
                size: 6,
                strokeWidth: 3,
                fillOpacity: 0,
                strokeOpacity: 0,
                hover: {
                    size: 8
                }
            },
            yaxis: {
                tickAmount: 5
            },
            xaxis: {

            },
            label: [],
            legend: {
                show: true,
                showForSingleSeries: true
            }
        }
    )
    const [optionsChallenges, setOptionsChallenges] = useState(
        {
            colors: ["#779ddb"],
            chart: {
                id: "basic-challenges-bar",
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: false
                }
            },
            plotOptions: {
                bar: {
                    columnWidth: "50%"
                }
            },
            markers: {
                size: 6,
                strokeWidth: 3,
                fillOpacity: 0,
                strokeOpacity: 0,
                hover: {
                    size: 8
                }
            },
            yaxis: {
                tickAmount: 5
            },
            xaxis: {

            },
            label: [],
            legend: {
                show: true,
                showForSingleSeries: true
            }
        }
    )
    const [seriesActivities, setSeriesActivities] = useState([])
    const [seriesChallenges, setSeriesChallenges] = useState([])

    useEffect(() => {
        setOptionsActivities({
            ...optionsActivities,
            xaxis: {
                tooltip: {
                    formatter: tooltipFormatter
                },
                labels: {
                    show: false
                }
            }
        })
        setOptionsChallenges({
            ...optionsChallenges,
            xaxis: {
                tooltip: {
                    formatter: tooltipFormatter
                },
                labels: {
                    show: false
                }
            }
        })
    }, [xaxisListData])

    const handleLineBreakForLabel = (label) => {
        const splitPosition = label.indexOf(' (')
        const campainName = label.substring(0, splitPosition)
        const campainWeek = label.substring(splitPosition + 1, label.length)
        return [campainName, campainWeek]
    }

    useEffect(() => {
        if (prepairLeaderBoardColumns && engagementByActivityKpis) {
            //Prepair series
            const xaxisList = []
            for (let i = 1; i < prepairLeaderBoardColumns.length; i++) {
                let goals = [
                    {
                        name: 'Target',
                        value: 0,
                        strokeHeight: 5,
                        strokeColor: '#775DD0'
                    }
                ]
                const dataSeries = engagementByActivityKpis?.result.map((item) => {
                    if (item?.label !== 'Target') {
                        if (!xaxisList.includes(item?.label)) {
                            xaxisList.push(item?.label)
                        }
                        if (prepairLeaderBoardColumns[i]?.dataIndex === "challenges_completed_per_week") {
                            return {
                                x: handleLineBreakForLabel(item?.label),
                                y: item?.challenges_completed_per_week,
                                goals
                            }
                        } else if (prepairLeaderBoardColumns[i]?.dataIndex === "activities_completed_per_week") {
                            return {
                                x: handleLineBreakForLabel(item?.label),
                                y: item?.activities_completed_per_week,
                                goals
                            }
                        }
                    } else {
                        if (prepairLeaderBoardColumns[i]?.dataIndex === "challenges_completed_per_week") {
                            goals = [
                                {
                                    name: 'Target',
                                    value: item?.challenges_completed_per_week ? item?.challenges_completed_per_week : 0,
                                    strokeHeight: 5,
                                    strokeColor: '#775DD0'
                                }
                            ]
                        } else if (prepairLeaderBoardColumns[i]?.dataIndex === "activities_completed_per_week") {
                            goals = [
                                {
                                    name: 'Target',
                                    value: item?.activities_completed_per_week ? item?.activities_completed_per_week : 0,
                                    strokeHeight: 5,
                                    strokeColor: '#fa1505'
                                }
                            ]
                        }
                    }
                }).filter((item) => {
                    return item !== undefined
                })

                if (prepairLeaderBoardColumns[i]?.dataIndex === "activities_completed_per_week") {
                    setSeriesActivities(
                        [
                            {
                                name: prepairLeaderBoardColumns[i]?.title,
                                type: "column",
                                data: dataSeries
                            }
                        ]
                    )
                } else if (prepairLeaderBoardColumns[i]?.dataIndex === "challenges_completed_per_week") {
                    setSeriesChallenges(
                        [
                            {
                                name: prepairLeaderBoardColumns[i]?.title,
                                type: "column",
                                data: dataSeries
                            }
                        ]
                    )
                }
            }
            setXaxisListData(xaxisList)
        }
    }, [prepairLeaderBoardColumns, engagementByActivityKpis])

    const hasSelected = selectedRowKeys.length > 0

    useEffect(() => {
        const userData = JSON.parse(sessionStorage.getItem("userData"))
        const { role } = userData?.user
        setUserRole(role)
        dispatch(fetchCommunityReportsCommunitiesList())

        setPrepairLeaderBoardColumns(EngagementByActivityParticipationKPIsTableColumns)
    }, [])

    useEffect(() => {
        setSelectedTeam(null)
        if (selectedCommunity) {
            const selectedCommunityData = communitiesData.filter((item) => {
                return item?.communityId === selectedCommunity
            })
            if (selectedCommunityData) {
                setTeamsData(selectedCommunityData[0]?.teams)
            } else {
                setTeamsData([])
            }
        } else {
            setTeamsData([])
        }
    }, [selectedCommunity])

    const _handleSearchAndFilter = (pagination = PAGINATION) => {
        if (selectedCommunity) {
            const newSelectedCommunityObject = communities.filter(i => {
                return i.communityId === selectedCommunity
            })
            setSelectedCommunityObject(newSelectedCommunityObject[0])
        } else {
            setSelectedCommunityObject(null)
        }
        const params = {
            ...pagination
        }
        const _current = JSON.parse(JSON.stringify(currentPagination))
        _current.current = pagination?.current || PAGINATION.current
        _current.pageSize = pagination?.pageSize || PAGINATION.pageSize
        setCurrentPagination(_current)
        // if (filterDateRange) {
        //     params['fromDate'] = moment(new Date(filterDateRange[0])).format(`DD/MM/YYYY`)
        //     params['toDate'] = moment(new Date(filterDateRange[1])).format(`DD/MM/YYYY`)
        // }
        // if (filterStatus) {
        //   params['status'] = filterStatus
        // }
        if (selectedCommunity) {
            params['communityId'] = selectedCommunity
        }
        if (selectedTeam) {
            params['teamId'] = selectedTeam
        }
        dispatch(fetchEngagementByActivityKpis(params))
    }

    const handleTableChange = (pagination) => {
        _handleSearchAndFilter(pagination)
    }

    useEffect(() => {
        if (userRole && communities) {
            if (userRole === 'community_leader') {
                const currentCommunityData = communities.filter((item) => { return item?.communityId === myCorporateProfile?._id })
                setCommunitiesData(currentCommunityData)
                if (currentCommunityData.length > 0) {
                    setDefaultCommunity({ label: currentCommunityData[0]?.communityName, value: currentCommunityData[0]?.communityId })
                } else {
                    setDefaultCommunity({ label: myCorporateProfile?.name, value: myCorporateProfile?._id })
                }
            } else {
                setCommunitiesData(communities)
                setDefaultCommunity({ label: communities[0]?.communityName, value: communities[0]?.communityId })
                // setDefaultCommunity({ label: "Dac Community", value: '642c00859255677b96b0a0e5' })
            }
        }
    }, [communities, userRole])
    //This will prevent API call 3 times when page is loaded first time
    // }, [userRole])

    useEffect(() => {
        if (defaultCommunity) {
            setSelectedCommunity(defaultCommunity?.value)
            setIsReadyToGetDefaultData(true)
        }
    }, [defaultCommunity])

    useEffect(() => {
        if (isReadyToGetDefaultData) {
            _handleSearchAndFilter()
            setIsReadyToGetDefaultData(false)
        }
    }, [isReadyToGetDefaultData])


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const rowSelection = {
        ...selectedRowKeys,
        onChange: onSelectChange
    }

    const _handleExportCsvAll = () => {
        const params = {

        }
        if (selectedCommunity) {
            params['communityId'] = selectedCommunity
        }
        if (selectedTeam) {
            params['teamId'] = selectedTeam
        }
        dispatch(fetchEngagementByActivityKpisExportCsvAll(params))
    }

    const _handleExportCsvSelected = () => {
        if (selectedRowKeys.length > 0) {
            dispatch(fetchEngagementByActivityKpisExportCsvAll({}, selectedRowKeys))
        }
    }

    const _renderKPIValue = (element, key) => {
        if (element[`${key}_kpi`] == '2') {
            //red
            return <span style={{ color: 'red' }}>{element[`${key}`]}</span>
        } else if (element[`${key}_kpi`] == '1') {
            //green
            return <span style={{ color: 'green' }}>{element[`${key}`]}</span>
        } else {
            // default is target value
            return <>{element[`${key}`]}</>
        }
    }


    return (
        <div className='bg-white shadow pb-3'>
            <PageHeader
                ghost={false}
                title={`Engagement - By Activity KPIs: ${selectedCommunityObject ? selectedCommunityObject?.communityName : ''}`}
            ></PageHeader>
            <Flex gap="middle">
                <Flex gap="middle" vertical>
                    <Flex gap="middle">
                        <Select
                            showSearch
                            allowClear
                            value={selectedCommunity}
                            disabled={defaultCommunity && userRole != 'admin'}
                            className='w-[300px] h-[40px] ml-4'
                            placeholder="Select community"
                            optionFilterProp='label'
                            options={communitiesData?.map(i => ({ label: i.communityName, value: i.communityId }))}
                            suffixIcon={<CgArrowsV />}
                            onChange={(h) => { setSelectedCommunity(h) }}
                        >
                        </Select>
                        {/* <Select
                            showSearch
                            value={selectedTeam}
                            allowClear
                            className='w-[300px] ml-4 mb-5'
                            placeholder="Select Teams"
                            optionFilterProp='label'
                            options={teamsData?.map(i => ({ label: i.teamName, value: i.teamId }))}
                            suffixIcon={<CgArrowsV />}
                            onChange={(h) => { setSelectedTeam(h) }}
                        >
                        </Select> */}
                    </Flex>
                </Flex>
                <Flex gap="middle" className='mb-4'>
                    <Button className='w-[200px] h-[40px]'
                        // disabled={userRole != 'admin'} 
                        onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
                    <Button className='w-[200px] h-[40px] bg-[#207868]' type='primary' disabled={!(engagementByActivityKpis)} onClick={_handleExportCsvAll}>
                        {`Export by Current Filter`}
                    </Button>
                </Flex>
            </Flex>
            <div className='mr-5 mb-5'>
                <div className="flex">
                    <div className='flex-1'>
                        {/* Activity complete chart */}
                        <Chart
                            options={optionsActivities}
                            series={seriesActivities}
                        // width={900}
                        />
                    </div>
                    <div className='flex-1'>
                        {/* Challenge complete chart */}
                        <Chart
                            options={optionsChallenges}
                            series={seriesChallenges}
                        // width={900}
                        />
                    </div>
                </div>

            </div>
            <Table
                className='z-[0] relative overflow-auto'
                // rowSelection={rowSelection}
                columns={prepairLeaderBoardColumns}
                dataSource={engagementByActivityKpis?.result?.map((i, it) => ({
                    key: i.key,
                    srno: (++it + ((currentPagination?.current - 1) * currentPagination?.pageSize)),
                    kpi: i?.label,
                    // teamName: i?.teamName,
                    activities_completed_per_week: _renderKPIValue(i, 'activities_completed_per_week'),
                    challenges_completed_per_week: _renderKPIValue(i, 'challenges_completed_per_week')
                }))}
                pagination={{
                    defaultPageSize: DEFAULT_PAGE_SIZE,
                    showSizeChanger: true,
                    hideOnSinglePage: true,
                    position: ["none", "bottomRight"],
                    total: engagementByActivityKpis?.totalCount,
                    showTotal: showTotalRecords
                }}
                onChange={handleTableChange}
            />
        </div>
    )
}