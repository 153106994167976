export const EngagementByActivityParticipationKPIsTableColumns = [
  {
    title: 'KPIs',
    dataIndex: 'kpi',
    key: 'kpi',
    notAllowToSearch: true
  },
  {
    title: 'Activities completed per week',
    dataIndex: 'activities_completed_per_week',
    key: 'activities_completed_per_week'
  },
  {
    title: 'Challenges completed per week',
    dataIndex: 'challenges_completed_per_week',
    key: 'challenges_completed_per_week'
  }
]
