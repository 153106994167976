import React, { useRef, useEffect, useState } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Table, Space, Input, Tooltip, Popconfirm, Tag, Select, Flex } from 'antd'
import { SearchOutlined, DeleteOutlined, QuestionCircleOutlined, EyeOutlined, PlusOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllUsers, deleteUser, mobileUsersExportCsvAll } from '../../../store/users/action'
import { fetchAllCommunity } from '../../../store/community/actions'
// import PreviewImage from '../../../components/image/PreviewImage'
import AddEditUsers from './AddEditUser'
// import { CSVLink } from 'react-csv'
import moment from 'moment'
import { ACCOUNT_STATUS, exportData, findUserPlatformFromMeta } from './Users.const'
import { MobileUserColums } from './Users.columns'
import { BE_FULL_DATE_FORMAT, BE_SHORT_DATE_FORMAT, DEFAULT_PAGE_SIZE, PAGINATION, showTotalRecords } from '../../../const/utils'
import { transformUserIdFormat } from '../Transactions/Transactions.const'

export const Users = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const users = useSelector(state => state.users.users) || [{ result: [] }]
  const communities = useSelector(state => state.community.communities)
  const searchInput = useRef(null)

  const [prepairMobileUserColumn, setPrepairMobileUserColumn] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [currentPagination, setCurrentPagination] = useState(PAGINATION)

  const handleColumnSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Button
            type="primary"
            className='mx-2 bg-[#207868]'
            onClick={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      } else {
        return false
      }

    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    }
  })

  useEffect(() => {
    dispatch(fetchAllUsers({ current: 1, pageSize: 10000 }))
    dispatch(fetchAllCommunity())
    const newMobileUserColumns = MobileUserColums.map((item) => {
      if (item.notAllowToSearch === true) {
        return item
      } else {
        return {
          ...item,
          ...getColumnSearchProps(item['dataIndex'])
        }
      }
    })
    setPrepairMobileUserColumn(newMobileUserColumns)
  }, [])

  // const [visible, setVisible] = useState(false)
  const [wordEntered, setWordEntered] = useState()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [filter, setFilter] = useState({
    verified: 'all',
    communityId: ''
  })

  const handleSearch = (event) => {
    const searchWord = event.target.value
    setWordEntered(searchWord)
    // if (searchWord === '') {
    //   dispatch(fetchAllUsers())
    // }
    // dispatch(fetchAllUsers({ search: searchWord.toLowerCase() }))
  }

  const handleVerfiyFilter = (value) => {
    if (value === 'all' || value === undefined) {
      setFilter({ ...filter, verified: '' })
      // dispatch(fetchAllUsers())
    } else {
      setFilter({ ...filter, verified: (value === 'true') })
      // dispatch(fetchAllUsers({ verified: JSON.parse(value.toLowerCase()) }))
    }
  }

  // const showModal = () => {
  //   setVisible(true)
  // }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
    setSelectedRows(newSelectedRowKeys.map(i => users?.result[i]))
  }

  const rowSelection = {
    ...selectedRowKeys,
    ...selectedRows,
    onChange: onSelectChange
  }

  const handleCommunityFilter = (id) => {
    // dispatch(fetchAllUsers({ communityId: id }))
    if (id) {
      setFilter({ ...filter, communityId: id })
    } else {
      setFilter({ ...filter, communityId: '' })
    }
  }

  // useEffect(() => {
  //   const _current = JSON.parse(JSON.stringify(currentPagination))
  //   _current.current = PAGINATION.current // 1
  //   _current.pageSize = PAGINATION.pageSize // 100
  //   setCurrentPagination(_current)
  //   dispatch(fetchAllUsers({ current: 1, pageSize: 100, verified: filter?.verified, communityId: filter?.communityId }))
  // }, [filter])

  const handleTableChange = (pagination) => {
    const _current = JSON.parse(JSON.stringify(currentPagination))
    _current.current = pagination?.current || PAGINATION.current
    _current.pageSize = pagination?.pageSize || PAGINATION.pageSize
    setCurrentPagination(_current)
    pagination = { ...pagination, verified: filter?.verified, communityId: filter?.communityId }
    dispatch(fetchAllUsers(pagination))
  }

  const deleteThisUser = (id) => {
    dispatch(deleteUser(id))
    return new Promise(resolve => {
      setTimeout(() => resolve(), 2000)
    })
  }

  const _userVerifiedStatus = (_user) => {
    const _status = Object.values(ACCOUNT_STATUS).find(i => i.value == _user.status)
    if (_status == ACCOUNT_STATUS.ACTIVE && _user.is_email_verified) {
      return <CheckCircleOutlined className='text-green-500' />
    }
    return <CloseCircleOutlined className='text-red-500' />
  }

  const _userSettingsStatus = (_status) => {
    if (_status === true) {
      return <CheckCircleOutlined className='text-green-500' />
    }
    return <CloseCircleOutlined className='text-red-500' />
  }

  /**
   * #43769 - Mobile Users - Export selected and Export All is missing
   * Export all CSV
   */
  const _handleMobileUsersExportCsvAll = () => {
    dispatch(mobileUsersExportCsvAll())
  }

  /**
   * #43769 - Mobile Users - Export selected and Export All is missing
   * Export selected records
   */
  const _handleMobileUsersExportCsvSelected = () => {
    if (selectedRowKeys.length > 0) {
      dispatch(mobileUsersExportCsvAll(selectedRowKeys))
    } else {
      //export from current result
      if (users?.result.length > 0) {
        const filterIds = users?.result.map(e => e._id)
        dispatch(mobileUsersExportCsvAll(filterIds))
      }
    }
  }

  const _handleSearchAndFilter = (pagination = PAGINATION) => {
    const params = {
      ...pagination
    }
    if (!!wordEntered) {
      params['search'] = wordEntered.toLowerCase()
    }
    if (filter.communityId) {
      params['communityId'] = filter.communityId
    }
    if (filter.verified !== 'all') {
      params['verified'] = filter.verified
    }
    dispatch(fetchAllUsers(params))
  }

  const hasSelected = selectedRowKeys.length > 0// || (users && users?.result?.length > 0)

  return (
    <div className='bg-white shadow'>
      <PageHeader
        ghost={false}
        title="Users"></PageHeader>
      <Flex gap="middle">
        <Flex gap="middle" vertical className='mb-4 ml-4'>
          <Input
            className='w-[450px] h-[40px]'
            placeholder='Search name, email'
            allowClear
            defaultValue={wordEntered}
            onChange={handleSearch}
            prefix={<SearchOutlined />}
          />
          {/* <Button className='py-5 mx-2 flex items-center' onClick={handleValidationSort}>Community</Button> */}
          <Select
            // bordered={false}
            className='w-[450px] h-[40px]'
            placeholder='Community'
            optionFilterProp='label'
            options={communities?.result?.map(c => ({ label: c.name, value: c._id }))}
            onChange={handleCommunityFilter}
            allowClear
          />
          <Select
            // bordered={false}
            className='w-[450px] h-[40px]'
            placeholder='Filter (all, verified and not verified)'
            optionFilterProp='label'
            onChange={handleVerfiyFilter}
            allowClear
          >
            <Select.Option key='all' value='all'>All</Select.Option>
            <Select.Option key='true' value='true'>Verified</Select.Option>
            <Select.Option key='false' value='false'>Not Verified</Select.Option>
          </Select>
        </Flex>
        <Flex gap="middle">
          <Flex gap="middle" vertical>
            <Button className='w-[200px] h-[40px]' onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
            <Flex gap="middle">
              <Button className='w-[200px] h-[40px] bg-[#207868]' disabled={!hasSelected} onClick={_handleMobileUsersExportCsvSelected}>
                {`Export Selected`}
              </Button>
              <Button className='w-[200px] h-[40px] bg-[#207868]' type='primary' disabled={!(users?.result)} onClick={_handleMobileUsersExportCsvAll}>
                {`Export All`}
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Table
        className='z-[0] relative w-full overflow-auto'
        columns={prepairMobileUserColumn}
        rowSelection={rowSelection}
        dataSource={
          users?.result?.map((i, it) => ({
            key: i._id,
            name: i.name,
            userId: transformUserIdFormat(i),
            email: i.email,
            community: i?.community?.map(i => i?.name),
            teamName: i?.teams?.map(t => t?.name),
            verified: _userVerifiedStatus(i),
            timezone: i?.timezone,
            platform: findUserPlatformFromMeta(i?.x_app_platforms),
            newsletter: _userSettingsStatus(i?.settings?.newsletter),
            pushNotifications: _userSettingsStatus(i?.settings?.pushNotifications),
            createdAt: i.createdAt && (moment(i.createdAt).format(BE_SHORT_DATE_FORMAT)),
            lastActiveAt: i.lastActiveAt && (moment(i.lastActiveAt).format(BE_FULL_DATE_FORMAT)),
            type: i.community.length > 0 ? <Tag color="cyan">Community</Tag> : <Tag color="geekblue">Individual</Tag>,
            status: <Tag color={i.status == 'ACTIVE' && i.is_email_verified ? 'green' : !(i.is_email_verified) ? 'gold' : 'red'}>
              {i.is_email_verified ? i.status : "PENDING"}
            </Tag>,
            action:
              <div style={{ minWidth: '65px' }}><Tooltip title="Info">
                <Button style={{ marginRight: "8px" }} onClick={() => { navigate((`/users/info/${i._id}`)) }} size="small" type="ghost" shape="circle" icon={<EyeOutlined />} />
              </Tooltip>
                <Popconfirm
                  title="Are you sure？"
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  onConfirm={() => deleteThisUser(i._id)}
                  onCancel={() => null}
                  okText="Yes"
                  okType="danger"
                  placement="topLeft"
                  cancelText="Cancel"
                >
                  <Button size="small" type="ghost" danger shape="circle" icon={<DeleteOutlined />} />
                </Popconfirm>
              </div>
          }))
        }
        pagination={{
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          hideOnSinglePage: true,
          position: ["none", "bottomRight"],
          total: users?.totalCount,
          current: currentPagination?.current,
          showTotal: showTotalRecords
        }}
        onChange={handleTableChange}
      ></Table>

      {/* <AddEditUsers
        visible={visible}
        setVisible={setVisible}
      /> */}
    </div >
  )
}