/**
 * #43616 - CHALLENGE - time period logic update
 */
export const CHALLENGE_WINDOW_MODE = {
  TIME_PERIOD: {
    value: 'TIME_PERIOD',
    label: 'Time Period'
  },
  COMPLETE_WITHIN: {
    value: 'COMPLETE_WITHIN',
    label: 'Complete within'
  }
}

/**
 * 
 * @param {*} rawCodes 
 * @returns 
 */
export const transformVoucherCodeInput = (rawCodes) => {
  try {
    const splittedVoucher = rawCodes?.split(',')
    //check for empty element
    const newArr = splittedVoucher.filter(element => {
      if (element.trim() === "") {
        return false
      } else {
        return true
      }
    })
    //set lai value
    return newArr.map(i => i.trim()).join(",")
  } catch (e) {
    return ""
  }
}