import { FETCH_ALL_REWARDS, FETCH_ALL_REWARDS_FOR_QUEST } from "./types"
import { toggleNetworkLoading } from "../common/actions"
import { message } from "antd"
import ServerApi from "../../utility/ServerApi"

export const fetchAllRewads = () => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/reward-lists`)
        .then(res => {
            dispatch({
                type: FETCH_ALL_REWARDS,
                payload: res.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in fetching Rewads", e)
            console.log(e)
        })
}

/**
 * #48853 - Quest Management
 * @returns 
 */
export const fetchAllRewadsForQuest = () => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/reward-lists/for-quest`)
        .then(res => {
            dispatch({
                type: FETCH_ALL_REWARDS_FOR_QUEST,
                payload: res.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in fetching Rewads", e)
            // console.log(e)
        })
}