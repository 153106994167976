import { FETCH_ALL_ACTIVITIES, FETCH_ACTIVITIES_BY_ID, FETCH_ALL_ACTIVITIES_FOR_CREATE_EDIT_CHALLENGE, FETCH_ACTIVITIES_BY_FOR_MESSAGING, FETCH_ALL_ACTIVITIES_FOR_CREATE_EDIT_QUEST } from "./types"

const initialState = {
    activities: [],
    activitiesForEditCreateChallenge: [],
    activitiesForEditCreateQuest: [],
    activity: {},
    activitiesForMessaging: []
}

const activityReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_ACTIVITIES:
            return { ...state, activities: action.payload }
        case FETCH_ALL_ACTIVITIES_FOR_CREATE_EDIT_CHALLENGE:
            return { ...state, activitiesForEditCreateChallenge: action.payload }
        case FETCH_ALL_ACTIVITIES_FOR_CREATE_EDIT_QUEST:
            return { ...state, activitiesForEditCreateQuest: action.payload }
        case FETCH_ACTIVITIES_BY_ID:
            return { ...state, activity: action.payload }
        case FETCH_ACTIVITIES_BY_FOR_MESSAGING:
            return { ...state, activitiesForMessaging: action.payload }
        default:
            return state
    }
}

export default activityReducer