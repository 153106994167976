import { FETCH_ALL_TEAMS, FETCH_TEAM_BY_ID, FETCH_TEAM_MEMBERS } from "./types"
import { toggleNetworkLoading } from "../common/actions"
import { message } from 'antd'
import ServerApi from "../../utility/ServerApi"
import { PAGINATION } from "../../const/utils"
import ServerApiCSV from "../../utility/ServerApiCSV"
import moment from 'moment'

/**
 * #44744 - Need to have function to download all team members and their status (Invited/Joined/Blocked)
 * @returns 
 */
export const exportAllTeamMembersAsCSV = (_teamId) => dispatch => {
    // dispatch(toggleNetworkLoading(true))
    ServerApiCSV().post(`/teams/admin-team-members-export-csv-all`, { teamId: _teamId })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `team-members-export-csv-all_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("Error Fetching data!")
            // dispatch(toggleNetworkLoading(false))
            console.error(e)
        })
}

export const fetchAllTeams = (filters = PAGINATION) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(
        `/teams?current=${filters.current || 1}&pageSize=${filters.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search)}` : ''}&communityId=${filters.communityId || ''}`)
        .then(res => {
            dispatch({
                type: FETCH_ALL_TEAMS,
                payload: res.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in fetching Teams", e)
            // console.log(e)
        })
}

export const fetchTeamById = (id) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`teams/admin-view-detail/${id}`)
        .then(res => {
            const data = res.data
            dispatch({
                type: FETCH_TEAM_BY_ID,
                payload: data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            message.error("Error Fetching Data!")
            dispatch(toggleNetworkLoading(false))
            console.log(e)
        })
}

export const fetchTeamMembers = (id) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`teams/team-members/${id}`)
        .then(res => {
            const data = res.data
            dispatch({
                type: FETCH_TEAM_MEMBERS,
                payload: data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            message.error("Error Fetching Data!")
            dispatch(toggleNetworkLoading(false))
        })
}

export const createTeam = (rawData, successCallback) => dispatch => {
    ServerApi().post(`/teams`, rawData)
        .then(res => {
            if (res.status === 200 || res.status === 201) {
                message.success("New Team Created")
                successCallback(res.data)
            } else {
                message.error("Error Creating Team")
            }
            successCallback()
        })
        .catch(e => {
            message.error("Error Creating New Team", e)
        })
}

export const deleteTeamById = (id) => dispatch => {
    ServerApi().delete(`teams/${id}`)
        .then(res => {
            message.success("Team Deleted Successfully!")
            dispatch(fetchAllTeams())
        })
        .catch(e => {
            message.error(e?.response?.data?.message || "Error Deleting")
        })
}

export const updateTeam = (_id, rawData, successCallback) => dispatch => {
    ServerApi().patch(`teams/${_id}`, rawData)
        .then(res => {
            if (res.status === 200 || res.status === 201) {
                message.success("Team updated.")
            } else {
                message.warning("Unable to Update Team")
            }
            dispatch(fetchAllTeams(false))
            successCallback()
        })
        .catch(e => {
            message.error(e?.response?.data?.message || e.message)
        })
}

/**
 * 
 * @param {*} rawData 
 * @param {*} successCallback 
 * @returns 
 */
export const updateTeamStatus = (rawData, successCallback) => dispatch => {
    ServerApi().post(`user/team/invite-member/update-status`, rawData)
        .then(res => {
            if (res.status === 200 || res.status === 201) {
                message.success(res.data.message)
            } else {
                message.error(res?.data?.message || "Error updating status")
            }
            successCallback()
        })
        .catch(e => {
            message.error(e?.response?.data?.message || e.message)
        })
}

/**
 * 
 * @param {*} rawData 
 * @param {*} successCallback 
 * @returns 
 */
export const massUpdateTeamStatus = (rawData, successCallback) => dispatch => {
    ServerApi().post(`user/team/invite-member/mass-update-status`, rawData)
        .then(res => {
            if (res.status === 200 || res.status === 201) {
                message.success(res.data.message)
            } else {
                message.error("Error updating status")
            }
            successCallback()
        })
        .catch(e => {
            message.error(e?.response?.data?.message || e.message)
        })
}


/**
 * 
 * @returns 
 */
export const downloadImportTeamCSVTemplate = () => dispatch => {
    // dispatch(toggleNetworkLoading(true))
    ServerApiCSV().get(`/teams/import-members-csv-template`)
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `import-members-csv-template.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("Error Fetching data!")
            // dispatch(toggleNetworkLoading(false))
            console.error(e)
        })
}


/**
 * #44934 - We encounter another issue related to Challenge Rewards for UBS - extra pts rewards does not count for each members
 * @param {*} params 
 * @param {*} successCallback 
 * @returns 
 */
export const adminManualUpdatePointsTeamMembers = (params, successCallback) => dispatch => {
    ServerApi().post(`/teams/admin-manual-update-points-team-members`, params)
        .then(res => {
            if (res.status === 200 || res.status === 201) {
                message.success("Points updated.")
            } else {
                message.warning("Unable to update points.")
            }
            successCallback(res)
        })
        .catch(e => {
            message.error(e)
            message.warning(`Unable to update points. Error: ${e.message}`)
        })
}