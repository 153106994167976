export const LeaderBoardMembersTableColumns = [
    {
        title: 'No',
        dataIndex: 'srno',
        key: 'srno',
        notAllowToSearch: true
    },
    {
        title: 'User ID',
        dataIndex: 'userId',
        key: 'userId'
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email'
    },
    {
        title: 'Team Name',
        dataIndex: 'teamName',
        key: 'teamName'
    },
    {
        title: 'Community Leaderboard Points',
        dataIndex: 'points',
        key: 'points',
        notAllowToSearch: true
    },
    {
        title: 'Personal Points',
        dataIndex: 'personalPoints',
        key: 'personalPoints',
        notAllowToSearch: true
    },
    {
        title: 'Community Name',
        dataIndex: 'communityName',
        key: 'communityName'
    }
]