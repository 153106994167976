import { FETCH_ALL_ILIGHT, FETCH_ILIGHT_DETAIL } from "./types"
import { toggleNetworkLoading } from "../common/actions"
import { message } from 'antd'
import ServerApi from "../../utility/ServerApi"
import ServerApiCSV from "../../utility/ServerApiCSV"
import moment from "moment-timezone"

export const fetchAllILight = (filters) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    let endpoint = 'i-pledge'
    endpoint = `${endpoint}?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}`
    if (filters && filters?.search) {
        endpoint = `${endpoint}&search=${filters?.search}`
    }
    ServerApi().get(endpoint)
        .then(res => {
            dispatch({
                type: FETCH_ALL_ILIGHT,
                payload: res?.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fetching ILight List", e)
            console.log(e)
        })
}

export const fetchILightDetail = (iLightId) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`i-pledge/${iLightId}/`)
        .then(res => {
            dispatch({
                type: FETCH_ILIGHT_DETAIL,
                payload: res?.data?.result
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch((err) => {
            dispatch(toggleNetworkLoading(false))
            message.error(`Error in Fetching ILight Detail with id ${iLightId}:`, err)
            console.log(err)
        })
}

export const deleteILightById = (iLightId, successCallback) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().delete(`i-pledge/${iLightId}/`)
        .then(res => {
            message.success("ILight Deleted Succesfully!")
            successCallback(res.data)
        })
        .catch(e => {
            message.error("Error Deleting ILight")
        })
}

export const iLightExportCsvAll = (_selected_rows = []) => dispatch => {
    // dispatch(toggleNetworkLoading(true))
    ServerApiCSV().post(`/i-pledge/export-csv-all`, { selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `ILight-list_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("Error Fetching data!")
            // dispatch(toggleNetworkLoading(false))
            console.error(e)
        })
}
