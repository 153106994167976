import React, { useState, useEffect } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Row, Col, Card, Select, Button, message, Descriptions, Tag } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { CgArrowsV } from 'react-icons/cg'
import { CommunityGoalStatisticEllipseComponent } from '../corporate-dashboard/components/CommunityGoalStatisticEllipse'
import { fetchMyCorporateProfile, fetchMyCorporateProfileError } from '../../../store/community/actions'
import { fetchCommunityReportsCommunitiesList } from '../../../store/communityReport/action'

export const CommunityGoals = () => {
    const [selectedCommunity, setSelectedCommunity] = useState(null)
    const [defaultCommunity, setDefaultCommunity] = useState(null)
    const [communitiesData, setCommunitiesData] = useState([])
    const [userRole, setUserRole] = useState('')
    const communities = useSelector(state => state.communityReport?.communities)
    const myCorporateProfile = useSelector(state => state?.community?.myCorporateProfile)
    const [selectedCommunityObject, setSelectedCommunityObject] = useState(null)
    const [isReadyToGetDefaultData, setIsReadyToGetDefaultData] = useState(false)

    const dispatch = useDispatch()


    useEffect(() => {
        const userData = JSON.parse(sessionStorage.getItem("userData"))
        const { role } = userData?.user
        setUserRole(role)
        dispatch(fetchCommunityReportsCommunitiesList())
    }, [])


    useEffect(() => {
        if (userRole && communities) {
            if (userRole === 'community_leader') {
                const currentCommunityData = communities.filter((item) => { return item?.communityId === myCorporateProfile?._id })
                setCommunitiesData(currentCommunityData)
                if (currentCommunityData.length > 0) {
                    setDefaultCommunity({ label: currentCommunityData[0]?.communityName, value: currentCommunityData[0]?.communityId })
                } else {
                    setDefaultCommunity({ label: myCorporateProfile?.name, value: myCorporateProfile?._id })
                }
            } else {
                setCommunitiesData(communities)
                setDefaultCommunity({ label: communities[0]?.communityName, value: communities[0]?.communityId })
            }
        }
        }, [communities, userRole])
        //This will prevent API call 3 times when page is loaded first time
    // }, [userRole])


    const _handleSearchAndFilter = () => {
        if (selectedCommunity) {
            dispatch(fetchMyCorporateProfile(selectedCommunity))
        } else {
            message.error("Please select community")
            dispatch(fetchMyCorporateProfileError())
        }
    }

    useEffect(() => {
        if (defaultCommunity) {
            setSelectedCommunity(defaultCommunity?.value)
            setIsReadyToGetDefaultData(true)
        }
    }, [defaultCommunity])

    useEffect(() => {
        if (isReadyToGetDefaultData) {
            _handleSearchAndFilter()
            setIsReadyToGetDefaultData(false)
        }
    }, [isReadyToGetDefaultData])

    useEffect(() => {
        const newSelectedCommunityObject = communities.filter(i => {
            return i.communityId === selectedCommunity
        })
        setSelectedCommunityObject(newSelectedCommunityObject[0])
    }, [selectedCommunity])


    return (
        <div className='shadow bg-white p-4'>
            <PageHeader
                ghost={false}
                title={`Community Goals: ${selectedCommunityObject ? selectedCommunityObject?.communityName : ''}`}
            ></PageHeader>
            <div className='flex items-center justify-between mr-5 mb-5'>
                <div className='flex'>
                    <div className="p-2">
                        <Select
                            showSearch
                            allowClear
                            value={selectedCommunity}
                            disabled={defaultCommunity && userRole != 'admin'}
                            className='w-[300px]'
                            // style={{ width: '100%' }}
                            placeholder="Select community"
                            optionFilterProp='label'
                            options={communitiesData?.map(i => ({ label: i.communityName, value: i.communityId }))}
                            suffixIcon={<CgArrowsV />}
                            onChange={(h) => { setSelectedCommunity(h) }}
                        >
                        </Select>
                    </div>

                    <div className="p-2">
                        <Button className='mx-2 border-[#207868] text-[#207868]' disabled={defaultCommunity && userRole != 'admin'} onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <Row gutter={16} className='mb-20'>
                {myCorporateProfile?.communityGoals && myCorporateProfile.communityGoals.map((goal_progress, i) => (
                    <Col span={8} key={i}>
                        <Card title={goal_progress?.goalName}>
                            <CommunityGoalStatisticEllipseComponent i={i} e={goal_progress}></CommunityGoalStatisticEllipseComponent>
                        </Card>
                        <Card title={'Description'}>
                            <Descriptions.Item label='Description'
                                labelStyle={{ fontWeight: 'bold' }}
                            >
                                <div dangerouslySetInnerHTML={{ __html: goal_progress.description }} />
                            </Descriptions.Item>
                        </Card>
                        {goal_progress.goalType == "Challenge" &&
                            <Card title={'Challenges'}>
                                {goal_progress?.challengeIds?.map(item => <Tag key={item._id}>{item.name}</Tag>)}
                            </Card>
                        }
                    </Col>
                ))}
            </Row>


        </div>
    )
}