import { FETCH_ALL_QUESTS, FETCH_ALL_QUESTS_AVAIL_FOR_SETUP_COMMUNITY, FETCH_QUEST_BY_ID, FETCH_ALL_QUEST_FOR_MESSAGING, FETCH_ALL_QUESTS_TRANSACTIONS } from "./types"

const initialState = {
    quests: [],
    availQuests: [],
    quest: {},
    questForMessaging: [],
    questsTransactions: []
}

const questReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_QUESTS_TRANSACTIONS:
            return { ...state, questsTransactions: action.payload } 
        case FETCH_ALL_QUESTS:
            return { ...state, quests: action.payload }

        case FETCH_ALL_QUESTS_AVAIL_FOR_SETUP_COMMUNITY:
            return { ...state, availQuests: action.payload }

        case FETCH_QUEST_BY_ID:
            return { ...state, quest: action.payload }

        case FETCH_ALL_QUEST_FOR_MESSAGING:
            return { ...state, questForMessaging: action.payload }

        default:
            return state
    }
}

export default questReducer