import { FETCH_ALL_QUESTS, FETCH_ALL_QUESTS_AVAIL_FOR_SETUP_COMMUNITY, FETCH_QUEST_BY_ID, FETCH_ALL_QUEST_FOR_MESSAGING, FETCH_ALL_QUESTS_TRANSACTIONS } from "./types"
import { toggleNetworkLoading } from "../common/actions"
import { message } from 'antd'
import ServerApi from "../../utility/ServerApi"
import { PAGINATION } from "../../const/utils"

export const fetchAllQuests = (filters = PAGINATION) => dispatch => {
  dispatch(toggleNetworkLoading(true))
  ServerApi().get(
    `/quests/admin-fetch-all?current=${filters.current || 1}&pageSize=${filters.pageSize || 100}
    ${filters?.search ? `&search=${encodeURIComponent(filters?.search)}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.cityId ? `&cityId=${filters?.cityId}` : ''}${filters?.questId ? `&questId=${filters?.questId}` : ''}
    `)
    .then(res => {
      dispatch({
        type: FETCH_ALL_QUESTS,
        payload: res.data
      })
      dispatch(toggleNetworkLoading(false))
    })
    .catch(e => {
      dispatch(toggleNetworkLoading(false))
      message.error("Error in Fecthing Quests Details", e)
      // console.log(e)
    })
}


export const fetchAllQuestsTransactions = (filters = PAGINATION) => dispatch => {
  dispatch(toggleNetworkLoading(true))
  ServerApi().get(
    `/quest-instances/admin-fetch-all-quests-transactions?current=${filters.current || 1}&pageSize=${filters.pageSize || 100}
    ${filters?.search ? `&search=${encodeURIComponent(filters?.search)}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.cityId ? `&cityId=${filters?.cityId}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.questId ? `&questId=${filters?.questId}` : ''}
    `)
    .then(res => {
      dispatch({
        type: FETCH_ALL_QUESTS_TRANSACTIONS,
        payload: res.data
      })
      dispatch(toggleNetworkLoading(false))
    })
    .catch(e => {
      dispatch(toggleNetworkLoading(false))
      // message.error("Error in Fecthing Quests Details", e)
      console.error(e)
    })
}

export const fetchAllQuestsForMessaging = () => dispatch => {
  dispatch(toggleNetworkLoading(true))
  ServerApi().get(
    `/quests/admin-all-quests-for-messagings`)
    .then(res => {
      dispatch({
        type: FETCH_ALL_QUEST_FOR_MESSAGING,
        payload: res?.data?.result
      })
      dispatch(toggleNetworkLoading(false))
    })
    .catch(e => {
      dispatch(toggleNetworkLoading(false))
      message.error("Error in Fecthing Quests Details", e)
      console.log(e)
    })
}

/**
 * 
 * @param {*} filters 
 * @returns 
 */
export const fetchAllQuestsAvailableForSetupCommunity = (filters = PAGINATION) => dispatch => {
  // dispatch(toggleNetworkLoading(true))
  ServerApi().get(
    `/quests/admin-fetch-all-for-create-edit-community?${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}`)
    .then(res => {
      dispatch({
        type: FETCH_ALL_QUESTS_AVAIL_FOR_SETUP_COMMUNITY,
        payload: res.data
      })
      // dispatch(toggleNetworkLoading(false))
    })
    .catch(e => {
      // dispatch(toggleNetworkLoading(false))
      // message.error("Error in Fecthing Quests Details", e)
      console.log(e)
    })
}

export const createQuest = (rawData, successCallback) => dispatch => {
  ServerApi().post(`/quests`, rawData)
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        message.success("New Quest Created")
        successCallback(res.data)
      } else {
        message.error("Error Creating Quest!")
      }
      successCallback()
    })
    .catch(e => {
      message.error("Error Creating New Quest", e)
      console.log(e)
    })
}

export const fetchQuestById = (id) => dispatch => {
  dispatch(toggleNetworkLoading(true))
  ServerApi().get(`/quests/${id}`)
    .then(res => {
      const data = res.data
      dispatch({
        type: FETCH_QUEST_BY_ID,
        payload: data
      })
      dispatch(toggleNetworkLoading(false))
    })
    .catch(e => {
      message.error("Error fetching data!")
      dispatch(toggleNetworkLoading(false))
      console.log(e)
    })
}

export const updateQuest = (_id, rawData, successCallback) => dispatch => {
  ServerApi().patch(`quests/${_id}`, rawData)
    .then(res => {

      if (res.status === 200 || res.status === 201) {
        message.success("Quest updated.")
      } else {
        message.warning("Unable to update Quest.")
      }
      dispatch(fetchAllQuests(false))
      successCallback()
    })
    .catch(e => {
      message.error(e)
    })
}

export const deleteQuestById = (id) => dispatch => {
  ServerApi().delete(`quests/${id}`)
    .then(res => {
      message.success("Quest Deleted Succesfully!")
      dispatch(fetchAllQuests())
    })
    .catch(e => {
      message.error(e?.response?.data?.message || "Error Deleting")
      console.log(e)
    })
}

