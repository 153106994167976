import { FETCH_STEPS_TRACKING_LOGS_TRANSACTIONS } from "./types"
import { toggleNetworkLoading } from "../common/actions"
import { message } from 'antd'
import ServerApi from "../../utility/ServerApi"
import { PAGINATION } from "../../const/utils"

export const fetchStepsTrackingLogsTransactions = (filters = PAGINATION, callback = null) => dispatch => {
    console.log(`fetchStepsTrackingLogsTransactions filters `, filters)
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/steps-tracking-logs/tracking-logs?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`)
        .then(res => {
            dispatch({
                type: FETCH_STEPS_TRACKING_LOGS_TRANSACTIONS,
                payload: res.data
            })
            if (callback) callback(res.data)
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in  fetchStepsTrackingLogsTransactions", e)
            console.log(e)
        })
} 