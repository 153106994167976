import { FETCH_STEPS_TRACKING_LOGS_TRANSACTIONS } from "./types"

const initialState = {
    stepsTrackingTransactionsLogs: []
}

const stepsTrackingLogsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_STEPS_TRACKING_LOGS_TRANSACTIONS:
            return { ...state, stepsTrackingTransactionsLogs: action.payload }        
        default:
            return state
    }
}

export default stepsTrackingLogsReducer