import { FETCH_ACTIVITY_TRANSACTION, FETCH_SHARE_WITH_COMMUNITY_ACTIVITY_TRANSACTION, FETCH_SHARE_WITH_COMMUNITY_TRANSACTION_BY_ID, FETCH_TRANSACTION_BY_ID } from "./types"

const initialState = {
    transactions: [],
    transaction: {},
    shareWithCommunityTransactions: [],
    shareWithCommunityTransaction: {}
}

const transactionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ACTIVITY_TRANSACTION:
            return { ...state, transactions: action.payload }
        case FETCH_TRANSACTION_BY_ID:
            return { ...state, transaction: action.payload }
        case FETCH_SHARE_WITH_COMMUNITY_ACTIVITY_TRANSACTION:
            return { ...state, shareWithCommunityTransactions: action.payload }
        case FETCH_SHARE_WITH_COMMUNITY_TRANSACTION_BY_ID:
            return { ...state, shareWithCommunityTransaction: action.payload }
        default:
            return state
    }
}

export default transactionsReducer