import React, { useState, useEffect, useRef } from 'react'
import { Modal, Collapse, Button, DatePicker, Radio, Select, Form, Input, message, Typography } from 'antd'
// import { InfoCircleOutlined, UploadOutlined, MinusCircleOutlined, PlusOutlined, CopyOutlined } from '@ant-design/icons'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
// import { useNavigate, Link, useParams } from 'react-router-dom'
import { fetchCurrentAppVersionInfo, updateAppVersionInfo } from '../../../store/appVersion/action'
import '../../../assets/css/editorStyles.css'
const { Text, Link, Title } = Typography
const { Panel } = Collapse
import { BE_FULL_DATE_FORMAT } from '../../../const/utils'

export const ManageAppVersionInfo = () => {

  // const [form] = Form.useForm()
  // const navigate = useNavigate()
  const dispatch = useDispatch()

  const currentAppVersionInfo = useSelector(state => state?.appVersion?.currentAppVersionInfo)


  useEffect(() => {
    dispatch(fetchCurrentAppVersionInfo())
  }, [])

  useEffect(() => {
    // console.log(`currentAppVersionInfo `, currentAppVersionInfo)
  }, [currentAppVersionInfo])

  const successCallback = () => {
    // navigate('/activities')
    dispatch(fetchCurrentAppVersionInfo())
  }

  const _onUpdateVersionInfo = (values) => {
    // console.log(`_onUpdateVersionInfo `, values)
    dispatch(updateAppVersionInfo(values, successCallback))
  }

  const _renderAppVersionByPlatform = (_versionInfo) => {
    // console.log(`_versionInfo `, _versionInfo)
    return (
      <Panel
        header={<p className='font-bold text-lg'>{_versionInfo?.platform}</p>}
        key={_versionInfo._id}
        className="bg-textbackgroundcolor"
        isActive={true}
      >
        <Form
          name={_versionInfo?.platform}
          // form={form}
          labelCol={{
            span: 4
          }}
          onFinish={(values) => _onUpdateVersionInfo(values)}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{
            versionId: _versionInfo._id,
            newVersionAvailable: _versionInfo?.newVersionAvailable,
            releaseType: _versionInfo?.versionInfo?.releaseType,
            appUrl: _versionInfo?.versionInfo?.appUrl,
            versionNo: _versionInfo?.versionInfo?.versionNo,
            releaseTitle: _versionInfo?.versionInfo?.releaseTitle,
            releaseMessage: _versionInfo?.versionInfo?.releaseMessage
          }}
        >
          <Form.Item
            name="versionId"
            hidden={true}
          >
            <Input></Input>
          </Form.Item>
          <Form.Item
            name="newVersionAvailable"
            colon={false}
            labelAlign="left"
            label="New version available"
            rules={[{ required: false }]}
          >
            <Radio.Group className="text-left">
              <Radio value={1}>Yes</Radio>
              <Radio value={0}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="releaseType"
            colon={false}
            labelAlign="left"
            label="Release type"
            rules={[{ required: false }]}
          >
            <Radio.Group className="text-left" >
              <Radio value={1}>Optional</Radio>
              <Radio value={2}>Mandatory</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="App URL"
            name="appUrl"
            labelAlign="left"
            colon={false}
            rules={[{ required: true, message: 'Please enter app url' }]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="App Version"
            name="versionNo"
            labelAlign="left"
            colon={false}
            rules={[{ required: true, message: 'Please enter version number!' }]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Release title"
            name="releaseTitle"
            labelAlign="left"
            colon={false}
            rules={[{ required: true, message: 'Please enter title!' }]}
            hasFeedback
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Release message"
            name="releaseMessage"
            labelAlign="left"
            colon={false}
            rules={[{ required: true, message: 'Please enter message!' }]}
            hasFeedback
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="lastUpdatedAt"
            colon={false}
            labelAlign="left"
            label="Last Updated At"
            rules={[{ required: false }]}
          >
            <Text>{moment(_versionInfo?.updatedAt).format(BE_FULL_DATE_FORMAT)}</Text>
          </Form.Item>

          <div className="text-center pt-2 pb-4">
            <Button className="bg-[#207868]" size="large" type="primary" htmlType="submit">
              Save Changes
            </Button>
          </div>
        </Form>
      </Panel >
    )
  }
  return (
    <>
      <div className="shadow bg-white">
        <Collapse className="border-none" expandIconPosition={'end'} activeKey={currentAppVersionInfo?.map(panel => panel._id)}>
          {currentAppVersionInfo?.map(i => (
            _renderAppVersionByPlatform(i)
          ))}
        </Collapse>

        {/* <div className="text-center pt-2 pb-4">
            <Button className="bg-[#207868]" size="large" type="primary" htmlType="submit">
              Save Changes
            </Button>
          </div> */}
      </div>
    </>
  )
}