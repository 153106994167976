import { FETCH_MIAANIMATIONS_ANIMATION_CONFIG_LIST, FETCH_MIAANIMATIONS_CHALLENGES_LIST, FETCH_MIAANIMATIONS_USERS_LIST } from "./types"
import { toggleNetworkLoading } from "../common/actions"
import { message } from 'antd'
import ServerApi from "../../utility/ServerApi"

/**
 * 
 * @returns 
 */
export const fetchMiaAnimationsUsersList = () => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/mia/test-users?verified=true`)
        .then(res => {
            dispatch({
                type: FETCH_MIAANIMATIONS_USERS_LIST,
                payload: res.data.result
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fectching Data", e)
            console.error(e)
        })
}

/**
 * 
 * @returns 
 */
export const fetchMiaAnimationsChallengesList = () => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/broadcastings/challenges-list`)
        .then(res => {
            dispatch({
                type: FETCH_MIAANIMATIONS_CHALLENGES_LIST,
                payload: res.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fectching Data", e)
            console.error(e)
        })
}

export const fetchMiaAnimationsConfigList = () => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/mia/animations-config`)
        .then(res => {
            dispatch({
                type: FETCH_MIAANIMATIONS_ANIMATION_CONFIG_LIST,
                payload: res.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fectching Data", e)
            console.error(e)
        })
}

export const createNewMiaAnimation = (rawData, successCallback) => dispatch => {
    ServerApi().post(`/mia/push-animation`, rawData)
        .then(res => {
            if (res.status === 200 || res.status === 201) {
                message.success(res.data.message)
                successCallback(res.data)
            } else {
                message.error("Error testing new animation!")
                successCallback(null)
            }
        })
        .catch(e => {
            message.error("Error testing new animation!")
            console.error(e)
        })
} 