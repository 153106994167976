import { FETCH_ALL_DEVICE_BLACKLISTED } from "./types"

const initialState = {
    devices: []
}

const devicesBlacklistedReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_DEVICE_BLACKLISTED:
            return { ...state, devices: action.payload }
        default:
            return state
    }
}

export default devicesBlacklistedReducer