export const FETCH_TEAMS_LEADERBOARD = 'FETCH_TEAMS_LEADERBOARD'
export const FETCH_MEMBERS_LEADERBOARD = 'FETCH_MEMBERS_LEADERBOARD'
export const FETCH_ENGAGEMENT_BY_COMMUNITY = 'FETCH_ENGAGEMENT_BY_COMMUNITY'
export const FETCH_ENGAGEMENT_BY_TEAM_JOINING_RATE = 'FETCH_ENGAGEMENT_BY_TEAM_JOINING_RATE'
export const FETCH_IMPACT_BY_ACTIVITIES = 'FETCH_IMPACT_BY_ACTIVITIES'
export const FETCH_IMPACT_BY_GOALS = 'FETCH_IMPACT_BY_GOALS'
export const FETCH_IMPACT_BY_HABITS = 'FETCH_IMPACT_BY_HABITS'
export const FETCH_IMPACT_BY_REWARDS = 'FETCH_IMPACT_BY_REWARDS'
export const FETCH_ENGAGEMENT_BY_PARTICIPATION_KPIS = 'FETCH_ENGAGEMENT_BY_PARTICIPATION_KPIS'
export const FETCH_ENGAGEMENT_BY_ACTIVITY_KPIS = 'FETCH_ENGAGEMENT_BY_ACTIVITY_KPIS'
export const FETCH_COMMUNITY_REPORTS_COMMUNITIES_LIST = 'FETCH_COMMUNITY_REPORTS_COMMUNITIES_LIST'
export const FETCH_COMMUNITY_CHALLENGES_TRANSACTIONS = 'FETCH_COMMUNITY_CHALLENGES_TRANSACTIONS'
export const FETCH_COMMUNITY_REPORTS_QUESTS_LIST = 'FETCH_COMMUNITY_REPORTS_QUESTS_LIST'