import React from 'react'
import { useNavigate, useLocation } from 'react-router'
import { Menu, Tooltip } from 'antd'
import navigation from '../../navigations/navigations.const'
import { canIAccessThisWithMyRoles } from '../../const/utils'

const { SubMenu } = Menu

const AdminSidePanelMenu = props => {

  const navigate = useNavigate()
  const location = useLocation()

  const findSelected = (open = false) => {
    const selectedMenues = []
    const openMenues = []
    navigation.forEach(navElementI => {
      if (navElementI?.children) {
        navElementI?.children?.forEach(navChildElementJ => {
          if (navChildElementJ?.route === location.pathname) {
            selectedMenues.push(navChildElementJ.route)
            openMenues.push(navElementI.title)
          }
          if (navChildElementJ?.children) {
            navChildElementJ?.children.forEach(elementK => {
              if (elementK?.SubMenus?.route === location.pathname) {
                selectedMenues.push(elementK.route)
                openMenues.push(navChildElementJ.title)
              }
            })
          }
        })
      } else {
        if (navElementI?.route === location.pathname) {
          selectedMenues.push(navElementI.route)
        }
      }
    })
    return open ? openMenues : selectedMenues
  }

  const childrenPermissionsChecker = (menus) => {
    let anyPermision = false

    menus.map(i => {
      anyPermision = true
    })

    return anyPermision
  }

  const navMenu = () => {
    return navigation.filter(i => canIAccessThisWithMyRoles(i.role))
  }

  return (
    <Menu className='admin-side-bar-menu' theme="light" defaultOpenKeys={() => findSelected(true)} defaultSelectedKeys={() => findSelected()} mode="inline">
      {navMenu().map((i, index) => (
        <React.Fragment key={`main_${index}`}>
          {!i.children && (
            <>
              <Menu.Item onClick={() => navigate(i.route)} key={i.route} icon={i.icon} style={{ whiteSpace: 'normal', height: 'auto' }}>
                {i.title}
              </Menu.Item>
            </>
          )}
          {i.children && (
            <>
              {childrenPermissionsChecker(i.children) && (
                <SubMenu key={i.title} icon={i.icon} title={i.title}>
                  {i.children.map((j, idx) => (
                    <React.Fragment key={`sub1_${index}_${idx}`}>
                      <Menu.Item onClick={() => navigate(j.route)} key={`${j.route}_${index}_${idx}`}>
                        <Tooltip
                          // placement="right"
                          title={j.title}
                        // className="tooltip"
                        >
                          {j.title}
                        </Tooltip>
                        {
                          j.children && (
                            <>
                              {childrenPermissionsChecker(j.children) && (
                                <SubMenu key={j.title} icon={j.icon} title={j.title}>
                                  {j?.children?.map((k, idxk) => {
                                    return (<React.Fragment key={`sub2_${index}_${idx}_${idxk}`}>
                                      <Menu.Item onClick={() => navigate(k.route)} key={`${k.route}_${index}_${idx}_${idxk}`}>
                                        {k.title}
                                      </Menu.Item>
                                    </React.Fragment>)
                                  })}
                                </SubMenu>
                              )}
                            </>
                          )
                        }
                      </Menu.Item>
                    </React.Fragment>
                  ))}
                </SubMenu>
              )}
            </>
          )}
        </React.Fragment>
      ))}
    </Menu>
  )
}

export default AdminSidePanelMenu
