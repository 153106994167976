import React, { useEffect, useState } from 'react'
// import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { Card, Col, Row, Statistic, Descriptions, Tag } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMyCorporateProfile } from '../../../store/community/actions'

// import fakeReport31 from '../../../assets/images/3.1-Challenge-Engagement.png'
// import fakeReport33 from '../../../assets/images/3.3-Popular-Challenges.png'
// import fakeReport41 from '../../../assets/images/4.1-Activity-Engagement.png'
// import fakeReport43 from '../../../assets/images/4.3-Popular-Activities.png'
// import fakeReport61 from '../../../assets/images/6.1-User-Growth.png'
import { CommunityGoalStatisticEllipseComponent } from './components/CommunityGoalStatisticEllipse'
// import { getPowerBIReports } from '../../../store/reports/action'
// import { PowerBIEmbed } from 'powerbi-client-react'
// import { models } from "powerbi-client"

/**
 * #43296 - My Corporate Dashboard Home
 * @returns 
 */
const MyCorporateDashboard = () => {

  const dispatch = useDispatch()

  const myCorporateProfile = useSelector(state => state.community.myCorporateProfile)
  // const powerbiDashboardReport = useSelector(state => state.reports.powerbiDashboardReport)
  const [embedConfig, setEmbebConfig] = useState([])
  // const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    dispatch(fetchMyCorporateProfile())
    // dispatch(getPowerBIReports())
  }, [])


  // useEffect(() => {
  //   if (isReady === false) {
  //     if (powerbiDashboardReport && myCorporateProfile) {
  //       setIsReady(true)
  //       // const powerBiReportEmbedConfigList = powerbiDashboardReport?.reportPage.map((item) => {
  //       //   const embedUrlString = `${powerbiDashboardReport.embedUrl}&filter=communities/_id eq '${myCorporateProfile._id}'`
  //       //   const prepairData = {
  //       //     type: 'report',
  //       //     id: powerbiDashboardReport.id,
  //       //     accessToken: powerbiDashboardReport?.accessToken?.token,
  //       //     embedUrl: embedUrlString,
  //       //     settings: {
  //       //       customLayout:{
  //       //         displayOption: 1
  //       //       },
  //       //       layoutType: 1,
  //       //       navContentPaneEnabled: false,
  //       //       panes: {
  //       //         filters: {
  //       //           expanded: false,
  //       //           visible: false
  //       //         }
  //       //       },
  //       //       background: models.BackgroundType.Transparent
  //       //     },
  //       //     tokenType: models.TokenType.Embed,
  //       //     pageName: item?.pageSectionName,
  //       //     pageTitle: item?.pageName
  //       //   }
  //       //   return prepairData
  //       // })
  //       // setEmbebConfig(powerBiReportEmbedConfigList)
  //     }
  //   }
  // }, [powerbiDashboardReport, isReady, myCorporateProfile])

  return (
    <div className='shadow bg-white p-4'>
      <PageHeader
        ghost={false}
        title={myCorporateProfile?.name}
      ></PageHeader>

      <div>
        <Row gutter={16} className='mb-20'>
          {myCorporateProfile?.communityGoals && myCorporateProfile.communityGoals.map((goal_progress, i) => (
            <Col span={8}>
              <Card title={goal_progress?.goalName}>
                <CommunityGoalStatisticEllipseComponent i={i} e={goal_progress}></CommunityGoalStatisticEllipseComponent>
              </Card>

              <Card title={'Description'}>
                <Descriptions.Item label='Description'
                  labelStyle={{ fontWeight: 'bold' }}
                >
                  <div dangerouslySetInnerHTML={{ __html: goal_progress.description }} />
                </Descriptions.Item>
              </Card>
              {goal_progress.goalType == "Challenge" &&
                <Card title={'Challenges'}>
                  {goal_progress?.challengeIds?.map(item => <Tag key={item._id}>{item.name}</Tag>)}
                </Card>
              }
            </Col>
          ))}
        </Row>
        {/* <Row gutter={16}>
          {
            embedConfig && embedConfig.length > 0 && 
            embedConfig.map((item, index) => {
                return (
                  <Col span={12}>
                    <Card title={item?.pageTitle}>
                      <PowerBIEmbed
                          embedConfig={item}
                          eventHandlers={
                              new Map([
                                  ['loaded', function () { console.log('Report loaded') }],
                                  ['rendered', function () { console.log('Report rendered') }],
                                  ['error', function (event) { console.log(event.detail) }]
                              ])
                          }
                          cssClassName={"report-style-class"}
                          getEmbeddedComponent={(embeddedReport) => {
                              window.report = embeddedReport
                          }}
                      />
                    </Card>
                </Col>
                )
            })
          }
        </Row> */}

        {/* <Row gutter={16}>
          <Col span={12}>
            <Card title="3.1 Challenge Engagement">
              <img src={fakeReport31} />
            </Card>
          </Col>
          <Col span={12}>
            <Card title="3.3 Popular Challenges">
              <img src={fakeReport33} />
            </Card>
          </Col>
        </Row>
        <Row gutter={16} className='pt-10'>
          <Col span={12}>
            <Card title="4.1 Activity Engagement">
              <img src={fakeReport41} />
            </Card>
          </Col>
          <Col span={12}>
            <Card title="4.3 Popular Activities">
              <img src={fakeReport43} />
            </Card>
          </Col>
        </Row>
        <Row gutter={16} className='pt-10'>
          <Col span={12}>
            <Card title="6.1 User Growth">
              <img src={fakeReport61} />
            </Card>
          </Col>
        </Row> */}
      </div>
    </div>
  )
}

export default MyCorporateDashboard