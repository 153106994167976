import React, { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Table, Input, Tooltip, Tag } from 'antd'
import { SearchOutlined, EditOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllGoals } from '../../../store/goal/actions'
import PreviewImage from '../../../components/image/PreviewImage'
import { DEFAULT_PAGE_SIZE } from '../../../const/utils'

const Goals = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const goals = useSelector(state => state?.goals?.goals)

  useEffect(() => {
    dispatch(fetchAllGoals())
  }, [])

  const [goalsData, setGoalsData] = useState(goals)
  const [wordEntered, setWordEntered] = useState()


  useEffect(() => {
    setGoalsData(goals)
  }, [goals])

  const handleSearch = (event) => {
    const searchWord = event?.target?.value
    setWordEntered(searchWord)
    const newFilter = goals?.filter((values) => {
      return values?.name?.toLowerCase().includes(searchWord?.toLowerCase()) || values?.redemption_name?.toLowerCase()?.includes(searchWord?.toLowerCase()) || values?.redemption_cost?.toString()?.toLowerCase()?.includes(searchWord?.toLowerCase())
    })

    if (searchWord === "") {
      setGoalsData(goals)
    } else {
      setGoalsData(newFilter)
    }
  }

  const columns = [
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
      // ...getColumnSearchProps('name')
    },
    {
      title: 'Redeem Item',
      dataIndex: 'redemption_name',
      key: 'redemption_name'
      // ...getColumnSearchProps('redemption_name')
    },
    {
      title: 'Redeem Cost',
      dataIndex: 'redemption_cost',
      key: 'redemption_cost'
      // ...getColumnSearchProps('redemption_cost')
    },
    {
      title: 'Habits',
      dataIndex: 'habits',
      key: 'habits'
      // width: '40%',
      // ...getColumnSearchProps('habits')
    },
    {
      title: 'Share Link',
      dataIndex: 'shareUrl',
      key: 'shareUrl'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.length - b.status.length,
      sortDirections: ['descend', 'ascend']
      // ...getColumnSearchProps('status')
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      width: '100px'
    }
  ]

  return (
    <div className='bg-white'>
      <PageHeader
        ghost={false}
        title="Manage Goals & Habits"
      // extra={[<Button icon={<PlusOutlined />} type='primary' className='bg-[#207868] flex items-center' onClick={() => navigate('/goals/add')} >New Goal</Button>]}
      ></PageHeader>
      <Input
        className='w-[220px] h-[40px] ml-4 mb-5'
        placeholder='Search'
        value={wordEntered}
        onChange={handleSearch}
        prefix={<SearchOutlined />}
      />
      <Table
        className='z-[0] relative overflow-auto'
        columns={columns}
        dataSource={
          goalsData.map(i => ({
            key: i._id,
            icon: <PreviewImage src={i?.icon} size='w-20' />,
            name: i.name,
            redemption_name: i?.rewardId?.name,
            redemption_cost: i?.redemption_cost,
            habits: i.habits?.map(i => <Tag className='my-0.5' color="blue" key={i?._id}>{i.name}</Tag>),
            // publish: <Tag color={i.publish === true ? 'green' : 'red'}>{i.publish ? 'Published' : 'Not published'}</Tag>,
            shareUrl: i.qrCode && (
              <>
                {i.qrCode.qr_code &&
                  <img
                    className="w-20"
                    src={`${i.qrCode.qr_code}`}
                  />
                }
                <CopyToClipboard text={i.qrCode.share_url}>
                  <p className='cursor-pointer text-blue-400 hover:text-blue-800 hover:underline' onClick={() => message.success('Link copied to Clipboard')}>Share Link</p>
                </CopyToClipboard>
              </>
            ),
            status: <Tag color={i.publish === true ? 'green' : 'red'}>{i.publish ? 'Published' : 'Not published'}</Tag>,
            action:
              <div style={{ minWidth: '65px', textAlign: 'center' }}><Tooltip title="Edit">
                <Button style={{ marginRight: "8px" }} onClick={() => { navigate((`/goals/edit/${i._id}`)) }} size="small" type="ghost" shape="circle" icon={<EditOutlined />} />
              </Tooltip>
              </div>
          }))}
        pagination={{
          showSizeChanger: true,
          hideOnSinglePage: true,
          position: ["none", "bottomRight"],
          defaultPageSize: DEFAULT_PAGE_SIZE
        }}
      />
    </div>
  )
}

export default Goals

