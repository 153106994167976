export const CommunityIndexTable = [
    // {
    //     title: 'No',
    //     dataIndex: 'srno',
    //     key: 'srno',
    //     notAllowToSearch: true
    // },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        notAllowToSearch: true
    },
    {
        title: 'Total Team',
        dataIndex: 'noOfTeam',
        key: 'noOfTeam',
        notAllowToSearch: true
    },
    {
        title: 'Total Member',
        dataIndex: 'totalMembers',
        key: 'totalMembers',
        notAllowToSearch: true
        // width: '20%',
        // ...getColumnSearchProps('totalMembers')
    },
    {
        title: 'Timezone',
        dataIndex: 'timezone',
        key: 'timezone',
        notAllowToSearch: true
        // width: '20%',
        // ...getColumnSearchProps('totalMembers')
    },
    {
        title: 'Created Date',
        dataIndex: 'createdAt',
        key: 'createdAt',
        notAllowToSearch: true
    },
    {
        title: 'State',
        dataIndex: 'communityStatus',
        // key: 'status',
        // sorter: (a, b) => a.status.length - b.status.length,
        // sortDirections: ['descend', 'ascend'],
        notAllowToSearch: true
    },
    {
        title: 'Status',
        dataIndex: 'published',
        notAllowToSearch: true
        // key: 'status',
        // sorter: (a, b) => a.published.length - b.published.length,
        // sortDirections: ['descend', 'ascend']
    },
    {
        title: 'Action',
        dataIndex: 'action',
        // key: 'action',
        fixed: 'right',
        width: '120px',
        notAllowToSearch: true
    }
]