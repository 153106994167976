import React, { Suspense } from 'react'
import { Spin } from 'antd'
import {
    BrowserRouter,
    Routes as RouterRoutes,
    Route
} from "react-router-dom"
import AdminLayout from '../layouts/AdminLayout'
import GuestLayout from '../layouts/GuestLayout'

import { Login } from '../views/auth/login'
import { ChangePassword } from '../views/auth/ChangePassword'
import { ResetPassword } from '../views/auth/ResetPassword'
import { Community } from '../views/admin/community/index'
import { NewCommunity } from '../views/admin/community/NewCommunity'
import { EditCommunity } from '../views/admin/community/EditCommunity'
import { Activity } from '../views/admin/Activity'
import { NewActivity } from '../views/admin/Activity/NewActivity'
import { EditActivity } from '../views/admin/Activity/EditActivity'
import Dashboard from '../views/admin/dashboard'
import MyCorporateDashboard from '../views/admin/corporate-dashboard'
import Goals from '../views/admin/goals'
import AddEdit from '../views/admin/goals/AddEdit'
import EditGoal from '../views/admin/goals/EditGoals'
import { Challenges } from '../views/admin/challenges'
import { AddChallenge } from '../views/admin/challenges/NewChallenge'
import { EditChallenge } from '../views/admin/challenges/EditChallenge'
import { Quests } from '../views/admin/quests'
import { AddQuest } from '../views/admin/quests/NewQuest'
import { EditQuest } from '../views/admin/quests/EditQuest'
import { Messaging } from '../views/admin/Messaging'
import { NewMessageDefault } from '../views/admin/Messaging/NewMessageDefault'
// import { UpdateMessageDefault } from '../views/admin/Messaging/EditMessageDefault'
// import { NewMessageGeneral } from '../views/admin/Messaging/NewMessageGeneral'
import { Banners } from '../views/admin/Banners'
import { NewBanner } from '../views/admin/Banners/NewBanner'
import { EditBanner } from '../views/admin/Banners/EditBanner'
import { Users } from '../views/admin/Users'
import { UserInfo } from '../views/admin/Users/UserInfo'
import { AdminUsers } from '../views/admin/Users/AdminUsers'
import { AddEditAdminUsers } from '../views/admin/Users/AddEditAdminUser'
import { Teams } from '../views/admin/teams'
import { NewTeam } from '../views/admin/teams/NewTeam'
import { EditTeam } from '../views/admin/teams/EditTeam'
import { Transactions } from '../views/admin/Transactions'
import { TransactionInfo } from '../views/admin/Transactions/TransactionInfo'
import { RedeemPointsRewardTransactions } from '../views/admin/Transactions/RedeemPointsRewardTransactions'
import { ChallengeRewardTransactions } from '../views/admin/Transactions/ChallengeRewardTransactions'
import { CommunityImpactRewardTransactions } from '../views/admin/Transactions/CommunityImpactRewardTransactions'
import { BroadcastingTool } from '../views/admin/Broadcasting'
import { MiaAnimations } from '../views/admin/MiaAnimations'
import Locations from '../views/admin/locations'
import EditLocation from '../views/admin/locations/EditLocation'
import AddNewLocation from '../views/admin/locations/AddNewLocation'
import { DeletedUsers } from '../views/admin/Users/DeletedUsers'
import { RolesAuthRoute } from './RolesAuthRoute'
import { SUSGAIN_ROLES } from '../const/roles'
import { PrivateRoute } from './PrivateRoute'
import { ManageAppVersionInfo } from '../views/admin/appVersion'
import { ZohoTransactions } from '../views/admin/zoho'
import { VoucherTransactions } from '../views/admin/voucher'
import { DomainWhitelisted } from '../views/admin/domains'
import { ILightList } from '../views/admin/iLight'
import { ILightInfo } from '../views/admin/iLight/iLightInfo'
import { StepsTrackingLogsTransactions } from '../views/admin/steps-tracking-logs'
import { CommunityGoals } from '../views/admin/CommunityReport/CommunityGoals'
import { LeaderBoardByTeams } from '../views/admin/CommunityReport/LeaderBoard/ByTeams'
import { LeaderBoardByMembers } from '../views/admin/CommunityReport/LeaderBoard/ByMember'
import { PNSQueueLogsTransactions } from '../views/admin/pnsqueue-logs'
import { EngagementByCommunity } from '../views/admin/CommunityReport/Engagement/ByCommunity'
import { EngagementByTeamJoiningRate } from '../views/admin/CommunityReport/Engagement/ByTeamJoiningRate'
import { ImpactByActivities } from '../views/admin/CommunityReport/Impact/ByActivities'
import { ImpactByGoals } from '../views/admin/CommunityReport/Impact/ByGoals'
import { ImpactByHabits } from '../views/admin/CommunityReport/Impact/ByHabits'
import { ImpactByRewards } from '../views/admin/CommunityReport/Impact/ByRewards'
import { EngagementByParticipationKPIs } from '../views/admin/CommunityReport/Engagement/ByParticipationKPIs'
import { EngagementByActivityParticipationKPIs } from '../views/admin/CommunityReport/Engagement/ByActivityParticipation'
import { CommunityChallengesTransactions } from '../views/admin/CommunityReport/Challenges'
import { CommunityScoringTransactions } from '../views/admin/Transactions/CommunityScoringTransactions'
import { DeviceBlacklisted } from '../views/admin/devices'
import { ShareWithCommunityTransactions } from '../views/admin/Transactions/ShareWithCommunityTransactions'
import { ShareWithCommunityTransactionInfo } from '../views/admin/Transactions/ShareWithCommunityTransactionInfo'
import { PendingShareWithCommunityTransactions } from '../views/admin/Transactions/PendingShareWithCommunityTransactions'
import { PendingTransactions } from '../views/admin/Transactions/PendingTransactions'
import { QuestTransactions } from '../views/admin/Transactions/QuestTransactions'
import { QuestPendingTransactions } from '../views/admin/Transactions/QuestPendingTransactions'
import { QuestsDetailTransactions } from '../views/admin/CommunityReport/Quests'
const Navigations = () => {

    return (
        <BrowserRouter basename={'/'}>
            <Suspense fallback={<AdminLayout><div className="text-center w-100 h-screen pt-40"><Spin /></div></AdminLayout>}>
                <RouterRoutes>

                    <Route path="/login" element={
                        <GuestLayout>
                            <Login />
                        </GuestLayout>
                    } />

                    <Route path="user/password-reset/:token" element={<GuestLayout><ResetPassword /></GuestLayout>} />

                    {/* Private Route */}
                    <Route element={<PrivateRoute />}>
                        <Route path="/change-password" element={<AdminLayout><ChangePassword /></AdminLayout>} />

                        {/* <Route path="/" element={<RolesAuthRoute roles={[SUSGAIN_ROLES.COMMUNITY_LEADER.role]} />} >
                            <Route path="/communities" element={<AdminLayout><Community /></AdminLayout>} />

                            <Route path="/communities/edit/:communityId" element={<AdminLayout><EditCommunity /></AdminLayout>} />

                            <Route path="/teams" element={<AdminLayout><Teams /></AdminLayout>} />                        
                            <Route path="/teams/edit/:teamId" element={<AdminLayout><EditTeam /></AdminLayout>} />
                        </Route> */}
                        <Route path="/dashboard" element={<RolesAuthRoute roles={[SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role, SUSGAIN_ROLES.CONTENT_EDITOR.role, SUSGAIN_ROLES.QUEST_LEADER.role]} />}>
                            <Route path="/dashboard" element={<AdminLayout><Dashboard /></AdminLayout>} />
                        </Route>

                        <Route path="/corporate-dashboard" element={<RolesAuthRoute roles={[SUSGAIN_ROLES.COMMUNITY_LEADER.role]} />}>
                            <Route path="/corporate-dashboard" element={<AdminLayout><MyCorporateDashboard /></AdminLayout>} />
                        </Route>

                        <Route path="/share-with-community-transactions" element={<RolesAuthRoute roles={[SUSGAIN_ROLES.COMMUNITY_LEADER.role, SUSGAIN_ROLES.ADMIN.role]} />}>
                            <Route path="/share-with-community-transactions" element={<AdminLayout><ShareWithCommunityTransactions /></AdminLayout>} />
                            <Route path="/share-with-community-transactions/info/:transactionId" element={<AdminLayout><ShareWithCommunityTransactionInfo /></AdminLayout>} />
                        </Route>
                        <Route path="/transactions" element={<RolesAuthRoute roles={[SUSGAIN_ROLES.COMMUNITY_LEADER.role, SUSGAIN_ROLES.ADMIN.role]} />}>
                            <Route path="/transactions" element={<AdminLayout><Transactions /></AdminLayout>} />
                            <Route path="/transactions/info/:transactionId" element={<AdminLayout><TransactionInfo /></AdminLayout>} />
                        </Route>
                        <Route path="/pending-share-with-community-transactions" element={<RolesAuthRoute roles={[SUSGAIN_ROLES.COMMUNITY_LEADER.role, SUSGAIN_ROLES.ADMIN.role]} />}>
                            <Route path="/pending-share-with-community-transactions" element={<AdminLayout><PendingShareWithCommunityTransactions /></AdminLayout>} />
                        </Route>
                        <Route path="/pending-transactions" element={<RolesAuthRoute roles={[SUSGAIN_ROLES.COMMUNITY_LEADER.role, SUSGAIN_ROLES.ADMIN.role]} />}>
                            <Route path="/pending-transactions" element={<AdminLayout><PendingTransactions /></AdminLayout>} />
                        </Route>

                        <Route path="/communities" element={<RolesAuthRoute roles={[SUSGAIN_ROLES.COMMUNITY_LEADER.role, SUSGAIN_ROLES.ADMIN.role]} />} >
                            <Route path="/communities" element={<AdminLayout><Community /></AdminLayout>} />
                            <Route path="/communities/edit/:communityId" element={<AdminLayout><EditCommunity /></AdminLayout>} />
                        </Route>

                        <Route path="/teams" element={<RolesAuthRoute roles={[SUSGAIN_ROLES.COMMUNITY_LEADER.role, SUSGAIN_ROLES.ADMIN.role]} />} >
                            <Route path="/teams" element={<AdminLayout><Teams /></AdminLayout>} />
                            <Route path="/teams/edit/:teamId" element={<AdminLayout><EditTeam /></AdminLayout>} />
                        </Route>

                        <Route path="/quests" element={<RolesAuthRoute roles={[SUSGAIN_ROLES.QUEST_LEADER.role, SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.CONTENT_EDITOR.role]} />} >
                            <Route path="/quests" element={<AdminLayout><Quests /></AdminLayout>} />
                            <Route path="/quests/add" element={<AdminLayout><AddQuest /></AdminLayout>} />
                            <Route path="/quests/edit/:questId" element={<AdminLayout><EditQuest /></AdminLayout>} />
                            <Route path="/quests/quest-pending-transactions" element={<AdminLayout><QuestPendingTransactions /></AdminLayout>} />
                            {/* <Route path="/quest-pending-transactions" element={<RolesAuthRoute roles={[SUSGAIN_ROLES.QUEST_LEADER.role, SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.CONTENT_EDITOR.role]} />}>
                                
                            </Route> */}
                            {/* <Route path="/quest-activity-transactions" element={<RolesAuthRoute roles={[SUSGAIN_ROLES.QUEST_LEADER.role, SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.CONTENT_EDITOR.role]} />}>
                                
                            </Route> */}
                            <Route path="/quests/quest-activity-transactions" element={<AdminLayout><QuestTransactions /></AdminLayout>} />
                            <Route path="/quests/quest-activity-transactions/info/:transactionId" element={<AdminLayout><TransactionInfo /></AdminLayout>} />
                            <Route path="/quests/quest-transactions" element={<AdminLayout><QuestsDetailTransactions /></AdminLayout>} />
                            {/* <Route path="/quest-transactions" element={<RolesAuthRoute roles={[SUSGAIN_ROLES.QUEST_LEADER.role, SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.CONTENT_EDITOR.role]} />}>
                                
                            </Route> */}
                        </Route>

                        <Route path="/" element={<RolesAuthRoute roles={[SUSGAIN_ROLES.ADMIN.role, SUSGAIN_ROLES.ILIGHT.role, SUSGAIN_ROLES.COMMUNITY_LEADER.role, SUSGAIN_ROLES.CONTENT_EDITOR.role, SUSGAIN_ROLES.QUEST_LEADER.role]} />} >
                            {/* <Route path="/communities" element={<AdminLayout><Community /></AdminLayout>} /> */}
                            <Route path="/communities/add" element={<AdminLayout><NewCommunity /></AdminLayout>} />
                            {/* <Route path="/communities/edit/:communityId" element={<AdminLayout><EditCommunity /></AdminLayout>} /> */}

                            <Route path="/activities" element={<AdminLayout><Activity /></AdminLayout>} />
                            <Route path="/activities/add" element={<AdminLayout><NewActivity /></AdminLayout>} />
                            <Route path="/activities/edit/:activityId" element={<AdminLayout><EditActivity /></AdminLayout>} />
                            <Route path="/challenges" element={<AdminLayout><Challenges /></AdminLayout>} />
                            <Route path="/challenges/add" element={<AdminLayout><AddChallenge /></AdminLayout>} />
                            <Route path="/challenges/edit/:challengeId" element={<AdminLayout><EditChallenge /></AdminLayout>} />
                            <Route path="/messaging" element={<AdminLayout><Messaging /></AdminLayout>} />
                            <Route path="/pns-queue-logs" element={<AdminLayout><PNSQueueLogsTransactions /></AdminLayout>} />
                            {/* <Route path="/messaging/edit/default-Challenge" element={<AdminLayout><NewMessageDefault /></AdminLayout>} /> */}
                            <Route path="/messaging/add" element={<AdminLayout><NewMessageDefault /></AdminLayout>} />
                            {/* <Route path="/messaging/edit/:messagingId" element={<AdminLayout><UpdateMessageDefault /></AdminLayout>} /> */}
                            <Route path="/banners" element={<AdminLayout><Banners /></AdminLayout>} />
                            <Route path="/banners/add" element={<AdminLayout><NewBanner /></AdminLayout>} />
                            <Route path="/banners/edit/:bannerId" element={<AdminLayout><EditBanner /></AdminLayout>} />
                            <Route path="/users" element={<AdminLayout><Users /></AdminLayout>} />
                            <Route path="/users/admin" element={<AdminLayout><AdminUsers /></AdminLayout>} />
                            <Route path="/users/deleted-users" element={<AdminLayout><DeletedUsers /></AdminLayout>} />
                            <Route path="/users/admin/add" element={<AdminLayout><AddEditAdminUsers /></AdminLayout>} />
                            <Route path="/users/info/:userId" element={<AdminLayout><UserInfo /></AdminLayout>} />

                            <Route path="/teams" element={<AdminLayout><Teams /></AdminLayout>} />
                            <Route path="/teams/add" element={<AdminLayout><NewTeam /></AdminLayout>} />
                            <Route path="/teams/edit/:teamId" element={<AdminLayout><EditTeam /></AdminLayout>} />

                            {/* <Route path="/transactions" element={<AdminLayout><Transactions /></AdminLayout>} />
                            <Route path="/transactions/info/:transactionId" element={<AdminLayout><TransactionInfo /></AdminLayout>} /> */}
                            <Route path="/transactions/redeem-rewards" element={<AdminLayout><RedeemPointsRewardTransactions /></AdminLayout>} />
                            <Route path="/transactions/challenges-rewards" element={<AdminLayout><ChallengeRewardTransactions /></AdminLayout>} />
                            <Route path="/transactions/community-rewards" element={<AdminLayout><CommunityImpactRewardTransactions /></AdminLayout>} />
                            <Route path="/transactions/community-scoring" element={<AdminLayout><CommunityScoringTransactions /></AdminLayout>} />

                            <Route path="/community-goals" element={<AdminLayout>< CommunityGoals /></AdminLayout>} />
                            <Route path="/leaderboard/by-teams" element={<AdminLayout>< LeaderBoardByTeams /></AdminLayout>} />
                            <Route path="/leaderboard/by-members" element={<AdminLayout>< LeaderBoardByMembers /></AdminLayout>} />
                            <Route path="/leaderboard/engagement-by-community" element={<AdminLayout>< EngagementByCommunity /></AdminLayout>} />
                            <Route path="/leaderboard/engagement-by-team-joining-rate" element={<AdminLayout>< EngagementByTeamJoiningRate /></AdminLayout>} />
                            <Route path="/leaderboard/engagement-by-participation-kpis" element={<AdminLayout>< EngagementByParticipationKPIs /></AdminLayout>} />
                            <Route path="/leaderboard/impact-by-activities" element={<AdminLayout>< ImpactByActivities /></AdminLayout>} />
                            <Route path="/leaderboard/impact-by-goals" element={<AdminLayout>< ImpactByGoals /></AdminLayout>} />
                            <Route path="/leaderboard/impact-by-habits" element={<AdminLayout>< ImpactByHabits /></AdminLayout>} />
                            <Route path="/leaderboard/impact-by-rewards" element={<AdminLayout>< ImpactByRewards /></AdminLayout>} />
                            <Route path="/leaderboard/engagement-by-activity-kpis" element={<AdminLayout>< EngagementByActivityParticipationKPIs /></AdminLayout>} />
                            <Route path="/leaderboard/challenges-transactions" element={<AdminLayout>< CommunityChallengesTransactions /></AdminLayout>} />


                            <Route path="/transactions/zoho" element={<AdminLayout><ZohoTransactions /></AdminLayout>} />
                            <Route path="/transactions/vouchers" element={<AdminLayout><VoucherTransactions /></AdminLayout>} />
                            <Route path="/transactions/steps-tracking-logs" element={<AdminLayout><StepsTrackingLogsTransactions /></AdminLayout>} />

                            <Route path="/broadcasting" element={<AdminLayout><BroadcastingTool /></AdminLayout>} />
                            <Route path="/miaAnimations" element={<AdminLayout><MiaAnimations /></AdminLayout>} />
                            <Route path="/appVersion" element={<AdminLayout><ManageAppVersionInfo /></AdminLayout>} />
                            <Route path="/domains" element={
                                <AdminLayout>
                                    <DomainWhitelisted />
                                </AdminLayout>
                            } />
                            <Route path="/devices" element={
                                <AdminLayout>
                                    <DeviceBlacklisted />
                                </AdminLayout>
                            } />

                            <Route path="/iLight" element={
                                <AdminLayout>
                                    <ILightList />
                                </AdminLayout>
                            }
                            />
                            <Route path="/iLight/:iLightId" element={
                                <AdminLayout>
                                    <ILightInfo />
                                </AdminLayout>
                            }
                            />

                            <Route path="/locations" element={
                                <AdminLayout>
                                    <Locations />
                                </AdminLayout>
                            }
                            />
                            <Route path="/locations/add" element={
                                <AdminLayout>
                                    <AddNewLocation />
                                </AdminLayout>
                            }
                            />

                            <Route path="/locations/edit/:locationID" element={
                                <AdminLayout>
                                    <EditLocation />
                                </AdminLayout>
                            }
                            />
                            <Route path="/goals" element={
                                <AdminLayout>
                                    <Goals />
                                </AdminLayout>
                            }
                            />

                            <Route path="/goals/add" element={
                                <AdminLayout>
                                    <AddEdit />
                                </AdminLayout>
                            }
                            />

                            <Route path="/goals/edit/:goalID" element={
                                <AdminLayout>
                                    <EditGoal />
                                </AdminLayout>
                            }
                            />
                        </Route>
                    </Route>


                    <Route path="/*" element={
                        <GuestLayout>
                            <Login />
                        </GuestLayout>
                    }
                    />

                </RouterRoutes>
            </Suspense>
        </BrowserRouter>
    )
}

export default Navigations
