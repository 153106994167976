import { FETCH_ALL_ACTIVITIES, FETCH_ACTIVITIES_BY_ID, FETCH_ALL_ACTIVITIES_FOR_CREATE_EDIT_CHALLENGE, FETCH_ACTIVITIES_BY_FOR_MESSAGING, FETCH_ALL_ACTIVITIES_FOR_CREATE_EDIT_QUEST } from "./types"
import { toggleNetworkLoading } from "../common/actions"
import { message } from 'antd'
import ServerApi from "../../utility/ServerApi"
import { PAGINATION } from "../../const/utils"
import ServerApiCSV from "../../utility/ServerApiCSV"

export const fetchAllActivities = (filters = PAGINATION) => dispatch => {
  dispatch(toggleNetworkLoading(true))
  ServerApi().get(
    `/activity/admin-fetch-all?current=${filters.current || 1}&pageSize=${filters.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search)}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.cityId ? `&cityId=${filters?.cityId}` : ''}${filters?.activityType ? `&activityType=${filters?.activityType}` : ''}`)
    .then(res => {
      dispatch({
        type: FETCH_ALL_ACTIVITIES,
        payload: res.data
      })
      dispatch(toggleNetworkLoading(false))
    })
    .catch(e => {
      dispatch(toggleNetworkLoading(false))
      message.error("Error in Fecthing Activities Details", e)
      console.log(e)
    })
}

export const fetchAllActivitiesMessaging  = () => dispatch => {
  dispatch(toggleNetworkLoading(true))
  ServerApi().get(`/activity/admin-all-activities-for-messagings`)
    .then(res => {
      dispatch({
        type: FETCH_ACTIVITIES_BY_FOR_MESSAGING,
        payload: res.data
      })
      dispatch(toggleNetworkLoading(false))
    })
    .catch(e => {
      dispatch(toggleNetworkLoading(false))
      message.error("Error in Fecthing Activities For Messaging Details", e)
      console.log(e)
    })
}


/**
 * #48853 - Quest Management
 * @param {*} filters 
 * @returns 
 */
export const fetchAllActivitiesForCreateEditQuest = (filters) => dispatch => {
  // dispatch(toggleNetworkLoading(true))
  ServerApi().get(
    `/activity/admin-fetch-all-for-create-edit-quest?${filters?.questId ? `questId=${filters?.questId}` : ''}${filters?.city ? `&city=${filters?.city}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}`)
    .then(res => {
      dispatch({
        type: FETCH_ALL_ACTIVITIES_FOR_CREATE_EDIT_QUEST,
        payload: res.data
      })
      // dispatch(toggleNetworkLoading(false))
    })
    .catch(e => {
      // dispatch(toggleNetworkLoading(false))
      // message.error("Error in Fecthing Activities Details", e)
      console.error(e)
    })
}
/**
 * 
 * @param {*} filters 
 * @returns 
 */
export const fetchAllActivitiesForCreateEditChallenge = (filters) => dispatch => {
  // dispatch(toggleNetworkLoading(true))
  ServerApi().get(
    `/activity/admin-fetch-all-for-create-edit-challenge?${filters?.city ? `&city=${filters?.city}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}`)
    .then(res => {
      dispatch({
        type: FETCH_ALL_ACTIVITIES_FOR_CREATE_EDIT_CHALLENGE,
        payload: res.data
      })
      // dispatch(toggleNetworkLoading(false))
    })
    .catch(e => {
      // dispatch(toggleNetworkLoading(false))
      // message.error("Error in Fecthing Activities Details", e)
      console.error(e)
    })
}

export const createActivity = (rawData, successCallback) => dispatch => {
  ServerApi().post(`/activity`, rawData)
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        message.success("New Activity Created")
        successCallback(res.data)
      } else {
        message.error("Error Creating Activity!")
      }
      successCallback()
    })
    .catch(e => {
      message.error("Error Creating New Activity", e)
      console.log(e)
    })
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
export const fetchActivityById = (id) => dispatch => {
  dispatch(toggleNetworkLoading(true))
  ServerApi().get(`/activity/admin-detail/${id}`)
    .then(res => {
      const data = res.data
      dispatch({
        type: FETCH_ACTIVITIES_BY_ID,
        payload: data
      })
      dispatch(toggleNetworkLoading(false))
    })
    .catch(e => {
      message.error("Error fetching data!")
      dispatch(toggleNetworkLoading(false))
      console.log(e)
    })
}

export const updateActivity = (_id, rawData, successCallback) => dispatch => {
  ServerApi().patch(`activity/${_id}`, rawData)
    .then(res => {

      if (res.status === 200 || res.status === 201) {
        message.success("Activity updated.")
      } else {
        message.warning("Unable to update Activity.")
      }
      dispatch(fetchAllActivities(false))
      successCallback()
    })
    .catch(e => {
      message.error(e)
    })
}

export const deleteActivityById = (id) => dispatch => {
  ServerApi().delete(`activity/${id}`)
    .then(res => {
      message.success("Activity Deleted Succesfully!")
      dispatch(fetchAllActivities())
    })
    .catch(e => {
      message.error(e?.response?.data?.message || "Error Deleting")
      console.log(e)
    })
}

/**
 * 
 * @returns 
 */
export const downloadImportGPSCSVTemplate = () => dispatch => {
  // dispatch(toggleNetworkLoading(true))
  ServerApiCSV().get(`/activity/import-gps-csv-template`)
    .then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `import-gps-csv-template.csv`)
      document.body.appendChild(link)
      link.click()
    })
    .catch(e => {
      message.error("Error Fetching data!")
      // dispatch(toggleNetworkLoading(false))
      console.error(e)
    })
}