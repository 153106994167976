export const ImpactByRewardsTableColumns = [
  {
    title: 'No',
    dataIndex: 'srno',
    key: 'srno',
    notAllowToSearch: true
  },
  {
    title: 'Community',
    dataIndex: 'communityName',
    key: 'communityName'
  },
  // {
  //   title: 'Team',
  //   dataIndex: 'teamName',
  //   key: 'teamName'
  // },
  {
    title: 'Rewards',
    dataIndex: 'rewardName',
    key: 'rewardName'
  },
  {
    title: 'Total Rewards',
    dataIndex: 'total',
    key: 'total'
  }
]
