import React, { useState, useEffect } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Collapse, Button, Radio, Select, Form, Input, Upload, Breadcrumb, AutoComplete, Typography, Table, Modal } from 'antd'
// import { UploadOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { CgArrowsV } from 'react-icons/cg'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
// import { CSVLink } from 'react-csv'
// import { useCSVReader } from 'react-papaparse'

import { fetchAllUsers, SearchUser } from '../../../store/users/action'
import { createTeam } from '../../../store/teams/actions'
import { fetchAllCommunityForAddEditTeam } from '../../../store/community/actions'
import { fetchPublishedLocations } from '../../../store/locations/actions'
import { TEAM_MODE } from './Teams.const'
import { TimeZone } from '../../../const/sdgs.const'

// import { PlusSquareFilled } from '@ant-design/icons'
// import AddMembers from './invite/AddMembers'

export const NewTeam = () => {

    const [form] = Form.useForm()
    const [modalForm] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // const { CSVReader } = useCSVReader()

    // const users = useSelector(state => state.users.users)
    const communities = useSelector(state => state.community.communities)
    const locations = useSelector(state => state.locations.locations)
    const teamModes = Object.values(TEAM_MODE)

    // const [pinValue, setPinValue] = useState()
    // const [visible, setVisible] = useState(false)
    // const [confirmLoading, setConfirmLoading] = useState(false)
    // const [userExist, setUserExist] = useState(false)
    // const [formSubmitState, setFormSubmitState] = useState(false)

    useEffect(() => {
        dispatch(fetchAllUsers())
        dispatch(fetchPublishedLocations())
        dispatch(fetchAllCommunityForAddEditTeam())
        // setPinValue(Math.floor(1000 + (Math.random() * 9000)))
    }, [])

    // useEffect(() => {
    //     form.setFieldsValue({ pincode: pinValue })
    // }, [pinValue])

    const successCallback = () => {
        navigate('/teams')
    }

    const createNewTeam = (values) => {
        const data = {
            name: values.name,
            teamMode: values.teamMode,
            communityId: values.community,
            leaderId: values.leadername,
            city: values.city,
            teamMembers: values.teamMembers,
            publish: values.publish,
            timezone: values.timezone
        }
        // console.log(data)
        dispatch(createTeam(data, successCallback))
    }

    const handleSearch = async (user) => {
        dispatch(SearchUser(user))
    }

    const columns = [
        {
            title: 'No',
            dataIndex: 'srno',
            key: 'srno'
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Updated At',
            dataIndex: 'updatedAt',
            key: 'updataedAt'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: '120px'
        }
    ]

    // const handleNewMemberSubmit = (value) => {

    //     // console.log('u', userExist, 'f', formSubmitState)
    //     setFormSubmitState(true)
    //     const searchedUser = value.email
    //     const filteredData = users.result?.filter((values) => {
    //         return values.email.toLowerCase().includes(searchedUser.toLowerCase())
    //     })
    //     if (filteredData[0]) {
    //         console.log(filteredData)
    //         setUserExist(true)
    //         modalForm.setFieldsValue({
    //             name: filteredData[0].name
    //         })
    //     } else {
    //         console.log(filteredData)
    //         console.log('else')
    //     }
    // }

    // const handleOnFileLoad = (d) => {
    //     console.log(d)
    // }

    // const onErrorHandler = (d) => {
    //     console.log(d)
    // }

    // const handleFileRemove = () => {

    // }

    return (
        <div className='bg-white p-5'>
            <PageHeader
                ghost={false}
                title="Team Info"
            ></PageHeader>
            <Form
                name='NewTeam'
                form={form}
                labelCol={{ span: 4 }}
                onFinish={(values) => createNewTeam(values)}
                initialValues={{ publish: false }}
                autoComplete='off'
            >
                <Form.Item
                    label='Community'
                    name='community'
                    labelAlign='left'
                    colon={false}
                    rules={[{ required: true, message: 'Please select community' }]}
                >
                    <Select
                        showSearch
                        optionFilterProp='label'
                        options={communities?.result?.map(i => ({ label: i?.name, value: i._id }))}
                        placeholder="Select Community"
                        suffixIcon={<CgArrowsV />}
                    />
                </Form.Item>
                <Form.Item
                    label='Team Name'
                    name='name'
                    labelAlign='left'
                    colon={false}
                    rules={[{ required: true, message: 'Please enter Team Name' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label='City'
                    name='city'
                    labelAlign='left'
                    colon={false}
                    rules={[{ required: true, message: 'Please Select a City' }]}
                >
                    <Select
                        showSearch
                        allowClear
                        options={locations?.map(i => ({ label: i?.name, value: i?._id }))}
                    />
                </Form.Item>

                <Form.Item name="teamMode" colon={false} labelAlign="left" label="Team Mode" defaultValue=''
                    rules={[{ required: true, message: 'Team mode is not selected!' }]} extra={`Private: only manual import / add members can join. Public: any one can join via team share url or QR code scan`}
                >
                    <Radio.Group className="text-left">
                        {teamModes.map(({value, label, desc}, idx) => (
                            <Radio key={value} value={`${value}`}>{`${label}`}</Radio>
                        ))}
                    </Radio.Group>
                </Form.Item>

                <Form.Item name="publish" colon={false} labelAlign="left" label="Publish" defaultValue='false'
                    rules={[{ required: true, message: 'Publish is not selected!' }]}
                >
                    <Radio.Group className="text-left">
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    labelAlign='left'
                    label='Timezone'
                    name='timezone'
                    colon='false'
                    extra={'Timezone will impact logic of closing Team Challenge'}
                >
                    <Select
                        showSearch
                        optionFilterProp='label'
                        options={TimeZone}
                        placeholder="Select Timezone"
                    />
                </Form.Item>


                <div className="text-center pt-2 pb-4">
                    <Button className="bg-[#207868] px-8" size="large" type="primary" htmlType="submit">
                        Save
                    </Button>
                </div>

            </Form>

        </div>
    )
}