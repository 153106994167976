import { FETCH_ALL_BANNERS, FETCH_BANNER_BY_ID } from "./types"

const initialState = {
    banners: [], 
    banner: {}
}

const bannerReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_BANNERS:
            return { ...state, banners: action.payload }
        case FETCH_BANNER_BY_ID:
            return { ...state, banner: action.payload }
        default: 
            return state
    }
}

export default bannerReducer