import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

export const PrivateRoute = ({ component: Component, ...rest }) => {
    
    const isLogin = () => {
        try {
            const { accessToken } = JSON.parse(sessionStorage.getItem("userData"))
            if (accessToken && accessToken !== null) {
                return true
            }
            return false
        } catch (err) {
            return false
        }
    }

    return isLogin() === true ? <Outlet /> : <Navigate to="/login" />
}