import React, { useRef, useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { PageHeader } from '@ant-design/pro-layout'
import { fetchAllILight, deleteILightById, iLightExportCsvAll } from "../../../store/iLight/actions"
import { Table, Input, Space, Button, Popconfirm, Tooltip, Typography } from "antd"
import { SearchOutlined, DeleteOutlined, QuestionCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, EyeOutlined } from '@ant-design/icons'

import { DEFAULT_PAGE_SIZE, showTotalRecords, PAGINATION } from '../../../const/utils'
import { ILightTableColumns } from "./iLight.columns"

import moment from 'moment'
import { TRANSACTION_DATE_FORMAT } from '../Transactions/Transactions.const'


export const ILightList = () => {
  const [iLightColumn, setILightColumn] = useState(null)
  const iLightData = useSelector(state => state?.iLight?.iLight)
  const dispatch = useDispatch()
  const searchInput = useRef(null)
  const navigate = useNavigate()

  const [wordEntered, setWordEntered] = useState()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [isShowPagination, setIsShowPagination] = useState(true)
  const [currentPagination, setCurrentPagination] = useState(PAGINATION)
  const [filterDateRange, setFilterDateRange] = useState(null)
  const [filterStatus, setFilterStatus] = useState(null)

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const handleColumnSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
    setSelectedRows(newSelectedRowKeys.map((i) => {
      return iLightData?.result?.filter(e => e._id === i)[0]
    }))
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const rowSelection = {
    ...selectedRowKeys,
    ...selectedRows,
    onChange: onSelectChange
  }

  const _handleExportCsvSelected = () => {
    if (selectedRowKeys.length > 0) {
      dispatch(iLightExportCsvAll(selectedRowKeys))
    } else {
      //export from current result
      if (iLightData?.result?.length > 0) {
        const filterIds = iLightData?.result?.map(e => e._id)
        dispatch(iLightExportCsvAll(filterIds))
      }
    }
  }

  const _handleExportCsvAll = () => {
    dispatch(iLightExportCsvAll())
  }
  const hasSelected = selectedRowKeys.length > 0 || (iLightData && iLightData?.result?.length > 0)

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Button
            type="primary"
            className='mx-2 bg-[#207868]'
            onClick={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        if (dataIndex === 'status') {
          return record[dataIndex]?.props.children.toLowerCase().includes(value.toLowerCase())
        } else {
          return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        }
      } else {
        return false
      }

    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    }
  })

  const successCallback = (value) => {
    dispatch(fetchAllILight())
  }

  const _handleDeleteILight = (id) => {
    dispatch(deleteILightById(id, successCallback))
  }

  const handleSearch = (event) => {
    const searchWord = event.target.value
    setWordEntered(searchWord)

    if (searchWord === '') {
      dispatch(fetchAllILight())
    } else {
      dispatch(fetchAllILight({ search: searchWord.toLowerCase() }))
    }
  }

  const _handleSearchAndFilter = (pagination = PAGINATION) => {
    const params = {
      ...pagination
    }
    const _current = JSON.parse(JSON.stringify(currentPagination))
    _current.current = pagination?.current || PAGINATION.current
    _current.pageSize = pagination?.pageSize || PAGINATION.pageSize
    setCurrentPagination(_current)
    if (!!wordEntered) {
      params['search'] = wordEntered.toLowerCase()
    }
    if (filterDateRange) {
      params['fromDate'] = moment(new Date(filterDateRange[0])).format(`DD/MM/YYYY`)
      params['toDate'] = moment(new Date(filterDateRange[1])).format(`DD/MM/YYYY`)
    }
    if (filterStatus) {
      params['status'] = filterStatus
    }

    dispatch(fetchAllILight(params))
  }


  const handleTableChange = (pagination, filters, sorter) => {
    const isNullUndefEmptyStr = Object.values(filters).every(value => {
      // 👇️ check for multiple conditions
      if (value === null || value === undefined || value === '') {
        return true
      }
      return false
    })
    if (isNullUndefEmptyStr === false) {
      pagination = {
        ...pagination,
        current: currentPagination.current
      }
      setIsShowPagination(false)
    } else {
      if (isShowPagination === false) {
        pagination = {
          ...pagination,
          current: currentPagination.current
        }
        setIsShowPagination(true)
      }
    }
    _handleSearchAndFilter(pagination)
  }

  useEffect(() => {
    dispatch(fetchAllILight())
    const newILightTableColumns = ILightTableColumns.map((item) => {
      if (item.notAllowToSearch === true) {
        return item
      } else {
        return {
          ...item,
          ...getColumnSearchProps(item['dataIndex'])
        }
      }
    })
    setILightColumn(newILightTableColumns)
  }, [])

  return (
    <div className='bg-white shadow'>
      <PageHeader
        ghost={false}
        title="ILight"
      >
      </PageHeader>
      <div className='relative flex items-center justify-between mr-5 mb-5'>
        <div className='flex'>
          <Input
            className='w-[220px] h-[40px] ml-4 mr-2'
            placeholder='Search'
            value={wordEntered}
            onChange={handleSearch}
            prefix={<SearchOutlined />}
          />
        </div>
        <div>
          <Button className='mx-2 border-[#207868] text-[#207868]' disabled={!hasSelected} onClick={_handleExportCsvSelected}>
            {`Export Selected`}
          </Button>
          <Button className='mx-2 bg-[#207868]' type='primary' disabled={!(iLightData)} onClick={_handleExportCsvAll}>
            {`Export All`}
          </Button>
        </div>
      </div>
      <Table
        className='z-[0] relative overflow-auto'
        rowSelection={rowSelection}
        columns={iLightColumn}
        dataSource={iLightData?.result?.map((i, it) => ({
          key: i?._id,
          id: i?._id,
          createdDate: i?.createdAt ? (moment(i?.createdAt).format(TRANSACTION_DATE_FORMAT)) : (i?.createdDate ? moment(i?.createdAt).format(TRANSACTION_DATE_FORMAT) : ''),
          srno: i?.autoId,
          name: i?.name,
          email: i?.email,
          message: i?.metadata?.message,
          country: i?.userIPLocationMetadata?.country_name ? <div className="country-name-display-flex"><img width={30} src={i?.userIPLocationMetadata?.country_flag} /> <Typography className="country-name-text-nowrap">{i?.userIPLocationMetadata?.country_name}</Typography></div> : '',
          zeroHero: i?.metadata && (i?.metadata?.pledge === 'recycle' || i?.metadata?.pledge === 'pledge-all') ? <CheckCircleOutlined className='text-green-500' /> : <CloseCircleOutlined className='text-red-500' />,
          switchOff: i?.metadata && (i?.metadata?.pledge === 'switch-off' || i?.metadata?.pledge === 'pledge-all') ? <CheckCircleOutlined className='text-green-500' /> : <CloseCircleOutlined className='text-red-500' />,
          // isSentEmail: i?.isSentEmail ? <CheckCircleOutlined className='text-green-500' /> : <CloseCircleOutlined className='text-red-500' />,
          action:
            <div style={{ minWidth: '90px' }}>
              <Tooltip title="Info">
                <Button style={{ marginRight: "8px" }} onClick={() => { navigate((`/iLight/${i?._id}`)) }} size="small" type="ghost" shape="circle" icon={<EyeOutlined />} />
              </Tooltip>
              <Popconfirm
                title="Are you sure?"
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                onConfirm={() => _handleDeleteILight(i._id)}
                onCancel={() => null}
                okText="Yes"
                okType="danger"
                placement="topLeft"
                cancelText="Cancel"
              >
                <Button size="small" type="ghost" danger shape="circle" icon={<DeleteOutlined />} />
              </Popconfirm>

            </div>

        }))}
        pagination={{
          showTotal: showTotalRecords,
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          // hideOnSinglePage: true,
          position: ["none", isShowPagination === true ? "bottomRight" : "none"],
          total: iLightData?.totalCount,
          current: currentPagination?.current
        }}
        onChange={handleTableChange}
      />
    </div>
  )
}