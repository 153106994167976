export const BROADCASTING_STATUS = {
  DELETED: { value: "DELETED", label: "Deleted" },
  PROCESSED: { value: "PROCESSED", label: "Processed" },
  PENDING: { value: "PENDING", label: "Pending" }
}

export const CHALLENGE_TYPE = {
  INDIVIDUAL: {
    value: 'individual',
    label: 'Individual'
  },
  TEAM: {
    value: 'team',
    label: 'Team'
  },
  COMMUNITY: {
    value: 'community',
    label: 'Community'
  }
}