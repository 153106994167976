import React, { useEffect, useState, useRef } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Table, Space, Input, Button, Select, Flex } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { fetchCommunityReportsCommunitiesList, fetchEngagementByCommunity, fetchEngagementByCommunityExportCsvAll } from '../../../../../store/communityReport/action'
import { EngagementByCommunityOverviewTableColumns } from './columns'
// import { SearchOutlined } from '@ant-design/icons'
import { CgArrowsV } from 'react-icons/cg'
import { DEFAULT_PAGE_SIZE, PAGINATION, showTotalRecords } from '../../../../../const/utils'


/**
 * #44821 - Engagement - by Community Overview
 * @returns 
 */
export const EngagementByCommunity = () => {
    const [currentPagination, setCurrentPagination] = useState(PAGINATION)

    const dispatch = useDispatch()
    const [userRole, setUserRole] = useState('')
    const myCorporateProfile = useSelector(state => state?.community?.myCorporateProfile)
    const engagementByActivityKpis = useSelector(state => state?.communityReport?.engagementByCommunity)
    const [selectedCommunity, setSelectedCommunity] = useState(null)
    const [selectedTeam, setSelectedTeam] = useState(null)
    const [communitiesData, setCommunitiesData] = useState([])
    const [teamsData, setTeamsData] = useState([])
    const communities = useSelector(state => state.communityReport?.communities)

    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [prepairLeaderBoardColumns, setPrepairLeaderBoardColumns] = useState(null)
    const [selectedCommunityObject, setSelectedCommunityObject] = useState(null)
    const [isReadyToGetDefaultData, setIsReadyToGetDefaultData] = useState(false)
    const [defaultCommunity, setDefaultCommunity] = useState(null)


    const hasSelected = selectedRowKeys.length > 0

    useEffect(() => {
        const userData = JSON.parse(sessionStorage.getItem("userData"))
        const { role } = userData?.user
        setUserRole(role)
        dispatch(fetchCommunityReportsCommunitiesList())

        setPrepairLeaderBoardColumns(EngagementByCommunityOverviewTableColumns)
    }, [])

    useEffect(() => {
        setSelectedTeam(null)
        if (selectedCommunity) {
            const selectedCommunityData = communitiesData.filter((item) => {
                return item?.communityId === selectedCommunity
            })
            if (selectedCommunityData) {
                setTeamsData(selectedCommunityData[0]?.teams)
            } else {
                setTeamsData([])
            }
        } else {
            setTeamsData([])
        }
    }, [selectedCommunity])

    const _handleSearchAndFilter = (pagination = PAGINATION) => {
        if (selectedCommunity) {
            const newSelectedCommunityObject = communities.filter(i => {
                return i.communityId === selectedCommunity
            })
            setSelectedCommunityObject(newSelectedCommunityObject[0])
        } else {
            setSelectedCommunityObject(null)
        }
        const params = {
            ...pagination
        }
        const _current = JSON.parse(JSON.stringify(currentPagination))
        _current.current = pagination?.current || PAGINATION.current
        _current.pageSize = pagination?.pageSize || PAGINATION.pageSize
        setCurrentPagination(_current)
        // if (filterDateRange) {
        //     params['fromDate'] = moment(new Date(filterDateRange[0])).format(`DD/MM/YYYY`)
        //     params['toDate'] = moment(new Date(filterDateRange[1])).format(`DD/MM/YYYY`)
        // }
        // if (filterStatus) {
        //   params['status'] = filterStatus
        // }
        if (selectedCommunity) {
            params['communityId'] = selectedCommunity
        }
        if (selectedTeam) {
            params['teamId'] = selectedTeam
        }
        dispatch(fetchEngagementByCommunity(params))
    }

    const handleTableChange = (pagination) => {
        _handleSearchAndFilter(pagination)
    }

    useEffect(() => {
        if (userRole && communities) {
            if (userRole === 'community_leader') {
                const currentCommunityData = communities.filter((item) => { return item?.communityId === myCorporateProfile?._id })
                setCommunitiesData(currentCommunityData)
                if (currentCommunityData.length > 0) {
                    setDefaultCommunity({ label: currentCommunityData[0]?.communityName, value: currentCommunityData[0]?.communityId })
                } else {
                    setDefaultCommunity({ label: myCorporateProfile?.name, value: myCorporateProfile?._id })
                }
            } else {
                setCommunitiesData(communities)
                setDefaultCommunity({ label: communities[0]?.communityName, value: communities[0]?.communityId })
            }
        }
    }, [communities, userRole])
    //This will prevent API call 3 times when page is loaded first time
    // }, [userRole])

    useEffect(() => {
        if (defaultCommunity) {
            setSelectedCommunity(defaultCommunity?.value)
            setIsReadyToGetDefaultData(true)
        }
    }, [defaultCommunity])

    useEffect(() => {
        if (isReadyToGetDefaultData) {
            _handleSearchAndFilter()
            setIsReadyToGetDefaultData(false)
        }
    }, [isReadyToGetDefaultData])


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const rowSelection = {
        ...selectedRowKeys,
        onChange: onSelectChange
    }

    const _handleExportCsvAll = () => {
        const params = {

        }
        if (selectedCommunity) {
            params['communityId'] = selectedCommunity
        }
        if (selectedTeam) {
            params['teamId'] = selectedTeam
        }
        dispatch(fetchEngagementByCommunityExportCsvAll(params))
    }

    const _handleExportCsvSelected = () => {
        if (selectedRowKeys.length > 0) {
            dispatch(fetchEngagementByCommunityExportCsvAll({}, selectedRowKeys))
        }
    }

    const _renderKPIValue = (element, key) => {
        if (element[`${key}_kpi`] == '2') {
            //red
            return <span style={{ color: 'red' }}>{element[`${key}`]}</span>
        } else if (element[`${key}_kpi`] == '1') {
            //green
            return <span style={{ color: 'green' }}>{element[`${key}`]}</span>
        } else {
            return <>{element[`${key}`]}</>
        }
    }


    return (
        <div className='bg-white shadow pb-3'>
            <PageHeader
                ghost={false}
                title={`Engagement - Number of Teams & Individuals: ${selectedCommunityObject ? selectedCommunityObject?.communityName : ''}`}
            ></PageHeader>
            <Flex gap="middle">
                <Flex gap="middle" vertical>
                    <Flex gap="middle">
                        <Select
                            showSearch
                            allowClear
                            value={selectedCommunity}
                            disabled={defaultCommunity && userRole != 'admin'}
                            className='w-[300px] h-[40px] ml-4'
                            placeholder="Select community"
                            optionFilterProp='label'
                            options={communitiesData?.map(i => ({ label: i.communityName, value: i.communityId }))}
                            suffixIcon={<CgArrowsV />}
                            onChange={(h) => { setSelectedCommunity(h) }}
                        >
                        </Select>
                        <Select
                            showSearch
                            value={selectedTeam}
                            allowClear
                            className='w-[300px] h-[40px]'
                            placeholder="Select Teams"
                            optionFilterProp='label'
                            options={teamsData?.map(i => ({ label: i.teamName, value: i.teamId }))}
                            suffixIcon={<CgArrowsV />}
                            onChange={(h) => { setSelectedTeam(h) }}
                        >
                        </Select>
                    </Flex>
                </Flex>
                <Flex gap="middle" className='mb-4'>
                    <Button className='w-[200px] h-[40px]'
                        // disabled={userRole != 'admin'} 
                        onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
                    {/* <Button className='mx-2 border-[#207868] text-[#207868]' disabled={!hasSelected} onClick={_handleExportCsvSelected}>
                            {`Export Selected`}
                        </Button> */}
                    <Button className='w-[200px] h-[40px] bg-[#207868]' type='primary' disabled={!(engagementByActivityKpis)} onClick={_handleExportCsvAll}>
                        {`Export by Current Filter`}
                    </Button>
                </Flex>
            </Flex>
            <Table
                className='z-[0] relative overflow-auto'
                // rowSelection={rowSelection}
                columns={prepairLeaderBoardColumns}
                dataSource={engagementByActivityKpis?.result?.map((i, it) => ({
                    key: i.key,
                    srno: (++it + ((currentPagination?.current - 1) * currentPagination?.pageSize)),
                    kpi: i?.label,
                    // teamName: i?.teamName,
                    number_of_teams: i?.number_of_teams,
                    number_of_members: i?.number_of_members
                }))}
                pagination={{
                    defaultPageSize: DEFAULT_PAGE_SIZE,
                    showSizeChanger: true,
                    hideOnSinglePage: true,
                    position: ["none", "bottomRight"],
                    total: engagementByActivityKpis?.totalCount,
                    showTotal: showTotalRecords
                }}
                onChange={handleTableChange}
            />
        </div>
    )
}