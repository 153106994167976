import { DASHBOARD_REPORT, POWERBI_DASHBOARD_REPORT } from "./types"
import { toggleNetworkLoading } from "../common/actions"
import { message } from 'antd'
import ServerApi from "../../utility/ServerApi"

export const fetchDashboardReports = () => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`reports/dashboard`)
        .then(res => {
            dispatch({
                type: DASHBOARD_REPORT,
                payload: res.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fecthing Dashboard report", e)
            console.log(e)
        })
}

export const getPowerBIReports = () => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`powerbi/getToken`)
        .then(res => {
            dispatch({
                type: POWERBI_DASHBOARD_REPORT,
                payload: res.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fecthing Dashboard report", e)
            console.log(e)
        })
}