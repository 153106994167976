import React, { useState, useEffect, useRef } from 'react'
import { Typography, Button, Form, Input, Upload, message, Table, Space, Dropdown, Radio, Breadcrumb, Collapse, Tooltip, Modal, Select, Popconfirm, InputNumber } from 'antd'
import { UploadOutlined, DeleteOutlined, EditOutlined, CopyOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import ServerApi from '../../../utility/ServerApi'
// import { SDGS } from '../../../const/sdgs.const'
import PreviewImage from '../../../components/image/PreviewImage'
import { availableTimezones, isSupportedFileFormatForGeneric, SUPPORTED_FILE_FORMAT_EXTRA } from '../../../const/utils'
import { fetchLocationById, updateLocationDetail } from '../../../store/locations/actions'


const { Panel } = Collapse

const EditLocation = () => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { locationID } = useParams()
    const [fileList, setFileList] = useState([])

    const locationData = useSelector((state) => state.locations.location)
    useEffect(() => {
        dispatch(fetchLocationById(locationID))
    }, [locationID])

    const successCallback = () => {
        navigate("/locations")
    }

    useEffect(() => {
        if (!!locationData) {
            form.setFieldsValue({
                name: locationData?.name,
                icon: locationData?.icon,
                display_order: locationData?.display_order,
                publish: locationData.publish,
                timezone: locationData?.timezone
            })
        }
        // setEditorState(editorText)
    }, [locationData])

    const _handleUpdateLocationDetail = (values) => {
        if (fileList[0]) values.icon = fileList[0].icon
        // console.log(values)
        dispatch(updateLocationDetail(locationID, values, successCallback))
    }

    const handleChange = async ({ file }) => {
        // if (!(file.size / 1024 / 1024 < 50)) {
        //     message.error('file size exceeded')
        //     return
        // }
        if (!isSupportedFileFormatForGeneric(file.type)) {
            message.error('You can only upload JPG/PNG file!')
            return
        }
        setFileList([{ uid: file.uid, name: "", status: "uploading" }])
        const formData = new FormData()
        formData.append("file", file)
        formData.append("folder", "goals")

        ServerApi()
            .post("media", formData)
            .then((res) => {
                setFileList([
                    {
                        uid: file.uid,
                        name: res.data.name,
                        icon: res.data.file,
                        status: "done",
                        url: `${res.data.path}`,
                        thumbUrl: `${res.data.path}`
                    }
                ])
                return true
            })
            .catch((e) => {
                console.log(e)
                return false
            })
    }

    const props = {
        customRequest: handleChange,
        listType: 'picture',
        onRemove: () => setFileList([]),
        fileList
    }

    return (
        <>
            <div className="bg-white">
                <Form
                    name="update_Location"
                    form={form}
                    onFinish={(values) => _handleUpdateLocationDetail(values)}
                    labelCol={{ span: 4 }}
                    requiredMark="sdlfjldfjs"
                >
                    <Collapse
                        className="border-none"
                        expandIconPosition={"end"}
                        defaultActiveKey={["1"]}
                    >
                        <Panel
                            header={<p className="font-bold text-lg">Location Info</p>}
                            key="1"
                            className="bg-textbackgroundcolor"
                        >
                            <Form.Item
                                label="Location Name"
                                name="name"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: "Please enter your location name!" }]}
                                hasFeedback
                            >
                                <Input className="border-bordercolor rounded flex items-center" />
                            </Form.Item>

                            <Form.Item
                                label="Display Order"
                                name="display_order"
                                labelAlign="left"
                                colon={false}
                                rules={[
                                    // { required: true, message: 'Please enter value limit!' },
                                    { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                ]}
                                hasFeedback
                            >
                                <InputNumber />
                            </Form.Item>
                            <Form.Item
                                label='Timezone'
                                name='timezone'
                                labelAlign='left'
                                colon={false}
                                rules={[{ required: false, message: 'Please select a timezone' }]}
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    options={availableTimezones()?.map(i => ({ label: i, value: i }))}
                                />
                            </Form.Item>
                            <Form.Item labelAlign="left"
                                label="Icon"
                                extra={SUPPORTED_FILE_FORMAT_EXTRA.CITY_LOCATION_ICON}
                            >
                                <div className='flex justify-between'>
                                    <div className='self-center'>
                                        <p className='flex'>Currently: <PreviewImage src={locationData?.icon} /></p>
                                    </div>
                                    <Upload className='flex space-x-4'{...props} fileList={fileList}>
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </div>
                            </Form.Item>
                            <Form.Item
                                name="publish"
                                colon={false}
                                labelAlign="left"
                                label="Publish"
                                rules={[{ required: true, message: "Publish is not selected!" }]}
                            >
                                <Radio.Group className="text-left">
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Panel>
                    </Collapse>
                    <div className="text-center pt-2 pb-4">
                        <Button
                            className="bg-[#207868]"
                            size="large"
                            type="primary"
                            htmlType="submit"
                        >
                            Save Changes
                        </Button>
                    </div>
                </Form>
            </div>

        </>
    )
}

export default EditLocation
