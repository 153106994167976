import React, { useRef, useState, useEffect } from 'react'
// import { SearchOutlined, EditOutlined, DeleteOutlined, PlusOutlined, QuestionCircleOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { Col, Row, Button, Input, Space, Form, Select } from 'antd'
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
// import PreviewImage from '../../../components/image/PreviewImage'
import { CgArrowsV } from 'react-icons/cg'
// import moment from 'moment'
import { createNewMiaAnimation, fetchMiaAnimationsChallengesList, fetchMiaAnimationsConfigList, fetchMiaAnimationsUsersList } from '../../../store/miaAnimations/action'

/**
 * #42098 - Home screen with hero banner
 * @returns 
 */
export const MiaAnimations = () => {

    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const challenges = useSelector(state => state.miaAnimations?.challenges)
    const animationConfigs = useSelector(state => state.miaAnimations?.animationConfig)
    const usersList = useSelector(state => state.miaAnimations?.users)

    useEffect(() => {
        dispatch(fetchMiaAnimationsUsersList())
        dispatch(fetchMiaAnimationsChallengesList())
        dispatch(fetchMiaAnimationsConfigList())
    }, [])

    const [selectedAnimationConfig, setSelectedAnimationConfig] = useState([])
    const [animationConfigsData, setAnimationConfigsData] = useState([])
    const [challengesData, setChallengesData] = useState([])
    const [usersListData, setUsersListData] = useState([])
    const [ctaEnable, setCtaEnable] = useState(false)

    useEffect(() => {
        setUsersListData(usersList)
    }, [usersList])

    useEffect(() => {
        setAnimationConfigsData(animationConfigs)
    }, [animationConfigs])

    useEffect(() => {
        setChallengesData(challenges)
    }, [challenges])

    const _successCallback = (data) => {
        //force refresh data
    }

    const _handleCreateNewBroadcasting = (values) => {
        console.log(`_handleCreateNewBroadcasting `, values)
        if (!!values.challengeId && values.userId && values.animationId) {
            const data = {
                challengeId: values.challengeId,
                userId: values.userId,
                push_type: values.animationId
            }
            dispatch(createNewMiaAnimation(data, _successCallback))
        }
    }


    return (
        <div className='bg-white shadow'>
            <PageHeader
                ghost={false}
                title="Mia Animations Testing"
                extra={[]}
            ></PageHeader>

            <div className='flex justify-between'>
                <Form
                    name="MiaAnimationsTesting"
                    form={form}
                    labelCol={{
                        span: 4
                    }}
                    onFinish={(values) => _handleCreateNewBroadcasting(values)}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <div className="grid grid-cols-4 gap-7">
                        <div className="p-2">
                            <Form.Item
                                label=""
                                name="animationId"
                                labelAlign="left"
                                colon={false}
                                extra="Step 1. Please select animation type"
                            // rules={[{ required: true, message: 'Please select community!' }]}
                            // hasFeedback
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Select animation"
                                    optionFilterProp='label'
                                    options={animationConfigsData?.map(i => ({ label: `${i.MiaAnimation}-${i.PushType}`, value: i.PushType }))}
                                    suffixIcon={<CgArrowsV />}
                                    onChange={(h) => { setSelectedAnimationConfig(h) }}
                                >
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="p-2">
                            <Form.Item
                                label=""
                                showSearch
                                allowClear
                                name="userId"
                                labelAlign="left"
                                colon={false}
                                className='col-span-3'
                                extra="Step 2. Please select user"
                            >
                                <Select
                                    optionFilterProp='label'
                                    showSearch
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Select user"
                                    options={usersListData?.map(i => ({ label: `${i.name}-${i.email}`, value: i._id }))}
                                    suffixIcon={<CgArrowsV />}
                                >
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="p-2">
                            <Form.Item
                                label=""
                                name="challengeId"
                                labelAlign="left"
                                colon={false}
                                className='col-span-3'
                                extra="Step 3. Please select challenge"
                            >
                                <Select
                                    optionFilterProp='label'
                                    showSearch
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Select challenge"
                                    options={challengesData?.map(i => ({ label: i.challengeName, value: i.challengeId }))}
                                    suffixIcon={<CgArrowsV />}
                                >
                                </Select>
                            </Form.Item>
                        </div>
                        <div className="p-2">
                            <Button className="bg-[#207868]" size="large" type="primary" htmlType="submit">
                                Send Test Animation
                            </Button>
                        </div>
                    </div>
                </Form>

            </div>
        </div>
    )
}