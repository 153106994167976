import React from 'react'

const GuestLayout = ({children}) => {

    return (
        <div>
            {children}
      </div>
    )
}

export default GuestLayout
