import React, { useState, useEffect, useRef } from 'react'
import { Button, Radio, Select, Form, Input, Upload, message, Collapse } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { CgArrowsV } from 'react-icons/cg'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createMessaging } from '../../../store/messaging/actions'
import { fetchAllCommunity } from '../../../store/community/actions'
import { adminFetchAllMobileUsersForSendingMessaging } from '../../../store/users/action'
import { fetchAllTeams } from '../../../store/teams/actions'
import { fetchCommonConfiguration } from '../../../store/common/actions'
import { fetchAllActivitiesMessaging } from '../../../store/activity/actions'
import { fetchAllHabitForMessaging } from '../../../store/goal/actions'
import { fetchAllChallengesForMessaging } from '../../../store/challenges/actions'
import ServerApi from '../../../utility/ServerApi'
import PreviewImage from '../../../components/image/PreviewImage'
import PushMessageFields from './PushMessageFields'
import InviteTeamMembers from '../teams/uploadCSV/InviteTeamMembers'

const { Panel } = Collapse
const { TextArea } = Input

export const NewMessageDefault = () => {

    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const [fileList, setFileList] = useState([])
    const [selectName, setSelectName] = useState('')
    const [visibleTeamMembers, setVisibleTeamMembers] = useState(false)

    const selectRef = useRef(null)
    const communityData = useSelector(state => state.community.communities?.result)
    const activeUsers = useSelector(state => state.users?.usersForMessaging)
    const teams = useSelector(state => state.teams.teams)
    const messagingPushActions = useSelector(state => state?.common?.data?.messagingPushActions)    
    const locations = useSelector(state => state.locations.locations)    
    const [publishedCommunity, setPublishedCommunity] = useState([])
    const [publishedTeams, setPublishedTeams] = useState([])
    const [radioEnable, setRadioEnable] = useState('')    
    const [savedPanelTitle, setSavedPanelTitle] = useState('')
    const [fileCsv, setFileCsv] = useState([])
    const activities = useSelector(state => state.activity.activitiesForMessaging)
    const habits = useSelector(state => state.goals.habitsForMessaging)
    const challengesForMessaging = useSelector(state => state.challenges.challengeForMessaging)
    const [pushTarget, setPushTarget] = useState('')
    const [pushActionEnums, setPushActionEmnums] = useState()
    const [selectActionName, setSelectActionName] = useState('')

    useEffect(() => {
        dispatch(fetchAllCommunity({ current: 1, pageSize: 100000 }))
        dispatch(adminFetchAllMobileUsersForSendingMessaging({ current: 1, pageSize: 100000 }))
        dispatch(fetchAllTeams({ current: 1, pageSize: 100000 }))
        dispatch(fetchCommonConfiguration({ current: 1, pageSize: 100000 }))
        dispatch(fetchAllActivitiesMessaging())
        dispatch(fetchAllHabitForMessaging())
        dispatch(fetchAllChallengesForMessaging())
    }, [])

    // useEffect(() => {
    //     if (users?.length) {
    //         // Find all users with staus active
    //         setActiveUsers(users)
    //     }
    // }, [users])

    useEffect(() => {
        if (communityData?.length) {
            // Find all users with staus active
            const publishedCommunity = communityData.filter(community => (community.status.toLowerCase() === 'active' && community.publish === true))
            setPublishedCommunity(publishedCommunity)
        }
    }, [communityData])

    useEffect(() => {
        if (teams?.result?.length) {
            // Find all users with staus active
            const publishedTeams = teams?.result?.filter(team => (team.status.toLowerCase() === 'active' && team.publish === true))
            setPublishedTeams(publishedTeams)
        }
    }, [teams])

    const successCallback = () => {
        navigate('/messaging')
    }

    const createNewMessaging = (values) => {
        try {
            values.rewardDetails = { extraPoint: values.extraPoint }
            const toPush = { push_content: {}, push_target: {}, push_action: {} }
            if (fileList[0]) toPush.image = fileList[0].image
            toPush.title = values.title
            toPush.body = values.body

            if (values.openLink && !values.openLink.startsWith('https://')) {
                values.openLink = `https://${values.openLink}`
            }
            toPush.link = values.openLink

            toPush.push_target = pushTarget

            if (values.teams) {
                toPush.teamIds = values.teams
            } else if (values.communities) {
                toPush.communityIds = values.communities
            } else if (values.selectedUsers) {
                toPush.userIds = values.selectedUsers
            } else if (values.toAllUser) {
            } else if (values.locations) {
                toPush.locationIds = values.locations
            }

            toPush.push_action = pushActionEnums

            if (values.habit) {
                toPush.push_action_cta = values.habit
                // toPush.push_action = 'Open Habit'
            } else if (values.activity) {
                toPush.push_action_cta = values.activity
                // toPush.push_action = 'Open Activity'
            } else if (values.challenge) {
                toPush.push_action_cta = values.challenge
                // toPush.push_action = 'Open Challenge'
            } else if (values.openLink) {
                toPush.push_action_cta = values.openLink
                // toPush.push_action = 'Open Link'
            } else if (values.section) {
                toPush.push_action_cta = values.section
                // toPush.push_action = 'Open Section'
            }

            if (fileCsv.length > 0) {
                toPush.importedCSV = fileCsv
            }

            dispatch(createMessaging(toPush, successCallback))
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = async ({ file }) => {
        if (!(file.size / 1024 / 1024 < 50)) {
            message.error('file size exceeded')
            return
        }
        if (!(file.type === 'image/jpeg' || file.type === 'image/png')) {
            message.error('You can only upload JPG/PNG file!')
            return
        }
        setFileList(
            [{ uid: file.uid, name: '', status: 'uploading' }]
        )
        const formData = new FormData()
        formData.append('file', file)
        formData.append('folder', 'messagings')

        ServerApi().post('media/admin-create-media', formData)
            .then(res => {
                setFileList([
                    {
                        uid: file.uid,
                        name: res.data.name,
                        image: res.data.file,
                        status: 'done',
                        url: `${res.data.path}`,
                        thumbUrl: `${res.data.path}`
                    }
                ])
                return true
            })
            .catch(e => { console.log(e); return false })
    }

    const props = {
        customRequest: handleChange,
        listType: 'picture',
        onRemove: () => setFileList([]),
        fileList
    }

    const handleUploadCsv = async ({ file }) => {
        if (!isSupportedFileFormatForGeneric(file.type)) {
            message.error('You can only upload JPG/PNG file!')
            return
        }
        setFileCsv(
            [{ uid: file.uid, name: '', status: 'uploading' }]
        )
        const formData = new FormData()
        formData.append('file', file)
        formData.append('folder', 'challenges')

        ServerApi().post('media/admin-create-media', formData)
            .then(res => {
                setFileCsv([
                    {
                        uid: file.uid,
                        name: res.data.name,
                        image: res.data.file,
                        status: 'done',
                        url: `${res.data.path}`,
                        thumbUrl: `${res.data.path}`
                    }
                ])
                return true
            })
            .catch(e => { console.log(e); return false })
    }

    const csvProps = {
        customRequest: handleUploadCsv,
        listType: 'csv',
        onRemove: () => setFileCsv([]),
        fileCsv
    }

    const handleClickSelectRadio = (e, name, panelName, enumValue) => {
        e.stopPropagation()
        setRadioEnable(name)
        if (panelName === 'push_target') {
            setPushTarget(enumValue)
            setSelectName(name)
        }
        if (panelName === 'push_action') {
            setPushActionEmnums(enumValue)
            setSelectActionName(name)
        }
    }    

    const getFileCSV = (e) => {
        if (e) {
            setFileCsv(e)
        }
    }
    return (
        <>
            <div className="shadow bg-white">
                <Form
                    name='NewMessageDefault'
                    initialValues={{ publish: false }}
                    form={form}
                    labelCol={{
                        span: 4
                    }}
                    onFinish={(values) => createNewMessaging(values)}
                    autoComplete='off'
                    className='px-4 py-4'
                >
                    {PushMessageFields && PushMessageFields.map((item, index) => {
                        return (
                            <Collapse key={index} className="border-none" expandIconPosition={'end'} defaultActiveKey={['1', '2', '3', '4']}>
                                <Panel name={item.panel_name} header={<p className='font-bold text-lg'>{item.panel_title}</p>} key="1" className="bg-textbackgroundcolor">
                                    {item.form_item && item.form_item.map((formItem, idx) => {
                                        return <Form.Item
                                            label={formItem?.label}
                                            name={formItem?.name}
                                            labelAlign="left"
                                            colon={false}
                                            labelCol={{ span: 4 }}
                                            hasFeedback
                                            extra={formItem?.extra && <p className='pt-2'>{formItem.extra}</p>}
                                        >
                                            {(() => {
                                                switch (formItem?.type) {
                                                    case 'input':
                                                        return <Input />
                                                    case 'file':
                                                        return (
                                                            <div className='flex justify-between'>
                                                                <div className='self-center'>
                                                                    <p className='flex items-center'>
                                                                        Currently : <PreviewImage src={''} width={120} height={69} />
                                                                    </p>
                                                                </div>
                                                                <Upload className='flex space-x-4' {...props} fileList={fileList}>
                                                                    Change: <Button icon={<UploadOutlined />}>Upload</Button>
                                                                </Upload>
                                                            </div>
                                                        )
                                                    case 'select':
                                                        return (
                                                            <Select
                                                                showSearch
                                                                allowClear
                                                                className='w-full'
                                                                placeholder={formItem?.placeholder}
                                                                optionFilterProp='label'
                                                                options={formItem.options}
                                                                suffixIcon={<CgArrowsV />}
                                                                name={formItem?.enum_value}
                                                            />
                                                        )
                                                    case 'text-area':
                                                        return <TextArea className='border-bordercolor rounded' rows={4} />
                                                    default:
                                                        return null
                                                }
                                            })()}
                                        </Form.Item>
                                    })}
                                    {item.form_item_radio && <>
                                        {item.form_item_radio.map((formItemRadio, idx) => {
                                            return <div className="flex items-center mb-3">
                                                {(() => {
                                                    switch (item?.panel_name) {
                                                        case 'push_target':
                                                            return <Radio
                                                                value={formItemRadio.enum_value}
                                                                label={formItemRadio.label}
                                                                className='custom-radio'
                                                                onClick={(e) => handleClickSelectRadio(e, formItemRadio.name, item?.panel_name, formItemRadio.enum_value)}
                                                                checked={selectName === formItemRadio.name}
                                                            />
                                                        case 'push_action':
                                                            return <Radio
                                                                value={formItemRadio.enum_value}
                                                                label={formItemRadio.label}
                                                                className='custom-radio'
                                                                onClick={(e) => handleClickSelectRadio(e, formItemRadio.name, item?.panel_name, formItemRadio.enum_value)}
                                                                checked={selectActionName === formItemRadio.name}
                                                            />
                                                    }
                                                })()}
                                                <Form.Item labelAlign="left"
                                                    label={formItemRadio.label}
                                                    name={formItemRadio.name}
                                                    colon={false}
                                                    className="w-full mb-0 pl-3"
                                                    hasFeedback
                                                    defaultValue={null}
                                                    onMouseDown={(e) => e.nativeEvent.stopImmediatePropagation()}
                                                >
                                                    {(() => {
                                                        switch (formItemRadio?.form_inner_type) {
                                                            case 'span':
                                                                return <span>To {activeUsers?.length} active users</span>
                                                            case 'select':
                                                                let selectOptions = []
                                                                if (formItemRadio?.name === 'selectedUsers') {
                                                                    selectOptions = activeUsers?.map(i => ({ label: `${i.name || 'susGain User'} (${i.email})`, value: i._id }))
                                                                } else if (formItemRadio?.name === 'communities') {
                                                                    selectOptions = publishedCommunity?.map(i => ({ label: i.name, value: i._id }))
                                                                } else if (formItemRadio?.name === 'teams') {
                                                                    selectOptions = publishedTeams?.map(i => ({ label: i.name, value: i._id }))
                                                                } else if (formItemRadio?.name === 'section') {
                                                                    selectOptions = messagingPushActions
                                                                } else if (formItemRadio?.name === 'locations') {
                                                                    selectOptions = locations?.map(i => ({ label: i.name, value: i._id }))
                                                                } else if (formItemRadio?.name === 'activity') {
                                                                    selectOptions = activities?.result?.map(i => ({ label: i.name, value: i._id }))
                                                                } else if (formItemRadio?.name === 'habit') {
                                                                    selectOptions = habits?.map(i => ({ label: i.name, value: i._id }))
                                                                } else if (formItemRadio?.name === 'challenge') {
                                                                    selectOptions = challengesForMessaging?.map(i => ({ label: i.name, value: i._id }))
                                                                }
                                                                return <Select
                                                                    ref={selectRef}
                                                                    showSearch
                                                                    mode={item?.panel_name === 'push_action' ? 'single' : 'multiple'}
                                                                    allowClear
                                                                    className='w-100'
                                                                    placeholder={formItemRadio?.placeholder}
                                                                    optionFilterProp='label'
                                                                    options={selectOptions}
                                                                    suffixIcon={<CgArrowsV />}
                                                                    onMouseDown={(e) => e.nativeEvent.stopImmediatePropagation()}
                                                                    // open={selectName === formItemRadio.name}
                                                                    disabled={radioEnable !== formItemRadio.name}
                                                                // values={selectedField}
                                                                />
                                                            case 'file':
                                                                return (
                                                                    <div className='flex justify-between'>
                                                                        <Button
                                                                            type='link'
                                                                            className='flex items-center message-import-csv'
                                                                            icon={<UploadOutlined />}
                                                                            onClick={() => setVisibleTeamMembers(true)}
                                                                        >
                                                                            Import CSV
                                                                            {fileCsv.length > 0 && <div className='flex items-center pl-3 text-green-500'>{fileCsv.length} users selected</div>}
                                                                        </Button>
                                                                    </div>
                                                                )
                                                            case 'input':
                                                                return <Input placeholder={formItemRadio?.placeholder}
                                                                    addonBefore="https://"
                                                                    // pattern="https:\/\/.*"
                                                                    name='link'
                                                                />
                                                            default:
                                                                return null
                                                        }
                                                    })()}
                                                </Form.Item>
                                            </div>
                                        })}
                                    </>}
                                </Panel>
                            </Collapse>
                        )
                    })}
                    <div className="text-center pt-2 pb-4">
                        <Button className="bg-[#207868] px-8" size="large" type="primary" htmlType="submit">
                            Send Push Notification
                        </Button>
                    </div>
                </Form>
                <InviteTeamMembers
                    // teamId={''}
                    visibleTeamMembers={visibleTeamMembers}
                    setVisibleTeamMembers={setVisibleTeamMembers}
                    getFileCSV={(e) => getFileCSV(e)}
                    notInviteTeamMember={true}
                />
            </div>
        </>
    )
}