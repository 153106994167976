import { FETCH_CURRENT_APP_VERSION_INFO } from "./types"
import { toggleNetworkLoading } from "../common/actions"
import { message } from 'antd'
import ServerApi from "../../utility/ServerApi"

/**
 * 
 * @returns 
 */
export const fetchCurrentAppVersionInfo = () => dispatch => {
  // dispatch(toggleNetworkLoading(true))
  ServerApi().get(`/appversions/admin-fetch-all`)
    .then(res => {
      dispatch({
        type: FETCH_CURRENT_APP_VERSION_INFO,
        payload: res.data
      })
      // dispatch(toggleNetworkLoading(false))
    })
    .catch(e => {
      // dispatch(toggleNetworkLoading(false))
      message.error("Error in Fectching Data", e)
      console.error(e)
    })
}


/**
 * 
 * @param {*} params 
 * @param {*} successCallback 
 * @returns 
 */
export const updateAppVersionInfo = (params, successCallback) => dispatch => {
  ServerApi().patch(`/appversions/admin-update-version-info/${params.versionId}`, params)
    .then(res => {
      if (res.status === 200 || res.status === 201) {
        message.success("Version updated.")
      } else {
        message.warning("Unable to update Version.")
      }
      // dispatch(fetchCurrentAppVersionInfo())
      successCallback()
    })
    .catch(e => {
      message.error(e)
    })
}