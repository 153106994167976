import { FETCH_ALL_CHALLENGES, FETCH_ALL_CHALLENGES_AVAIL_FOR_SETUP_COMMUNITY, FETCH_CHALLENGE_BY_ID, FETCH_ALL_CHALLENGE_FOR_MESSAGING } from "./types"

const initialState = {
    challenges: [],
    availChallenges: [],
    challenge: {},
    challengeForMessaging: []
}

const challengeReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_CHALLENGES:
            return { ...state, challenges: action.payload }

        case FETCH_ALL_CHALLENGES_AVAIL_FOR_SETUP_COMMUNITY:
            return { ...state, availChallenges: action.payload }

        case FETCH_CHALLENGE_BY_ID:
            return { ...state, challenge: action.payload }

        case FETCH_ALL_CHALLENGE_FOR_MESSAGING:
            return { ...state, challengeForMessaging: action.payload }
            
        default:
            return state
    }
}

export default challengeReducer