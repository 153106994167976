import { FETCH_ALL_USERS, FETCH_USER_BY_ID, SEARCH_USER_BY_NAME, FETCH_MY_ACTIVITIES, FETCH_MY_CHALLENGES, FETCH_DELETED_USERS, FETCH_LAST_HERO_PROGRESS_USER_BY_ID, FETCH_USER_MY_CHALLENGES_BY_ID, FETCH_ALL_USERS_FOR_MESSAGING, FETCH_USER_MY_QUESTS_BY_ID } from "./types"

const initialState = {
    users: [],
    userDetail: {},
    searchedUsers: [],
    myActivities: [],
    myChallenges: [],
    lastHeroProgress: [],
    lastMyChallenges: [],
    usersForMessaging: [],
    lastMyQuests: []
}

const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_USERS:
            return { ...state, users: action.payload }
        case FETCH_DELETED_USERS:
            return { ...state, users: action.payload }
        case FETCH_USER_BY_ID:
            return { ...state, userDetail: action.payload }
        case SEARCH_USER_BY_NAME:
            return { ...state, searchedUsers: action.payload }
        case FETCH_MY_ACTIVITIES:
            return { ...state, myActivities: action.payload }
        case FETCH_MY_CHALLENGES:
            return { ...state, myChallenges: action.payload }
        case FETCH_LAST_HERO_PROGRESS_USER_BY_ID:
            return { ...state, lastHeroProgress: action.payload }
        case FETCH_USER_MY_CHALLENGES_BY_ID:
            return { ...state, lastMyChallenges: action.payload }
        case FETCH_ALL_USERS_FOR_MESSAGING:
            return { ...state, usersForMessaging: action.payload }
        case FETCH_USER_MY_QUESTS_BY_ID:
            return { ...state, lastMyQuests: action.payload }
        default:
            return state
    }
}

export default usersReducer