import React, { useRef, useState, useEffect } from 'react'
import { SearchOutlined, EditOutlined, DeleteOutlined, PlusOutlined, QuestionCircleOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Input, Space, Table, Tooltip, Popconfirm, Tag, Select, Typography, message, Flex } from 'antd'
import { useNavigate } from "react-router-dom"
import { deleteTeamById, fetchAllTeams } from '../../../store/teams/actions'
import { fetchAllCommunity } from '../../../store/community/actions'
import { useSelector, useDispatch } from 'react-redux'
// import ServerApi from '../../../utility/ServerApi'
import CopyToClipboard from 'react-copy-to-clipboard'
import moment from 'moment'
import { BE_SHORT_DATE_FORMAT, canIAccessThisWithMyRoles, DEFAULT_PAGE_SIZE, PAGINATION, showTotalRecords } from '../../../const/utils'
import { transformTeamModeLabel } from './Teams.const'
import { SUSGAIN_ROLES } from '../../../const/roles'

export const Teams = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const teams = useSelector(state => state.teams.teams)
    const communities = useSelector(state => state.community.communities)

    const [teamsData, setTeamData] = useState(teams?.result)
    const [searchText, setSearchText] = useState('')
    const [selectedCommunityId, setSelectedCommunityId] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const searchInput = useRef(null)
    const [wordEntered, setWordEntered] = useState('')

    useEffect(() => {
        dispatch(fetchAllTeams())
        dispatch(fetchAllCommunity())
    }, [])

    // const handleSearch = (selectedKeys, confirm, dataIndex) => {
    //     confirm()
    //     setSearchText(selectedKeys[0])
    //     setSearchedColumn(dataIndex)
    // }

    const handleSearch = (event) => {
        const searchWord = event.target.value
        setWordEntered(searchWord)
        // if (searchWord === '') {
        //     dispatch(fetchAllTeams())
        //     return
        // }
        // dispatch(fetchAllTeams({ search: searchWord.toLowerCase() }))
    }

    const _handleSearchAndFilter = (pagination = PAGINATION) => {
        const params = {
            ...pagination
        }
        if (!!wordEntered) {
            params['search'] = wordEntered.toLowerCase()
        }
        if (selectedCommunityId) {
            params['communityId'] = selectedCommunityId
        }
        dispatch(fetchAllTeams(params))
    }

    useEffect(() => {
        setTeamData(teams?.result)
    }, [teams])

    useEffect(() => {
        // if (selectedCommunityId) {
        //     dispatch(fetchAllTeams({ communityId: selectedCommunityId }))
        // } else {
        //     dispatch(fetchAllTeams())
        // }
        setSelectedCommunityId(selectedCommunityId)
    }, [selectedCommunityId])

    const handleReset = (clearFilters) => {
        clearFilters()
        setSearchText('')
    }

    const handleColumnSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block'
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        className='mx-2 bg-[#207868]'
                        onClick={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined
                }}
            />
        ),
        onFilter: (value, record) => {
            if (record[dataIndex]) {
                if (dataIndex === 'teamMode' || dataIndex === 'status') {
                    if (record[dataIndex]?.props?.children) {
                        return record[dataIndex].props.children.toLowerCase().includes(value.toLowerCase())
                    } else {
                        return false
                    }
                } else {
                    return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                }
            } else {
                return false
            }
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        }
        // render: (text) => (searchedColumn === dataIndex ? (
        //     <>
        //         highlightStyle={{
        //             backgroundColor: '#ffc069',
        //             padding: 0
        //         }}
        //         searchWords={[searchText]}
        //         autoEscape
        //         textToHighlight={text ? text.toString() : ''}
        //     </>
        // ) : (
        //     text
        // ))
    })

    const columns = [
        // {
        //     title: 'No',
        //     dataIndex: 'srno',
        //     key: 'srno'
        // },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            notAllowToSearch: true
            // ...getColumnSearchProps('name')
        },
        {
            title: 'Team Mode',
            dataIndex: 'teamMode',
            key: 'teamMode',
            notAllowToSearch: true
            // ...getColumnSearchProps('teamMode')

        },
        {
            title: 'Location Name',
            dataIndex: 'location',
            key: 'location',
            notAllowToSearch: true
            // ...getColumnSearchProps('location')

        },
        {
            title: 'Community Name',
            dataIndex: 'community',
            key: 'community',
            notAllowToSearch: true
            // ...getColumnSearchProps('community')

        },
        {
            title: 'Timezone',
            dataIndex: 'timezone',
            key: 'timezone',
            notAllowToSearch: true
            // ...getColumnSearchProps('community')

        },
        {
            title: 'Total Member',
            dataIndex: 'totalMembers',
            key: 'totalMembers',
            notAllowToSearch: true
            // ...getColumnSearchProps('totalMembers')
        },
        {
            title: 'Share Link',
            dataIndex: 'shareUrl',
            key: 'shareUrl'
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '5%',
            // sorter: (a, b) => a.status.length - b.status.length,
            sortDirections: ['descend', 'ascend'],
            notAllowToSearch: true
            // ...getColumnSearchProps('status')
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            fixed: 'right',
            width: '120px'
        }
    ]

    const deleteTeam = (id) => {
        dispatch(deleteTeamById(id))
        return new Promise(resolve => {
            setTimeout(() => resolve(), 2000)
        })
    }

    const handleCommunityFilter = (id) => {

        if (id === undefined) {
            setSelectedCommunityId(null)
            // setTeamData(teams?.result)
        } else {
            setSelectedCommunityId(id)
            // dispatch(fetchAllTeams({ communityId: id }))
            // ServerApi().get(`teams?communityId=${id}`)
            //     .then(res => {
            //         setTeamData(res.data?.result)
            //     })
            //     .catch(e => {
            //         console.log(e)
            //     })
        }
    }

    const canAccess = () => {
        return canIAccessThisWithMyRoles([SUSGAIN_ROLES.ADMIN.role])
    }

    const handleTableChange = (pagination) => {
        console.log(`handleTableChange called `, pagination)
        dispatch(fetchAllTeams(pagination))
    }

    return (
        <div className='bg-white shadow'>
            <PageHeader
                ghost={false}
                title="Teams"
                extra={canAccess() ? [<Button icon={<PlusOutlined />} key="btn" type="primary" className="bg-[#207868] flex items-center" onClick={() => navigate('/teams/add')} >New Team</Button>] : <></>}
            ></PageHeader>
            <Flex gap="middle">
                <Flex gap="middle" vertical>
                    <Flex gap="middle" vertical className='mb-4'>
                        <Input
                            className='w-[450px] h-[40px] ml-4'
                            placeholder='Search by Team Name'
                            defaultValue={wordEntered}
                            onChange={handleSearch}
                            allowClear
                            prefix={<SearchOutlined />}
                        />
                        <Select
                            // bordered={false}
                            className='w-[450px] h-[40px] ml-4'
                            placeholder='Community'
                            optionFilterProp='label'
                            options={communities.result?.map(i => ({ label: i.name, value: i._id }))}
                            onChange={handleCommunityFilter}
                            allowClear
                        />
                    </Flex>
                </Flex>
                <Flex gap="middle">
                    <Button className='w-[200px] h-[40px]' onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
                </Flex>
            </Flex>
            <Table
                className='z-[0] relative overflow-auto'
                columns={columns}
                dataSource={teamsData?.map((i, it) => ({
                    key: i._id,
                    // srno: ++it,
                    name: i.name,
                    teamMode: <Tag color={i.teamMode == 'private' ? '#119D49' : 'blue'}>{transformTeamModeLabel(i.teamMode)}</Tag>,
                    timezone: i?.timezone || '-',
                    location: i?.city?.name || '-',
                    community: i?.communityId?.name,
                    totalMembers: i.totalMembers,
                    shareUrl: i.qrCode && (
                        <>
                            {i.qrCode.qr_code &&
                                <img
                                    className="w-20"
                                    src={`${i.qrCode.qr_code}`}
                                />
                            }
                            <CopyToClipboard text={i.qrCode.share_url}>
                                <p className='cursor-pointer text-blue-400 hover:text-blue-800 hover:underline' onClick={() => message.success('Link copied to Clipboard')}>Share Link</p>
                            </CopyToClipboard>
                        </>
                    ),
                    createdAt: i.createdAt && (moment(i.createdAt).format(BE_SHORT_DATE_FORMAT)),
                    status: <Tag color={i.publish === true ? 'green' : 'red'}>{i.publish ? 'Published' : 'Not published'}</Tag>,
                    action:
                        <div style={{ minWidth: '65px' }}>
                            <Tooltip title="Edit">
                                <Button style={{ marginRight: "8px" }} onClick={() => navigate((`/teams/edit/${i._id}`))} size="small" type="ghost" shape="circle" icon={<EditOutlined />} />
                            </Tooltip>
                            {canAccess() &&
                                <Popconfirm
                                    title="Are you sure？"
                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                    onConfirm={() => deleteTeam(i._id)}
                                    onCancel={() => null}
                                    okText="Yes"
                                    okType="danger"
                                    placement="topLeft"
                                    cancelText="Cancel"
                                >
                                    <Button size="small" type="ghost" danger shape="circle" icon={<DeleteOutlined />} />
                                </Popconfirm>
                            }
                        </div>
                }))}
                pagination={{
                    showSizeChanger: true,
                    // hideOnSinglePage: true,
                    position: ["none", "bottomRight"],
                    total: teams?.totalCount,
                    defaultPageSize: DEFAULT_PAGE_SIZE,
                    showTotal: showTotalRecords
                }}
                onChange={handleTableChange}
            />
        </div>
    )
}