import { GET_ALL_SDGS } from "./types"
import { toggleNetworkLoading } from "../common/actions"
import { message } from "antd"
import ServerApi from "../../utility/ServerApi"

export const fetchAllSDGS = () => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/sdgs`)
        .then(res => {
            dispatch({
                type: GET_ALL_SDGS,
                payload: res.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error('Unable to fetch sdgs')
            console.log(e)
        })
}