export const ImpactByHabitsTableColumns = [
  {
    title: 'No',
    dataIndex: 'srno',
    key: 'srno',
    notAllowToSearch: true
  },
  {
    title: 'Community',
    dataIndex: 'communityName',
    key: 'communityName'
  },
  // {
  //   title: 'Team',
  //   dataIndex: 'teamName',
  //   key: 'teamName'
  // },
  {
    title: 'Habit Name',
    dataIndex: 'habitName',
    key: 'habitName'
  },
  {
    title: 'Total Activities',
    dataIndex: 'total',
    key: 'total'
  },
  {
    title: 'Total Carbon',
    dataIndex: 'total_carbon',
    key: 'total_carbon'
  }
]
