import React, { useState, useEffect } from 'react'
import { Collapse, Button, Form, Typography, Table, Modal, InputNumber } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'

/**
 * #44934 - We encounter another issue related to Challenge Rewards for UBS - extra pts rewards does not count for each members
 * @param {*} param0 
 * @returns 
 */
const ManualAddRemovePointsModal = ({ mode, onConfirm, setVisible, visible, refresh }) => {

  // const [form] = Form.useForm()
  const [modalForm] = Form.useForm()
  // const navigate = useNavigate()
  // const dispatch = useDispatch()

  const [formSubmitState, setFormSubmitState] = useState(false)

  const resetModal = () => {
    setFormSubmitState(false)
    modalForm.setFieldsValue({ points: 0 })
    // refresh()
    setVisible(false)
  }

  const handleNewMemberSubmit = (values) => {
    onConfirm({ mode, points: values.points })
    resetModal()
  }

  return (
    <Modal
      open={visible}
      onCancel={() => resetModal()}
      footer={""}
    >
      <div className='text-center mb-6'>
        <Typography className='text-xl font-bold'>{mode == 'add' ? `Add Points for Members` : `Remove Points for Members`}</Typography>
        {mode == 'add' ? <Typography>Add the points for every single member personal profile of the teams. This will also count towards community / team points.</Typography> : <Typography>Remove the points for every single member personal profile of the teams. This will not deduct against community / team points.</Typography>}
      </div>
      <Form
        form={modalForm}
        name='Add_Points_Team_Member'
        labelCol={{ span: 4 }}
        onFinish={(values) => handleNewMemberSubmit(values)}
        autoComplete='off'
      >
        <Form.Item
          labelAlign='left'
          label='Points'
          name='points'
          extra={`Extra points adding to each members and community/team points.`}
          rules={[{ required: true, message: 'Please input number of points' }]}
        >
          <InputNumber max={1000} maxLength={5} />
        </Form.Item>

        <div className='flex justify-center'>
          <Button className="bg-[#207868] px-6" type="primary" htmlType="submit">
            {`Confirm`}
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default ManualAddRemovePointsModal