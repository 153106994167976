export const DevicesActionsTableColumns = [
  {
    title: 'No',
    dataIndex: 'srno',
    key: 'srno',
    notAllowToSearch: true
  },
  {
    title: 'Device Name',
    dataIndex: 'deviceName',
    key: 'deviceName'
  },

  {
    title: 'Created Date',
    dataIndex: 'createdDate',
    key: 'createdDate',
    notAllowToSearch: true
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    notAllowToSearch: true
  }
]