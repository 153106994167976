import { FETCH_ZOHO_TRANSACTIONS, FETCH_ZOHO_TRANSACTION_BY_ID } from "./types"
import { toggleNetworkLoading } from "../common/actions"
import { message } from 'antd'
import ServerApi from "../../utility/ServerApi"
import { PAGINATION } from "../../const/utils"
import ServerApiCSV from "../../utility/ServerApiCSV"
import moment from 'moment'

export const fetchZohoTransactions = (filters = PAGINATION, callback = null) => dispatch => {
    // console.log(`fetchZohoTransactions filters `, filters)
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/zoho/activity-transactions?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`)
        .then(res => {
            dispatch({
                type: FETCH_ZOHO_TRANSACTIONS,
                payload: res.data
            })
            if (callback) callback(res.data)
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fetching Transactions", e)
            console.log(e)
        })
}
export const fetchTransactionById = (id) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/activities-instances/activity-transactions-details/${id}`)
        .then(res => {
            dispatch({
                type: FETCH_ZOHO_TRANSACTION_BY_ID,
                payload: res.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            message.error("Error Fetching data!")
            dispatch(toggleNetworkLoading(false))
            console.log(e)
        })
}

export const refreshNewTransaction = (newTransactionData) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    dispatch({
        type: FETCH_ZOHO_TRANSACTION_BY_ID,
        payload: newTransactionData
    })
    dispatch(toggleNetworkLoading(false))
}
