import { SearchOutlined, EditOutlined, DeleteOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Input, Space, Table, Tooltip, Tag, Popconfirm, Flex } from 'antd'
import { useNavigate } from "react-router-dom"
import React, { useEffect, useRef, useState } from 'react'
import { fetchAllCommunity, deleteCommunityById } from '../../../store/community/actions'
import { useSelector, useDispatch } from 'react-redux'
// import PreviewImage from '../../../components/image/PreviewImage'
// import Highlighter from 'react-highlight-words'
import moment from 'moment'
import { CommunityIndexTable } from './Community.columns'
import { BE_SHORT_DATE_FORMAT, canIAccessThisWithMyRoles, DEFAULT_PAGE_SIZE, PAGINATION, showTotalRecords } from '../../../const/utils'
import { SUSGAIN_ROLES } from '../../../const/roles'

export const Community = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const communities = useSelector(state => state.community.communities)

    const [prepairCommunityIndexTableColumns, setPrepairCommunityIndexTableColumns] = useState(null)
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')
    const searchInput = useRef(null)

    const handleColumnSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = (clearFilters) => {
        clearFilters()
        setSearchText('')
    }


    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block'
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        className='mx-2 bg-[#207868]'
                        onClick={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined
                }}
            />
        ),
        onFilter: (value, record) => {
            if (record[dataIndex]) {
                if (dataIndex === 'status') {
                    return record[dataIndex]?.props.children.toLowerCase().includes(value.toLowerCase())
                } else {
                    return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                }
            } else {
                return false
            }

        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        }
    })

    useEffect(() => {
        dispatch(fetchAllCommunity())


        const newCommunityIndexTableColumns = CommunityIndexTable.map((item) => {
            if (item.notAllowToSearch === true) {
                return item
            } else {
                return {
                    ...item,
                    ...getColumnSearchProps(item['dataIndex'])
                }
            }
        })
        setPrepairCommunityIndexTableColumns(newCommunityIndexTableColumns)
    }, [])

    const [communitiesData, setCommunitesData] = useState(communities?.result)
    const [wordEntered, setWordEntered] = useState('')

    useEffect(() => {
        setCommunitesData(communities?.result)
    }, [communities])

    const handleSearch = (event) => {
        const searchWord = event.target.value
        setWordEntered(searchWord)
    }

    const _handleSearchAndFilter = (pagination = PAGINATION) => {
        const params = {
            ...pagination
        }
        if (!!wordEntered) {
            params['search'] = wordEntered.toLowerCase()
        }
        dispatch(fetchAllCommunity(params))
    }

    const canAccess = () => {
        return canIAccessThisWithMyRoles([SUSGAIN_ROLES.ADMIN.role])
    }

    const deleteCommunity = (id) => {
        dispatch(deleteCommunityById(id))
        return new Promise(resolve => {
            setTimeout(() => resolve(), 2000)
        })
    }

    const handleTableChange = (pagination) => {
        dispatch(fetchAllCommunity(pagination))
    }
    return (
        <div className='bg-white shadow'>
            <PageHeader
                ghost={false}
                title="Manage Community"
                extra={canAccess() ? [<Button className='bg-mainThemeColor hover:bg-mainThemeColor flex items-center' key="btn" icon={<PlusOutlined />} type="primary" onClick={() => navigate('/communities/add')} >New Community</Button>] : <></>}
            ></PageHeader>
            <Flex gap="middle">
                <Flex gap="middle" vertical>
                    <Flex gap="middle">
                        <Input
                            className='w-[450px] h-[40px] ml-4'
                            placeholder='Search Community Name'
                            defaultValue={wordEntered}
                            allowClear
                            onChange={handleSearch}
                            prefix={<SearchOutlined />}
                        />
                    </Flex>
                </Flex>
                <Flex gap="middle" vertical>
                    <Flex gap="middle" className='mb-4'>
                        <Button className='w-[200px] h-[40px]' onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
                    </Flex>
                </Flex>
            </Flex>
            <Table
                className='z-[0] relative overflow-auto'
                columns={prepairCommunityIndexTableColumns}
                dataSource={communitiesData?.map((i, it) => ({
                    key: i._id,
                    // srno: ++it,
                    name: i.name,
                    timezone: i?.timezone || '-',
                    noOfTeam: i?.totalTeams,
                    totalMembers: i.totalMembers,
                    communityStatus: i.status,
                    published: <Tag color={i.publish === true ? 'green' : 'red'}>{i.publish ? 'Published' : 'Not published'}</Tag>,
                    createdAt: i.createdAt && (moment(i.createdAt).format(BE_SHORT_DATE_FORMAT)),
                    action:
                        <div style={{ minWidth: '65px' }}>
                            <Tooltip title="Edit">
                                <Button style={{ marginRight: "8px" }} onClick={() => navigate((`/communities/edit/${i._id}`))} size="small" type="ghost" shape="circle" icon={<EditOutlined />} />
                            </Tooltip>
                            {canAccess() &&
                                <Popconfirm
                                    title="Are you sure？"
                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                    onConfirm={() => deleteCommunity(i._id)}
                                    onCancel={() => null}
                                    okText="Yes"
                                    okType="danger"
                                    placement="topLeft"
                                    cancelText="Cancel"
                                >
                                    <Button size="small" type="ghost" danger shape="circle" icon={<DeleteOutlined />} />
                                </Popconfirm>
                            }
                        </div>
                }))}
                pagination={{
                    showSizeChanger: true,
                    // hideOnSinglePage: true,
                    position: ["none", "bottomRight"],
                    total: communities?.totalCount,
                    defaultPageSize: DEFAULT_PAGE_SIZE,
                    showTotal: showTotalRecords
                }}
                onChange={handleTableChange}
            />
        </div>
    )
}