import { Action } from "history"
import { FETCH_COMMUNITY_SCORING_TRANSACTIONS} from "./types"

const initialState = {
    communityScoringTransactions: []
}

const scoringTransactionReducer = (state = initialState, aciton) => {
    switch (aciton.type) {
        case FETCH_COMMUNITY_SCORING_TRANSACTIONS:
            return { ...state, communityScoringTransactions: aciton.payload }
        default:
            return state
    }
}

export default scoringTransactionReducer