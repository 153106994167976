export const exportData = [
  {
    label: 'Id',
    key: 'id'
  },
  {
    label: 'Name',
    key: 'name'
  },
  {
    label: 'Email',
    key: 'email'
  },
  {
    label: 'Created_Date',
    key: 'createddate'
  },
  {
    label: 'Type',
    key: 'type'
  },
  {
    label: 'Community_Id',
    key: 'communityid'
  },
  {
    label: 'Community_Name',
    key: 'communityname'
  },
  {
    label: 'Team_Id',
    key: 'teamid'
  },
  {
    label: 'Team_Name',
    key: 'teamname'
  },
  {
    label: 'Current_Points',
    key: 'currentpoints'
  },
  {
    label: 'Total_Trees',
    key: 'trees'
  },
  {
    label: 'Trash_Removed',
    key: 'trashremoved'
  },
  {
    label: 'Meals',
    key: 'meals'
  },
  {
    label: 'Voucher',
    key: 'voucher'
  },
  {
    label: 'Verified',
    key: 'verified'
  }
]

export const ACCOUNT_STATUS = {
  DELETED: { value: "DELETED", label: "Deleted" },
  ACTIVE: { value: "ACTIVE", label: "Verified" },
  NOT_ACTIVE: { value: "NOTACTIVE", label: "Not Verified" }
}

/**
 * 
 * @param {*} x_app_platforms 
 * @returns 
 */
export const findUserPlatformFromMeta = (x_app_platforms) => {
  //find x_app_platform   
  // try {
  //   if (x_app_platform) {
  //     return x_app_platform
  //   }
  //   if (xheaders_meta && xheaders_meta['x-app-platform']) {
  //     return xheaders_meta['x-app-platform']
  //   }
  //   //find from string
  //   if (xheaders) {
  //     const jheader = JSON.parse(xheaders)
  //     if (jheader && jheader['x-app-platform']) {
  //       return jheader['x-app-platform']
  //     }
  //   }
  // } catch (e) {
  //   console.error(e)
  // }
  try {
    if (x_app_platforms && x_app_platforms.length > 0) {
      return x_app_platforms.join(", ")
    }
  } catch (e) {

  }
  return "unknown"
}