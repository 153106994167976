import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { fetchILightDetail } from '../../../store/iLight/actions'
import { Descriptions, Col, Row } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { BE_FULL_DATE_FORMAT } from '../../../const/utils'
import moment from 'moment'

export const ILightInfo = () => {

  const dispatch = useDispatch()
  const { iLightId } = useParams()

  const iLightDetail  = useSelector(state => state.iLight.iLightDetail)

  useEffect(() => {
    dispatch(fetchILightDetail(iLightId))
  }, [])

  let country_name
  let country_flag_url

  return (
    <div className='bg-white p-5 overflow-auto'>
        <Descriptions
        title='ILight Info'
        className='bg-white mb-10'
        labelCol={{ span: 4 }}
        bordered
      >
        <Descriptions.Item label='ID'
          labelStyle={{ fontWeight: 'bold' }}
          span={3}
        >
          {iLightDetail?._id}
        </Descriptions.Item>

        <Descriptions.Item label='Name'
          labelStyle={{ fontWeight: 'bold' }}
          span={3}
        >
          {iLightDetail?.name}
        </Descriptions.Item>

        <Descriptions.Item label='Email'
          labelStyle={{ fontWeight: 'bold' }}
          span={3}
        >
          {iLightDetail?.email}
        </Descriptions.Item>

        {/* <Descriptions.Item label='Is Email Sent'
          labelStyle={{ fontWeight: 'bold' }}
          span={3}
        >
          {iLightDetail?.isSentEmail === true ? <CheckCircleOutlined className='text-green-500' /> : <CloseCircleOutlined className='text-red-500' /> } 
        </Descriptions.Item> */}

        {/* <Descriptions.Item label='Metadata'
          labelStyle={{ fontWeight: 'bold' }}
          span={3}
        >
          {iLightDetail?.metadata ? JSON.stringify(iLightDetail?.metadata) : ""}
        </Descriptions.Item> */}
        <Descriptions.Item label="Switch Off & Turn Up"
          labelStyle={{ fontWeight: 'bold' }}
          span={3}
        >
          {iLightDetail?.metadata && (iLightDetail?.metadata?.pledge === 'switch-off' || iLightDetail?.metadata?.pledge === 'pledge-all') ? <CheckCircleOutlined className='text-green-500' /> : <CloseCircleOutlined className='text-red-500' />}
        </Descriptions.Item>

        <Descriptions.Item label="Be a Zero Hero"
          labelStyle={{ fontWeight: 'bold' }}
          span={3}
        >
          {iLightDetail?.metadata && (iLightDetail?.metadata?.pledge === 'recycle' || iLightDetail?.metadata?.pledge === 'pledge-all') ? <CheckCircleOutlined className='text-green-500' /> : <CloseCircleOutlined className='text-red-500' />}
        </Descriptions.Item>
        
        <Descriptions.Item label="Join the lucky draw"
          labelStyle={{ fontWeight: 'bold' }}
          span={3}
        >
          {iLightDetail?.metadata && iLightDetail?.metadata?.lucky_draw === 'on' ? <CheckCircleOutlined className='text-green-500' /> : <CloseCircleOutlined className='text-red-500' />}
        </Descriptions.Item>

        <Descriptions.Item label="Message"
          labelStyle={{ fontWeight: 'bold' }}
          span={3}
        >
          {iLightDetail?.metadata && iLightDetail?.metadata?.message}
        </Descriptions.Item>

        <Descriptions.Item label="Created Data"
          labelStyle={{ fontWeight: 'bold' }}
          span={3}
        >
          {iLightDetail.createdAt ? (moment(iLightDetail.createdAt).format(BE_FULL_DATE_FORMAT)) : (iLightDetail?.createDate ? moment(iLightDetail.createDate).format(BE_FULL_DATE_FORMAT) : '')}
        </Descriptions.Item>

        {/* <Descriptions.Item label="User IP Location Metadata"
          labelStyle={{ fontWeight: 'bold' }}
          span={3}
        >
          { 
           iLightDetail?.userIPLocationMetadata && Object.keys(iLightDetail?.userIPLocationMetadata).length > 0 && Object.entries(iLightDetail?.userIPLocationMetadata).map(([key, value]) => {
              if (key === 'currency' || key === 'time_zone') {
                return (
                  <div>{`${key}: {
                    ${Object.entries(value).map(([item_key, item_value]) => {
                      return (
                        `${item_key}: ${item_value}`
                      )
                    })}
                }`}</div>
                 )
              } else {
                return (
                  <div>{`${key}: ${value}`}</div>
                 )
              }
            })
          }
        </Descriptions.Item> */}

        <Descriptions.Item label="Country"
          labelStyle={{ fontWeight: 'bold' }}
          span={3}
        >
          { 
           iLightDetail?.userIPLocationMetadata && Object.keys(iLightDetail?.userIPLocationMetadata).length > 0 && Object.entries(iLightDetail?.userIPLocationMetadata).map(([key, value]) => {
              if (key === 'country_name') {
                country_name = value
              } else if (key === 'country_flag') {
                country_flag_url = value
              }
            }) && <Row>
                    <Col span={2}><img width={30} src={country_flag_url}/></Col>
                    <Col >{country_name}</Col>
                </Row>
          }

        </Descriptions.Item>

      </Descriptions>
    </div>
  )

}