// import { SearchOutlined, EditOutlined, DeleteOutlined, PlusOutlined, QuestionCircleOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { useNavigate, Link } from 'react-router-dom'
import {
    useCSVReader,
    lightenDarkenColor, formatFileSize
} from 'react-papaparse'
import { downloadImportGPSCSVTemplate } from '../../../store/activity/actions'

const GREY = '#CCC'
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)'
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919'
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
    DEFAULT_REMOVE_HOVER_COLOR,
    40
)
const GREY_DIM = '#686868'

const styles = {
    zone: {
        alignItems: 'center',
        border: `2px dashed ${GREY}`,
        borderRadius: 20,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        padding: 20
    },
    file: {
        background: 'linear-gradient(to bottom, #EEE, #DDD)',
        borderRadius: 20,
        display: 'flex',
        height: 120,
        width: 120,
        position: 'relative',
        zIndex: 10,
        flexDirection: 'column',
        justifyContent: 'center'
    },
    info: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 10,
        paddingRight: 10
    },
    size: {
        backgroundColor: GREY_LIGHT,
        borderRadius: 3,
        marginBottom: '0.5em',
        justifyContent: 'center',
        display: 'flex'
    },
    name: {
        backgroundColor: GREY_LIGHT,
        borderRadius: 3,
        fontSize: 12,
        marginBottom: '0.5em'
    },
    progressBar: {
        bottom: 14,
        position: 'absolute',
        width: '100%',
        paddingLeft: 10,
        paddingRight: 10
    },
    zoneHover: {
        borderColor: GREY_DIM
    },
    default: {
        borderColor: GREY
    },
    remove: {
        height: 23,
        position: 'absolute',
        right: 6,
        top: 6,
        width: 23
    }
}
/**
 * 
 * @param {*} param0 
 * @returns 
 */
export const UploadGpsCsv = ({ onCsvUploaded }) => {
    const dispatch = useDispatch()
    useEffect(() => {

    }, [])
    const [csvDataUploaded, setCsvDataUploaded] = useState(null)

    const { CSVReader } = useCSVReader()
    const [zoneHover, setZoneHover] = useState(false)
    const [csvMembersSelected, setCsvMembersSelected] = useState(null)
    const [removeHoverColor, setRemoveHoverColor] = useState(
        DEFAULT_REMOVE_HOVER_COLOR
    )

    useEffect(() => {
        if (csvDataUploaded) {
            setCsvMembersSelected(`${csvDataUploaded.length} locations selected...`)
        } else {
            setCsvMembersSelected('')
        }
    }, [csvDataUploaded])

    const handleOnRemoveFile = (data) => {
        console.log('handleOnRemoveFile called')
        setCsvDataUploaded(null)
    }

    /**
     * First row is csv header
     * fields: ['Name', 'Address', 'Location', 'Radius']
     * @param {*} _data_selected 
     */
    const _NOC = 4
    const _onPreProcessCSVFileUpload = (_data_selected) => {
        if (_data_selected && _data_selected.length > 1) {
            //preprocess value
            const _result = []
            for (let i = 1; i < _data_selected.length; i++) {
                try {
                    if (_data_selected[i].length == _NOC) {
                        //Location field should be in {lat,lng}
                        if (_data_selected[i][2].split(',').length == 2) {
                            //push valid address object
                            _result.push({
                                locationName: _data_selected[i][0],
                                address: _data_selected[i][1],
                                image: null,
                                lat: _data_selected[i][2].split(',')[0],
                                lng: _data_selected[i][2].split(',')[1],
                                radius: _data_selected[i][3],
                                isImported: true
                            })
                        }
                    }
                } catch (err) {
                    console.error(err)
                }
            }
            setCsvDataUploaded(_result)
            if (_result.length > 0) {
                if (onCsvUploaded) {
                    onCsvUploaded(_result)
                }
            }
        }
    }

    const _renderCSVUpload = () => {
        return (
            <CSVReader
                onUploadAccepted={(results: any) => {
                    // console.log('---------------------------')
                    // console.log(results)
                    // setCsvDataUploaded(results.data)
                    _onPreProcessCSVFileUpload(results.data)
                    // console.log('---------------------------')
                    setZoneHover(false)
                }}
                onDragOver={(event: DragEvent) => {
                    event.preventDefault()
                    setZoneHover(true)
                }}
                onDragLeave={(event: DragEvent) => {
                    event.preventDefault()
                    setZoneHover(false)
                }}
                onRemoveFile={handleOnRemoveFile}
                onUploadRejected={handleOnRemoveFile}
                noDrag
            >
                {({
                    getRootProps,
                    acceptedFile,
                    ProgressBar,
                    getRemoveFileProps,
                    Remove
                }: any) => (
                    <>
                        <div
                            {...getRootProps()}
                            style={Object.assign(
                                {},
                                styles.zone,
                                zoneHover && styles.zoneHover
                            )}
                        >
                            {acceptedFile ? (
                                <>
                                    <div style={styles.file}>
                                        <div style={styles.info}>
                                            <span style={styles.size}>
                                                {formatFileSize(acceptedFile.size)}
                                            </span>
                                            <span style={styles.name}>{acceptedFile.name}</span>
                                        </div>
                                        <div style={styles.progressBar}>
                                            <ProgressBar />
                                        </div>
                                        <div
                                            {...getRemoveFileProps()}
                                            style={styles.remove}
                                            onMouseOver={(event: Event) => {
                                                event.preventDefault()
                                                setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT)
                                            }}
                                            onMouseOut={(event: Event) => {
                                                event.preventDefault()
                                                setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR)
                                            }}
                                        // onClick={(event: Event) => {
                                        //     console.log('remove file')
                                        //     setCsvDataUploaded([])
                                        //     event.preventDefault()
                                        // }}
                                        >
                                            <Remove color={removeHoverColor} />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                'Upload GPS in CSV'
                            )}
                        </div>
                    </>
                )}
            </CSVReader>
        )
    }

    const _downloadImportGPSCSVTemplate = () => {
        dispatch(downloadImportGPSCSVTemplate())
    }

    return (<div>
        {_renderCSVUpload()}
        <div className='text-center mb-6'>
            <Typography className='mt-2'>First time import CSV? Please download the template <a onClick={_downloadImportGPSCSVTemplate}>here</a></Typography>
        </div>
    </div>)
}

