import React, { useState, useEffect } from 'react'
import { Typography, Button, Form, Input, Upload, message, Table, Space, Dropdown, Radio, Collapse, Tooltip, Modal, Select, Popconfirm } from 'antd'
import { UploadOutlined, DeleteOutlined, EditOutlined, QuestionCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
// import { CgArrowsV } from 'react-icons/cg'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux' 
import ServerApi from '../../../utility/ServerApi'
import { availableTimezones, isSupportedFileFormatForGeneric, SUPPORTED_FILE_FORMAT_EXTRA } from '../../../const/utils'
import { createNewLocation } from '../../../store/locations/actions'

const { Panel } = Collapse

const AddNewLocation = () => {

  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const successCallback = () => {
    navigate('/locations')
  }

  const [fileList, setFileList] = useState([])

  const _handleCreateNewLocation = (values) => {
    if (fileList[0]) values.icon = fileList[0].icon
    dispatch(createNewLocation(values, successCallback))
  }

  const handleChange = async ({ file }) => {
    if (!isSupportedFileFormatForGeneric(file.type)) {
      message.error('You can only upload JPG/PNG file!')
      return
    }
    setFileList(
      [{ uid: file.uid, name: '', status: 'uploading' }]
    )
    const formData = new FormData()
    formData.append('file', file)
    formData.append('folder', 'activities')

    ServerApi().post('media/admin-create-media', formData)
      .then(res => {
        setFileList([
          {
            uid: file.uid,
            name: res.data.name,
            icon: res.data.file,
            status: 'done',
            url: `${res.data.path}`,
            thumbUrl: `${res.data.path}`
          }
        ])
        return true
      })
      .catch(e => { console.log(e); return false })
  }


  const props = {
    customRequest: handleChange,
    listType: 'picture',
    onRemove: () => setFileList([]),
    fileList
  }

  return (
    <>
      <div className='container mx-auto bg-white'>
        <Form
          name='NewLocation'
          form={form}
          initialValues={{ publish: false, colorCode: "RED" }}
          onFinish={(values) => _handleCreateNewLocation(values)}
          labelCol={{ span: 4 }}
          requiredMark=''
        >
          <Collapse className="border-none" expandIconPosition={'end'} defaultActiveKey={['1']}>
            <Panel header={<p className='font-bold text-lg'>Location Info</p>} key="1" className="bg-textbackgroundcolor">
              <Form.Item
                label='Location Name'
                name='name'
                labelAlign='left'
                colon={false}
                rules={[{ required: true, message: 'Please enter your location name!' }]}
                hasFeedback
              >
                <Input className='border-bordercolor rounded flex items-center' />
              </Form.Item>

              <Form.Item
                label='Timezone'
                name='timezone'
                labelAlign='left'
                colon={false}
                rules={[{ required: false, message: 'Please select a timezone' }]}
              >
                <Select
                  showSearch
                  allowClear
                  options={availableTimezones()?.map(i => ({ label: i, value: i }))}
                />
              </Form.Item>

              <Form.Item
                labelAlign="left"
                colon={false}
                label='Icon'
                extra={SUPPORTED_FILE_FORMAT_EXTRA.CITY_LOCATION_ICON}
              >
                <div className='flex justify-end'>
                  <Upload className='flex space-x-4' {...props} fileList={fileList}>
                    <Button className='flex items-align' icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </div>
              </Form.Item>


              <Form.Item name="publish" colon={false} labelAlign="left" label="Publish"
                rules={[{ required: true, message: 'Publish is not selected!' }]}
              >
                <Radio.Group className="text-left">
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Panel>
          </Collapse>
          <div className="text-center pt-2 pb-4">
            <Button className="bg-[#207868]" size="large" type="primary" htmlType="submit">
              Save Changes
            </Button>
          </div>
        </Form>
      </div>
    </>
  )
}

export default AddNewLocation