import { CREATE, FETCH_ALL_HABITS } from "./types"

const initialState = {
    habits: [],
    habit: {}
}

const habitReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_HABITS:
            return { ...state, habits: action.payload }

        default:
            return state
    }
}

export default habitReducer