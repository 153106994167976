import React from 'react'
import { Card, Form, Input, Button } from 'antd'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { changePassword } from '../../../store/auth/actions'

export const ChangePassword = () => {

    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const goToLogin = () => {
        navigate('/login')
    }

    const changeThisPassword = (values) => {
        dispatch(changePassword(values, goToLogin))
        // dispatch(changePassword(values))
    }

  return (
    <div className='flex justify-center items-center'>
        <Card title='Change Password' style={{ width: 550 }}>
            <Form
                name='ChangePassword'
                form={form}
                labelCol={{ 
                    span: 4 
                }}
                onFinish={(values) => changeThisPassword(values)}
                autoComplete='off'
            >
                <Form.Item
                    label='Current Password'
                    labelCol={{
                        span: 7
                    }}
                    labelAlign='left'
                    name='currentPassword'
                    rules={[{ required: true, message: 'Please enter your Current Password!' }]}
                >
                    <Input type='password'/>
                </Form.Item>
                <Form.Item
                    label='New Password'
                    labelCol={{
                        span: 7
                    }}
                    labelAlign='left'
                    name='newPassword'
                    rules={[
                        { required: true, message: 'Please enter your New Password!' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword').length > 5) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(new Error('Password too SHORT!'))
                            }
                        }),
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('currentPassword') === value) {
                                    return Promise.reject(new Error('New Password can not be same as current password'))
                                }
                                return Promise.resolve()
                            }
                        })
                ]}
                >
                    <Input type='password' />
                </Form.Item>
                <Form.Item
                    label='Comform Password'
                    labelCol={{
                        span: 7
                    }}
                    labelAlign='left'
                    name='confirmPassword'
                    rules={[
                        { required: true, message: 'Please confirm your password!' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve()
                                }

                                return Promise.reject(new Error('The two passwords that you entered do not match!'))
                            }
                        })
                    ]}
                >
                    <Input type='password' />
                </Form.Item>

                <div className="text-center pt-2 pb-4">
                    <Button className="bg-[#207868] px-8" size="large" type="primary" htmlType="submit">
                        Save
                    </Button>
                </div>

            </Form>
        </Card>
    </div>
  )
}