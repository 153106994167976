import { FETCH_ALL_DEVICE_BLACKLISTED } from "./types"
import { toggleNetworkLoading } from "../common/actions"
import { message } from 'antd'
import ServerApi from "../../utility/ServerApi"

export const fetchAllDeviceBlacklisted = () => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`device-blacklisted/admin-fetch-all-device-blacklists`)
        .then(res => {
            dispatch({
                type: FETCH_ALL_DEVICE_BLACKLISTED,
                payload: res.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fetching Locations", e)
            console.log(e)
        })
}

export const createNewDeviceBlacklisted = (rawData, successCallback) => dispatch => {
    ServerApi().post(`/device-blacklisted/admin-create-device-blacklist`, rawData)
        .then(res => {
            if (res.status === 200 || res.status === 201) {
                message.success("New Device Blacklisted Created")
                successCallback(res.data)
            } else {
                message.error("Error Creating Device Blacklisted!")
            }
            successCallback()
        })
        .catch(e => {
            message.error("Error Creating New Device Blacklisted", e)
            console.log(e)
        })
}


export const deleteDeviceBlacklistedById = (id, successCallback) => dispatch => {
    ServerApi().delete(`device-blacklisted/admin-delete-device-blacklist/${id}`)
        .then(res => {
            message.success("Device Blacklisted Deleted Succesfully!")
            successCallback(res.data)
        })
        .catch(e => {
            message.error("Error Deleting Device Blacklisted")
        })
}