import React, { useRef, useState, useEffect } from 'react'
import { SearchOutlined, EyeOutlined, DeleteOutlined, PlusOutlined, QuestionCircleOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Input, Space, Table, Tag, Checkbox, DatePicker, Select, Popover, Flex } from 'antd'
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
// import { CSVLink } from 'react-csv'
// import { ZohoActionsTableColumns } from './zoho.columns'
// import { exportFormat, TRANSACTION_DATE_FORMAT, ACTIVITY_VALIDATION_METHOD_STATUS } from './Transactions.const'
// import { BE_SHORT_DATE_FORMAT } from '../../../const/utils'
// import { ACTIVITY_VALIDATION_METHOD } from '../Activity/Activity.const'
// import { fetchZohoTransactions } from '../../../store/zoho/actions'
// import { TRANSACTION_DATE_FORMAT, ACTIVITY_VALIDATION_METHOD_STATUS } from '../Transactions/Transactions.const'
import { fetchChallengeVoucherRewardsTransactions } from '../../../store/transactions/rewardTransactions/actions'
import { VoucherActionsTableColumns } from './voucher.columns'
import { TRANSACTION_DATE_FORMAT, transformUserIdFormat } from '../Transactions/Transactions.const'
import { DEFAULT_PAGE_SIZE, PAGINATION, showTotalRecords } from '../../../const/utils'

export const VoucherTransactions = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const transactions = useSelector(state => state?.rewardTransactions?.voucherRewardTransactions)
  const searchInput = useRef(null)

  const [prepairActivityActionsTableColumns, setPrepairActivityActionsTableColumns] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [currentPagination, setCurrentPagination] = useState(PAGINATION)
  const handleColumnSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Button
            type="primary"
            className='mx-2 bg-[#207868]'
            onClick={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        if (dataIndex === 'status') {
          return record[dataIndex]?.props.children.toLowerCase().includes(value.toLowerCase())
        } else {
          return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        }
      } else {
        return false
      }

    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    }
  })

  useEffect(() => {
    dispatch(fetchChallengeVoucherRewardsTransactions())
    const newActivityActionsTableColumns = VoucherActionsTableColumns.map((item) => {
      if (item.notAllowToSearch === true) {
        return item
      } else {
        return {
          ...item,
          ...getColumnSearchProps(item['dataIndex'])
        }
      }
    })
    setPrepairActivityActionsTableColumns(newActivityActionsTableColumns)
  }, [])

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [filterDateRange, setFilterDateRange] = useState(null)
  const [filterStatus, setFilterStatus] = useState(null)
  // const filterStatuses = Object.values(ACTIVITY_VALIDATION_METHOD_STATUS)
  // const [selectedRows, setSelectedRows] = useState([])
  const [wordEntered, setWordEntered] = useState()

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
    // console.log(newSelectedRowKeys)
    // setSelectedRows(newSelectedRowKeys.map(i => transactions?.result[i]))
  }

  const rowSelection = {
    ...selectedRowKeys,
    // ...selectedRows,
    onChange: onSelectChange
  }

  const handleSearch = (event) => {
    const searchWord = event.target.value
    setWordEntered(searchWord)
  }

  const handleStatusFilter = (value) => {
    // console.log(value)
    setFilterStatus(value)
  }

  // /**
  //  * Export all CSV
  //  */
  // const _handleActivityTransactionsExportCsvAll = () => {
  //   // dispatch(activityTransactionsExportCsvAll())
  // }

  /**
   * Export selected records
   */
  // const _handleActivityTransactionsExportCsvSelected = () => {
  //   if (selectedRowKeys.length > 0) {
  //     dispatch(activityTransactionsExportCsvAll(selectedRowKeys))
  //   } else {
  //     //export from current result
  //     if (transactions?.result.length > 0) {
  //       const filterIds = transactions?.result.map(e => e._id)
  //       dispatch(activityTransactionsExportCsvAll(filterIds))
  //     }
  //   }
  // }

  // const _transformValidationMethod = (_method) => {
  //   try {
  //     return Object.values(ACTIVITY_VALIDATION_METHOD).filter(i => i.value == _method)[0].label
  //   } catch (e) {
  //   }
  //   return _method
  // }

  const _handleSearchAndFilter = (pagination = PAGINATION) => {
    const params = {
      ...pagination
    }
    const _current = JSON.parse(JSON.stringify(currentPagination))
    _current.current = pagination?.current || PAGINATION.current
    _current.pageSize = pagination?.pageSize || PAGINATION.pageSize
    setCurrentPagination(_current)
    if (!!wordEntered) {
      params['search'] = wordEntered.toLowerCase()
    }
    if (filterDateRange) {
      params['fromDate'] = moment(new Date(filterDateRange[0])).format(`DD/MM/YYYY`)
      params['toDate'] = moment(new Date(filterDateRange[1])).format(`DD/MM/YYYY`)
    }
    if (filterStatus) {
      params['status'] = filterStatus
    }
    dispatch(fetchChallengeVoucherRewardsTransactions(params))
  }

  const handleTableChange = (pagination) => {
    _handleSearchAndFilter(pagination)
  }

  const _handleFromToSelected = (v) => {
    // console.log(`_handleFromToSelected `, v)
    setFilterDateRange(v)
  }

  const hasSelected = selectedRowKeys.length > 0 || (transactions && transactions?.result?.length > 0)

  return (
    <div className='bg-white shadow'>
      <PageHeader
        ghost={false}
        title="Voucher Rewards"
      ></PageHeader>
      <Flex gap="middle">
        <Flex gap="middle" vertical>
          <Flex gap="middle">
            <Input
              className='w-[450px] h-[40px] ml-4'
              placeholder='Search Challenge Name, Email'
              value={wordEntered}
              onChange={handleSearch}
              prefix={<SearchOutlined />}
            />
            <DatePicker.RangePicker onChange={(v) => _handleFromToSelected(v)} />
          </Flex>
          <Flex gap="middle" className='mb-4'></Flex>
        </Flex>
        <Flex gap="middle" vertical>
          <Flex gap="middle" className='mb-4'>
            <Button className='w-[200px] h-[40px]' onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
            {/* <Button className='w-[200px] h-[40px] border-[#207868] text-[#207868]' disabled={!hasSelected} onClick={_handleRejectTransactionsSelected}>
              {`Reject Selected`}
            </Button> */}
          </Flex>
          {/* <Flex gap="middle" className='mb-4'>
            <Button className='w-[200px] h-[40px] border-[#207868] text-[#207868]' disabled={!hasSelected} onClick={_handleActivityTransactionsExportCsvSelected}>
              {`Export Selected`}
            </Button>
            <Button className='w-[200px] h-[40px] bg-[#207868]' type='primary' disabled={!(transactions?.result)} onClick={_handleActivityTransactionsExportCsvAll}>
            {`Export by Current Filter`}
            </Button>
          </Flex> */}
        </Flex>
      </Flex>
      <Table
        className='z-[0] relative overflow-auto'
        // rowSelection={rowSelection}
        columns={prepairActivityActionsTableColumns}
        dataSource={transactions?.result?.map((i, it) => ({
          key: i._id,
          srno: (++it + ((currentPagination?.current - 1) * currentPagination?.pageSize)),
          challengeName: i?.challengeData?.name,
          createdDate: i?.createdAt && (moment(i?.createdAt).format(TRANSACTION_DATE_FORMAT)),
          updatedDate: i?.updatedAt && (moment(i?.updatedAt).format(TRANSACTION_DATE_FORMAT)),
          userId: transformUserIdFormat(i?.userProfile),
          name: i?.userProfile?.name,
          email: i?.userProfile?.email,
          voucher_code: <Tag color={'blue'}>{i?.voucher_code}</Tag>,
          claim_email: i?.claim_email,
          claim_status: i?.status
          // activityName: i?.activity_id?.name,
          // callbackData: <Popover content={i?.meta_data} title="Callback Data">
          //   <p className='cursor-pointer text-blue-400 hover:text-blue-800 hover:underline'>View</p>
          // </Popover>
        }))}
        pagination={{
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          hideOnSinglePage: true,
          position: ["none", "bottomRight"],
          total: transactions?.totalCount,
          showTotal: showTotalRecords
        }}
        onChange={handleTableChange}
      />
    </div>
  )
}
