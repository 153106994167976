import React, { useRef, useState, useEffect } from 'react'
import { SearchOutlined, EyeOutlined, DeleteOutlined, PlusOutlined, QuestionCircleOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Input, Space, Table, Tag, Checkbox, DatePicker, Select } from 'antd'
import { CgArrowsV } from 'react-icons/cg'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { CommunityImpactRewardTableColumns } from './Transactions.columns'
import { exportFormat, TRANSACTION_DATE_FORMAT, transformUserIdFormat } from './Transactions.const'
import { exportCommunityImpactRewardsTransactionsAsCSV, fetchCommunityImpactRewardsTransactions, handleCommunityImpactRewardsTransactionsRejectSelected } from '../../../store/transactions/rewardTransactions/actions'
import { DEFAULT_PAGE_SIZE, PAGINATION, showTotalRecords } from '../../../const/utils'
import { fetchBroadcastingsCommunitiesList } from '../../../store/broadcasting/action'

export const CommunityImpactRewardTransactions = () => {

  const dispatch = useDispatch()
  const communities = useSelector(state => state.broadcasting?.communities)
  const [currentPagination, setCurrentPagination] = useState(PAGINATION)
  const transactions = useSelector(state => state.rewardTransactions.communityImpactRewardTransactions)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [communitiesData, setCommunitiesData] = useState([])
  const [filterDateRange, setFilterDateRange] = useState(null)
  const [filterStatus, setFilterStatus] = useState(null)
  const [selectedCommunity, setSelectedCommunity] = useState(null)
  const [wordEntered, setWordEntered] = useState()
  const [userRole, setUserRole] = useState('')
  const [defaultCommunity, setDefaultCommunity] = useState(null)
  const myCorporateProfile = useSelector(state => state?.community?.myCorporateProfile)

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
    // console.log(newSelectedRowKeys)
  }

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("userData"))
    const { role } = userData?.user
    setUserRole(role)
    dispatch(fetchBroadcastingsCommunitiesList())
  }, [])

  useEffect(() => {
    if (userRole === 'community_leader') {
      const currentCommunityData = communities.filter((item) => { return item?.communityId === myCorporateProfile?._id })
      setCommunitiesData(currentCommunityData)
      if (currentCommunityData.length > 0) {
        setDefaultCommunity({ label: currentCommunityData[0]?.communityName, value: currentCommunityData[0]?.communityId })
      } else {
        setDefaultCommunity({ label: myCorporateProfile?.name, value: myCorporateProfile?._id })
      }
    } else {
      setCommunitiesData(communities)
    }
  }, [communities, userRole])
  //This will prevent API call 3 times when page is loaded first time
  // }, [userRole])


  const handleSearch = (event) => {
    const searchWord = event.target.value
    setWordEntered(searchWord)
  }

  const rowSelection = {
    ...selectedRowKeys,
    // ...selectedRows,
    onChange: onSelectChange
  }
  const searchInput = useRef(null)

  const [prepairChallengeRewardsColumns, setPrepairChallengeRewardsColumns] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const handleColumnSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Button
            type="primary"
            className='mx-2 bg-[#207868]'
            onClick={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        if (dataIndex === 'status') {
          return record[dataIndex]?.props.children.toLowerCase().includes(value.toLowerCase())
        } else {
          return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        }
      } else {
        return false
      }

    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    }
  })

  useEffect(() => {
    dispatch(fetchCommunityImpactRewardsTransactions())
    const newChallengeRewardsTableColumns = CommunityImpactRewardTableColumns.map((item) => {
      if (item.notAllowToSearch === true) {
        return item
      } else {
        return {
          ...item,
          ...getColumnSearchProps(item['dataIndex'])
        }
      }
    })
    setPrepairChallengeRewardsColumns(newChallengeRewardsTableColumns)
  }, [])

  const _handleActivityTransactionsExportCsvAll = () => {
    const params = {}
    if (!!wordEntered) {
      params['search'] = wordEntered.toLowerCase()
    }
    if (filterDateRange) {
      params['fromDate'] = moment(new Date(filterDateRange[0])).format(`DD/MM/YYYY`)
      params['toDate'] = moment(new Date(filterDateRange[1])).format(`DD/MM/YYYY`)
    }
    if (filterStatus) {
      params['status'] = filterStatus
    }
    dispatch(exportCommunityImpactRewardsTransactionsAsCSV(params))
  }

  /**
   * Export selected records
   */
  const _handleActivityTransactionsExportCsvSelected = () => {
    if (selectedRowKeys.length > 0) {
      dispatch(exportCommunityImpactRewardsTransactionsAsCSV({}, selectedRowKeys))
    } else {
      // //export from current result
      // if (transactions?.result.length > 0) {
      //   const filterIds = transactions?.result.map(e => e._id)
      //   dispatch(exportCommunityImpactRewardsTransactionsAsCSV(filterIds))
      // }
    }
  }

  const handleStatusFilter = (value) => {
    // console.log(value)
    setFilterStatus(value)
  }

  const _handleSearchAndFilter = (pagination = PAGINATION) => {
    const params = {
      ...pagination
    }
    const _current = JSON.parse(JSON.stringify(currentPagination))
    _current.current = pagination?.current || PAGINATION.current
    _current.pageSize = pagination?.pageSize || PAGINATION.pageSize
    setCurrentPagination(_current)
    if (!!wordEntered) {
      params['search'] = wordEntered.toLowerCase()
    }
    if (filterDateRange) {
      params['fromDate'] = moment(new Date(filterDateRange[0])).format(`DD/MM/YYYY`)
      params['toDate'] = moment(new Date(filterDateRange[1])).format(`DD/MM/YYYY`)
    }
    if (filterStatus) {
      params['status'] = filterStatus
    }
    if (selectedCommunity) {
      params['communityId'] = selectedCommunity
    }
    dispatch(fetchCommunityImpactRewardsTransactions(params))
  }

  const handleTableChange = (pagination) => {
    // dispatch(fetchActivityTransactions(pagination))
    _handleSearchAndFilter(pagination)
  }
  const _handleFromToSelected = (v) => {
    // console.log(`_handleFromToSelected `, v)
    setFilterDateRange(v)
  }

  const hasSelected = selectedRowKeys.length > 0// || (transactions && transactions?.result && transactions?.result?.length > 0)

  const _successCallback = (e) => {
    _handleSearchAndFilter()
  }

  useEffect(() => {
    if (defaultCommunity) {
      setSelectedCommunity(defaultCommunity)
    }
  }, [defaultCommunity])

  /**
   * #44868 - 5 May - URGENT - UBS Challenge duplicated rewards
   */
  const _handleRejectTransactionsSelected = () => {
    if (selectedRowKeys.length > 0) {
      dispatch(handleCommunityImpactRewardsTransactionsRejectSelected(selectedRowKeys, _successCallback))
    } else {

    }
  }

  return (
    <div className='bg-white shadow pb-3'>
      <PageHeader
        ghost={false}
        title="Community Rewards Transaction"
      ></PageHeader>

      <div className='flex items-center justify-between mr-5 mb-5'>
        <div className='flex'>
          {/* <Input
            className='w-[300px] h-[40px] ml-4 mr-2'
            placeholder='Search Transaction Id, Email'
            value={wordEntered}
            onChange={handleSearch}
            prefix={<SearchOutlined />}
          /> */}
          <div className="p-2">
            <Select
              showSearch
              allowClear
              value={selectedCommunity}
              disabled={defaultCommunity}
              className='w-[300px]'
              // style={{ width: '100%' }}
              placeholder="Select community"
              optionFilterProp='label'
              options={communitiesData?.map(i => ({ label: i.communityName, value: i.communityId }))}
              suffixIcon={<CgArrowsV />}
              onChange={(h) => { setSelectedCommunity(h) }}
            >
            </Select>
          </div>
          <div className="p-2">
            <DatePicker.RangePicker onChange={(v) => _handleFromToSelected(v)} />
          </div>

          {/* <Select
            bordered={false}
            className='w-[90px] h-[40px] mx-2 border-[1px] border-mainThemeColorBorder'
            placeholder='Status'
            name='status'
            allowClear
          // onChange={handleStatusFilter}
          >
            <Select.Option value='COMPLETED'>COMPLETED</Select.Option>
            <Select.Option value='PENDING'>PENDING</Select.Option>
            <Select.Option value='APPROVED'>APPROVED</Select.Option>
            <Select.Option value='CANCELED'>CANCELED</Select.Option>
          </Select> */}

          {/* <Select
            bordered={false}
            className='w-[90px] h-[40px] mx-2 border-[1px] border-mainThemeColorBorder'
            placeholder='Type'
            name='type'
            allowClear
          // onChange={handleStatusFilter}
          >
            // {/* <Select.Option value='COMPLETED'>COMPLETED</Select.Option>
            //   <Select.Option value='PENDING'>PENDING</Select.Option>
            //   <Select.Option value='APPROVED'>APPROVED</Select.Option>
            //   <Select.Option value='CANCELED'>CANCELED</Select.Option>}
          </Select> */}
          <div className="p-2">
            <Button className='mx-2 border-[#207868] text-[#207868]' onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
          </div>
        </div>
        <div>
          <Button className='mx-2 border-[#207868] text-[#207868]' disabled={!hasSelected} onClick={_handleRejectTransactionsSelected}>
            {`Reject Selected`}
          </Button>
          <Button className='mx-2 border-[#207868] text-[#207868]' disabled={!hasSelected} onClick={_handleActivityTransactionsExportCsvSelected}>
            {`Export Selected`}
          </Button>
          <Button className='mx-2 bg-[#207868]' type='primary' disabled={!(transactions?.result)} onClick={_handleActivityTransactionsExportCsvAll}>
            {`Export All`}
          </Button>
        </div>
      </div>

      <Table
        className='z-[0] relative overflow-auto'
        rowSelection={rowSelection}
        columns={prepairChallengeRewardsColumns}
        dataSource={transactions?.result?.map((i, it) => ({
          key: i?._id,
          srno: (++it + ((currentPagination?.current - 1) * currentPagination?.pageSize)),
          // transactionId: i?.transactionId,
          submittedData: i?.createdAt && (moment(i?.createdAt).format(TRANSACTION_DATE_FORMAT)),
          userId: transformUserIdFormat(i?.userProfile),
          email: i?.userProfile?.email,
          fullname: i?.userProfile?.name,
          communityName: i?.communityData?.name || '-',
          teamName: i?.teamData?.name || '-',
          amount: i?.count,
          impactType: i?.impactType,
          rewardSource: i?.rewardSource,
          remarks: i?.remarks,
          status: <Tag color={!i?.status || i?.status === 'DONE' || i?.status === 'SUCCESS' ? 'green' : 'red'}>{i?.status || 'SUCCESS'}</Tag>
        }))}
        pagination={{
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          hideOnSinglePage: true,
          position: ["none", "bottomRight"],
          total: transactions?.totalCount,
          showTotal: showTotalRecords
        }}
        onChange={handleTableChange}
      />
    </div >
  )
}