//#50429
export const QuestTransactionsTableColumns = [
  {
    title: 'No',
    dataIndex: 'srno',
    key: 'srno',
    notAllowToSearch: true
  },
  {
    title: 'User',
    dataIndex: 'userName',
    key: 'userName'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Quest Name',
    dataIndex: 'questName',
    key: 'questName'
  },
  {
    title: 'Community',
    dataIndex: 'communityName',
    key: 'communityName'
  },
  {
    title: 'Points/Target',
    dataIndex: 'progress',
    key: 'progress'
  },
  {
    title: 'Completion Mode',
    dataIndex: 'completionMode',
    key: 'completionMode'
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt'
  },
  {
    title: 'Updated At',
    dataIndex: 'updatedAt',
    key: 'updatedAt'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  }
]

export const QUEST_ACTION_STATUS = {
  ACTIVE: { value: "ACTIVE", label: "Active" },
  COMPLETED: { value: "COMPLETED", label: "Completed" },
  CLOSED: { value: "CLOSED", label: "Closed" }
}