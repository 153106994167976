import { FETCH_ALL_TEAMS, FETCH_TEAM_BY_ID, FETCH_TEAM_MEMBERS } from "./types"

const initialState = {
    teams: [],
    team: {},
    teamMembers: {}
}

const teamsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_TEAMS:
            return { ...state, teams: action.payload }
        case FETCH_TEAM_BY_ID:
            return { ...state, team: action.payload }
        case FETCH_TEAM_MEMBERS:
            return { ...state, teamMembers: action.payload }
        default:
            return state
    }
}

export default teamsReducer