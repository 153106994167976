import React, { useState, useEffect } from 'react'
import { Button, Radio, Select, Form, Input, Upload, message, InputNumber } from 'antd'
import { CodeSandboxCircleFilled, UploadOutlined } from '@ant-design/icons'
import { CgArrowsV } from 'react-icons/cg'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { fetchAllActivities } from '../../../store/activity/actions'
import { fetchAllChallenges } from '../../../store/challenges/actions'
import { createBanner } from '../../../store/banner/action'
import ServerApi from '../../../utility/ServerApi'
import { fetchAllCommunity } from '../../../store/community/actions'
import { isSupportedFileFormatForBanner, SUPPORTED_FILE_FORMAT_EXTRA } from '../../../const/utils'
import { fetchPublishedLocations } from '../../../store/locations/actions'

export const NewBanner = () => {

    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const activityData = useSelector(state => state.activity.activities)
    const challenges = useSelector(state => state.challenges.challenges)
    const coummunityData = useSelector(state => state.community.communities)
    const locations = useSelector(state => state.locations.locations)


    useEffect(() => {
        dispatch(fetchAllActivities({ pageSize: 10000 }))
        dispatch(fetchAllChallenges({ pageSize: 10000 }))
        dispatch(fetchAllCommunity())
        dispatch(fetchPublishedLocations())
    }, [])

    const [fileList, setFileList] = useState([])
    const [radioDisabled, setRadioDisabled] = useState(1)

    const successCallback = () => {
        navigate('/banners')
    }

    const createNewBanner = (values) => {
        const data = {
            name: 'b1',
            communityIds: values.communityIds,
            activityId: values.activity,
            challengeId: values.challenge,
            link: values.link,
            cities: values.cities,
            section: values.section,
            publish: values.publish,
            displayOrder: values.displayOrder
        }
        // console.log(data)
        if (fileList[0]) data.image = fileList[0].image
        dispatch(createBanner(data, successCallback))
    }

    const handleChange = async ({ file }) => {
        console.log(file.type)
        // if (!(file.size / 1024 / 1024 < 50)) {
        //     message.error('file size exceeded')
        //     return
        // }
        if (!isSupportedFileFormatForBanner(file.type)) {
            message.error('You can only upload JPG/PNG/GIF file!')
            return
        }
        setFileList(
            [{ uid: file.uid, name: '', status: 'uploading' }]
        )
        const formData = new FormData()
        formData.append('file', file)
        formData.append('folder', 'banners')

        ServerApi().post('media/admin-create-media', formData)
            .then(res => {
                setFileList([
                    {
                        uid: file.uid,
                        name: res.data.name,
                        image: res.data.file,
                        status: 'done',
                        url: `${res.data.path}`,
                        thumbUrl: `${res.data.path}`
                    }
                ])
                return true
            })
            .catch(e => { console.log(e); return false })
    }

    const props = {
        customRequest: handleChange,
        listType: 'picture',
        onRemove: () => setFileList([]),
        fileList
    }

    return (
        <>
            <div className="shadow bg-white">
                <Form
                    name='NewBanner'
                    form={form}
                    labelCol={{ span: 4 }}
                    autoComplete='off'
                    className='px-4 py-4'
                    onFinish={(values) => createNewBanner(values)}
                    initialValues={{ publish: false }}
                >
                    <Form.Item
                        label='Community'
                        name='communityIds'
                        labelAlign='left'
                        colon={false}
                        hasFeedback
                        extra='Leave blank if global'
                    >
                        <Select
                            mode='multiple'
                            showSearch
                            allowClear
                            style={{ width: '100%' }}
                            placeholder='Select Community'
                            optionFilterProp='label'
                            options={
                                coummunityData.result?.map(i => ({ label: i?.name, value: i?._id }))
                            }
                            suffixIcon={<CgArrowsV />}
                        >
                        </Select>
                    </Form.Item>

                    <Form.Item
                        labelAlign="left"
                        colon={false}
                        label="Upload Banner"
                        extra={SUPPORTED_FILE_FORMAT_EXTRA.BANNER}
                        name='image'
                    >
                        <div className='flex justify-end'>
                            <Upload className='flex space-x-4' {...props} fileList={fileList}>
                                <Button icon={<UploadOutlined />}>Upload</Button>
                            </Upload>
                        </div>
                    </Form.Item>

                    <Radio.Group className="text-left w-full flex flex-col" defaultValue={'activity'}>
                        <Radio
                            value={'activity'}
                            label='act'
                            onChange={
                                (e) => {
                                    // setRadio1(!e.target.checked)
                                    // setRadio2(true)
                                    // setRadio3(true)
                                    setRadioDisabled(e.target.checked ? 1 : true)
                                }
                            }
                        >
                            <Form.Item labelAlign="left"
                                label="Open Activity"
                                name="activity"
                                colon={false}
                                className="w-[1260px]"
                                hasFeedback
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    className='ml-[-20px] w-full'
                                    placeholder="Select Activity"
                                    optionFilterProp='label'
                                    options={activityData.result?.map(i => ({ label: i?.name, value: i?._id }))}
                                    // disabled={radio1}
                                    disabled={!(radioDisabled == 1)}
                                    suffixIcon={<CgArrowsV />}
                                >
                                </Select>
                            </Form.Item>
                        </Radio>
                        <Radio
                            value={'challenges'}
                            onChange={
                                (e) => {
                                    // setRadio1(true)
                                    // setRadio2(!e.target.checked)
                                    // setRadio3(true)
                                    setRadioDisabled(e.target.checked ? 2 : true)
                                }
                            }
                        >
                            <Form.Item labelAlign="left"
                                label="Open Challenge"
                                name="challenge"
                                colon={false}
                                className="w-[1260px]"
                                hasFeedback
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    className='ml-[-20px] w-full'
                                    placeholder="Select Challenge"
                                    optionFilterProp='label'
                                    options={challenges.result?.map(i => ({ label: i?.name, value: i?._id }))}
                                    // disabled={radio2}
                                    disabled={!(radioDisabled == 2)}
                                    suffixIcon={<CgArrowsV />}
                                >
                                </Select>
                            </Form.Item>
                        </Radio>
                        <Radio
                            value={'link'}
                            onChange={
                                (e) => {
                                    // setRadio1(true)
                                    // setRadio2(true)
                                    // setRadio3(!e.target.checked)
                                    setRadioDisabled(e.target.checked ? 3 : true)
                                }
                            }
                        >
                            <Form.Item
                                label='Open Link'
                                name='link'
                                labelAlign='left'
                                colon={false}
                                className="w-[1260px]"
                            >
                                <Input
                                    type='url'
                                    className='ml-[-20px] w-full'
                                    // disabled={radio3}
                                    disabled={!(radioDisabled == 3)}
                                />
                            </Form.Item>
                        </Radio>
                        <Radio
                            value={'section'}
                            onChange={
                                (e) => {
                                    setRadioDisabled(e.target.checked ? 4 : true)
                                    // setRadio2(!e.target.checked)
                                    // setRadio3(true)
                                }
                            }
                        >
                            <Form.Item labelAlign="left"
                                label="Section"
                                name="section"
                                colon={false}
                                className="w-[1260px]"
                                hasFeedback
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    className='ml-[-20px] w-full'
                                    placeholder="Select section"
                                    optionFilterProp='label'
                                    // disabled={radio2}
                                    disabled={!(radioDisabled == 4)}
                                    suffixIcon={<CgArrowsV />}
                                >
                                    <Select.Option value='MyImpact'>My Impact</Select.Option>
                                    <Select.Option value='InviteFriends'>Invite Friends</Select.Option>
                                    <Select.Option value='Community'>Community</Select.Option>
                                    <Select.Option value='OpenCommunityFeed'>Community Feed</Select.Option>
                                </Select>
                            </Form.Item>
                        </Radio>
                    </Radio.Group>

                    <Form.Item
                        labelAlign="left"
                        colon={false}
                        label="Cities"
                        name="cities"
                        hasFeedback
                        extra={'Leave blank if global'}
                    >
                        <Select
                            mode="multiple"
                            showSearch
                            allowClear
                            style={{ width: '100%' }}
                            optionFilterProp="label"
                            placeholder="Select cities"
                            // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            suffixIcon={<CgArrowsV />}
                            options={locations?.map(i => ({ label: i?.name, value: i?._id }))}
                        >
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Display Order"
                        name="displayOrder"
                        labelAlign="left"
                        colon={false}
                        rules={[
                            { required: false, message: 'Please enter number of days!' },
                            { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                        ]}
                        hasFeedback
                    >
                        <InputNumber placeholder='Order' min={0} max={1800} />
                    </Form.Item>

                    <Form.Item name="publish" colon={false} labelAlign="left" label="Publish" defaultValue='false'>
                        <Radio.Group className="text-left">
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <div className="text-center pt-2 pb-4">
                        <Button className="bg-[#207868]" size="large" type="primary" htmlType="submit">
                            Save Changes
                        </Button>
                    </div>

                </Form>
            </div>

        </>
    )
}