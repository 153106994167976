export const TEAM_MODE = {
  PRIVATE: {
    value: 'private',
    label: 'Private',
    desc: 'only members added manually or via import can join.'
  },
  PUBLIC: {
    value: 'public',
    label: 'Public',
    desc: 'anyone can join via team share URL/QR'
  }
}

/**
 * 
 * @param {*} value 
 * @returns 
 */
export const transformTeamModeLabel = (value) => {
  if (!!value) {
    return Object.values(TEAM_MODE).filter(i => i.value == value)[0].label
  }
  return '-'
}