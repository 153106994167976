import React, { useState, useEffect, useRef } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Row, Col, Select, Button, message, Table, Divider, Typography, Input, Space, Flex } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux'
import { CgArrowsV } from 'react-icons/cg'
import { EngagementByParticipationTableColumns } from './columns'
import { fetchCommunityReportsCommunitiesList, fetchEngagementByParticipationKPIs, engagementByParticipationKPIsExportCsvAll } from '../../../../../store/communityReport/action'
import { DEFAULT_PAGE_SIZE } from '../../../../../const/utils'
import ReactApexChart from "react-apexcharts"

export const EngagementByParticipationKPIs = () => {

    const [selectedCommunityObject, setSelectedCommunityObject] = useState(null)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [userRole, setUserRole] = useState('')
    const [selectedCommunity, setSelectedCommunity] = useState(null)
    const [defaultCommunity, setDefaultCommunity] = useState(null)
    const [isReadyToGetDefaultData, setIsReadyToGetDefaultData] = useState(false)
    const [communitiesData, setCommunitiesData] = useState([])
    const communities = useSelector(state => state.communityReport?.communities)
    const engagementByParticipationKPIs = useSelector(state => state?.communityReport?.engagementByParticipationKPIs)
    const [prepairEngagementByParticipationData, setPrepairEngagementByParticipationData] = useState([])
    const [selectedTeam, setSelectedTeam] = useState(null)
    const [teamsData, setTeamsData] = useState([])

    const myCorporateProfile = useSelector(state => state?.community?.myCorporateProfile)

    const searchInput = useRef(null)

    const dispatch = useDispatch()

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        setIsReady(false)
        console.log('=======engagementByParticipationKPIs is changing')
        if (engagementByParticipationKPIs) {
            setPrepairEngagementByParticipationData(engagementByParticipationKPIs)
        } else {
            setPrepairEngagementByParticipationData([])
        }
        setTimeout(() => {
            setIsReady(true)
        }, 1000)
    }, [engagementByParticipationKPIs])

    const [prepairEngagementByParticipationTableColumns, setPrepairEngagementByParticipationTableColumns] = useState([])
    const rowSelection = {
        ...selectedRowKeys,
        onChange: onSelectChange
    }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block'
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        className='mx-2 bg-[#207868]'
                        onClick={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined
                }}
            />
        ),
        onFilter: (value, record) => {
            if (record[dataIndex]) {
                return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            } else {
                return false
            }

        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        }
    })

    const _handleSearchAndFilter = () => {
        if (selectedCommunity) {
            dispatch(fetchEngagementByParticipationKPIs({ communityId: selectedCommunity, teamId: selectedTeam }))
        } else {
            message.error("Please select community")
            dispatch(fetchEngagementByParticipationKPIs())
        }
    }

    const handleTableChange = () => {
        _handleSearchAndFilter()
    }

    const [series, setSeries] = useState([]
        // [
        //     {
        //         name: "KPI 1",
        //         data: [0, 6, 12, 12, 17, 19, 26, 26, 26, 26, 26]
        //     },
        //     {
        //         name: "KPI 2",
        //         data: [0, 6, 12, 12, 13, 15, 22, 22, 22, 22, 22]
        //     },
        //     {
        //         name: "KPI 3",
        //         data: [0, 5, 11, 11, 11, 12, 16, 16, 16, 16, 16]
        //     },
        //     {
        //         name: "KPI 4",
        //         data: [0, 9, 6, 3, 2, 1, 8, 0, 0, 0, 0]
        //     },
        //     {
        //         name: "KPI 5",
        //         data: [0, 5, 2, 2, 0, 0, 6, 0, 0, 0, 0]
        //     }
        // ]
    )

    const [options, setOptions] = useState(
        {
            colors: [
                "#005725",
                "#31aa62",
                "#5280c7",
                "#c55b11",
                "#ff8d70",
                "#005725"
            ],
            chart: {
                type: "bar",
                toolbar: {
                    show: true
                }
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    barHeight: '90%',
                    dataLabels: {
                        position: "top"
                    }
                }
            },
            dataLabels: {
                enabled: true,
                offsetX: -6,
                style: {
                    fontSize: "12px",
                    colors: ["#fff"]
                }
            },
            stroke: {
                colors: ["transparent"],
                width: 1
            },
            tooltip: {
                shared: true,
                intersect: false
            },
            xaxis: {
                categories: []
            },
            annotations: {
                xaxis: []
            }
        }
    )
    const [xaxisListData, setXaxisListData] = useState([])

    useEffect(() => {
        const userData = JSON.parse(sessionStorage.getItem("userData"))
        const { role } = userData?.user
        setUserRole(role)
        dispatch(fetchCommunityReportsCommunitiesList())

        const newEngagementByParticipationTableColumns = EngagementByParticipationTableColumns.map((item) => {
            if (item.notAllowToSearch === true) {
                return item
            } else {
                return {
                    ...item,
                    ...getColumnSearchProps(item['dataIndex'])
                }
            }
        })
        setPrepairEngagementByParticipationTableColumns(newEngagementByParticipationTableColumns)
    }, [])

    useEffect(() => {
        if (userRole && communities) {
            if (userRole === 'community_leader') {
                const currentCommunityData = communities.filter((item) => { return item?.communityId === myCorporateProfile?._id })
                setCommunitiesData(currentCommunityData)
                if (currentCommunityData.length > 0) {
                    setDefaultCommunity({ label: currentCommunityData[0]?.communityName, value: currentCommunityData[0]?.communityId })
                } else {
                    setDefaultCommunity({ label: myCorporateProfile?.name, value: myCorporateProfile?._id })
                }
            } else {
                setCommunitiesData(communities)
                setDefaultCommunity({ label: communities[0]?.communityName, value: communities[0]?.communityId })
                // setDefaultCommunity({ label: "Dac Community", value: '642c00859255677b96b0a0e5' })
            }
        }
    }, [communities, userRole])

    useEffect(() => {
        setSelectedTeam(null)
        if (selectedCommunity) {
            const newSelectedCommunityObject = communities.filter(i => {
                return i.communityId === selectedCommunity
            })
            if (newSelectedCommunityObject) {
                setTeamsData(newSelectedCommunityObject[0]?.teams)
                setSelectedCommunityObject(newSelectedCommunityObject[0])
            } else {
                setTeamsData([])
                setSelectedCommunityObject(null)
            }
        } else {
            setTeamsData([])
        }

    }, [selectedCommunity])

    useEffect(() => {
        if (defaultCommunity) {
            setSelectedCommunity(defaultCommunity?.value)
            setIsReadyToGetDefaultData(true)
        }
    }, [defaultCommunity])

    useEffect(() => {
        if (isReadyToGetDefaultData) {
            _handleSearchAndFilter()
            setIsReadyToGetDefaultData(false)
        }
    }, [isReadyToGetDefaultData])

    const _handleExportCsvAll = () => {
        const params = {

        }
        if (selectedCommunity) {
            params['communityId'] = selectedCommunity
        }
        if (selectedTeam) {
            params['teamId'] = selectedTeam
        }
        dispatch(engagementByParticipationKPIsExportCsvAll(params))
    }

    const handleLineBreakForLabel = (label) => {
        const splitPosition = label.indexOf(' (')
        const campainName = label.substring(0, splitPosition)
        const campainWeek = label.substring(splitPosition + 1, label.length)
        return [campainName, campainWeek]
    }

    const tooltipFormatter = (val, opts) => {
        const result = xaxisListData.length > 0 ? xaxisListData[val - 1] : val
        return result
    }

    // useEffect(() => {
    //     setOptions({
    //         ...options,
    //         xaxis: {
    //             ...options.xaxis,
    //             // tooltip: {
    //             //     formatter: tooltipFormatter
    //             // },
    //             // labels: {
    //             //     show: false
    //             // },
    //             categories: xaxisListData
    //         }
    //     })
    // }, [xaxisListData])

    useEffect(() => {
        if (prepairEngagementByParticipationData && prepairEngagementByParticipationTableColumns) {
            //Prepair series
            const seriesList = []
            const xaxisList = []
            const xTargetLine = []

            const totalNumberOfCommunityMembersList = []
            const joinedCommunityMembersList = []
            const participationRateList = []
            const activeParticipationRate1List = []
            const activeParticipationRate2List = []

            for (let i = 0; i < prepairEngagementByParticipationData.length; i++) {
                if (prepairEngagementByParticipationData[i]?.label !== 'Target') {
                    xaxisList.push(prepairEngagementByParticipationData[i]?.label)
                    totalNumberOfCommunityMembersList.push(prepairEngagementByParticipationData[i]?.totalNumberOfCommunityMembers)
                    joinedCommunityMembersList.push(prepairEngagementByParticipationData[i]?.joinedCommunityMembers)
                    participationRateList.push(prepairEngagementByParticipationData[i]?.participationRate)
                    activeParticipationRate1List.push(prepairEngagementByParticipationData[i]?.activeParticipationRate1)
                    activeParticipationRate2List.push(prepairEngagementByParticipationData[i]?.activeParticipationRate2)
                } else {
                    xTargetLine.push({
                        x: prepairEngagementByParticipationData[i]?.totalNumberOfCommunityMembers,
                        borderColor: options.colors[0],
                        label: {
                            borderColor: options.colors[0],
                            style: {
                                color: '#fff',
                                background: options.colors[0]
                            },
                            text: `Target KPI 1 (${prepairEngagementByParticipationData[i]?.totalNumberOfCommunityMembers})`
                        }
                    })

                    xTargetLine.push({
                        x: prepairEngagementByParticipationData[i]?.joinedCommunityMembers,
                        borderColor: options.colors[1],
                        label: {
                            borderColor: options.colors[1],
                            style: {
                                color: '#fff',
                                background: options.colors[1]
                            },
                            text: `Target KPI 2 (${prepairEngagementByParticipationData[i]?.joinedCommunityMembers})`
                        }
                    })

                    xTargetLine.push({
                        x: prepairEngagementByParticipationData[i]?.participationRate,
                        borderColor: options.colors[2],
                        label: {
                            borderColor: options.colors[2],
                            style: {
                                color: '#fff',
                                background: options.colors[2]
                            },
                            text: `Target KPI 3 (${prepairEngagementByParticipationData[i]?.participationRate})`
                        }
                    })

                    xTargetLine.push({
                        x: prepairEngagementByParticipationData[i]?.activeParticipationRate1,
                        borderColor: options.colors[3],
                        label: {
                            borderColor: options.colors[3],
                            style: {
                                color: '#fff',
                                background: options.colors[3]
                            },
                            text: `Target KPI 4 (${prepairEngagementByParticipationData[i]?.activeParticipationRate1})`
                        }
                    })

                    xTargetLine.push({
                        x: prepairEngagementByParticipationData[i]?.activeParticipationRate2,
                        borderColor: options.colors[4],
                        label: {
                            borderColor: options.colors[4],
                            style: {
                                color: '#fff',
                                background: options.colors[4]
                            },
                            text: `Target KPI 5 (${prepairEngagementByParticipationData[i]?.activeParticipationRate2})`
                        }
                    })
                }
            }
            seriesList.push(
                {
                    name: 'KPI 1',
                    data: totalNumberOfCommunityMembersList
                }
            )
            seriesList.push(
                {
                    name: 'KPI 2',
                    data: joinedCommunityMembersList
                }
            )
            seriesList.push(
                {
                    name: 'KPI 3',
                    data: participationRateList
                }
            )
            seriesList.push(
                {
                    name: 'KPI 4',
                    data: activeParticipationRate1List
                }
            )
            seriesList.push(
                {
                    name: 'KPI 5',
                    data: activeParticipationRate2List
                }
            )

            setSeries(seriesList)
            // setXaxisListData(xaxisList)
            const newOptions = options
            newOptions.xaxis.categories = xaxisList.map(item => (handleLineBreakForLabel(item)))
            newOptions.annotations.xaxis = xTargetLine
            setOptions(newOptions)
        }
    }, [prepairEngagementByParticipationData, prepairEngagementByParticipationTableColumns])

    useEffect(() => {
        if (series && options) {
            setTimeout(() => {
                setIsReady(true)
            }, 1000)
        }
    }, [series, options])

    return (
        <div className='shadow bg-white p-4'>
            <PageHeader
                ghost={false}
                title={`Engagement By Participation: ${selectedCommunityObject ? selectedCommunityObject?.communityName : ''}`}
            ></PageHeader>
            <Flex gap="middle">
                <Flex gap="middle" vertical>
                    <Flex gap="middle">
                        <Select
                            showSearch
                            allowClear
                            value={selectedCommunity}
                            disabled={defaultCommunity && userRole != 'admin'}
                            className='w-[300px] h-[40px] ml-4'
                            // style={{ width: '100%' }}
                            placeholder="Select community"
                            optionFilterProp='label'
                            options={communitiesData?.map(i => ({ label: i.communityName, value: i.communityId }))}
                            suffixIcon={<CgArrowsV />}
                            onChange={(h) => { setSelectedCommunity(h) }}
                        >
                        </Select>
                        {/* <Select
                            showSearch
                            value={selectedTeam}
                            allowClear
                            className='w-[300px] ml-4 mb-5'
                            placeholder="Select Teams"
                            optionFilterProp='label'
                            options={teamsData?.map(i => ({ label: i.teamName, value: i.teamId }))}
                            suffixIcon={<CgArrowsV />}
                            onChange={(h) => { setSelectedTeam(h) }}
                        >
                        </Select> */}
                    </Flex>
                </Flex>
                <Flex gap="middle" classNamemb-4>
                    <Button className='w-[200px] h-[40px]'
                        // disabled={defaultCommunity && userRole != 'admin'} 
                        onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
                    {/* <Button className='mx-2 border-[#207868] text-[#207868]' disabled={!hasSelected} onClick={_handleExportCsvSelected}>
                            {`Export Selected`}
                        </Button> */}
                    <Button className='w-[200px] h-[40px] bg-[#207868]' type='primary' disabled={!(prepairEngagementByParticipationData)} onClick={_handleExportCsvAll}>
                        {`Export by Current Filter`}
                    </Button>
                </Flex>
            </Flex>
            <div className='mr-5 mb-5'>
                {isReady ? <ReactApexChart
                    options={options}
                    series={series}
                    height={series?.length > 0 ? (series[0]?.data.length <= 5 ? 300 : series[0]?.data.length * 100) : 900}
                    type="bar"
                /> : ''}
            </div>
            <Table
                className='z-[0] relative overflow-auto'
                // rowSelection={rowSelection}
                columns={prepairEngagementByParticipationTableColumns}
                dataSource={prepairEngagementByParticipationData}
                onChange={handleTableChange}
                pagination={{
                    defaultPageSize: DEFAULT_PAGE_SIZE,
                    showSizeChanger: true,
                    hideOnSinglePage: true,
                    position: ["none", "bottomRight"]
                    // total: engagementByActivityKpis?.totalCount
                }}
            />
        </div>
    )
}