export const EngagementByParticipationTableColumns = [
    {
        title: 'KPIs',
        dataIndex: 'label',
        key: 'label',
        notAllowToSearch: true
    },
    {
        title: 'Total Number of Community Members (KPI 1)',
        dataIndex: 'totalNumberOfCommunityMembers',
        key: 'totalNumberOfCommunityMembers',
        notAllowToSearch: true,
        render: (text, row) => (
            <span className={`color-${row.totalNumberOfCommunityMembersColor}`}>
                {text}
            </span>
        )
    },
    {
        title: 'Joined Community Members (KPI 2)',
        dataIndex: 'joinedCommunityMembers',
        key: 'joinedCommunityMembers',
        notAllowToSearch: true,
        render: (text, row) => (
            <span className={`color-${row.joinedCommunityMembersColor}`}>
                {text}
            </span>
        )

    },
    {
        title: 'Participation Rate (Number of people who did at least 1 activity over campaign period) (KPI 3)',
        dataIndex: 'participationRate',
        key: 'participationRate',
        notAllowToSearch: true,
        render: (text, row) => (
            <span className={`color-${row.participationRateColor}`}>
                {text}
            </span>
        )

    },
    {
        title: 'Active Participation Rate (Number of people who did at least 1 activity per week) (KPI 4)',
        dataIndex: 'activeParticipationRate1',
        key: 'activeParticipationRate1',
        notAllowToSearch: true,
        render: (text, row) => (
            <span className={`color-${row.activeParticipationRate1Color}`}>
                {text}
            </span>
        )

    },
    {
        title: 'Active Participation Rate (Number of people who did at least 2 activities per week) (KPI 5)',
        dataIndex: 'activeParticipationRate2',
        key: 'activeParticipationRate2',
        notAllowToSearch: true,
        render: (text, row) => (
            <span className={`color-${row.activeParticipationRate2Color}`}>
                {text}
            </span>
        )
    }
]