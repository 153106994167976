import { FETCH_ALL_LOCATIONS, FETCH_ALL_LOCATION_BY_ID } from "./types"
import { toggleNetworkLoading } from "../common/actions"
import { message } from 'antd'
import ServerApi from "../../utility/ServerApi"

export const fetchAllLocations = () => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`locations/admin-fetch-all`)
        .then(res => {
            dispatch({
                type: FETCH_ALL_LOCATIONS,
                payload: res.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fetching Locations", e)
            console.log(e)
        })
}


export const fetchPublishedLocations = () => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`locations`)
        .then(res => {
            dispatch({
                type: FETCH_ALL_LOCATIONS,
                payload: res.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fetching Locations", e)
            console.log(e)
        })
}


export const createNewLocation = (rawData, successCallback) => dispatch => {
    ServerApi().post(`/locations`, rawData)
        .then(res => {
            if (res.status === 200 || res.status === 201) {
                message.success("New Location Created")
                successCallback(res.data)
            } else {
                message.error("Error Creating Location!")
            }
            successCallback()
        })
        .catch(e => {
            message.error("Error Creating New Location", e)
            console.log(e)
        })
}

export const fetchLocationById = (id) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/locations/${id}`)
        .then(res => {
            const data = res.data
            dispatch({
                type: FETCH_ALL_LOCATION_BY_ID,
                payload: data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            message.error("Error fetching data!")
            dispatch(toggleNetworkLoading(false))
            console.log(e)
        })
}

export const updateLocationDetail = (_id, rawData, successCallback) => dispatch => {
    ServerApi().patch(`locations/${_id}`, rawData)
        .then(res => {

            if (res.status === 200 || res.status === 201) {
                message.success("Location updated.")
            } else {
                message.warning("Unable to update Location.")
            }
            // dispatch(fetchAllMessagings(false))
            successCallback()
        })
        .catch(e => {
            message.error(e)
        })
}

// export const deleteMessagingById = (id) => dispatch => {
//     ServerApi().delete(`messagings/${id}`)
//         .then(res => {
//             message.success("Message Deleted Succesfully!")
//             dispatch(fetchAllMessagings())
//         })
//         .catch(e => {
//             message.error("Error Deleting")
//             console.log(e)
//         })
// }