import React, { useRef, useState, useEffect } from 'react'
import { SearchOutlined, EyeOutlined, DeleteOutlined, PlusOutlined, QuestionCircleOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Input, Space, Table, Popconfirm, Tag, Checkbox, DatePicker, Select, Popover } from 'antd'
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { deleteDomainById, fetchAllDomains } from '../../../store/domainwhitelisted/actions'
import { DomainsActionsTableColumns } from './domains.columns'
import { TRANSACTION_DATE_FORMAT } from '../Transactions/Transactions.const'
import AddNewDomainModal from './AddNewDomainModal'
import { DEFAULT_PAGE_SIZE } from '../../../const/utils'

export const DomainWhitelisted = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const domainsData = useSelector(state => state?.domains?.domains)
  const searchInput = useRef(null)
  const [visibleAddNewDomain, setVisibleAddNewDomain] = useState(false)
  const [prepairActivityActionsTableColumns, setPrepairActivityActionsTableColumns] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const handleColumnSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Button
            type="primary"
            className='mx-2 bg-[#207868]'
            onClick={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        if (dataIndex === 'status') {
          return record[dataIndex]?.props.children.toLowerCase().includes(value.toLowerCase())
        } else {
          return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        }
      } else {
        return false
      }

    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    }
  })

  useEffect(() => {
    dispatch(fetchAllDomains())
    const newActivityActionsTableColumns = DomainsActionsTableColumns.map((item) => {
      if (item.notAllowToSearch === true) {
        return item
      } else {
        return {
          ...item,
          ...getColumnSearchProps(item['dataIndex'])
        }
      }
    })
    setPrepairActivityActionsTableColumns(newActivityActionsTableColumns)
  }, [])

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [filterDateRange, setFilterDateRange] = useState(null)
  const [filterStatus, setFilterStatus] = useState(null)
  // const filterStatuses = Object.values(ACTIVITY_VALIDATION_METHOD_STATUS)
  // const [selectedRows, setSelectedRows] = useState([])
  const [wordEntered, setWordEntered] = useState()

  // const onSelectChange = (newSelectedRowKeys) => {
  //   setSelectedRowKeys(newSelectedRowKeys)
  //   // console.log(newSelectedRowKeys)
  //   // setSelectedRows(newSelectedRowKeys.map(i => transactions?.result[i]))
  // }

  // const rowSelection = {
  //   ...selectedRowKeys,
  //   // ...selectedRows,
  //   onChange: onSelectChange
  // }

  const handleSearch = (event) => {
    const searchWord = event.target.value
    setWordEntered(searchWord)
  }

  const successAddNewCallback = (value) => {
    dispatch(fetchAllDomains())
  }


  const _handleSearchAndFilter = (pagination = PAGINATION) => {
    const params = {
      ...pagination
    }
    if (!!wordEntered) {
      params['search'] = wordEntered.toLowerCase()
    }
    if (filterDateRange) {
      params['fromDate'] = moment(new Date(filterDateRange[0])).format(`DD/MM/YYYY`)
      params['toDate'] = moment(new Date(filterDateRange[1])).format(`DD/MM/YYYY`)
    }
    if (filterStatus) {
      params['status'] = filterStatus
    }
    dispatch(fetchAllDomains(params))
  }

  const handleTableChange = (pagination) => {
    _handleSearchAndFilter(pagination)
  }

  const _handleFromToSelected = (v) => {
    // console.log(`_handleFromToSelected `, v)
    setFilterDateRange(v)
  }

  const _handleDeleteDomain = (id) => {
    dispatch(deleteDomainById(id, successAddNewCallback))
  }

  // const hasSelected = selectedRowKeys.length > 0 || (transactions && transactions?.result?.length > 0)

  return (
    <div className='bg-white shadow'>
      <PageHeader
        ghost={false}
        title="Domain Whitelisted"
        extra={[
          <Button icon={<PlusOutlined />} key="btn" type="primary" className="bg-[#207868] flex items-center hover:bg-[#0A5043]" onClick={() => setVisibleAddNewDomain(true)} >
            New Domain
          </Button>
        ]}
      >
        <p>All whitelisted domains listing here will allow users who using email belong to the domain can signup without required OTP.</p>
      </PageHeader>

      {/* <div className='flex items-center justify-between mr-5 mb-5'>
        <div className='flex'>
          <Input
            className='w-[220px] h-[40px] ml-4 mr-2'
            placeholder='Search name'
            value={wordEntered}
            onChange={handleSearch}
            prefix={<SearchOutlined />}
          />
          <Button className='py-5 mx-2 flex items-center' onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
        </div>
      </div> */}

      <Table
        className='z-[0] relative overflow-auto'
        // rowSelection={rowSelection}
        columns={prepairActivityActionsTableColumns}
        dataSource={domainsData?.map((i, it) => ({
          key: i._id,
          srno: ++it,
          domainName: i?.domainName,
          createdDate: i?.createdAt && (moment(i?.createdAt).format(TRANSACTION_DATE_FORMAT)),
          action:
            <div style={{ minWidth: '90px' }}>
              <Popconfirm
                title="Are you sure?"
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                onConfirm={() => _handleDeleteDomain(i._id)}
                onCancel={() => null}
                okText="Yes"
                okType="danger"
                placement="topLeft"
                cancelText="Cancel"
              >
                <Button size="small" type="ghost" danger shape="circle" icon={<DeleteOutlined />} />
              </Popconfirm>

            </div>

        }))}
        pagination={{
          showSizeChanger: true,
          hideOnSinglePage: true,
          position: ["none", "bottomRight"],
          defaultPageSize: DEFAULT_PAGE_SIZE
        }}
      // onChange={handleTableChange}
      />

      <AddNewDomainModal
        visible={visibleAddNewDomain}
        setVisible={setVisibleAddNewDomain}
        refresh={successAddNewCallback}
      />
    </div>
  )
}
