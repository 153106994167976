export const exportFormat = [
  {
    label: 'Transaction ID',
    key: 'transactionId'
  },
  {
    label: 'Submitted Date',
    key: 'submittedData'
  },
  {
    label: 'Full Name',
    key: 'name'
  },
  {
    label: 'Email',
    key: 'email'
  },
  {
    label: 'Activity Name',
    key: 'activity'
  },
  {
    label: 'Goal',
    key: 'goal'
  },
  {
    label: 'Transaction Method',
    key: 'transactionMethod'
  },
  {
    label: 'Points',
    key: 'points'
  },
  {
    label: 'Cancel Reason',
    key: 'cancelReason'
  },
  {
    label: 'Status',
    key: 'status'
  }
]

export const ACTIVITY_VALIDATION_METHOD_STATUS = {
  PENDING: { value: "PENDING", label: "Pending" },
  COMPLETED: { value: "COMPLETED", label: "Completed" },
  DENIED: { value: "DENIED", label: "Denied" },
  UNSUCCESSFUL: {
    value: 'UNSUCCESSFUL',
    label: 'Unsuccessful'
  },
  QUIT: {
    value: 'QUIT',
    label: 'Quit'
  }
}

export const COMMUNITY_TRANSACTION_STATUS = {
  SUCCESS: { value: "SUCCESS", label: "Success" },
  REJECTED: { value: "REJECTED", label: "Rejected" }  
}

export const TRANSACTION_DATE_FORMAT = "DD MMM YYYY HH:mm"

/**
 * #43173 - Add auto increasment int field for custom user id, so it should be in format of CUXXXXXX same as old susGain system.
 * @param {*} item 
 * @returns 
 */
export const transformUserIdFormat = (item) => {
  if (item) {
    if (item.autoId) {
      return `CU${item.autoId}`
    }
    if (item.cus_id) {
      return `CU${item.cus_id}`
    }
    return `${item._id}`
  }
  return "-"
}

/**
 * #44095 - Change transaction IDs for displayed as running 10-digit numbers:
 * @param {*} number 
 * @param {*} size 
 * @returns 
 */
export const zPrefillFormat = (number, size) => {
  try {
    let num = number.toString()
    while (num.length < size) num = `0${num}`
    return num
  } catch (e) {
    return number
  }
}

export const RUNNING_TRANSACTION_TYPE = {
  TRANSACTION: { value: "T" },
  REDEMPTION: { value: "R" },
  CHALLENGES: { value: "C" }
}

/**
 * #44095 - Change transaction IDs for displayed as running 10-digit numbers:
 * @param {*} item 
 * @param {*} type 
T0000000001 for transaction
R0000000001 for redemption 
C0000000001 for challenges
 * @returns 
 */
export const transformTransactionIdFormat = (item, type) => {
  if (item) {
    if (item.autoId) {
      return `${type}${zPrefillFormat(item.autoId, 10)}`
    }
    return `${item.transactionId}`
  }
  return "-"
}