import React, { useState, useEffect } from 'react'
import { Button, Form, Input, Upload, message, Radio, Collapse, Select } from 'antd'
import { UploadOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchGoalById, updateGoal } from '../../../store/goal/actions'
import { fetchAllRewads } from '../../../store/rewards/actions'
import { fetchAllSDGS } from '../../../store/sdgs/actions'
import ServerApi from '../../../utility/ServerApi'
import PreviewImage from '../../../components/image/PreviewImage'
import { isSupportedFileFormatForGeneric, SUPPORTED_FILE_FORMAT_EXTRA } from '../../../const/utils'
const { Panel } = Collapse
const { TextArea } = Input

const EditGoal = () => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { goalID } = useParams()

    const [fileList, setFileList] = useState([])
    const [repeaterFileList, setRepeaterFileList] = useState({})
    const [redemptionFileList, setRedemptionFileList] = useState([])

    const goalsData = useSelector((state) => state.goals.goal)
    const rewards = useSelector(state => state.rewards.rewards)
    const sdgs = useSelector(state => state.sdgs.sdgs)

    useEffect(() => {
        dispatch(fetchGoalById(goalID))
        dispatch(fetchAllRewads())
        dispatch(fetchAllSDGS())
    }, [goalID])

    const successCallback = () => {
        navigate("/goals")
    }

    useEffect(() => {
        form.setFieldsValue({
            name: goalsData?.name,
            colorCode: goalsData?.colorCode,
            shareUrl: goalsData?.qrCode?.share_url,
            description: goalsData.description,
            habits: goalsData?.habits?.map((i) => ({
                habitId: i._id,
                name: i.name,
                icon: i.icon,
                description: i.description,
                sids: i?.sids?.map(s => s?._id)
            })),
            rewardId: goalsData?.rewardId?._id,
            redemption_cost: goalsData?.redemption_cost,
            redemption_content: goalsData?.redemption_content,
            publish: goalsData.publish
        })
        // setEditorState(editorText)
    }, [goalsData])

    const updateNewGoal = (values) => {
        // values.rewardId = values.redemption_name
        if (fileList[0]) values.icon = fileList[0].icon
        values.redemption_image = redemptionFileList[0]?.icon || ''
        if (Object.keys(repeaterFileList).length > 0) values.habits = values.habits.map((habit, index) => ({ ...habit, icon: repeaterFileList[index] ? repeaterFileList[index][0]?.icon || '' : (goalsData?.habits[index] && goalsData?.habits[index].icon ? goalsData?.habits[index].icon : '') }))

        // const rewardDetails = {
        //     redemption_cost: values.redemption_cost,
        //     rewardId: values.redemption_name
        // }
        // values.rewardId = values.rewardId.toString()
        // console.log(values)
        dispatch(updateGoal(goalID, values, successCallback))
    }

    const handleChange = async ({ file }) => {
        // if (!(file.size / 1024 / 1024 < 50)) {
        //     message.error('file size exceeded')
        //     return
        // }
        if (!isSupportedFileFormatForGeneric(file.type)) {
            message.error('You can only upload JPG/PNG file!')
            return
        }
        setFileList([{ uid: file.uid, name: "", status: "uploading" }])
        const formData = new FormData()
        formData.append("file", file)
        formData.append("folder", "goals")

        ServerApi()
            .post("media", formData)
            .then((res) => {
                setFileList([
                    {
                        uid: file.uid,
                        name: res.data.name,
                        icon: res.data.file,
                        status: "done",
                        url: `${res.data.path}`,
                        thumbUrl: `${res.data.path}`
                    }
                ])
                return true
            })
            .catch((e) => {
                console.log(e)
                return false
            })
    }

    const handleRedmptionChange = async ({ file }) => {
        // if (!(file.size / 1024 / 1024 < 50)) {
        //     message.error('file size exceeded')
        //     return
        // }
        if (!isSupportedFileFormatForGeneric(file.type)) {
            message.error('You can only upload JPG/PNG file!')
            return
        }
        setRedemptionFileList(
            [{ uid: file.uid, name: '', status: 'uploading' }]
        )
        const formData = new FormData()
        formData.append('path', file)
        formData.append('folder', 'activities')

        ServerApi().post('media/admin-create-media', formData)
            .then(res => {
                setRedemptionFileList([
                    {
                        uid: file.uid,
                        name: res.data.name,
                        icon: res.data.file,
                        status: 'done',
                        url: `${res.data.path}`,
                        thumbUrl: `${res.data.path}`
                    }
                ])
                return true
            })
            .catch(e => { console.log(e); return false })
    }

    const handleMultipleUploads = ({ file }, index) => {
        // if (!(file.size / 1024 / 1024 < 5)) {
        //     message.error('file size exceeded')
        //     return
        // }
        if (!isSupportedFileFormatForGeneric(file.type)) {
            message.error('You can only upload JPG/PNG file!')
            return
        }
        setRepeaterFileList({
            ...repeaterFileList,
            [index]: [{ uid: file.uid, name: "", status: "uploading" }]
        })
        const formData = new FormData()
        formData.append("file", file)
        formData.append("folder", "activities")

        ServerApi()
            .post("media", formData)
            .then((res) => {
                setRepeaterFileList({
                    ...repeaterFileList,
                    [index]: [
                        {
                            uid: file.uid,
                            name: res.data.name,
                            icon: res.data.file,
                            status: "done",
                            url: `${res.data.path}`,
                            thumbUrl: `${res.data.path}`
                        }
                    ]
                })
                return true
            })
            .catch((e) => {
                console.log(e)
                return false
            })
    }

    const props = {
        customRequest: handleChange,
        listType: "picture",
        onRemove: () => setFileList([]),
        fileList
    }

    const redemptionProps = {
        customRequest: handleRedmptionChange,
        listType: 'picture',
        onRemove: () => setRedemptionFileList([]),
        fileList
    }

    return (
        <>
            <div className="bg-white">
                <Form
                    name="update_Goal"
                    form={form}
                    onFinish={(values) => updateNewGoal(values)}
                    labelCol={{ span: 4 }}
                    requiredMark="sdlfjldfjs"
                >
                    <Collapse
                        className="border-none"
                        expandIconPosition={"end"}
                        defaultActiveKey={["1", "2", "3"]}
                    >
                        <Panel
                            header={<p className="font-bold text-lg">Goal Info</p>}
                            key="1"
                            className="bg-textbackgroundcolor"
                        >
                            <Form.Item
                                label="Goal Name"
                                name="name"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: "Please enter your goal name!" }]}
                                hasFeedback
                            >
                                <Input className="border-bordercolor rounded flex items-center" />
                            </Form.Item>

                            <Form.Item
                                name='colorCode'
                                label='Theme'
                                labelAlign='left'
                                colon={false}
                                labelCol={{
                                    span: 4
                                }}
                                rules={[{ required: true, message: 'Color Code is not selected!' }]}
                            >
                                <Radio.Group>
                                    <Radio value={'RED'}>Red</Radio>
                                    <Radio value={'BLUE'}>Blue</Radio>
                                    <Radio value={'GREEN'}>Green</Radio>
                                </Radio.Group>
                            </Form.Item>

                            {/* <div className='flex justify-between'>
                                <Form.Item
                                    label="Share Link"
                                    name="shareUrl"
                                    labelAlign="left"
                                    labelCol={{ span: 4 }}
                                    className='w-full ml-2'
                                    colon={false}
                                    hasFeedback
                                >
                                    <Input disabled />
                                </Form.Item>
                                <CopyToClipboard text={goalsData?.shareUrl} title='copy'>
                                    <Button icon={<CopyOutlined />} onClick={() => (goalsData?.shareUrl ? message.success('Link copied to clipboard') : message.warn('Link not available'))} />
                                </CopyToClipboard>
                            </div> */}

                            <Form.Item
                                label="Description"
                                labelAlign="left"
                                name="description"
                                colon={false}
                                rules={[{ required: true, message: "Please enter Goal Descriptoin!" }]}
                            >
                                <TextArea className="border-bordercolor rounded" rows={4} />
                            </Form.Item>

                            {/* <Form.Item labelAlign="left" colon={false} label="Goal Icon">
                                <div className="flex justify-between">
                                    <div className="self-center">
                                        <p className='flex'>
                                            Currently:<PreviewImage src={goalsData.icon} />
                                        </p>
                                    </div>
                                    <Upload
                                        className="flex space-x-4"
                                        {...props}
                                        fileList={fileList}
                                    >
                                        <Button
                                            className="flex items-align"
                                            icon={<UploadOutlined />}
                                        >
                                            Upload
                                        </Button>
                                    </Upload>
                                </div>
                            </Form.Item> */}

                            {/* </Panel>

                        <Panel
                            header={<p className="font-bold text-lg">Redemption</p>}
                            key="2"
                            className="bg-textbackgroundcolor"
                        > */}
                            <Form.Item
                                label="Reward"
                                name="rewardId"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: "Please enter Item Name!" }]}
                                hasFeedback
                            >
                                {/* <Input className="border-bordercolor rounded" /> */}
                                <Select
                                    placeholder='Select goal reward type'
                                    optionFilterProp='label'
                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    options={rewards?.map(i => ({ label: i?.name, value: i?._id }))}
                                >
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Redeem Cost"
                                name="redemption_cost"
                                labelAlign="left"
                                colon={false}
                                rules={[
                                    { required: true, message: "Please enter Redeem Cost!" },
                                    { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                ]}
                                extra='How many points to exchange for 1 reward type item.'
                            >
                                <Input
                                    placeholder="Point"
                                    className="border-bordercolor rounded"
                                />
                            </Form.Item>

                            {/* <Form.Item labelAlign="left" label="Image" colon={false}>
                                <div className="flex justify-between">
                                    <div className="self-center">
                                        <p className='flex'>Currently:<PreviewImage src={goalsData.redemption_image} /></p>
                                    </div>
                                    <Upload className='flex space-x-4' {...redemptionProps} fileList={redemptionFileList}>
                                        <Button className='flex items-align' icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </div>
                            </Form.Item> */}

                            {/* <Form.Item
                                label="Reward Content"
                                name="redemption_content"
                                labelAlign="left"
                                colon={false}
                            >

                                <JoditEditor 
                                    ref={textEditor}
                                    value={editorState}
                                />

                            </Form.Item> */}
                        </Panel>

                        <Panel
                            header={<p className="font-bold text-lg">Habits</p>}
                            key="3"
                            className="bg-textbackgroundcolor"
                            style={{ borderBottom: "none" }}
                        >
                            <Form.List
                                name="habits"
                                rules={[
                                    {
                                        validator: async (_, names) => {
                                            if (!names || names.length < 1) {
                                                return Promise.reject(
                                                    new Error("At least 1 Habit is required")
                                                )
                                            }
                                        }
                                    }
                                ]}
                            >
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map(({ key, name, ...restField }, idx) => (
                                            <div
                                                key={key}
                                                align="baseline"
                                                className={`grid grid-cols-10 gap-4 items-end ${idx % 2 == 0 ? 'row-odd' : 'row-even'}`}
                                            >
                                                <Form.Item
                                                    {...restField}
                                                    label={name === 0 ? "Name" : ""}
                                                    name={[name, "name"]}
                                                    rules={[{ required: true, message: "Missing name" }]}
                                                    className="col-span-2"
                                                    labelCol={{ span: 24 }}
                                                >
                                                    <Input placeholder="Name" />
                                                </Form.Item>
                                                <Form.Item>
                                                    <PreviewImage src={goalsData.habits[name]?.icon} size={'w-14'} />
                                                </Form.Item>
                                                <Form.Item
                                                    label={name === 0 ? "Icon" : ""}
                                                    className="col-span-2"
                                                    extra={SUPPORTED_FILE_FORMAT_EXTRA.HABITS_ICON}
                                                    labelCol={{ span: 24 }}
                                                >
                                                    <Upload
                                                        customRequest={(obj) => {
                                                            return handleMultipleUploads(obj, name)
                                                        }}
                                                        fileList={repeaterFileList[name]}
                                                        listType="picture"
                                                        onRemove={() => {
                                                            setRepeaterFileList({
                                                                ...repeaterFileList,
                                                                [name]: []
                                                            })
                                                        }}
                                                    >
                                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                                    </Upload>
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    label={name === 0 ? "Description" : ""}
                                                    name={[name, "description"]}
                                                    rules={[{ required: true, message: "Missing discription" }]}
                                                    className="col-span-2"
                                                    labelCol={{ span: 24 }}
                                                >
                                                    <Input placeholder="Description" />
                                                </Form.Item>
                                                <Form.Item
                                                    label={name === 0 ? "SDGs" : ""}
                                                    {...restField}
                                                    name={[name, "sids"]}
                                                    rules={[{ required: true, message: "Select SDGs" }]}
                                                    className="col-span-2"
                                                    labelCol={{ span: 24 }}
                                                >
                                                    <Select
                                                        mode='multiple'
                                                        showSearch
                                                        placeholder="Multiple"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) => option.children
                                                            .toLowerCase()
                                                            .includes(input.toLowerCase())
                                                        }
                                                        style={{ width: "100%" }}
                                                    // options={sdgs?.map(i => ({ label: i.name, value: i._id }))}
                                                    >
                                                        {sdgs.map(i => (
                                                            <Select.Option value={i._id}>{i.name}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item>
                                                    <MinusCircleOutlined
                                                        className="ml-3"
                                                        onClick={() => remove(name)}
                                                    />
                                                </Form.Item>
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                icon={<PlusOutlined />}
                                            >
                                                Add Habits
                                            </Button>
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>

                            <Form.Item
                                name="publish"
                                colon={false}
                                labelAlign="left"
                                label="Publish"
                                rules={[{ required: true, message: "Publish is not selected!" }]}
                            >
                                <Radio.Group className="text-left">
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Panel>
                    </Collapse>
                    <div className="text-center pt-2 pb-4">
                        <Button
                            className="bg-[#207868]"
                            size="large"
                            type="primary"
                            htmlType="submit"
                        >
                            Save Changes
                        </Button>
                    </div>
                </Form>
            </div>

        </>
    )
}

export default EditGoal
