import React, { useState, useEffect } from 'react'
import { Button, Radio, Select, Form, Input, Upload, message, InputNumber } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { CgArrowsV } from 'react-icons/cg'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchAllActivities } from '../../../store/activity/actions'
import { fetchAllChallenges } from '../../../store/challenges/actions'
import { fetchAllCommunity } from '../../../store/community/actions'
import { updateBanner, fetchBannerById } from '../../../store/banner/action'
import ServerApi from '../../../utility/ServerApi'
import PreviewImage from '../../../components/image/PreviewImage'
import { isSupportedFileFormatForBanner, SUPPORTED_FILE_FORMAT_EXTRA } from '../../../const/utils'
import { fetchPublishedLocations } from '../../../store/locations/actions'


export const EditBanner = () => {

    const [form] = Form.useForm()
    const { bannerId } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const activityData = useSelector(state => state.activity.activities)
    const challenges = useSelector(state => state.challenges.challenges)
    const coummunityData = useSelector(state => state.community.communities)
    const bannerData = useSelector(state => state.banners.banner)
    const locations = useSelector(state => state.locations.locations)


    const [fileList, setFileList] = useState([])
    const [radioDisabled, setRadioDisabled] = useState({ checkedRadio: 0 })

    useEffect(() => {
        dispatch(fetchAllActivities({ pageSize: 10000 }))
        dispatch(fetchAllChallenges({ pageSize: 10000 }))
        dispatch(fetchAllCommunity())
        dispatch(fetchBannerById(bannerId))
        dispatch(fetchPublishedLocations())

    }, [])

    useEffect(() => {
        setRadioDisabled({ ...radioDisabled, checkedRadio: bannerData?.activityId ? 1 : bannerData?.challengeId ? 2 : bannerData?.link ? 3 : bannerData?.section ? 4 : 0 })
    }, [bannerData])

    const successCallback = () => {
        navigate('/banners')
    }

    useEffect(() => {
        form.setFieldsValue({
            communityIds: bannerData?.communityIds?.map(i => i._id),
            activityId: bannerData?.activityId,
            challengeId: bannerData?.challengeId,
            link: bannerData?.link,
            section: bannerData?.section,
            cities: bannerData?.cities,
            publish: bannerData?.publish,
            displayOrder: bannerData?.displayOrder
        })
    }, [bannerData])

    const updateBanners = (values) => {

        const data = {
            name: 'b1',
            communityIds: values.communityIds,
            activityId: radioDisabled.checkedRadio == 1 ? values.activityId : null,
            challengeId: radioDisabled.checkedRadio == 2 ? values.challengeId : null,
            cities: values.cities,
            link: radioDisabled.checkedRadio == 3 ? values.link : null,
            section: values.section,
            publish: values.publish,
            displayOrder: values.displayOrder
        }
        if (fileList[0]) data.image = fileList[0].image
        dispatch(updateBanner(bannerId, data, successCallback))
    }

    const handleChange = async ({ file }) => {
        // if (!(file.size / 1024 / 1024 < 50)) {
        //     message.error('file size exceeded')
        //     return
        // }
        // if (!(file.type === 'image/jpeg' || file.type === 'image/png')) {
        //     message.error('You can only upload JPG/PNG file!')
        //     return
        // }
        if (!isSupportedFileFormatForBanner(file.type)) {
            message.error('You can only upload JPG/PNG/GIF file!')
            return
        }
        setFileList(
            [{ uid: file.uid, name: '', status: 'uploading' }]
        )
        const formData = new FormData()
        formData.append('file', file)
        formData.append('folder', 'banners')

        ServerApi().post('media/admin-create-media', formData)
            .then(res => {
                setFileList([
                    {
                        uid: file.uid,
                        name: res.data.name,
                        image: res.data.file,
                        status: 'done',
                        url: `${res.data.path}`,
                        thumbUrl: `${res.data.path}`
                    }
                ])
                return true
            })
            .catch(e => { console.log(e); return false })
    }

    const props = {
        customRequest: handleChange,
        listType: 'picture',
        onRemove: () => setFileList([]),
        fileList
    }

    return (
        <div className='container mx-auto shadow bg-white'>
            <Form
                name='NewBanner'
                form={form}
                labelCol={{ span: 4 }}
                autoComplete='off'
                className='px-4 py-4'
                onFinish={(values) => updateBanners(values)}
            >
                <Form.Item
                    label='Community'
                    name='communityIds'
                    labelAlign='left'
                    colon={false}
                    hasFeedback
                    defaultValue='null'
                    extra='Leave blank if global'
                >
                    <Select
                        mode='multiple'
                        showSearch
                        allowClear
                        style={{ width: '100%' }}
                        placeholder='Select Community'
                        optionFilterProp='label'
                        options={coummunityData.result?.map(i => ({ label: i?.name, value: i?._id }))}
                    >
                    </Select>
                </Form.Item>

                <Form.Item
                    labelAlign="left"
                    colon={false}
                    label="Upload Banner"
                    extra={SUPPORTED_FILE_FORMAT_EXTRA.BANNER}
                    name='image'
                >
                    <div className='flex justify-between'>
                        <div className='self-center'>
                            <p className='flex space'>Currently: <PreviewImage src={bannerData?.image} /></p>
                        </div>
                        <Upload className='flex space-x-4' {...props} fileList={fileList}>
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </div>
                </Form.Item>

                <Radio.Group className="text-left w-full flex flex-col" value={radioDisabled.checkedRadio}>
                    <Radio
                        value={1}
                        label='act'
                        onChange={
                            (e) => {
                                setRadioDisabled({ ...radioDisabled, checkedRadio: e.target.checked ? 1 : true })
                            }
                        }

                    >
                        <Form.Item labelAlign="left"
                            label="Open Activity"
                            name="activityId"
                            colon={false}
                            className="w-[1260px]"
                            hasFeedback
                            defaultValue={null}
                        >
                            <Select
                                // showSearch
                                allowClear
                                className='ml-[-20px] w-full'
                                optionFilterProp='label'
                                placeholder="Select Activity"
                                options={activityData.result?.map(i => ({ label: i?.name, value: i?._id }))}
                                disabled={!(radioDisabled.checkedRadio == 1)}
                                suffixIcon={<CgArrowsV />}
                            >
                            </Select>
                        </Form.Item>
                    </Radio>
                    <Radio
                        value={2}
                        onChange={
                            (e) => {
                                setRadioDisabled({ ...radioDisabled, checkedRadio: e.target.checked ? 2 : true })
                            }
                        }
                    >
                        <Form.Item labelAlign="left"
                            label="Open Challenge"
                            name="challengeId"
                            colon={false}
                            className="w-[1260px]"
                            hasFeedback
                        >
                            <Select
                                showSearch
                                allowClear
                                className='ml-[-20px] w-full'
                                placeholder="Select Challenge"
                                optionFilterProp='label'
                                options={challenges.result?.map(i => ({ label: i?.name, value: i?._id }))}
                                disabled={!(radioDisabled.checkedRadio == 2)}
                                suffixIcon={<CgArrowsV />}
                            >
                            </Select>
                        </Form.Item>
                    </Radio>
                    <Radio
                        value={3}
                        onChange={
                            (e) => {
                                setRadioDisabled({ ...radioDisabled, checkedRadio: e.target.checked ? 3 : true })
                            }
                        }
                    >
                        <Form.Item
                            label='Open Link'
                            name='link'
                            labelAlign='left'
                            colon={false}
                            className="w-[1260px]"
                        >
                            <Input
                                type='url'
                                className='ml-[-20px] w-full'
                                // disabled={radio3}
                                disabled={!(radioDisabled.checkedRadio == 3)}
                            />
                        </Form.Item>
                    </Radio>
                    <Radio
                        value={4}
                        onChange={
                            (e) => {
                                setRadioDisabled({ ...radioDisabled, checkedRadio: e.target.checked ? 4 : true })
                            }
                        }
                    >
                        <Form.Item labelAlign="left"
                            label="Section"
                            name="section"
                            colon={false}
                            className="w-[1260px]"
                            hasFeedback
                        >
                            <Select
                                showSearch
                                allowClear
                                className='ml-[-20px] w-full'
                                placeholder="Select section"
                                optionFilterProp='label'
                                // disabled={radio2}
                                disabled={!(radioDisabled.checkedRadio == 4)}
                                suffixIcon={<CgArrowsV />}
                            >
                                <Select.Option value='MyImpact'>My Impact</Select.Option>
                                <Select.Option value='InviteFriends'>Invite Friends</Select.Option>
                                <Select.Option value='Community'>Community</Select.Option>
                                <Select.Option value='OpenCommunityFeed'>Community Feed</Select.Option>
                            </Select>
                        </Form.Item>
                    </Radio>
                </Radio.Group>

                <Form.Item
                    labelAlign="left"
                    colon={false}
                    label="Cities"
                    name="cities"
                    hasFeedback
                    extra={'Leave blank if global'}
                >
                    <Select
                        mode="multiple"
                        showSearch
                        allowClear
                        style={{ width: '100%' }}
                        optionFilterProp="label"
                        placeholder="Select cities"
                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        suffixIcon={<CgArrowsV />}
                        options={locations?.map(i => ({ label: i?.name, value: i?._id }))}
                    >
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Display Order"
                    name="displayOrder"
                    labelAlign="left"
                    colon={false}
                    rules={[
                        { required: false, message: 'Please enter number of days!' },
                        { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                    ]}
                    hasFeedback
                >
                    <InputNumber placeholder='Order' min={0} max={1800} />
                </Form.Item>

                <Form.Item name="publish" colon={false} labelAlign="left" label="Publish"
                    rules={[{ required: true, message: 'Publish is not selected!' }]}
                >
                    <Radio.Group className="text-left">
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                </Form.Item>

                <div className="text-center pt-2 pb-4">
                    <Button className="bg-[#207868]" size="large" type="primary" htmlType="submit">
                        Save Changes
                    </Button>
                </div>

            </Form>
        </div>
    )
}