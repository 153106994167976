import { FETCH_ALL_LOCATIONS, FETCH_ALL_LOCATION_BY_ID } from "./types"

const initialState = {
    locations: [],
    location: {}
}

 const locationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_LOCATIONS:
            return { ...state, locations: action.payload }
            case FETCH_ALL_LOCATION_BY_ID:
                return { ...state, location: action.payload }
        default:
            return state
    }
 }

 export default locationsReducer