import React, { useEffect, useState, useRef } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Table, Space, Input, Button, Select, message, DatePicker, Flex } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { CHALLENGES_ACTION_STATUS, CommunityChallengesTransactionsTableColumns } from './columns'
// import { SearchOutlined } from '@ant-design/icons'
import { CgArrowsV } from 'react-icons/cg'
import { DEFAULT_PAGE_SIZE, PAGINATION, showTotalRecords } from '../../../../const/utils'
import { fetchCommunityChallengesTransactions, fetchCommunityChallengesTransactionsExportCsvAll, fetchCommunityReportsCommunitiesList } from '../../../../store/communityReport/action'
import moment from 'moment'
import { TRANSACTION_DATE_FORMAT } from '../../Transactions/Transactions.const'

/**
 * #44826 - Impact 1 - By Activities
 * @returns 
 */
export const CommunityChallengesTransactions = () => {
    const [currentPagination, setCurrentPagination] = useState(PAGINATION)

    const dispatch = useDispatch()
    const [userRole, setUserRole] = useState('')
    const myCorporateProfile = useSelector(state => state?.community?.myCorporateProfile)
    const challengesTransactions = useSelector(state => state?.communityReport?.challengesTransactions)
    const [selectedCommunity, setSelectedCommunity] = useState(null)
    // const [selectedTeam, setSelectedTeam] = useState(null)
    const [communitiesData, setCommunitiesData] = useState([])
    const filterStatuses = Object.values(CHALLENGES_ACTION_STATUS)
    const communities = useSelector(state => state.communityReport?.communities)
    const [filterDateRange, setFilterDateRange] = useState(null)
    const [filterStatus, setFilterStatus] = useState(null)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [prepairLeaderBoardColumns, setPrepairLeaderBoardColumns] = useState(null)
    const [selectedCommunityObject, setSelectedCommunityObject] = useState(null)
    const [isReadyToGetDefaultData, setIsReadyToGetDefaultData] = useState(false)
    const [defaultCommunity, setDefaultCommunity] = useState(null)

    const hasSelected = selectedRowKeys.length > 0

    useEffect(() => {
        const userData = JSON.parse(sessionStorage.getItem("userData"))
        const { role } = userData?.user
        setUserRole(role)
        dispatch(fetchCommunityReportsCommunitiesList())
        setPrepairLeaderBoardColumns(CommunityChallengesTransactionsTableColumns)
    }, [])

    const handleStatusFilter = (value) => {
        setFilterStatus(value)
    }

    const _handleSearchAndFilter = (pagination = PAGINATION) => {
        if (selectedCommunity) {
            const newSelectedCommunityObject = communities.filter(i => {
                return i.communityId === selectedCommunity
            })
            setSelectedCommunityObject(newSelectedCommunityObject[0])
        } else {
            setSelectedCommunityObject(null)
        }
        const params = {
            ...pagination
        }
        const _current = JSON.parse(JSON.stringify(currentPagination))
        _current.current = pagination?.current || PAGINATION.current
        _current.pageSize = pagination?.pageSize || PAGINATION.pageSize
        setCurrentPagination(_current)
        if (filterDateRange) {
            params['fromDate'] = moment(new Date(filterDateRange[0])).format(`DD/MM/YYYY`)
            params['toDate'] = moment(new Date(filterDateRange[1])).format(`DD/MM/YYYY`)
        }
        if (filterStatus) {
            params['status'] = filterStatus
        }
        if (selectedCommunity) {
            params['communityId'] = selectedCommunity
        }
        // if (selectedTeam) {
        //     params['teamId'] = selectedTeam
        // }
        dispatch(fetchCommunityChallengesTransactions(params))
    }

    useEffect(() => {
        if (userRole && communities) {
            if (userRole === 'community_leader') {
                const currentCommunityData = communities.filter((item) => { return item?.communityId === myCorporateProfile?._id })
                setCommunitiesData(currentCommunityData)
                if (currentCommunityData.length > 0) {
                    setDefaultCommunity({ label: currentCommunityData[0]?.communityName, value: currentCommunityData[0]?.communityId })
                } else {
                    setDefaultCommunity({ label: myCorporateProfile?.name, value: myCorporateProfile?._id })
                }
            } else {
                setCommunitiesData(communities)
                setDefaultCommunity({ label: communities[0]?.communityName, value: communities[0]?.communityId })
            }
        }
    }, [communities, userRole])
    //This will prevent API call 3 times when page is loaded first time
    // }, [userRole])

    useEffect(() => {
        if (defaultCommunity) {
            setSelectedCommunity(defaultCommunity?.value)
            setIsReadyToGetDefaultData(true)
        }
    }, [defaultCommunity])

    useEffect(() => {
        if (isReadyToGetDefaultData) {
            _handleSearchAndFilter()
            setIsReadyToGetDefaultData(false)
        }
    }, [isReadyToGetDefaultData])

    const handleTableChange = (pagination) => {
        _handleSearchAndFilter(pagination)
    }

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const rowSelection = {
        ...selectedRowKeys,
        onChange: onSelectChange
    }

    const _handleExportCsvAll = () => {
        const params = {

        }
        if (selectedCommunity) {
            params['communityId'] = selectedCommunity
        }
        if (filterDateRange) {
            params['fromDate'] = moment(new Date(filterDateRange[0])).format(`DD/MM/YYYY`)
            params['toDate'] = moment(new Date(filterDateRange[1])).format(`DD/MM/YYYY`)
        }
        if (filterStatus) {
            params['status'] = filterStatus
        }
        // if (selectedTeam) {
        //     params['teamId'] = selectedTeam
        // }
        dispatch(fetchCommunityChallengesTransactionsExportCsvAll(params))
    }

    const _handleExportCsvSelected = () => {
        if (selectedRowKeys.length > 0) {
            dispatch(fetchCommunityChallengesTransactionsExportCsvAll({}, selectedRowKeys))
        }
    }

    const _handleFromToSelected = (v) => {
        // console.log(`_handleFromToSelected `, v)
        setFilterDateRange(v)
    }


    return (
        <div className='bg-white shadow pb-3'>
            <PageHeader
                ghost={false}
                title={`Challenge Transaction: ${selectedCommunityObject ? selectedCommunityObject?.communityName : ''}`}
            ></PageHeader>
            <Flex gap="middle">
                <Flex gap="middle" vertical>
                    <Flex gap="middle">
                        <Select
                            showSearch
                            allowClear
                            value={selectedCommunity}
                            disabled={defaultCommunity && userRole != 'admin'}
                            className='w-[300px] h-[40px] ml-4'
                            placeholder="Select community"
                            optionFilterProp='label'
                            options={communitiesData?.map(i => ({ label: i.communityName, value: i.communityId }))}
                            suffixIcon={<CgArrowsV />}
                            onChange={(h) => { setSelectedCommunity(h) }}
                        >
                        </Select>
                        <DatePicker.RangePicker onChange={(v) => _handleFromToSelected(v)} />
                    </Flex>
                    <Flex gap="middle" className='mb-4'>
                        <Select
                            // bordered={false}
                            className='w-[300px] h-[40px] ml-4'
                            placeholder='Status'
                            name='status'
                            allowClear
                            onChange={handleStatusFilter}
                            options={filterStatuses.map(i => ({ label: i.label, value: i.value }))}>
                        </Select>
                    </Flex>
                </Flex>
                <Flex gap="middle" className='mb-4'>
                    <Button className='w-[200px] h-[40px]'
                        // disabled={userRole != 'admin'} 
                        onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
                    <Button className='w-[200px] h-[40px] bg-[#207868]' type='primary' disabled={!(challengesTransactions)} onClick={_handleExportCsvAll}>
                        {`Export by Current Filter`}
                    </Button>
                    {/* <Button className='mx-2 border-[#207868] text-[#207868]' disabled={!hasSelected} onClick={_handleExportCsvSelected}>
                            {`Export Selected`}
                        </Button> */}
                </Flex>
            </Flex>
            <Table
                className='z-[0] relative overflow-auto'
                // rowSelection={rowSelection}
                columns={prepairLeaderBoardColumns}
                dataSource={challengesTransactions?.result?.map((i, it) => ({
                    key: i?._id,
                    srno: (++it + ((currentPagination?.current - 1) * currentPagination?.pageSize)),
                    challengeName: i?.challengeData?.name,
                    // communityId: i?.communityId,
                    broadcastingId: i?.broadcastingId,
                    status: i?.status,
                    createdAt: i?.createdAt && (moment(i?.createdAt).format(TRANSACTION_DATE_FORMAT)),
                    updatedAt: i?.updatedAt && (moment(i?.updatedAt).format(TRANSACTION_DATE_FORMAT)),
                    progress: `${i?.points}/${i?.targetPoints}`,
                    completionMode: i?.windowMode,
                    challengeType: i?.type
                }))}
                pagination={{
                    defaultPageSize: DEFAULT_PAGE_SIZE,
                    showSizeChanger: true,
                    // hideOnSinglePage: true,
                    position: ["none", "bottomRight"],
                    total: challengesTransactions?.totalCount,
                    showTotal: showTotalRecords
                }}
                onChange={handleTableChange}
            />
        </div>
    )
}