import React, { useState, useEffect, useRef } from 'react'
import { Collapse, Button, DatePicker, Radio, Select, Form, Input, Upload, Typography, message, InputNumber } from 'antd'
import { InfoCircleOutlined, UploadOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { CgArrowsV, CgCommunity } from 'react-icons/cg'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { createActivity } from '../../../store/activity/actions'
import { fetchAllGoals } from '../../../store/goal/actions'
import { fetchAllCommunity } from '../../../store/community/actions'
import ServerApi from '../../../utility/ServerApi'
import { ACTIVITY_IMPACT_UNITS, ACTIVITY_TYPE, ACTIVITY_VALIDATION_METHOD, ACTIVITY_VALIDATION_METHOD_FOR_WEB } from './Activity.const'
import { fetchPublishedLocations } from '../../../store/locations/actions'
import { isSupportedFileFormatForGeneric, parseUTCToMomentDate, SUPPORTED_FILE_FORMAT_EXTRA } from '../../../const/utils'
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw, convertFromHTML, ContentState } from "draft-js"
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
// import createToolbarPlugin from '@draft-js-plugins/static-toolbar'
import '../../../assets/css/editorStyles.css'
import { fetchCommonConfiguration } from '../../../store/common/actions'
import { UploadGpsCsv } from './UploadGpsCsv'


const { Panel } = Collapse
const { RangePicker } = DatePicker

export const NewActivity = () => {

    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const activityOptions = useSelector(state => state.common?.data?.activityFrequencyOptions)
    const activityFrequencyOptionsForWeb = useSelector(state => state.common?.data?.activityFrequencyOptionsForWeb)

    const activityCompletionDurationTime = useSelector(state => state.common?.data?.activityCompletionDurationTime)
    const goalsData = useSelector(state => state.goals.goals)
    // const habitData = useSelector(state => state.habits.habits)
    const communities = useSelector(state => state.community.communities)
    const locations = useSelector(state => state.locations.locations)
    const [activityType, setActivityType] = useState('')
    const [validationOption, setValidationOption] = useState("")
    const [frequencyOption, setFrequencyOption] = useState("")
    const [completionDuration, setCompletionDuration] = useState("")
    const [selectedGoal, setSelectedGoal] = useState([])
    // const [qrCodeData, setQRCodeData] = useState(String(Math.floor(1000 + (Math.random() * 9000))))
    const [pinValue, setPinValue] = useState("")
    const [fileList, setFileList] = useState([])
    const [activityImageFile, setActivityImageFile] = useState([])
    const [repeaterFileList, setRepeaterFileList] = useState({})
    const [thisIsEvent, setThisIsEvent] = useState(false)

    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [activityDescription, setActivityDescription] = useState()

    useEffect(() => {
        dispatch(fetchCommonConfiguration())
        dispatch(fetchAllGoals())
        dispatch(fetchAllCommunity())
        dispatch(fetchPublishedLocations())
    }, [])

    const successCallback = () => {
        navigate('/activities')
    }

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState)
    }

    const createNewActivity = (values) => {
        values.description = activityDescription
        if (values.gps) {
            if (Object.keys(repeaterFileList).length > 0) {
                values.gps = values.gps.map((gps, index) => ({ ...gps, image: repeaterFileList[index] ? repeaterFileList[index][0]?.image || '' : '' }))
            }
        }
        const data = {
            name: values.name,
            activityType: values.activityType,
            description: values.description,
            cities: values.cities,
            goalId: values.goal,
            // habitIds: values.habit,
            habitIds: values.habitIdsSelected,
            communityId: values.communityId,
            validationMethod: values.validationMethod,
            validationMethodDetails: {
                qr: values.qr,
                pin: values.pin,
                link: values.link,
                // gps: {
                //     location: values.location,
                //     address: values.address,
                //     lat: values.lat,
                //     lng: values.lng
                // }
                gps: values.gps,
                zohoFormLink: values.zohoFormLink,
                zohoSurveyLink: values.zohoSurveyLink,
                zohoSurveyMinScore: values.zohoSurveyMinScore,
                trackStepsTargetPerDay: values.trackStepsTargetPerDay
            },
            points: values.points,
            pointsEarnPerLike: values.pointsEarnPerLike,
            frequency: values.frequency,
            completionDuration: values.completionDuration,
            valueTimeLimit: values.valueTimeLimit,
            frequencyDetails: {
                type: values.type,
                value: values.time
            },
            instructions: values.instructions,
            duration: {
                from: values.duration ? parseUTCToMomentDate(values.duration[0]) : null,
                to: values.duration ? parseUTCToMomentDate(values.duration[1]) : null
            },
            publish: values.publish,
            thisIsEvent: values.thisIsEvent == 'true',
            eventDetails: {
                register_link: values.eventregisterlink,
                location_address: values.eventlocationaddress,
                location_type: values.eventlocationtype,
                duration_from: values.eventtime ? parseUTCToMomentDate(values.eventtime[0]) : '',
                duration_to: values.eventtime ? parseUTCToMomentDate(values.eventtime[1]) : ''
            },
            impact: {
                qty_per_unit: values.qty_per_unit,
                carbon_per_unit: values.carbon_per_unit,
                unit: values.unit
            }
        }
        if (fileList[0]) data.icon = fileList[0].icon
        if (activityImageFile[0]) data.image = activityImageFile[0].icon
        // data.validationMethodDetails.gps.image = values.gps.map((gpsImg, index) => ({ ...gpsImg, image: repeaterFileList[index][0]?.image || '' }))
        dispatch(createActivity(data, successCallback))
    }

    const handleChange = async ({ file }) => {
        // if (!(file.size / 1024 / 1024 < 50)) {
        //     message.error('file size exceeded')
        //     return
        // }
        if (!isSupportedFileFormatForGeneric(file.type)) {
            message.error('You can only upload JPG/PNG file!')
            return
        }
        setFileList(
            [{ uid: file.uid, name: '', status: 'uploading' }]
        )
        const formData = new FormData()
        formData.append('file', file)
        formData.append('folder', 'activities')

        ServerApi().post('media/admin-create-media', formData)
            .then(res => {
                setFileList([
                    {
                        uid: file.uid,
                        name: res.data.name,
                        icon: res.data.file,
                        status: 'done',
                        url: `${res.data.path}`,
                        thumbUrl: `${res.data.path}`
                    }
                ])
                return true
            })
            .catch(e => { console.log(e); return false })
    }

    const handleActivityImage = async ({ file }) => {
        if (!(file.size / 1024 / 1024 < 50)) {
            message.error('file size exceeded')
            return
        }
        if (!(file.type === 'image/jpeg' || file.type === 'image/png')) {
            message.error('You can only upload JPG/PNG file!')
            return
        }
        setActivityImageFile(
            [{ uid: file.uid, name: '', status: 'uploading' }]
        )
        const formData = new FormData()
        formData.append('file', file)
        formData.append('folder', 'activities')

        ServerApi().post('media/admin-create-media', formData)
            .then(res => {
                setActivityImageFile([
                    {
                        uid: file.uid,
                        name: res.data.name,
                        icon: res.data.file,
                        status: 'done',
                        url: `${res.data.path}`,
                        thumbUrl: `${res.data.path}`
                    }
                ])
                return true
            })
            .catch(e => { console.log(e); return false })
    }

    const handleMultipleUploads = ({ file }, index) => {
        if (!(file.size / 1024 / 1024 < 50)) {
            message.error('file size exceeded')
            return
        }
        if (!(file.type === 'image/jpeg' || file.type === 'image/png')) {
            message.error('You can only upload JPG/PNG file!')
            return
        }
        setRepeaterFileList(
            { ...repeaterFileList, [index]: [{ uid: file.uid, name: '', status: 'uploading' }] }
        )
        const formData = new FormData()
        formData.append('file', file)
        formData.append('folder', 'activities_gps')

        ServerApi().post('media/admin-create-media', formData)
            .then(res => {
                setRepeaterFileList(
                    {
                        ...repeaterFileList,
                        [index]: [
                            {
                                uid: file.uid,
                                name: res.data.name,
                                image: res.data.file,
                                status: 'done',
                                url: `${res.data.path}`,
                                thumbUrl: `${res.data.path}`
                            }
                        ]
                    }
                )
                return true
            })
            .catch(e => { console.log(e); return false })

    }

    const props = {
        customRequest: handleChange,
        listType: 'picture',
        onRemove: () => setFileList([]),
        fileList
    }

    const activityImageProps = {
        customRequest: handleActivityImage,
        listType: 'picture',
        onRemove: () => setActivityImageFile([]),
        fileList
    }

    //Habit options based on selected goals
    const habitsData = goalsData.find(i => selectedGoal === i._id)?.habits?.map(j => ({ label: j.name, value: j._id }))

    //random number generator
    const pinGenerator = () => {
        setPinValue(Math.floor(1000 + (Math.random() * 9000)))
    }

    useEffect(() => {
        form.setFieldsValue({ pin: pinValue })
    }, [pinValue])

    useEffect(() => {
    }, [thisIsEvent])

    const handleThisIsEventChange = (e) => {
        setThisIsEvent(e.target.value)
    }

    /**
     * fields: ['Name', 'Address', 'Latitude', 'Longitude', 'Radius']
     * @param {*} data 
     */
    const _handleOnCsvUploaded = (data) => {
        if (!!data) {
            const _currentGPS = form.getFieldValue('gps')
            //add more item to gps
            const _len = _currentGPS.length
            for (let i = 0; i < data.length; i++) {
                _currentGPS.push({
                    locationId: (_len + i + 1),
                    ...data[i]
                })
            }
            form.setFieldsValue({ gps: _currentGPS })
        }
    }

    /**
     * #48854 - Web Activity Management
     * @param {*} e 
     */
    const handleActivityTypeChange = (e) => {
        setActivityType(e.target.value)
    }

    return (
        <>
            <div className="shadow bg-white">
                <Form
                    name="NewActivities"
                    form={form}
                    labelCol={{
                        span: 4
                    }}
                    onFinish={(values) => createNewActivity(values)}
                    initialValues={{ publish: false, thisIsEvent: "false" }}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Collapse className="border-none" expandIconPosition={'end'} defaultActiveKey={['1', '2', '3', '4', '5']}>
                        <Panel header={<p className='font-bold text-lg'>General Info</p>} key="1" className="bg-textbackgroundcolor">
                            <Form.Item
                                label="Activity Name"
                                name="name"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please enter Activity name!' }]}
                                hasFeedback
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Share Link"
                                name="sharelink"
                                labelAlign="left"
                                colon={false}
                                // rules={[{ required: true, message: 'Please enter Activity name!' }]}
                                hasFeedback
                            >
                                <Input placeholder="Link will be auto generated when form is submitted!" disabled />
                            </Form.Item>
                            <Form.Item
                                name="activityType"
                                colon={false}
                                labelAlign="left"
                                label="Activity type"
                                rules={[{ required: true, message: 'Please select activity type' }]}
                                onChange={handleActivityTypeChange}
                            >
                                <Radio.Group className="text-left">
                                    <Radio value={ACTIVITY_TYPE.APP.value}>{ACTIVITY_TYPE.APP.label}</Radio>
                                    <Radio value={ACTIVITY_TYPE.WEB.value}>{ACTIVITY_TYPE.WEB.label}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {activityType == ACTIVITY_TYPE.APP.value &&
                                <div className="grid grid-cols-7 gap-4">
                                    <Form.Item labelAlign="left"
                                        label="Goal & Habit"
                                        name="goal"
                                        colon={false}
                                        labelCol={{
                                            span: 7
                                        }}
                                        className="col-span-4"
                                        rules={[{ required: true, message: 'Please select goal!' }]}
                                        hasFeedback
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Select Goal"
                                            optionFilterProp='label'
                                            options={goalsData.map(i => ({ label: i.name, value: i._id, habits: i.habits }))}
                                            onChange={(h) => { setSelectedGoal(h) }}
                                            suffixIcon={<CgArrowsV />}
                                        >
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label=""
                                        name="habitIdsSelected"
                                        labelAlign="left"
                                        colon={false}
                                        mode="multiple"
                                        className='col-span-3'
                                    // rules={[{ required: true, message: 'Please select habit!' }]}
                                    // hasFeedback
                                    >
                                        <Select
                                            showSearch
                                            mode="multiple"
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Select Habit"
                                            optionFilterProp='label'
                                            options={habitsData}
                                            suffixIcon={<CgArrowsV />}
                                        >
                                        </Select>
                                    </Form.Item>
                                </div>
                            }
                            <Form.Item
                                labelAlign="left"
                                colon={false}
                                label="Activity Icon"
                                extra={SUPPORTED_FILE_FORMAT_EXTRA.ACTIVITY_THUMB}
                            >
                                <div className='flex justify-end items-center'>
                                    <Upload className='flex space-x-4' {...props} fileList={fileList}>
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </div>
                            </Form.Item>
                            <Form.Item
                                labelAlign="left"
                                colon={false}
                                label="Activity Image"
                                extra={SUPPORTED_FILE_FORMAT_EXTRA.ACTIVITY_MAIN}
                            >
                                <div className='flex justify-end items-center'>
                                    <Upload className='flex space-x-4' {...activityImageProps} fileList={activityImageFile}>
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </div>
                            </Form.Item>
                            <Form.Item
                                labelAlign="left"
                                colon={false}
                                label="Community"
                                name="communityId"
                                hasFeedback
                                extra='Leave blank if global'
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: '100%' }}
                                    optionFilterProp='label'
                                    placeholder="Select Community"
                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    options={communities?.result?.map(i => ({ label: i?.name, value: i?._id }))}
                                    suffixIcon={<CgArrowsV />}
                                >
                                </Select>
                            </Form.Item>
                            <Form.Item
                                labelAlign="left"
                                colon={false}
                                label="Cities"
                                name="cities"
                                extra={'Leave blank if global'}
                                hasFeedback
                            >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    allowClear
                                    style={{ width: '100%' }}
                                    optionFilterProp='label'
                                    placeholder="Select cities"
                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    suffixIcon={<CgArrowsV />}
                                    options={locations?.map(i => ({ label: i?.name, value: i?._id }))}
                                >
                                </Select>
                            </Form.Item>
                            <Form.Item
                                labelAlign="left"
                                colon={false}
                                label="Description"
                                name="description"
                                rules={[{ required: true, message: 'Please enter description!' }]}
                                hasFeedback
                            >
                                {/* <Input.TextArea rows={3} /> */}
                                <Editor
                                    toolbar={{
                                        fontFamily: {
                                            options: ["Poppins"]
                                        }

                                    }}
                                    editorState={editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={onEditorStateChange}
                                    // plugins={plugins}
                                    onChange={() => {
                                        const tempData = (editorState && draftToHtml(convertToRaw(editorState.getCurrentContent())))
                                        setActivityDescription(tempData)
                                    }

                                    }
                                />
                            </Form.Item>
                        </Panel>

                        <Panel header={<p className='font-bold text-lg py-0'>Verification</p>} key="2" className="bg-textbackgroundcolor">
                            <Form.Item labelAlign="left" colon={false} label="Validation" name="validationMethod"
                                rules={[{ required: true, message: 'Validation is required!' }]}
                                hasFeedback
                            >
                                <Select
                                    showSearch
                                    placeholder="Single Select Verification Action"
                                    optionFilterProp='label'
                                    onSelect={setValidationOption}
                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    options={Object.values(activityType == ACTIVITY_TYPE.APP.value ? ACTIVITY_VALIDATION_METHOD : ACTIVITY_VALIDATION_METHOD_FOR_WEB).map(i => ({ label: i.label, value: i.value }))}
                                    suffixIcon={<CgArrowsV />}
                                >
                                </Select>
                            </Form.Item>
                            {validationOption == ACTIVITY_VALIDATION_METHOD.PIN.value && (
                                <div className="grid grid-cols-7 gap-3">
                                    <Form.Item
                                        label="PIN"
                                        name="pin"
                                        labelAlign="left"
                                        colon={false}
                                        labelCol={{
                                            span: 7
                                        }}
                                        className="col-span-4"
                                        rules={[
                                            { required: true, message: 'Please enter or generate PIN!' },
                                            { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                        ]}
                                        hasFeedback
                                        extra={"4 digits only"}
                                    >
                                        <Input placeholder='Enter or click generate' maxLength={4} width={50} />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button onClick={pinGenerator} size='small'>Generate</Button>
                                    </Form.Item>
                                </div>
                            )}
                            {validationOption == ACTIVITY_VALIDATION_METHOD.GPS.value && (
                                <Form.List name="gps"
                                    rules={[
                                        {
                                            validator: async (_, names) => {
                                                if (!names || names.length < 1) {
                                                    return Promise.reject(new Error('At least 1 location is required'))
                                                }
                                            }
                                        }
                                    ]}
                                >
                                    {(fields, { add, remove }, { errors }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <div key={key} align="top" className="grid grid-cols-12">
                                                    <div className='col-span-2 flex'>
                                                        <Form.Item>Location {name + 1}</Form.Item>
                                                    </div>
                                                    <div className='col-span-10 grid md:grid-cols-6 lg:grid-cols-12 gap-x-3'>
                                                        <Form.Item
                                                            {...restField}
                                                            label={name === 0 ? 'Name' : ''}
                                                            name={[name, 'locationName']}
                                                            rules={[{ required: true, message: 'Missing name' }]}
                                                            className="col-span-2"
                                                            labelCol={{ span: 24 }}
                                                        >
                                                            <Input placeholder="Name" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            label={name === 0 ? 'Address' : ''}
                                                            name={[name, "address"]}
                                                            rules={[{ required: true, message: 'Missing address' }]}
                                                            className="col-span-2"
                                                            labelCol={{ span: 24 }}
                                                        >
                                                            <Input placeholder='Address' />
                                                        </Form.Item>
                                                        <Form.Item
                                                            label={name === 0 ? 'Image' : ''}
                                                            name={[name, 'imag']}
                                                            className="col-span-2"
                                                            labelCol={{ span: 24 }}
                                                            extra={SUPPORTED_FILE_FORMAT_EXTRA.GPS_LOCATION_ICON}
                                                        >
                                                            <Upload customRequest={(obj) => {
                                                                return handleMultipleUploads(obj, name)
                                                            }} fileList={repeaterFileList[name]} listType="picture" onRemove={() => {
                                                                setRepeaterFileList({ ...repeaterFileList, [name]: [] })
                                                            }}>
                                                                <Button icon={<UploadOutlined />}>Upload</Button>
                                                            </Upload>
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            label={name === 0 ? 'Latitude' : ''}
                                                            name={[name, "lat"]}
                                                            rules={[{ required: true, message: 'Missing latitude' }]}
                                                            className="col-span-2"
                                                            labelCol={{ span: 24 }}
                                                        >
                                                            <Input placeholder='Latitude' />
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            label={name === 0 ? 'Longitude' : ''}
                                                            name={[name, "lng"]}
                                                            rules={[{ required: true, message: 'Missing longitude' }]}
                                                            className="col-span-2"
                                                            labelCol={{ span: 24 }}
                                                        >
                                                            <Input placeholder='Longitude' />
                                                        </Form.Item>
                                                        <div className='col-span-2 grid grid-cols-6 flex'>
                                                            <Form.Item
                                                                {...restField}
                                                                label={name === 0 ? 'Radius' : ''}
                                                                name={[name, "radius"]}
                                                                rules={[{ required: true, message: 'Missing radius' }]}
                                                                className="col-span-5"
                                                                labelCol={{ span: 24 }}
                                                                extra={'in meters'}
                                                            >
                                                                <Input placeholder='Radius' />
                                                            </Form.Item>

                                                            <Form.Item>
                                                                <MinusCircleOutlined className='ml-3' onClick={() => remove(name)} />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    className='font-semibold border-[#207868] text-[#207868] hover:bg-[#207868] hover:text-white'
                                                    onClick={() => add()} icon={<PlusOutlined />}
                                                >
                                                    Add Locations
                                                </Button>
                                                {/* <Form.ErrorList errors={errors} /> */}
                                            </Form.Item>
                                            <Form.Item>
                                                <UploadGpsCsv onCsvUploaded={_handleOnCsvUploaded}></UploadGpsCsv>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            )}
                            {(validationOption == ACTIVITY_VALIDATION_METHOD.ZOHO_FORM.value || validationOption == ACTIVITY_VALIDATION_METHOD.ZOHO_SURVEY.value) && (
                                <Form.Item
                                    label={validationOption == ACTIVITY_VALIDATION_METHOD.ZOHO_FORM.value ? "Zoho Form Link" : "Zoho Survey Link"}
                                    name={validationOption == ACTIVITY_VALIDATION_METHOD.ZOHO_FORM.value ? "zohoFormLink" : "zohoSurveyLink"}
                                    labelAlign="left"
                                    colon={false}
                                    rules={[{ required: true, message: 'Please enter URL!' }]}
                                    extra={(validationOption || activityData?.validationMethod) == ACTIVITY_VALIDATION_METHOD.ZOHO_FORM.value ? "User should always earn the activity point if they submit the form." : "User should only earn the activity point if they score the minimum points."}
                                    hasFeedback
                                >
                                    <Input placeholder='Paste in URL' />
                                </Form.Item>
                            )}
                            {validationOption == ACTIVITY_VALIDATION_METHOD.ZOHO_SURVEY.value && (
                                <Form.Item
                                    label="Min Score"
                                    name="zohoSurveyMinScore"
                                    labelAlign="left"
                                    colon={false}
                                    rules={[
                                        { required: true, message: 'Please enter score!' },
                                        { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                    ]}
                                    hasFeedback
                                >
                                    <InputNumber />
                                </Form.Item>
                            )}
                            {validationOption == ACTIVITY_VALIDATION_METHOD.TRACK_STEPS.value && (
                                <Form.Item
                                    label="Steps per Day"
                                    extra={'Total steps required to complete this activity. The system will reset user step at every midnight.'}
                                    name="trackStepsTargetPerDay"
                                    labelAlign="left"
                                    colon={false}
                                    rules={[
                                        { required: true, message: 'Please enter steps per day!' },
                                        { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                    ]}
                                    hasFeedback
                                >
                                    <InputNumber max={999999} />
                                </Form.Item>
                            )}
                            <Form.Item
                                label="Points earned"
                                name="points"
                                labelAlign="left"
                                colon={false}
                                rules={[
                                    { required: true, message: 'Please enter points!' },
                                    { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                ]}
                                hasFeedback
                                extra={'Points earned for completing this activity.'}
                            >
                                <InputNumber max={99999} min={0} style={{ width: '100%' }} />
                            </Form.Item>
                            {validationOption == ACTIVITY_VALIDATION_METHOD.SHARE_WITH_COMMUNITY.value && (
                                <Form.Item
                                    label="Points per like"
                                    name="pointsEarnPerLike"
                                    labelAlign="left"
                                    colon={false}
                                    rules={[
                                        { required: true, message: 'Please enter points earn per like!' },
                                        { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                    ]}
                                    hasFeedback
                                    extra={'Points a user will earn for a like of his post.'}
                                >
                                    <InputNumber max={99999} min={0} style={{ width: '100%' }} />
                                </Form.Item>
                            )}
                        </Panel>

                        <Panel header={<p className='font-bold text-lg py-0'>Settings</p>} key="4" className="bg-textbackgroundcolor" style={{ borderBottom: "none" }}>
                            <Form.Item
                                label="Frequency"
                                extra={'Please setup No limit if activity type as Track Steps.'}
                                name="frequency"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please select frequency!' }]}
                                hasFeedback
                            >
                                <Select
                                    showSearch
                                    placeholder="Select (No Limit, Once a week, Once a day, customize...etc)"
                                    optionFilterProp="label"
                                    onSelect={setFrequencyOption}
                                    options={activityType == ACTIVITY_TYPE.APP.value ? activityOptions?.map(i => ({ label: i.label, value: i.value })) : activityFrequencyOptionsForWeb?.map(i => ({ label: i.label, value: i.value }))}
                                    suffixIcon={<CgArrowsV />}
                                >
                                </Select>
                            </Form.Item>
                            {frequencyOption == 'CUSTOMIZE' && (
                                <div className="grid grid-cols-7 gap-3">
                                    <Form.Item
                                        label="Once time Per"
                                        name="time"
                                        extra={"Setup your custom rule"}
                                        labelAlign="left"
                                        colon={false}
                                        labelCol={{ span: 7 }}
                                        className="col-span-4"
                                        rules={[
                                            { required: true, message: 'Please select type!' },
                                            { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                        ]}
                                        hasFeedback
                                    >
                                        <Input placeholder='Number' />
                                    </Form.Item>
                                    <Form.Item
                                        label=""
                                        name="type"
                                        className="col-span-3"
                                        labelAlign="left"
                                        colon={false}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Hour, Day, Week, Month"
                                            optionFilterProp="children"
                                            // onChange={onChange}
                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                            suffixIcon={<CgArrowsV />}
                                        >
                                            <Select.Option value="Hour">Hour</Select.Option>
                                            <Select.Option value="Day">Day</Select.Option>
                                            <Select.Option value="Week">Week</Select.Option>
                                            <Select.Option value="Month">Month</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </div>
                            )}
                            <div className="grid grid-cols-7 gap-3">
                                <Form.Item
                                    label="Value Time Limit"
                                    name="valueTimeLimit"
                                    labelAlign="left"
                                    colon={false}
                                    labelCol={{ span: 7 }}
                                    className="col-span-4"
                                    rules={[
                                        // { required: true, message: 'Please enter value limit!' },
                                        { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                    ]}
                                    tooltip={{ title: 'Total max number of submissions available for this activity', icon: <InfoCircleOutlined /> }}
                                    hasFeedback
                                >
                                    <InputNumber placeholder='Enter Value' />
                                </Form.Item>
                            </div>
                            {activityType == ACTIVITY_TYPE.APP.value &&
                                <Form.Item
                                    label="Duration"
                                    name="duration"
                                    labelAlign="left"
                                    colon={false}
                                    extra={'Please do not setup duration if activity type as Track Steps'}
                                    // rules={[{ required: true, message: 'Please enter duration!' }]}
                                    hasFeedback
                                >
                                    <RangePicker style={{ width: "100%" }} />
                                </Form.Item>
                            }
                            {activityType == ACTIVITY_TYPE.APP.value &&
                                <Form.Item
                                    label="Expected completion timing"
                                    name="completionDuration"
                                    labelAlign="left"
                                    colon={false}
                                    rules={[{ required: true, message: 'Expected completion duration timings is required' }]}
                                    hasFeedback
                                    extra={'Expected completion duration time, which is used for logic of virtual world. Please setup as Never if activity type as Track Steps'}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select one"
                                        optionFilterProp="label"
                                        onSelect={setCompletionDuration}
                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        options={activityCompletionDurationTime?.map(i => ({ label: i.label, value: i.value }))}
                                        suffixIcon={<CgArrowsV />}
                                    >
                                    </Select>
                                </Form.Item>
                            }
                            {activityType == ACTIVITY_TYPE.APP.value &&
                                <Form.Item name="thisIsEvent" colon={false} labelAlign="left" label="This is an event"
                                    rules={[{ required: false }]} onChange={handleThisIsEventChange}
                                >
                                    <Radio.Group className="text-left">
                                        <Radio value={"true"}>Yes</Radio>
                                        <Radio value={"false"}>No</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            }
                            {thisIsEvent == "true" &&
                                <>
                                    <Form.Item
                                        label="Time"
                                        name="eventtime"
                                        labelAlign="left"
                                        colon={false}
                                        rules={[{ required: true, message: 'Please enter event time!' }]}
                                        hasFeedback
                                        extra={'Singapore time (GMT+8)'}
                                    >
                                        <RangePicker style={{ width: "100%" }}
                                            showTime={{ format: 'HH:mm' }}
                                            format="YYYY-MM-DD HH:mm"
                                        // onChange={onChangeEventTime}
                                        // onOk={onOkEventTime}
                                        />
                                    </Form.Item>
                                    <Form.Item name="eventlocationtype" colon={false} labelAlign="left" label="Location"
                                        rules={[{ required: true, message: 'Please select location type' }]}
                                    >
                                        <Radio.Group className="text-left">
                                            <Radio value={'Online'}>Online</Radio>
                                            <Radio value={'Offline'}>Offline</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        label="Address"
                                        name="eventlocationaddress"
                                        labelAlign="left"
                                        colon={false}
                                        hasFeedback
                                        extra={'Please specify full address if offline'}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        label="Register link"
                                        name="eventregisterlink"
                                        labelAlign="left"
                                        colon={false}
                                        hasFeedback
                                        extra={'Register link should start with http/https'}
                                    >
                                        <Input />
                                    </Form.Item>
                                </>
                            }

                            <Form.Item name="publish" colon={false} labelAlign="left" label="Publish"
                                rules={[{ required: true, message: 'Publish is not selected!' }]}
                            >
                                <Radio.Group className="text-left">
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Panel>
                        <Panel header={<p className='font-bold text-lg py-0'>Impact</p>} key="5" className="bg-textbackgroundcolor" style={{ borderBottom: "none" }}>
                            <div className="grid grid-cols-7 gap-4">
                                <Form.Item
                                    label="Quantity per Unit"
                                    labelCol={{
                                        span: 7
                                    }}
                                    name="qty_per_unit"
                                    labelAlign="left"
                                    colon={false}
                                    hasFeedback
                                    rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }]}
                                    className="col-span-4"
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    // label="Unit"
                                    name="unit"
                                    labelAlign="left"
                                    colon={false}
                                    hasFeedback
                                    className="col-span-3"
                                    extra={'Unit'}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select unit"
                                        optionFilterProp="label"
                                        // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        options={ACTIVITY_IMPACT_UNITS.map(i => ({ label: i.label, value: i.value }))}
                                        suffixIcon={<CgArrowsV />}
                                    >
                                    </Select>
                                </Form.Item>
                            </div>
                            <Form.Item
                                label="Carbon per Unit"
                                name="carbon_per_unit"
                                labelAlign="left"
                                colon={false}
                                hasFeedback
                                extra={'CO2'}
                                rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Panel>
                    </Collapse>

                    <div className="text-center pt-2 pb-4">
                        <Button className="bg-[#207868] px-8" size="large" type="primary" htmlType="submit">
                            Save
                        </Button>
                    </div>
                </Form>
            </div>

        </>
    )
}