import React, { Suspense } from 'react'
import { Spin } from 'antd'
import {
  BrowserRouter,
  Routes as RouterRoutes,
  Route,
  Outlet,
  Navigate
} from "react-router-dom"
import { canIAccessThisWithMyRoles } from '../const/utils'

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export function RolesAuthRoute({ roles }) {
  return canIAccessThisWithMyRoles(roles) ? <Outlet /> : <Navigate to="/not-authorized" />
}