import React, { useState, useEffect } from 'react'
import { UploadOutlined, PlusOutlined, ConsoleSqlOutlined } from '@ant-design/icons'
import { Collapse, Button, Checkbox, Radio, Form, Input, Upload, Select, AutoComplete } from 'antd'
// import { CgArrowsV } from 'react-icons/cg'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import ServerApi from '../../../utility/ServerApi'
import { SearchUser } from '../../../store/users/action'
import { fetchCommunityById, updateCommunity } from '../../../store/community/actions'
import PreviewImage from '../../../components/image/PreviewImage'
import { fetchAllChallengesAvailableForSetupCommunity } from '../../../store/challenges/actions'
import { fetchAvailCommunityLeaders } from '../../../store/users/adminUsers/actions'
import { GoalTypes, ImpactTypes, TimeZone } from '../../../const/sdgs.const'

import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw, convertFromHTML, ContentState } from "draft-js"
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
// import createToolbarPlugin from '@draft-js-plugins/static-toolbar'
import '../../../assets/css/editorStyles.css'
import { BE_FULL_DATE_FORMAT, canIAccessThisWithMyRoles, isSupportedFileFormatForGeneric, SUPPORTED_FILE_FORMAT_EXTRA } from '../../../const/utils'
import { SUSGAIN_ROLES } from '../../../const/roles'
import moment from 'moment'
const { Panel } = Collapse
import htmlToDraft from 'html-to-draftjs'
import { fetchAllActivitiesForCreateEditChallenge } from '../../../store/activity/actions'

export const EditCommunity = () => {

    const [form] = Form.useForm()
    const { communityId } = useParams()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const communityData = useSelector(state => state.community.community)
    // const searchedUsers = useSelector(state => state.users.searchedUsers)
    // const teamsData = useSelector(state => state.teams.teams)
    const activitiesData = useSelector(state => state.activity.activitiesForEditCreateChallenge?.result)
    const challengesData = useSelector(state => state.challenges.availChallenges?.result)
    const editorWelcomeMessageText = useSelector(state => state.community?.community?.welcomeMessage)

    const editorDescriptioGoals1Text = useSelector(state => {
        return state?.community?.community?.communityGoals?.length > 0 ? state?.community?.community?.communityGoals[0]?.description : ''
    })
    const editorDescriptioGoals2Text = useSelector(state => {
        return state?.community?.community?.communityGoals?.length > 0 ? state?.community?.community?.communityGoals[1]?.description : ''
    })
    const editorDescriptioGoals3Text = useSelector(state => {
        return state?.community?.community?.communityGoals?.length > 0 ? state?.community?.community?.communityGoals[2]?.description : ''
    })

    const adminUsers = useSelector(state => state.adminUsers.availCommunityLeaders)

    const filteredAdminUsers = adminUsers?.result?.filter((values) => {
        return values?.role?.includes('community_leader')
    })


    useEffect(() => {
        dispatch(fetchCommunityById(communityId))
        // dispatch(fetchAllTeams())
        // dispatch(fetchAllChallengesAvailableForSetupCommunity())
        dispatch(fetchAvailCommunityLeaders(communityId))
    }, [])

    // const [visible, setVisible] = useState(false)
    const [goalType1, setGoalType1] = useState("")
    const [goalType2, setGoalType2] = useState("")
    const [goalType3, setGoalType3] = useState("")
    const [fileList, setFileList] = useState([])
    const [fileBannerList, setFileBannerList] = useState([])

    const [welcomeMessage, setWelcomeMessage] = useState()

    const [descriptionGoals1, setDescriptionGoals1] = useState()
    const [descriptionGoals2, setDescriptionGoals2] = useState()
    const [descriptionGoals3, setDescriptionGoals3] = useState()


    const [editorWelComeMessageState, setEditorWelComeMessageState] = useState(EditorState.createEmpty())

    const [editorDescriptioGoals1State, setEditorDescriptioGoals1State] = useState(EditorState.createEmpty())
    const [editorDescriptioGoals2State, setEditorDescriptioGoals2State] = useState(EditorState.createEmpty())
    const [editorDescriptioGoals3State, setEditorDescriptioGoals3State] = useState(EditorState.createEmpty())

    useEffect(() => {
        //teams options based on selected community
        // console.log(`goalType2 `, goalType2)
        if (goalType1 == 'Challenge') {
            dispatch(fetchAllChallengesAvailableForSetupCommunity({ communityId }))
            // force reset team selected
            form.setFieldsValue({ challengeIds1: undefined })
            // form.setFieldsValue({ challengeIds2: undefined })
            // form.setFieldsValue({ challengeIds3: undefined })
        } else if (goalType1 == 'Activity') {
            dispatch(fetchAllActivitiesForCreateEditChallenge({ communityId }))
            // force reset team selected
            form.setFieldsValue({ activitiesIds1: undefined })
            // form.setFieldsValue({ activitiesIds2: undefined })
            // form.setFieldsValue({ activitiesIds3: undefined })
        }
    }, [goalType1])


    useEffect(() => {
        //teams options based on selected community
        // console.log(`goalType2 `, goalType2)
        if (goalType2 == 'Challenge') {
            dispatch(fetchAllChallengesAvailableForSetupCommunity({ communityId }))
            // force reset team selected
            // form.setFieldsValue({ challengeIds1: undefined })
            form.setFieldsValue({ challengeIds2: undefined })
            // form.setFieldsValue({ challengeIds3: undefined })
        } else if (goalType2 == 'Activity') {
            dispatch(fetchAllActivitiesForCreateEditChallenge({ communityId }))
            // force reset team selected
            // form.setFieldsValue({ activitiesIds1: undefined })
            form.setFieldsValue({ activitiesIds2: undefined })
            // form.setFieldsValue({ activitiesIds3: undefined })
        }
    }, [goalType2])


    useEffect(() => {
        //teams options based on selected community
        // console.log(`goalType2 `, goalType2)
        if (goalType3 == 'Challenge') {
            dispatch(fetchAllChallengesAvailableForSetupCommunity({ communityId }))
            // force reset team selected
            // form.setFieldsValue({ challengeIds1: undefined })
            // form.setFieldsValue({ challengeIds2: undefined })
            form.setFieldsValue({ challengeIds3: undefined })
        } else if (goalType3 == 'Activity') {
            dispatch(fetchAllActivitiesForCreateEditChallenge({ communityId }))
            // force reset team selected
            // form.setFieldsValue({ activitiesIds1: undefined })
            // form.setFieldsValue({ activitiesIds2: undefined })
            form.setFieldsValue({ activitiesIds3: undefined })
        }
    }, [goalType3])

    const onEditorWelcomeMessageStateChange = (editorState) => {
        setEditorWelComeMessageState(editorState)
    }

    const onEditorDescriptionGoals1StateChange = (editorState) => {
        setEditorDescriptioGoals1State(editorState)
    }

    const onEditorDescriptionGoals2StateChange = (editorState) => {
        setEditorDescriptioGoals2State(editorState)
    }

    const onEditorDescriptionGoals3StateChange = (editorState) => {
        setEditorDescriptioGoals3State(editorState)
    }

    const canAccess = () => {
        return canIAccessThisWithMyRoles([SUSGAIN_ROLES.ADMIN.role])
    }

    useEffect(() => {
        form.setFieldsValue({
            name: communityData?.name,
            pincode: communityData?.pincode,
            // welcomeMessage: communityData?.welcomeMessage,
            leaderId: communityData?.leaderId?._id,
            teamIds: communityData?.teamIds?.map(i => i._id),
            publish: communityData?.publish,
            communityFeedAvailable: communityData?.communityFeedAvailable,
            status: communityData?.status,
            goalName1: communityData?.communityGoals?.[0]?.goalName,
            goalType1: communityData?.communityGoals?.[0]?.goalType,
            impactType1: communityData?.communityGoals?.[0]?.impactType,
            goalDiscription1: communityData?.communityGoals?.[0]?.description,
            goalTarget1: communityData?.communityGoals?.[0]?.goalTarget,
            challengeIds1: communityData?.communityGoals?.[0]?.challengeIds?.map(c => c?._id),
            activityIds1: communityData?.communityGoals?.[0]?.activityIds?.map(c => c?._id),
            goalName2: communityData?.communityGoals?.[1]?.goalName,
            goalType2: communityData?.communityGoals?.[1]?.goalType,
            impactType2: communityData?.communityGoals?.[1]?.impactType,
            goalDiscription2: communityData?.communityGoals?.[1]?.description,
            goalTarget2: communityData?.communityGoals?.[1]?.goalTarget,
            challengeIds2: communityData?.communityGoals?.[1]?.challengeIds?.map(j => j?._id),
            activityIds2: communityData?.communityGoals?.[1]?.activityIds?.map(j => j?._id),
            goalName3: communityData?.communityGoals?.[2]?.goalName,
            goalType3: communityData?.communityGoals?.[2]?.goalType,
            impactType3: communityData?.communityGoals?.[2]?.impactType,
            goalDiscription3: communityData?.communityGoals?.[2]?.description,
            goalTarget3: communityData?.communityGoals?.[2]?.goalTarget,
            challengeIds3: communityData?.communityGoals?.[2]?.challengeIds?.map(i => i?._id),
            activityIds3: communityData?.communityGoals?.[2]?.activityIds?.map(i => i?._id),
            email: communityData?.accountInfo?.email,
            number_of_people_invited: communityData?.participation_kpi?.number_of_people_invited,
            signup_rate: communityData?.participation_kpi?.signup_rate,
            participation_rate_1: communityData?.participation_kpi?.participation_rate_1,
            active_participation_rate_1: communityData?.participation_kpi?.active_participation_rate_1,
            active_participation_rate_2: communityData?.participation_kpi?.active_participation_rate_2,
            activities_completed_per_week: communityData?.activity_kpi?.activities_completed_per_week,
            challenges_completed_per_week: communityData?.activity_kpi?.challenges_completed_per_week,
            timezone: communityData?.timezone

        })
        setGoalType1(communityData?.communityGoals?.[0]?.goalType)
        setGoalType2(communityData?.communityGoals?.[1]?.goalType)
        setGoalType3(communityData?.communityGoals?.[2]?.goalType)
    }, [communityData])

    useEffect(() => {
        setTimeout(() => {
            // const blocksFromHTML = convertFromHTML(editorWelcomeMessageText)
            // const state = ContentState.createFromBlockArray(
            //     blocksFromHTML.contentBlocks,
            //     blocksFromHTML.entityMap
            // )
            // setEditorWelComeMessageState(EditorState.createWithContent(state))


            setWelcomeMessage(editorWelcomeMessageText)
            const contentBlock = htmlToDraft(editorWelcomeMessageText)
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
                const editorState = EditorState.createWithContent(contentState)
                setEditorWelComeMessageState(editorState)
            }
        }, 1000)
    }, [editorWelcomeMessageText])

    useEffect(() => {
        setTimeout(() => {
            // const blocksFromHTML = convertFromHTML(editorDescriptioGoals1Text)
            // const state = ContentState.createFromBlockArray(
            //     blocksFromHTML.contentBlocks,
            //     blocksFromHTML.entityMap
            // )
            // setEditorDescriptioGoals1State(EditorState.createWithContent(state))
            // setDescriptionGoals1(editorDescriptioGoals1Text)

            setDescriptionGoals1(editorDescriptioGoals1Text)
            const contentBlock = htmlToDraft(editorDescriptioGoals1Text)
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
                const editorState = EditorState.createWithContent(contentState)
                setEditorDescriptioGoals1State(editorState)
            }
        }, 1000)
    }, [editorDescriptioGoals1Text])

    useEffect(() => {
        setTimeout(() => {
            // const blocksFromHTML = convertFromHTML(editorDescriptioGoals2Text)
            // const state = ContentState.createFromBlockArray(
            //     blocksFromHTML.contentBlocks,
            //     blocksFromHTML.entityMap
            // )
            // setEditorDescriptioGoals2State(EditorState.createWithContent(state))
            // setDescriptionGoals2(editorDescriptioGoals2Text)

            setDescriptionGoals2(editorDescriptioGoals2Text)
            const contentBlock = htmlToDraft(editorDescriptioGoals2Text)
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
                const editorState = EditorState.createWithContent(contentState)
                setEditorDescriptioGoals2State(editorState)
            }
        }, 1000)
    }, [editorDescriptioGoals2Text])

    useEffect(() => {
        setTimeout(() => {
            // const blocksFromHTML = convertFromHTML(editorDescriptioGoals3Text)
            // const state = ContentState.createFromBlockArray(
            //     blocksFromHTML.contentBlocks,
            //     blocksFromHTML.entityMap
            // )
            // setEditorDescriptioGoals3State(EditorState.createWithContent(state))
            // setDescriptionGoals3(editorDescriptioGoals3Text)

            setDescriptionGoals3(editorDescriptioGoals3Text)
            const contentBlock = htmlToDraft(editorDescriptioGoals3Text)
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
                const editorState = EditorState.createWithContent(contentState)
                setEditorDescriptioGoals3State(editorState)
            }
        }, 1000)
    }, [editorDescriptioGoals3Text])

    const successcallBack = () => {
        navigate('/communities')
    }

    const updateThisCommunity = (values) => {
        values.welcomeMessage = welcomeMessage
        values.descriptionGoals1 = descriptionGoals1
        values.descriptionGoals2 = descriptionGoals2
        values.descriptionGoals3 = descriptionGoals3
        const data = {
            name: values.name,
            pincode: values.pincode,
            welcomeMessage: values.welcomeMessage,
            leaderId: values.leaderId,
            teamIds: values.teamIds,
            status: values.status,
            publish: values.publish,
            communityFeedAvailable: values.communityFeedAvailable,
            timezone: values.timezone,
            // accountInfo: {
            //     email: values.email
            //     // password: values.password
            // },
            communityGoals: [
                {
                    goalName: values.goalName1,
                    goalType: values.goalType1,
                    impactType: values.impactType1,
                    description: values.descriptionGoals1,
                    challengeIds: values.challengeIds1,
                    activityIds: values.activityIds1,
                    goalTarget: values.goalTarget1
                },
                {
                    goalName: values.goalName2,
                    goalType: values.goalType2,
                    impactType: values.impactType2,
                    description: values.descriptionGoals2,
                    challengeIds: values.challengeIds2,
                    activityIds: values.activityIds2,
                    goalTarget: values.goalTarget2
                },
                {
                    goalName: values.goalName3,
                    goalType: values.goalType3,
                    impactType: values.impactType3,
                    description: values.descriptionGoals3,
                    challengeIds: values.challengeIds3,
                    activityIds: values.activityIds3,
                    goalTarget: values.goalTarget3
                }
            ],
            participation_kpi: {
                number_of_people_invited: values?.number_of_people_invited ? parseFloat(values?.number_of_people_invited) : 0,
                signup_rate: values?.signup_rate ? parseFloat(values?.signup_rate) : 0,
                participation_rate_1: values?.participation_rate_1 ? parseFloat(values?.participation_rate_1) : 0,
                active_participation_rate_1: values?.active_participation_rate_1 ? parseFloat(values?.active_participation_rate_1) : 0,
                active_participation_rate_2: values?.active_participation_rate_2 ? parseFloat(values?.active_participation_rate_2) : 0
            },
            activity_kpi: {
                activities_completed_per_week: values?.activities_completed_per_week ? parseFloat(values?.activities_completed_per_week) : 0,
                challenges_completed_per_week: values?.challenges_completed_per_week ? parseFloat(values?.challenges_completed_per_week) : 0
            }

        }
        if (fileList[0]) data.logo = fileList[0].logo
        if (fileBannerList[0]) data.banner = fileBannerList[0].banner
        dispatch(updateCommunity(communityId, data, successcallBack))
    }

    const handleUserSearch = (user) => {
        dispatch(SearchUser(user))
    }

    const handleChange = async ({ file }) => {
        // if (!(file.size / 1024 / 1024 < 50)) {
        //     message.error('file size exceeded')q
        //     return
        // }
        if (!isSupportedFileFormatForGeneric(file.type)) {
            message.error('You can only upload JPG/PNG file!')
            return
        }
        setFileList(
            [{ uid: file.uid, name: '', status: 'uploading' }]
        )
        const formData = new FormData()
        formData.append('file', file)
        formData.append('folder', 'community')

        ServerApi().post('media/admin-create-media', formData)
            .then(res => {
                setFileList([
                    {
                        uid: file.uid,
                        name: res.data.name,
                        logo: res.data.file,
                        status: 'done',
                        url: `${res.data.path}`,
                        thumbUrl: `${res.data.path}`
                    }
                ])
                return true
            })
            .catch(e => { console.log(e); return false })
    }

    const handleBannerChange = async ({ file }) => {
        // if (!(file.size / 1024 / 1024 < 50)) {
        //     message.error('file size exceeded')
        //     return
        // }
        if (!isSupportedFileFormatForGeneric(file.type)) {
            message.error('You can only upload JPG/PNG file!')
            return
        }
        setFileBannerList(
            [{ uid: file.uid, name: '', status: 'uploading' }]
        )
        const formData = new FormData()
        formData.append('file', file)
        formData.append('folder', 'community')

        ServerApi().post('media/admin-create-media', formData)
            .then(res => {
                setFileBannerList([
                    {
                        uid: file.uid,
                        name: res.data.name,
                        banner: res.data.file,
                        status: 'done',
                        url: `${res.data.path}`,
                        thumbUrl: `${res.data.path}`
                    }
                ])
                return true
            })
            .catch(e => { console.log(e); return false })
    }

    const props = {
        customRequest: handleChange,
        listType: 'picture',
        onRemove: () => setFileList([]),
        fileList
    }

    const bannerProps = {
        customRequest: handleBannerChange,
        listType: 'picture',
        onRemove: () => setFileBannerList([]),
        fileList
    }

    const showModal = () => {
        setVisible(true)
    }

    return (
        <div className="shadow bg-white">
            <Form
                name="basic"
                form={form}
                labelCol={{
                    span: 4
                }}
                initialValues={{ publish: false }}
                onFinish={(values) => updateThisCommunity(values)}
                autoComplete="off"
                className='px-4 py-4'
            >
                <Collapse className="border-none" expandIconPosition={'end'} defaultActiveKey={['1', '2', '3', '4', '5', '6']}>
                    {canAccess() &&
                        <Panel header={<p className='font-bold text-lg'>Leader Account Info</p>} Info key="1" className="bg-textbackgroundcolor">

                            <div className='flex justify-between'>
                                <Form.Item
                                    labelAlign='left'
                                    label='Leader Account'
                                    className='w-full'
                                    name='leaderId'
                                    colon={false}
                                    rules={[{ required: true, message: 'Please select a Community Leader!' }]}
                                >
                                    <Select
                                        placeholder='Select a Community Leader account'
                                        options={filteredAdminUsers?.map(i => ({ label: i?.name, value: i?._id }))}
                                    ></Select>

                                </Form.Item>
                                <Button
                                    icon={<PlusOutlined />} type="primary" className="bg-[#207868] ml-5"
                                    onClick={() => navigate('/users/admin')}
                                >
                                    Create New Community Leader
                                </Button>
                            </div>
                        </Panel>
                    }
                    <Panel header={<p className='font-bold text-lg'>Community</p>} Info key="2" className="bg-textbackgroundcolor">
                        <Form.Item
                            labelAlign="left"
                            label="Community Name"
                            name="name"
                            colon={false}
                        >
                            <Input />
                        </Form.Item>
                        {/* <Form.Item
                            labelAlign="left"
                            label="PIN Code"
                            name="pincode"
                            colon={false}
                            rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }]}
                        >
                            <Input />
                        </Form.Item> */}
                        <Form.Item labelAlign="left"
                            label="Logo"
                            extra={SUPPORTED_FILE_FORMAT_EXTRA.COMMUNITY_LOGO}
                        >
                            <div className='flex justify-between'>
                                <div className='self-center'>
                                    <p className='flex'>Currently: <PreviewImage src={communityData?.logo} /></p>
                                </div>
                                <Upload className='flex space-x-4'{...props} fileList={fileList}>
                                    <Button icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </div>
                        </Form.Item>
                        <Form.Item
                            labelAlign="left"
                            label="Banner Image"
                            colon={false}
                            extra={SUPPORTED_FILE_FORMAT_EXTRA.COMMUNITY_BANNER}
                        >
                            <div className='flex justify-between'>
                                <div className='self-center'>
                                    <p className='flex'>Currently: <PreviewImage src={communityData?.banner} /></p>
                                </div>
                                <Upload className='flex space-x-4'{...bannerProps} fileList={fileBannerList}>
                                    <Button icon={<UploadOutlined />}>Upload</Button>
                                </Upload>
                            </div>
                        </Form.Item>
                        <Form.Item
                            labelAlign="left"
                            label="Welcoming Message"
                            name="welcomeMessage"
                            colon={false}
                        >
                            {/* <Input.TextArea showCount rows={3} /> */}

                            <Editor
                                toolbar={{
                                    fontFamily: {
                                        options: ["Poppins"]
                                    }
                                }}
                                editorState={editorWelComeMessageState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={onEditorWelcomeMessageStateChange}
                                // plugins={plugins}
                                onChange={() => {
                                    const tempData = (editorWelComeMessageState && draftToHtml(convertToRaw(editorWelComeMessageState.getCurrentContent())))
                                    setWelcomeMessage(tempData)
                                }

                                }
                            />
                        </Form.Item>
                        <Form.Item
                            labelAlign='left'
                            label='Timezone'
                            name='timezone'
                            colon='false'
                            extra={'Timezone will impact logic of closing Community Challenge'}
                        >
                            <Select
                                showSearch
                                optionFilterProp='label'
                                options={TimeZone}
                                placeholder="Select Timezone"
                            />
                        </Form.Item>
                        {/* <div className='flex justify-between '>
                            <Form.Item
                                labelAlign="left"
                                label="Community Leader"
                                name="leaderId"
                                colon={false}
                                labelCol={{
                                    span: 4
                                }}
                                rules={[{ required: true, message: 'Select Leader' }]}
                                className='w-full'
                            > */}
                        {/* <AutoComplete
                                    optionFilterProp='label'
                                    // showArrow={false}
                                    options={searchedUsers.map(i => ({ label: i.name, value: i._id }))}
                                    onSearch={(text) => handleUserSearch(text)}
                                >
                                    <>
                                        <Input size='large' placeholder="search / add community leader" />
                                        <Button size='large' onClick={showModal} icon={<PlusOutlined />} className="bg-[#207868] hover:bg-[#0A5043]" type="primary" />
                                    </>
                                </AutoComplete> */}
                        {/* <Select
                                    showSearch
                                    style={{
                                        width: '100%',
                                        width: '-moz-avaliable',
                                        width: '-webkit-fill-available',
                                        width: 'fill-available'
                                    }}
                                    options={searchedUsers?.map(i => ({ label: i?.name || i?.email, value: i?._id }))}
                                    placeholder="search / add community leader"
                                    onSearch={(text) => handleUserSearch(text)}
                                    suffixIcon={<CgArrowsV />}
                                >
                                    <Input />
                                </Select>
                            </Form.Item>
                            <Button size='medium' onClick={showModal} icon={<PlusOutlined />} className="bg-[#207868] hover:bg-[#0A5043]" type="primary" />
                        </div> */}
                        {/* <Form.Item
                            labelAlign="left"
                            label="Team Name"
                            name="teamIds"
                            colon={false}
                        >
                            <Select
                                mode='multiple'
                                optionFilterProp='label'
                                showSearch
                                allowClear
                                options={
                                    teamsData?.result?.map(i => ({ label: i?.name, value: i?._id }))
                                }
                            >
                                <Input />
                            </Select>
                        </Form.Item> */}

                    </Panel>
                    <Panel header={<p className='font-bold text-lg'>Goals</p>} Info key="3" className="bg-textbackgroundcolor" style={{ borderBottom: "none" }}>
                        <div className='grid grid-cols-3 gap-4'>
                            <div>
                                <Form.Item
                                    label="Community Goal 1"
                                    name="goalName1"
                                    labelAlign="left"
                                    labelCol={{ span: 24 }}
                                    colon={false}
                                    rules={[{ required: true, message: 'Please enter community goal !' }]}
                                    hasFeedback
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    labelAlign="left"
                                    labelCol={{ span: 24 }}
                                    label="Goal Type"
                                    name="goalType1"
                                    colon={false}
                                    className='w-full'
                                >
                                    <Select
                                        showSearch
                                        optionFilterProp='label'
                                        options={GoalTypes}
                                        placeholder="Select goal type"
                                        onChange={setGoalType1}
                                    />
                                </Form.Item>

                                {(goalType1 || communityData?.communityGoals?.[0]?.goalType) === "ImpactReward" && (
                                    <Form.Item
                                        labelAlign="left"
                                        labelCol={{ span: 24 }}
                                        label="Impact Type"
                                        name="impactType1"
                                        colon={false}
                                        className='w-full'
                                        rules={[{ required: true, message: 'Please select Impact Type' }]}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp='label'
                                            options={ImpactTypes}
                                            placeholder="Select Impact type"
                                        />
                                    </Form.Item>
                                )}

                                <Form.Item
                                    labelAlign="left"
                                    labelCol={{ span: 24 }}
                                    label="Description"
                                    name="goalDiscription1"
                                    colon={false}
                                >
                                    {/* <Input.TextArea showCount rows={2} /> */}
                                    <Editor
                                        toolbar={{
                                            fontFamily: {
                                                options: ["Poppins"]
                                            }
                                        }}
                                        editorState={editorDescriptioGoals1State}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorDescriptionGoals1StateChange}
                                        // plugins={plugins}
                                        onChange={() => {
                                            const tempData = (editorDescriptioGoals1State && draftToHtml(convertToRaw(editorDescriptioGoals1State.getCurrentContent())))
                                            setDescriptionGoals1(tempData)
                                        }

                                        }
                                    />
                                </Form.Item>

                                {(goalType1 || communityData?.communityGoals?.[0]?.goalType) === "ImpactReward" && (
                                    <Form.Item
                                        labelAlign="left"
                                        labelCol={{ span: 24 }}
                                        label="Goal"
                                        name="goalTarget1"
                                        colon={false}
                                        rules={[
                                            { required: true, message: 'Please enter points!' },
                                            { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                        ]}
                                        hasFeedback
                                    >
                                        <Input placeholder='Goal target' />
                                    </Form.Item>
                                )}

                                {(goalType1 || communityData?.communityGoals?.[0]?.goalType) === "Challenge" && (
                                    <Form.Item
                                        labelAlign="left"
                                        labelCol={{ span: 24 }}
                                        label="Challenges"
                                        name="challengeIds1"
                                        colon={false}
                                        className='w-full'
                                        rules={[{ required: true, message: 'Please select Challenges' }]}
                                    >
                                        <Select
                                            mode='multiple'
                                            showSearch
                                            optionFilterProp='label'
                                            options={challengesData?.map(i => ({ label: i.name, value: i._id }))}
                                            placeholder="Select Challenges"
                                        />
                                    </Form.Item>
                                )}
                                {(goalType1 || communityData?.communityGoals?.[0]?.goalType) === "Activity" && (
                                    <>
                                        <Form.Item
                                            labelAlign="left"
                                            labelCol={{ span: 24 }}
                                            label="Activities"
                                            name="activityIds1"
                                            colon={false}
                                            className='w-full'
                                            rules={[{ required: true, message: 'Please select Activities' }]}
                                        >
                                            <Select
                                                mode='multiple'
                                                showSearch
                                                optionFilterProp='label'
                                                options={activitiesData?.map(i => ({ label: `${i.name} (${i.points}pts) (${i.cities && i.cities.length > 0 ? i.cities?.map(k => k.name).join(", ") : '-'})`, value: i._id }))}
                                                placeholder="Select Activities"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            labelAlign="left"
                                            labelCol={{ span: 24 }}
                                            label="Goal"
                                            extra="Number of times completed of any activities"
                                            name="goalTarget1"
                                            colon={false}
                                            rules={[
                                                { required: true, message: 'Please enter points!' },
                                                { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                            ]}
                                            hasFeedback
                                        >
                                            <Input placeholder='Goal target' />
                                        </Form.Item>
                                    </>

                                )}
                            </div>

                            <div>
                                <Form.Item
                                    label="Community Goal 2"
                                    name="goalName2"
                                    labelAlign="left"
                                    labelCol={{ span: 24 }}
                                    colon={false}
                                    rules={[{ required: true, message: 'Please enter community goal !' }]}
                                    hasFeedback
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    labelAlign="left"
                                    labelCol={{ span: 24 }}
                                    label="Goal Type"
                                    name="goalType2"
                                    colon={false}
                                    className='w-full'
                                // rules={[{ required: true, message: 'Please select Goal Type' }]}
                                >
                                    <Select
                                        showSearch
                                        optionFilterProp='label'
                                        options={GoalTypes}
                                        placeholder="Select goal type"
                                        onChange={setGoalType2}
                                    />
                                </Form.Item>
                                {(goalType2 || communityData?.communityGoals?.[1]?.goalType) === "ImpactReward" && (
                                    <Form.Item
                                        labelAlign="left"
                                        labelCol={{ span: 24 }}
                                        label="Impact Type"
                                        name="impactType2"
                                        colon={false}
                                        className='w-full'
                                        rules={[{ required: true, message: 'Please select Impact Type' }]}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp='label'
                                            options={ImpactTypes}
                                            placeholder="Select Impact type"
                                        />
                                    </Form.Item>
                                )}

                                <Form.Item
                                    labelAlign="left"
                                    labelCol={{ span: 24 }}
                                    label="Description"
                                    name="goalDiscription2"
                                    colon={false}
                                >
                                    {/* <Input.TextArea showCount rows={2} /> */}
                                    <Editor
                                        toolbar={{
                                            fontFamily: {
                                                options: ["Poppins"]
                                            }
                                        }}
                                        editorState={editorDescriptioGoals2State}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorDescriptionGoals2StateChange}
                                        // plugins={plugins}
                                        onChange={() => {
                                            const tempData = (editorDescriptioGoals2State && draftToHtml(convertToRaw(editorDescriptioGoals2State.getCurrentContent())))
                                            setDescriptionGoals2(tempData)
                                        }

                                        }
                                    />
                                </Form.Item>

                                {(goalType2 || communityData?.communityGoals?.[1]?.goalType) === "ImpactReward" && (
                                    <Form.Item
                                        labelAlign="left"
                                        labelCol={{ span: 24 }}
                                        label="Goal"
                                        name="goalTarget2"
                                        colon={false}
                                        rules={[
                                            { required: true, message: 'Please enter points!' },
                                            { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                        ]}
                                        hasFeedback
                                    >
                                        <Input placeholder='Goal target' />
                                    </Form.Item>
                                )}

                                {(goalType2 || communityData?.communityGoals?.[1]?.goalType) === "Challenge" && (
                                    <Form.Item
                                        labelAlign="left"
                                        labelCol={{ span: 24 }}
                                        label="Challenges"
                                        name="challengeIds2"
                                        colon={false}
                                        className='w-full'
                                        rules={[{ required: true, message: 'Please select Challenges' }]}
                                    >
                                        <Select
                                            mode='multiple'
                                            showSearch
                                            optionFilterProp='label'
                                            options={challengesData?.map(i => ({ label: i.name, value: i._id }))}
                                            placeholder="Select Challenges"
                                        />
                                    </Form.Item>
                                )}
                                {(goalType2 || communityData?.communityGoals?.[1]?.goalType) === "Activity" && (
                                    <>
                                        <Form.Item
                                            labelAlign="left"
                                            labelCol={{ span: 24 }}
                                            label="Activities"
                                            name="activityIds2"
                                            colon={false}
                                            className='w-full'
                                            rules={[{ required: true, message: 'Please select Activities' }]}
                                        >
                                            <Select
                                                mode='multiple'
                                                showSearch
                                                optionFilterProp='label'
                                                options={activitiesData?.map(i => ({ label: `${i.name} (${i.points}pts) (${i.cities && i.cities.length > 0 ? i.cities?.map(k => k.name).join(", ") : '-'})`, value: i._id }))}
                                                placeholder="Select Activities"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            labelAlign="left"
                                            labelCol={{ span: 24 }}
                                            label="Goal"
                                            extra="Number of times completed of any activities"
                                            name="goalTarget2"
                                            colon={false}
                                            rules={[
                                                { required: true, message: 'Please enter points!' },
                                                { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                            ]}
                                            hasFeedback
                                        >
                                            <Input placeholder='Goal target' />
                                        </Form.Item>
                                    </>
                                )}
                            </div>
                            <div>
                                <Form.Item
                                    label="Community Goal 3"
                                    name="goalName3"
                                    labelAlign="left"
                                    labelCol={{ span: 24 }}
                                    colon={false}
                                    rules={[{ required: true, message: 'Please enter community goal !' }]}
                                    hasFeedback
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    labelAlign="left"
                                    labelCol={{ span: 24 }}
                                    label="Goal Type"
                                    name="goalType3"
                                    colon={false}
                                    className='w-full'
                                // rules={[{ required: true, message: 'Please select Goal Type' }]}
                                >
                                    <Select
                                        showSearch
                                        optionFilterProp='label'
                                        options={GoalTypes}
                                        placeholder="Select goal type"
                                        onChange={setGoalType3}
                                    />
                                </Form.Item>

                                {(goalType3 || communityData?.communityGoals?.[2]?.goalType) === "ImpactReward" && (
                                    <Form.Item
                                        labelAlign="left"
                                        labelCol={{ span: 24 }}
                                        label="Impact Type"
                                        name="impactType3"
                                        colon={false}
                                        className='w-full'
                                        rules={[{ required: true, message: 'Please select Impact Type' }]}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp='label'
                                            options={ImpactTypes}
                                            placeholder="Select Impact type"
                                        />
                                    </Form.Item>
                                )}
                                <Form.Item
                                    labelAlign="left"
                                    labelCol={{ span: 24 }}
                                    label="Description"
                                    name="goalDiscription3"
                                    colon={false}
                                >
                                    {/* <Input.TextArea showCount rows={2} /> */}
                                    <Editor
                                        toolbar={{
                                            fontFamily: {
                                                options: ["Poppins"]
                                            }
                                        }}
                                        editorState={editorDescriptioGoals3State}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorDescriptionGoals3StateChange}
                                        // plugins={plugins}
                                        onChange={() => {
                                            const tempData = (editorDescriptioGoals3State && draftToHtml(convertToRaw(editorDescriptioGoals3State.getCurrentContent())))
                                            setDescriptionGoals3(tempData)
                                        }

                                        }
                                    />
                                </Form.Item>

                                {(goalType3 || communityData?.communityGoals?.[2]?.goalType) === "ImpactReward" && (
                                    <Form.Item
                                        labelAlign="left"
                                        labelCol={{ span: 24 }}
                                        label="Goal"
                                        name="goalTarget3"
                                        colon={false}
                                        rules={[
                                            { required: true, message: 'Please enter points!' },
                                            { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                        ]}
                                        hasFeedback
                                    >
                                        <Input placeholder='Goal target' />
                                    </Form.Item>
                                )}

                                {(goalType3 || communityData?.communityGoals?.[2]?.goalType) === "Challenge" && (
                                    <Form.Item
                                        labelAlign="left"
                                        labelCol={{ span: 24 }}
                                        label="Challenges"
                                        name="challengeIds3"
                                        colon={false}
                                        className='w-full'
                                        rules={[{ required: true, message: 'Please select Challenges' }]}
                                    >
                                        <Select
                                            mode='multiple'
                                            showSearch
                                            optionFilterProp='label'
                                            options={challengesData?.map(i => ({ label: i?.name, value: i?._id }))}
                                            placeholder="Select Challenges"
                                        />
                                    </Form.Item>
                                )}
                                {(goalType3 || communityData?.communityGoals?.[2]?.goalType) === "Activity" && (
                                    <>
                                        <Form.Item
                                            labelAlign="left"
                                            labelCol={{ span: 24 }}
                                            label="Activities"
                                            name="activityIds3"
                                            colon={false}
                                            className='w-full'
                                            rules={[{ required: true, message: 'Please select Activities' }]}
                                        >
                                            <Select
                                                mode='multiple'
                                                showSearch
                                                optionFilterProp='label'
                                                options={activitiesData?.map(i => ({ label: `${i.name} (${i.points}pts) (${i.cities && i.cities.length > 0 ? i.cities?.map(k => k.name).join(", ") : '-'})`, value: i?._id }))}
                                                placeholder="Select Activities"
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            labelAlign="left"
                                            labelCol={{ span: 24 }}
                                            label="Goal"
                                            extra="Number of times completed of any activities"
                                            name="goalTarget3"
                                            colon={false}
                                            rules={[
                                                { required: true, message: 'Please enter points!' },
                                                { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                            ]}
                                            hasFeedback
                                        >
                                            <Input placeholder='Goal target' />
                                        </Form.Item>
                                    </>
                                )}
                            </div>
                        </div>
                    </Panel>

                    <Panel header={<p className='font-bold text-lg'>Status</p>} Info key="4" className="bg-textbackgroundcolor">

                        <Form.Item
                            label="Community Status"
                            name="status"
                            labelAlign="left"
                            colon={false}
                        >
                            <Radio.Group className="text-left">
                                {/* <div className='grid grid-rows-3'> */}
                                <Radio value={'ONBOARDING'} className='mb-5' disabled={communityData?.activeDate || communityData?.endingDate || communityData?.endedDate} >Onboarding{communityData?.onboardingDate && <>(since {moment(communityData?.onboardingDate).format(BE_FULL_DATE_FORMAT)})</>}
                                    <div>
                                        <ul>
                                            <li>- All points, rewards earned by any members do not count toward Community Goals.</li>
                                            <li>- Members can join community via invite link and see community tab in mobile app.</li>
                                            <li>- No push notification when change to this state.</li>
                                        </ul>
                                    </div>
                                </Radio>
                                <Radio value={'ACTIVE'} className='mb-5' disabled={communityData?.endingDate || communityData?.endedDate} >Active{communityData?.activeDate && <>(since {moment(communityData?.activeDate).format(BE_FULL_DATE_FORMAT)})</>}
                                    <div>
                                        <ul>
                                            <li>- The community is in active mode, all users still seeing the community in their mobile app.</li>
                                            <li>- All points, rewards earned by any members do count toward Community Goals.</li>
                                            <li>- No push notification when change to this state.</li>
                                        </ul>
                                    </div>
                                </Radio>
                                <Radio value={'ENDING'} className='mb-5' disabled={communityData?.endedDate}>Ending {communityData?.endingDate && <>(since {moment(communityData?.endingDate).format(BE_FULL_DATE_FORMAT)})</>}
                                    <div>
                                        <ul>
                                            <li>- The community is in read-only mode, all users still seeing the community in their mobile app, BUT all points, rewards will not counting towards the community goals.</li>
                                            <li>- Push notification <strong>CLOSING_COMMUNITY_NOTIFICATION_ENDING</strong> sent to all members.</li>
                                        </ul>
                                    </div>
                                </Radio>
                                <Radio value={'ENDED'} disabled={communityData?.endedDate}>Ended {communityData?.endedDate && <>(since {moment(communityData?.endedDate).format(BE_FULL_DATE_FORMAT)})</>}
                                    <div><ul>
                                        <li>- The community is now ended. All members become individual users.</li>
                                        <li>- Push notification <strong>CLOSING_COMMUNITY_NOTIFICATION_ENDED</strong> sent to all members.
                                        </li>
                                    </ul>

                                    </div>
                                </Radio>
                                {/* </div> */}
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label="Community Feed Available"
                            name="communityFeedAvailable"
                            labelAlign="left"
                            colon={false}
                        >
                            <Radio.Group className="text-left">
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            label="Publish"
                            name="publish"
                            labelAlign="left"
                            colon={false}
                        >
                            <Radio.Group className="text-left">
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Panel>
                    <Panel header={<p className='font-bold text-lg py-0'>Participation KPIs</p>} key="5" className="bg-textbackgroundcolor" style={{ borderBottom: "none" }}>
                        <Form.Item
                            label="Total Number of Community Members"
                            labelCol={{
                                span: 18
                            }}
                            name="number_of_people_invited"
                            labelAlign="left"
                            colon={false}
                            hasFeedback
                            rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }]}
                            className="col-span-4"
                        >
                            <Input text={communityData?.participation_kpi?.number_of_people_invited} />
                        </Form.Item>
                        <Form.Item
                            label="Joined Community Members"
                            labelCol={{
                                span: 18
                            }}
                            name="signup_rate"
                            labelAlign="left"
                            colon={false}
                            hasFeedback
                            rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }]}
                            className="col-span-4"
                        >
                            <Input text={communityData?.participation_kpi?.signup_rate} />
                        </Form.Item>
                        <Form.Item
                            label="Participation Rate (Number of people who did at least 1 activity over campaign period)"
                            labelCol={{
                                span: 18
                            }}
                            name="participation_rate_1"
                            labelAlign="left"
                            colon={false}
                            hasFeedback
                            rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }]}
                            className="col-span-4"
                        >
                            <Input text={communityData?.participation_kpi?.participation_rate_1} />
                        </Form.Item>
                        <Form.Item
                            label="Active Participation Rate (Number of people who did at least 1 activity per week)"
                            labelCol={{
                                span: 18
                            }}
                            name="active_participation_rate_1"
                            labelAlign="left"
                            colon={false}
                            hasFeedback
                            rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }]}
                            className="col-span-4"
                        >
                            <Input text={communityData?.participation_kpi?.active_participation_rate_1} />
                        </Form.Item>
                        <Form.Item
                            label="Active Participation Rate (Number of people who did at least 2 activities per week)"
                            labelCol={{
                                span: 18
                            }}
                            name="active_participation_rate_2"
                            labelAlign="left"
                            colon={false}
                            hasFeedback
                            rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }]}
                            className="col-span-4"
                        >
                            <Input text={communityData?.participation_kpi?.active_participation_rate_2} />
                        </Form.Item>
                    </Panel>

                    <Panel header={<p className='font-bold text-lg py-0'>Activity KPIs</p>} key="6" className="bg-textbackgroundcolor" style={{ borderBottom: "none" }}>
                        <Form.Item
                            label="Activities completed per week"
                            labelCol={{
                                span: 18
                            }}
                            name="activities_completed_per_week"
                            labelAlign="left"
                            colon={false}
                            hasFeedback
                            rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }]}
                            className="col-span-4"
                        >
                            <Input text={communityData?.activity_kpi?.activities_completed_per_week} />
                        </Form.Item>
                        <Form.Item
                            label="Challenges completed per week"
                            labelCol={{
                                span: 18
                            }}
                            name="challenges_completed_per_week"
                            labelAlign="left"
                            colon={false}
                            hasFeedback
                            rules={[{ pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }]}
                            className="col-span-4"
                        >
                            <Input text={communityData?.activity_kpi?.challenges_completed_per_week} />
                        </Form.Item>
                    </Panel>
                </Collapse>

                <div className="text-center pt-2 pb-4">
                    <Button className="bg-[#207868] px-8" size="large" type="primary" htmlType="submit">
                        Save
                    </Button>
                </div>
            </Form>
            {/* <AddEditUsers
                visible={visible}
                setVisible={setVisible}
            /> */}
        </div>

    )
}
