import React, { useState, useEffect } from 'react'
import { SearchOutlined, EditOutlined, DeleteOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Input, Table, Tooltip, Popconfirm, Tag } from 'antd'
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllMessagings, deleteMessagingById } from '../../../store/messaging/actions'
import PreviewImage from '../../../components/image/PreviewImage'
import moment from 'moment'
import { BE_FULL_DATE_FORMAT, DEFAULT_PAGE_SIZE, showTotalRecords } from '../../../const/utils'

export const Messaging = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const messagings = useSelector(state => state.messages.messages)

    const [messagesData, setMessagesData] = useState(messagings)
    const [wordEntered, setWordEntered] = useState()

    useEffect(() => {
        dispatch(fetchAllMessagings())
    }, [])

    useEffect(() => {
        setMessagesData(messagings)
    }, [messagings])

    const handleSearch = (event) => {
        const searchWord = event?.target?.value
        setWordEntered(searchWord)
        const newFilter = messagings.filter((values) => {
            return values?.event?.toLowerCase()?.includes(searchWord.toLowerCase()) || values?.title?.toLowerCase()?.includes(searchWord?.toLowerCase()) || values?.body?.toString()?.toLowerCase()?.includes(searchWord?.toLowerCase())
        })

        if (searchWord === "") {
            setMessagesData(messagings)
        } else {
            setMessagesData(newFilter)
        }
    }

    const MessageColumns = [
        {
            title: 'No',
            dataIndex: 'index',
            key: 'index'
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Message',
            dataIndex: 'body',
            key: 'body'
        },
        {
            title: 'Content Type',
            dataIndex: 'push_action_cta',
            key: 'push_action_cta'
        },
        {
            title: 'Action',
            dataIndex: 'push_action',
            key: 'push_action'
        },
        {
            title: 'Target',
            dataIndex: 'push_target',
            key: 'push_target'
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt'
        }
    ]

    const deleteMessage = (id) => {
        dispatch(deleteMessagingById(id))
        return new Promise(resolve => {
            setTimeout(() => resolve(), 2000)
        })
    }

    return (
        <>
            <div className='bg-white shadow'>

                <PageHeader
                    ghost={false}
                    title="Messaging"
                    extra={[
                        <Button icon={<PlusOutlined />} key="btn" type="primary" className="bg-[#207868] flex items-center hover:bg-[#0A5043]" onClick={() => navigate('/messaging/add')} >
                            New Push Message
                        </Button>
                    ]}
                ></PageHeader>
                <Input
                    className='w-[220px] h-[40px] ml-4 mb-5'
                    placeholder='Search'
                    value={wordEntered}
                    onChange={handleSearch}
                    prefix={<SearchOutlined />}
                />
                {/* <div> */}
                {/* <Typography.Title level={5} className='ml-4'>Default Challenge</Typography.Title> */}
                {/* <Table
                        className='z-[0] relative overflow-auto'
                        columns={DefaultChallengeColums}
                        dataSource={messagesData.map(i => ({
                            key: i._id,
                            event: i.event,
                            image: <PreviewImage src={i.image} />,
                            title: i.title,
                            isDefault: i.isDefault ? "Yes" : "No",
                            body: i.body,
                            status: <Tag color={i.publish === true ? 'green' : 'red'}>{i.publish ? 'Published' : 'Not published'}</Tag>,
                            action:
                                <div style={{ minWidth: '65px' }}>
                                    <Tooltip title="Edit">
                                        <Button style={{ marginRight: "8px" }} onClick={() => navigate((`/messaging/edit/${i._id}`))} size="small" type="ghost" shape="circle" icon={<EditOutlined />} />
                                    </Tooltip>
                                    <Popconfirm
                                        title="Are you sure?"
                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        onConfirm={() => deleteMessage(i._id)}
                                        onCancel={() => null}
                                        okText="Yes"
                                        okType="danger"
                                        placement="topLeft"
                                        cancelText="Cancel"
                                    >
                                        <Button size="small" type="ghost" danger shape="circle" icon={<DeleteOutlined />} />
                                    </Popconfirm>
                                </div>
                        }))}
                    />
                </div> */}

                {/* New Messages table */}
                <div>
                    <Table
                        className='overflow-auto'
                        columns={MessageColumns}
                        // dataSource={[]}
                        dataSource={messagesData.map((i, index) => ({
                            key: i._id,
                            index: index + 1,
                            event: i.event,
                            image: <PreviewImage src={i.image} />,
                            title: i.title,
                            isDefault: i.isDefault ? "Yes" : "No",
                            body: i.body,
                            push_action: i.push_action,
                            push_target: i.push_target,
                            push_action_cta: i.push_action_cta,
                            status: <Tag color={i.publish === true ? 'green' : 'red'}>{i.publish ? 'Published' : 'Not published'}</Tag>,
                            createdAt: moment(i.createdAt).format(BE_FULL_DATE_FORMAT),
                            action:
                                <div style={{ minWidth: '65px' }}>
                                    <Tooltip title="Edit">
                                        <Button style={{ marginRight: "8px" }} onClick={() => navigate((`/messaging/edit/${i._id}`))} size="small" type="ghost" shape="circle" icon={<EditOutlined />} />
                                    </Tooltip>
                                    <Popconfirm
                                        title="Are you sure?"
                                        icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                        onConfirm={() => deleteMessage(i._id)}
                                        onCancel={() => null}
                                        okText="Yes"
                                        okType="danger"
                                        placement="topLeft"
                                        cancelText="Cancel"
                                    >
                                        <Button size="small" type="ghost" danger shape="circle" icon={<DeleteOutlined />} />
                                    </Popconfirm>
                                </div>
                        }))}
                        // pagination={{
                        //     pageSize: 100,
                        //     showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                        //     defaultPageSize: DEFAULT_PAGE_SIZE
                        // }}
                        pagination={{
                            defaultPageSize: DEFAULT_PAGE_SIZE,
                            showSizeChanger: true,
                            hideOnSinglePage: true,
                            position: ["none", "bottomRight"],
                            showTotal: showTotalRecords
                        }}
                    />
                </div>

            </div>
        </>
    )
}