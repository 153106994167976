import { DASHBOARD_REPORT, POWERBI_DASHBOARD_REPORT } from "./types"

const initialState = {
    dashboard: {},
    powerbiDashboardReport: []
}

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case DASHBOARD_REPORT:
            return { ...state, dashboard: action.payload }
        case POWERBI_DASHBOARD_REPORT:
            return { ...state, powerbiDashboardReport: action.payload }
        default:
            return state
    }
}

export default dashboardReducer