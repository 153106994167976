import React, { useState } from 'react'
import { Button, Form, Input, Upload, message, Radio, Collapse, Select } from 'antd'
import { UploadOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { CgArrowsV } from 'react-icons/cg'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { createGoal } from '../../../store/goal/actions'
import ServerApi from '../../../utility/ServerApi'
import { SDGS } from '../../../const/sdgs.const'

import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw } from "draft-js"
// import draftToMarkdown from 'draftjs-to-markdown'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import createToolbarPlugin from '@draft-js-plugins/static-toolbar'
import '../../../assets/css/editorStyles.css'
import { isSupportedFileFormatForGeneric, SUPPORTED_FILE_FORMAT_EXTRA } from '../../../const/utils'

const { Panel } = Collapse

const AddEditGoals = () => {

  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const successCallback = () => {
    navigate('/goals')
  }

  const { TextArea } = Input

  const [fileList, setFileList] = useState([])
  const [repeaterFileList, setRepeaterFileList] = useState({})
  const [redemptionFileList, setRedemptionFileList] = useState([])
  const [redemptionContent, setRedemptionContent] = useState()

  const staticToolbarPlugin = createToolbarPlugin()
  const plugins = [staticToolbarPlugin]

  const createNewGoal = (values) => {
    values.redemption_content = redemptionContent
    if (fileList[0]) values.icon = fileList[0].icon
    values.redemption_image = redemptionFileList[0]?.icon || ''
    values.habits = values.habits.map((habit, index) => ({ ...habit, icon: repeaterFileList[index] ? repeaterFileList[index][0] === undefined ? '' : repeaterFileList[index][0]?.icon : '' }))
    // values.habits.map((habit, index) => console.log(habit, index))
    dispatch(createGoal(values, successCallback))
  }

  const handleChange = async ({ file }) => {
    if (!(file.size / 1024 / 1024 < 5)) {
      message.error('file size exceeded')
      return
    }
    if (!(file.type === 'image/jpeg' || file.type === 'image/png')) {
      message.error('You can only upload JPG/PNG file!')
      return
    }
    setFileList(
      [{ uid: file.uid, name: '', status: 'uploading' }]
    )
    const formData = new FormData()
    formData.append('file', file)
    formData.append('folder', 'activities')

    ServerApi().post('media/admin-create-media', formData)
      .then(res => {
        setFileList([
          {
            uid: file.uid,
            name: res.data.name,
            icon: res.data.file,
            status: 'done',
            url: `${res.data.path}`,
            thumbUrl: `${res.data.path}`
          }
        ])
        return true
      })
      .catch(e => { console.log(e); return false })
  }

  const handleRedmptionChange = async ({ file }) => {
    if (!(file.size / 1024 / 1024 < 50)) {
      message.error('file size exceeded')
      return
    }
    if (!(file.type === 'image/jpeg' || file.type === 'image/png')) {
      message.error('You can only upload JPG/PNG file!')
      return
    }
    setRedemptionFileList(
      [{ uid: file.uid, name: '', status: 'uploading' }]
    )
    const formData = new FormData()
    formData.append('file', file)
    formData.append('folder', 'activities')

    ServerApi().post('media/admin-create-media', formData)
      .then(res => {
        setRedemptionFileList([
          {
            uid: file.uid,
            name: res.data.name,
            icon: res.data.file,
            status: 'done',
            url: `${res.data.path}`,
            thumbUrl: `${res.data.path}`
          }
        ])
        return true
      })
      .catch(e => { console.log(e); return false })
  }

  const handleMultipleUploads = ({ file }, index) => {
    // if (!(file.size / 1024 / 1024 < 50)) {
    //   message.error('file size exceeded')
    //   return
    // }
    if (!isSupportedFileFormatForGeneric(file.type)) {
      message.error('You can only upload JPG/PNG file!')
      return
    }
    setRepeaterFileList(
      { ...repeaterFileList, [index]: [{ uid: file.uid, name: '', status: 'uploading' }] }
    )
    const formData = new FormData()
    formData.append('file', file)
    formData.append('folder', 'activities')

    ServerApi().post('media/admin-create-media', formData)
      .then(res => {
        setRepeaterFileList(
          {
            ...repeaterFileList,
            [index]: [
              {
                uid: file.uid,
                name: res.data.name,
                icon: res.data.file,
                status: 'done',
                url: `${res.data.path}`,
                thumbUrl: `${res.data.path}`
              }
            ]
          }
        )
        return true
      })
      .catch(e => { console.log(e); return false })
  }

  const props = {
    customRequest: handleChange,
    listType: 'picture',
    onRemove: () => setFileList([]),
    fileList
  }

  const redemptionProps = {
    customRequest: handleRedmptionChange,
    listType: 'picture',
    onRemove: () => setRedemptionFileList([]),
    fileList
  }

  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
  }

  return (
    <>
      <div className='container mx-auto bg-white'>
        <Form
          name='NewGoal'
          form={form}
          initialValues={{ publish: false, colorCode: "RED" }}
          onFinish={(values) => createNewGoal(values)}
          labelCol={{ span: 4 }}
          requiredMark='sdlfjldfjs'
        >
          <Collapse className="border-none" expandIconPosition={'end'} defaultActiveKey={['1', '2', '3']}>
            <Panel header={<p className='font-bold text-lg'>Goal Info</p>} key="1" className="bg-textbackgroundcolor">
              <Form.Item
                label='Goal Name'
                name='name'
                labelAlign='left'
                colon={false}
                rules={[{ required: true, message: 'Please enter your goal name!' }]}
                hasFeedback
              >
                <Input className='border-bordercolor rounded flex items-center' />
              </Form.Item>
              <Form.Item
                label='Description'
                labelAlign="left"
                name='description'
                colon={false}
                rules={[{ required: true, message: 'Please enter Goal Description!' }]}
              >
                <TextArea className='border-bordercolor rounded' rows={4} />
              </Form.Item>

              <Form.Item
                labelAlign="left"
                colon={false}
                label='Goal Icon'
              >
                <div className='flex justify-end'>
                  <Upload className='flex space-x-4' {...props} fileList={fileList}>
                    <Button className='flex items-align' icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </div>
              </Form.Item>

              <Form.Item
                name='colorCode'
                label='Theme'
                labelAlign='left'
                colon={false}
                labelCol={{
                  span: 4
                }}
                rules={[{ required: true, message: 'Color Code is not selected!' }]}
              >
                <Radio.Group>
                  <Radio value={'RED'}>Red</Radio>
                  <Radio value={'BLUE'}>Blue</Radio>
                  <Radio value={'GREEN'}>Green</Radio>
                </Radio.Group>
              </Form.Item>
            </Panel>

            <Panel header={<p className='font-bold text-lg'>Redemption</p>} key="2" className="bg-textbackgroundcolor">
              <Form.Item
                label='Item Name'
                name='redemption_name'
                labelAlign="left"
                colon={false}
                rules={[{ required: true, message: 'Please enter Item Name!' }]}
              >
                <Input className='border-bordercolor rounded' />
              </Form.Item>
              <Form.Item
                label='Redeem Cost'
                name='redemption_cost'
                labelAlign="left"
                colon={false}
                rules={[
                  { required: true, message: 'Please enter Redeem Cost!' },
                  { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                ]}
              >
                <Input
                  placeholder='Point'
                  className='border-bordercolor rounded'
                  maxLength={25}
                />
              </Form.Item>

              <Form.Item labelAlign="left" label='Image' colon={false}>
                <div className='flex justify-end'>
                  <Upload className='flex space-x-4' {...redemptionProps} fileList={redemptionFileList}>
                    <Button className='flex items-align' icon={<UploadOutlined />}>Upload</Button>
                  </Upload>
                </div>
              </Form.Item>

              <Form.Item
                label='Reward Content'
                name='redemption_content'
                labelAlign="left"
                colon={false}
                rules={[{ required: true, message: 'Please enter Reward Content!' }]}
              >
                {/* <TextEditor value={value} /> */}
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"

                  onEditorStateChange={onEditorStateChange}
                  plugins={plugins}
                  onChange={() => {
                    const tempData = (editorState && draftToHtml(convertToRaw(editorState.getCurrentContent())))
                    setRedemptionContent(tempData)
                  }

                  }
                />
              </Form.Item>

            </Panel>

            <Panel header={<p className='font-bold text-lg'>Habits</p>} key="3" className="bg-textbackgroundcolor" style={{ borderBottom: "none" }}>

              <Form.List name="habits"
                rules={[
                  {
                    validator: async (_, names) => {
                      if (!names || names.length < 1) {
                        return Promise.reject(new Error('At least 1 Habit is required'))
                      }
                    }
                  }
                ]}
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, idx) => (
                      <div key={key} align="baseline" className={`grid grid-cols-10 gap-4 items-end ${idx % 2 == 0 ? 'row-odd' : 'row-even'}`}>

                        <Form.Item
                          {...restField}
                          label={name === 0 ? 'Name' : ''}
                          name={[name, 'name']}
                          rules={[{ required: true, message: 'Missing name' }]}
                          className="col-span-2"
                          labelCol={{ span: 24 }}
                        >
                          <Input placeholder="Name" />
                        </Form.Item>
                        <Form.Item
                          label={name === 0 ? 'Icon' : ''}
                          name={[name, 'icon']}
                          className="col-span-2"
                          extra={SUPPORTED_FILE_FORMAT_EXTRA.HABITS_ICON}
                          labelCol={{ span: 24 }}
                        >
                          <Upload customRequest={(obj) => {
                            return handleMultipleUploads(obj, name)
                          }} fileList={repeaterFileList[name]} listType="picture" onRemove={() => {
                            setRepeaterFileList({ ...repeaterFileList, [name]: [] })
                          }}>
                            <Button icon={<UploadOutlined />}>Upload</Button>
                          </Upload>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          label={name === 0 ? 'Description' : ''}
                          name={[name, 'description']}
                          rules={[{ required: true, message: 'Missing discription' }]}
                          className="col-span-2"
                          labelCol={{ span: 24 }}
                        >
                          <Input placeholder="Description" />
                        </Form.Item>
                        <Form.Item
                          label={name === 0 ? 'SDGs' : ""}
                          {...restField}
                          name={[name, 'sids']}
                          rules={[{ required: true, message: 'Select SDGs' }]}
                          className="col-span-2"
                          labelCol={{ span: 24 }}
                        >
                          <Select
                            mode='multiple'
                            showSearch
                            placeholder="Multiple"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                            style={{ width: "100%" }}
                            suffixIcon={<CgArrowsV />}
                          >
                            {SDGS.map(i => (
                              <Select.Option value={i.value}>{i.label}</Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item>
                          <MinusCircleOutlined className='ml-3' onClick={() => remove(name)} />
                        </Form.Item>
                      </div>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                        Add Habits
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <Form.Item name="publish" colon={false} labelAlign="left" label="Publish"
                rules={[{ required: true, message: 'Publish is not selected!' }]}
              >
                <Radio.Group className="text-left">
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Panel>
          </Collapse>
          <div className="text-center pt-2 pb-4">
            <Button className="bg-[#207868]" size="large" type="primary" htmlType="submit">
              Save Changes
            </Button>
          </div>
        </Form>
      </div>
    </>
  )
}

export default AddEditGoals