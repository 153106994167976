import React, { useState, useEffect } from 'react'
import { Collapse, Button, Form, Input, Upload, Breadcrumb, AutoComplete, Typography, Table, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { createNewDeviceBlacklisted } from '../../../store/deviceblacklisted/actions'

const AddNewDeviceModal = (props) => {

    // const [form] = Form.useForm()
    const [modalForm] = Form.useForm()
    // const navigate = useNavigate()
    const dispatch = useDispatch()

    // const [userExist, setUserExist] = useState(false)
    const [formSubmitState, setFormSubmitState] = useState(false)

    // const searchUserCallback = (data) => {
    //     if (data && data[0]) {
    //         setUserExist(true)
    //         modalForm.setFieldsValue({
    //             name: data[0]['name'] || 'N/A'
    //         })
    //     } else {
    //         setUserExist(false)
    //     }
    // }

    const resetModal = () => {
        setFormSubmitState(false)
        modalForm.setFieldsValue({ name: null })
        props.refresh()
        props.setVisible(false)
    }

    const handleNewDomainSubmit = (values) => {
        dispatch(createNewDeviceBlacklisted({ deviceName: values.name }, resetModal))
        // resetModal()
    }

    return (
        <Modal
            open={props.visible}
            onCancel={() => resetModal()}
            footer={""}
        >
            <div className='text-center mb-6'>
                <Typography className='text-xl font-bold'>Add New Device Blacklisted</Typography>
                <Typography>Device name that is in blacklisted will not render animation for Hero 3D World.</Typography>
            </div>
            <Form
                form={modalForm}
                name='Add_New_Device'
                labelCol={{ span: 4 }}
                onFinish={(values) => handleNewDomainSubmit(values)}
                autoComplete='off'
            >
                <Form.Item
                    labelAlign='left'
                    label='Device'
                    name='name'
                >
                    <Input maxLength={100} />
                </Form.Item>

                <div className='flex justify-center'>
                    <Button className="bg-[#207868] px-6" type="primary" htmlType="submit">
                        Create
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}

export default AddNewDeviceModal