import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import './assets/css/app.css'
const moment = require('moment-timezone')
console.log = () => { }
console.info = () => { }
console.warn = () => { }
console.error = () => { }
moment.tz.setDefault("Asia/Singapore")

//https://sentry.io/gkim-digital/susgain-dashboard/getting-started/javascript-react/
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.REACT_APP_SENTRY_ENV
})

const root = ReactDOM.createRoot(document.getElementById('root'))

//BUG: <React.StrictMode> makes useEffect trigger twice 
//https://github.com/facebook/react/issues/24502

root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
