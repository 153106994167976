export const DomainsActionsTableColumns = [
  {
    title: 'No',
    dataIndex: 'srno',
    key: 'srno',
    notAllowToSearch: true
  },
  {
    title: 'Domain Name',
    dataIndex: 'domainName',
    key: 'domainName'
  },

  {
    title: 'Created Date',
    dataIndex: 'createdDate',
    key: 'createdDate',
    notAllowToSearch: true
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    notAllowToSearch: true
  }
]