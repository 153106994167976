import { FETCH_ALL_DOMAIN_WHITELISTED } from "./types"
import { toggleNetworkLoading } from "../common/actions"
import { message } from 'antd'
import ServerApi from "../../utility/ServerApi"

export const fetchAllDomains = () => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`domain-whitelisted/admin-fetch-all-domains`)
        .then(res => {
            dispatch({
                type: FETCH_ALL_DOMAIN_WHITELISTED,
                payload: res.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fetching Locations", e)
            console.log(e)
        })
}

export const createNewDomain = (rawData, successCallback) => dispatch => {
    ServerApi().post(`/domain-whitelisted/admin-create-domain`, rawData)
        .then(res => {
            if (res.status === 200 || res.status === 201) {
                message.success("New Domain Created")
                successCallback(res.data)
            } else {
                message.error("Error Creating Domain!")
            }
            successCallback()
        })
        .catch(e => {
            message.error("Error Creating New Domain", e)
            console.log(e)
        })
}


export const deleteDomainById = (id, successCallback) => dispatch => {
    ServerApi().delete(`domain-whitelisted/admin-delete-domain/${id}`)
        .then(res => {
            message.success("Domain Deleted Succesfully!")
            successCallback(res.data)
        })
        .catch(e => {
            message.error("Error Deleting Domain")
        })
}