import { FETCH_ALL_USERS, FETCH_USER_BY_ID, SEARCH_USER_BY_NAME, FETCH_MY_ACTIVITIES, FETCH_MY_CHALLENGES, FETCH_DELETED_USERS, FETCH_LAST_HERO_PROGRESS_USER_BY_ID, FETCH_USER_MY_CHALLENGES_BY_ID, FETCH_ALL_USERS_FOR_MESSAGING, FETCH_USER_MY_QUESTS_BY_ID } from "./types"
import { toggleNetworkLoading } from "../common/actions"
import { message } from 'antd'
import ServerApi from "../../utility/ServerApi"
import { PAGINATION } from "../../const/utils"
import ServerApiCSV from "../../utility/ServerApiCSV"
import moment from 'moment'
/**
 * 
 * @param {*} filters 
 * @param {*} callback 
 * @returns 
 */
export const fetchDeletedUsers = (filters = PAGINATION, callback = null) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(
        `/user/admin-fetch-deleted-users?current=${filters?.current || 1}
        &pageSize=${filters?.pageSize || 100}
        ${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}
        ${filters?.search ? `&search=${encodeURIComponent(filters?.search)}` : ''}
        ${filters?.email ? `&email=${encodeURIComponent(filters?.email)}` : ''}
        ${typeof filters?.verified === 'boolean' ? `&verified=${filters?.verified}` : ''}
        `)
        .then(res => {
            dispatch({
                type: FETCH_DELETED_USERS,
                payload: res.data
            })
            if (callback) callback(res.data)
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fecthing Goals Details", e)
            console.log(e)
        })
}


/**
 * #43769 - Mobile Users - Export selected and Export All is missing
 * @returns 
 */
export const mobileUsersExportCsvAll = (_selected_rows = []) => dispatch => {
    // dispatch(toggleNetworkLoading(true))
    ServerApiCSV().post(`/user/admin-mobile-users-export-csv-all`, { selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `mobile-users_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("Error Fetching data!")
            // dispatch(toggleNetworkLoading(false))
            console.error(e)
        })
}

/**
 * #43769 - Mobile Users - Export selected and Export All is missing
 * @param {*} _selected_rows 
 * @returns 
 */
export const mobileUsersDeletedExportCsvAll = (_selected_rows = []) => dispatch => {
    // dispatch(toggleNetworkLoading(true))
    ServerApiCSV().post(`/user/admin-mobile-users-deleted-export-csv-all`, { selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `mobile-users-deleted_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("Error Fetching data!")
            // dispatch(toggleNetworkLoading(false))
            console.error(e)
        })
}

/**
 * 
 * @param {*} filters 
 * @param {*} callback 
 * @returns 
 */
export const fetchAllUsers = (filters = PAGINATION, callback = null) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(
        `/user/admin-fetch-mobile-users?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.search ? `&search=${encodeURIComponent(filters?.search)}` : ''}${filters?.email ? `&email=${encodeURIComponent(filters?.email)}` : ''}${typeof filters?.verified === 'boolean' ? `&verified=${filters?.verified}` : ''}
        `)
        .then(res => {
            dispatch({
                type: FETCH_ALL_USERS,
                payload: res.data
            })
            if (callback) callback(res.data)
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fecthing Goals Details", e)
            console.log(e)
        })
}

export const adminFetchAllMobileUsersForSendingMessaging = (filters = PAGINATION, callback = null) => dispatch => {
    // dispatch(toggleNetworkLoading(true))
    ServerApi().get(
        `/user/admin-fetch-mobile-users-for-messaging?current=${filters?.current || 1}
        &pageSize=${filters?.pageSize || 100}
        ${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}
        ${filters?.search ? `&search=${encodeURIComponent(filters?.search)}` : ''}
        ${filters?.email ? `&email=${encodeURIComponent(filters?.email)}` : ''}
        ${typeof filters?.verified === 'boolean' ? `&verified=${filters?.verified}` : ''}
        `)
        .then(res => {
            dispatch({
                type: FETCH_ALL_USERS_FOR_MESSAGING,
                payload: res.data
            })
            if (callback) callback(res.data)
            // dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            // dispatch(toggleNetworkLoading(false))
            message.error("Error in Fecthing Goals Details", e)
            console.log(e)
        })
}

/**
 * #43623 - User details dashboards not yet set up
 * @param {*} id 
 * @returns 
 */
export const adminFetchUserDetailById = (id) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/user/admin-fetch-user-detail/${id}`)
        .then(res => {
            const data = res.data
            dispatch({
                type: FETCH_USER_BY_ID,
                payload: data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            message.error("Error fetching data!")
            dispatch(toggleNetworkLoading(false))
            // console.log(e)
        })
}

export const SearchUser = (query, callback = null) => dispatch => {
    ServerApi().get(`/user/search?q=${encodeURIComponent(query)}`)
        .then(res => {
            dispatch({
                type: SEARCH_USER_BY_NAME,
                payload: res.data
            })
            if (callback) callback(res.data)
        })
        .catch(e => {
            message.error("Error fetching data!")
            console.log(e)
        })
}

export const createUser = (rawData, successCallback) => dispatch => {
    ServerApi().post(`/auth/admin-create-user`, rawData)
        .then(res => {
            if (res.status === 200 || res.status === 201) {
                message.success("New User Created")
                successCallback(res.data)
            } else {
                message.error("Error Creating User")
            }
            successCallback()
        })
        .catch(e => {
            message.error(e.response.data.message || "Error Creating User!")
            console.log(e)
        })
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
export const fetchLastHeroProgress = (id) => dispatch => {
    ServerApi().get(`/hero-progress/last-current-progress/${id}`)
        .then(res => {
            dispatch({
                type: FETCH_LAST_HERO_PROGRESS_USER_BY_ID,
                payload: res.data
            })
            // dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            message.error(e?.response?.data?.message)
        })
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
export const fetchUserMyChallenges = (id) => dispatch => {
    ServerApi().get(`/challenge-instances/admin-view-user-my-challenges/${id}`)
        .then(res => {
            dispatch({
                type: FETCH_USER_MY_CHALLENGES_BY_ID,
                payload: res.data
            })
            // dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            message.error(e?.response?.data?.message)
        })
}

/**
 * 
 * @param {*} id 
 * @returns 
 */
export const fetchUserMyQuests = (id) => dispatch => {
    ServerApi().get(`/quest-instances/admin-view-user-my-quests/${id}`)
        .then(res => {
            dispatch({
                type: FETCH_USER_MY_QUESTS_BY_ID,
                payload: res.data
            })
            // dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            message.error(e?.response?.data?.message)
        })
}
/**
 * #45328 - Under User >User Details: Ability to download the "My Challenges" record.
 * @param {*} filters 
 * @param {*} _selected_rows 
 * @returns 
 */
export const userChallengeTransactionsExportCsvAll = (filters, _selected_rows = []) => dispatch => {
    // dispatch(toggleNetworkLoading(true))
    ServerApiCSV().post(`/challenge-instances/admin-export-all-user-my-challenges?${filters?.userId ? `&userId=${filters?.userId}` : ''}`, { selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `user-my-challenges_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("Error Fetching data!")
            // dispatch(toggleNetworkLoading(false))
            console.error(e)
        })
}

export const deleteUser = (id) => dispatch => {
    ServerApi().delete(`user/${id}`)
        .then(res => {
            message.success("User Deleted Successfully!")
            dispatch(fetchAllUsers())
        })
        .catch(e => {
            message.error(e?.response?.data?.message || "Unable to Delete User")
        })
}

export const inviteMember = (rawData) => dispatch => {
    ServerApi().post(`/user/invite-member`, rawData)
        .then(res => {
            if (res.status === 200 || res.status === 201) {
                message.success("Member invited")
            } else {
                message.error("Unable to invite member")
            }
        })
        .catch(e => {
            message.error(e?.response?.data?.message || "Unable to invite member!")
            // console.error(e.response)
        })
}

/**
 * 
 * @param {*} rawData 
 * @param {*} callback 
 * @returns 
 */
export const massInviteMembersViaCSVImport = (rawData, callback) => dispatch => {
    ServerApi().post(`/user/mass-invite-members`, rawData)
        .then(res => {
            if (res.status === 200 || res.status === 201) {
                message.success(res.message || "Members invited")
            } else {
                message.error("Unable to invite member")
            }
            if (callback) {
                callback()
            }
        })
        .catch(e => {
            message.error(e?.response?.data?.message || "Unable to invite member!")
            console.error(e)
        })
}

export const fetchMyActivity = (PAGINATION) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`activities-instances/my-activity`)
        .then(res => {
            dispatch({
                type: FETCH_MY_ACTIVITIES,
                payload: res.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fecthing my activities", e)
            console.log(e)
        })
}

export const fetchMyChallenges = (PAGINATION) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`challenge-instances/my-challenges`)
        .then(res => {
            dispatch({
                type: FETCH_MY_CHALLENGES,
                payload: res.data
            })
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fetching my Challenges", e)
            console.log(e)
        })
}