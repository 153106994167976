import { FETCH_ALL_ILIGHT, FETCH_ILIGHT_DETAIL } from "./types"

const initialState = {
    iLight: [],
    iLightDetail: {}
}

const ILightReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_ILIGHT:
            return { ...state, iLight: action.payload }
        case FETCH_ILIGHT_DETAIL:
            return { ...state, iLightDetail: action.payload}
        default:
            return state
    }
}

export default ILightReducer