import { FETCH_COMMUNITY_SCORING_TRANSACTIONS } from "./types"
import { toggleNetworkLoading } from "../../common/actions"
import { message } from 'antd'
import ServerApi from "../../../utility/ServerApi"
import { PAGINATION } from "../../../const/utils"
import ServerApiCSV from "../../../utility/ServerApiCSV"
import moment from 'moment'

/**
 * #45721 - Reward Transaction - add option to allow the admin reject the transaction
 * @param {*} _selected_rows 
 * @param {*} callback 
 * @returns 
 */
export const handleRejectThisScoringTransaction = (_selected_rows, callback) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().post(`/community-scoring/reject-this-scoring-transaction`, { selected_rows: _selected_rows })
        .then(res => {
            message.success("Transaction rejected!")
            if (callback) callback()
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            console.error(e)
            if (callback) callback()
        })
}


/**
 * #44868 - 5 May - URGENT - UBS Challenge duplicated rewards
 * @param {*} filters 
 * @param {*} callback 
 * @returns 
 */
export const fetchCommunityScoringTransactions = (filters = PAGINATION, callback = null) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/community-scoring/mycommunity-scoring-transactions?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}`)
        .then(res => {
            dispatch({
                type: FETCH_COMMUNITY_SCORING_TRANSACTIONS,
                payload: res.data
            })
            if (callback) callback(res.data)
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in fetch community scoring transactions", e)
        })
}


/**
 * 
 * @param {*} _selected_rows 
 * @returns 
 */
export const exportCommunityScoringTransactionsAsCSV = (filters, _selected_rows) => dispatch => {
    ServerApiCSV().post(`/community-scoring/mycommunity-scoring-transactions-export-csv-all?${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}`, { ...filters, selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `mycommunity-scoring-transactions_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("No data found!")
            // dispatch(toggleNetworkLoading(false))
            console.error(e)
        })
}