import React, { useEffect, useState } from 'react'

import { Button, Table, Space, Image, message, Tag, Descriptions, Modal, Typography, Input } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
// import { fetchUserById } from '../../../store/users/action'
import { approveOrRejectTransaction, fetchShareWithCommunityTransactionById } from '../../../store/transactions/actions'
// import QRCode from "react-qr-code"
import { BE_FULL_DATE_FORMAT } from '../../../const/utils'
import { ACTIVITY_VALIDATION_METHOD } from '../Activity/Activity.const'
import PreviewImage from '../../../components/image/PreviewImage'
import { ACTIVITY_VALIDATION_METHOD_STATUS, RUNNING_TRANSACTION_TYPE, transformTransactionIdFormat, transformUserIdFormat } from './Transactions.const'
import CopyToClipboard from 'react-copy-to-clipboard'

/**
 * #45852 - Dashboard UX: Share with Community approval process
 * @returns 
 */
export const ShareWithCommunityTransactionInfo = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { transactionId } = useParams()
    const [rejectReason, setRejectReason] = useState('')
    const [newTransactionData, setNewTransactionData] = useState(null)

    const transaction = useSelector(state => state.transactions.shareWithCommunityTransaction)
    const [tempTransactionId, setTempTransactionId] = useState('')

    const isEmpty = (obj) => {
        return Object.keys(obj).length === 0
    }

    useEffect(() => {
        dispatch(fetchShareWithCommunityTransactionById(transactionId))
    }, [location])

    const [modalVisible, setModalVisible] = useState(false)
    
    /**
     * if pending, should go back pending, else go back to transaction
     */
    const _goBack = () => {
        if (transaction?.status === ACTIVITY_VALIDATION_METHOD_STATUS.PENDING.value) {
            navigate('/pending-share-with-community-transactions')
        } else {
            navigate('/share-with-community-transactions')
        }
    }

    const handleModal = () => {
        setModalVisible(true)
    }

    const _successCallback = (res) => {
        // if (res?.data?.nextTransaction && !isEmpty(res.data.nextTransaction)) {
        if (transaction?.nextTransaction && !isEmpty(transaction?.nextTransaction)) {
            // setNewTransactionData(res?.data?.nextTransaction)
            navigate(`/share-with-community-transactions/info/${transaction?.nextTransaction?._id}`)
        } else {
            _goBack()
        }
    }

    // useEffect(() => {
    //     if (newTransactionData) {
    //         const newTransactionId = newTransactionData?._id
    //         // window.history.replaceState(null, null, `/share-with-community-transactions/info/${newTransactionId}`)
    //         navigate(`/share-with-community-transactions/info/${newTransactionId}`)
    //         dispatch(fetchShareWithCommunityTransactionById(newTransactionId))
    //     }
    // }, [newTransactionData])

    const _handleRejectTransaction = () => {
        setModalVisible(false)
        const _params = {}
        _params['transactionId'] = newTransactionData?._id ? newTransactionData?._id : transactionId
        _params['status'] = ACTIVITY_VALIDATION_METHOD_STATUS.DENIED.value
        _params['remarks'] = rejectReason
        // console.log(`approveOrRejectTransaction `, _params)
        dispatch(approveOrRejectTransaction(_params, _successCallback))
    }

    /**
     * 
     */
    const _handleApprovedTransaction = () => {
        // setModalVisible(false)
        setModalVisible(false)
        const _params = {}
        _params['transactionId'] = newTransactionData?._id ? newTransactionData?._id : transactionId
        _params['status'] = ACTIVITY_VALIDATION_METHOD_STATUS.COMPLETED.value
        _params['remarks'] = 'Approved by Admin'
        // console.log(`approveOrRejectTransaction `, _params)
        dispatch(approveOrRejectTransaction(_params, _successCallback))
    }

    const _renderDataSubmitted = () => {
        if (transaction?.activityId?.validationMethod === ACTIVITY_VALIDATION_METHOD.PHOTO_UPLOAD.value) {
            return <>
                <p className='flex'>
                    <PreviewImage src={transaction?.verifyData?.data} />
                </p>
            </>
        } else if (transaction?.activityId?.validationMethod === ACTIVITY_VALIDATION_METHOD.SHARE_WITH_COMMUNITY.value) {
            const imageList = transaction?.verifyData?.data.split(',')
            return imageList.map((item) => {
                return <>
                    <p className='flex'>
                        <PreviewImage src={item} />
                    </p>
                </>
            })
        } else {
            return <>
                <pre className='flex'>{transaction?.verifyData?.data}</pre>
            </>
        }
    }

    const _transformValidationMethod = (_method) => {
        try {
            return Object.values(ACTIVITY_VALIDATION_METHOD).filter(i => i.value == _method)[0].label
        } catch (e) {

        }
        return _method
    }

    // const fetchTransaction = (currentTransactionId = '') => {
    //     if (currentTransactionId) {
    //         // window.history.replaceState(null, null, `/share-with-community-transactions/info/${currentTransactionId}`)
    //         navigate(`/share-with-community-transactions/info/${currentTransactionId}`)
    //         dispatch(fetchShareWithCommunityTransactionById(currentTransactionId))
    //     }
    // }

    useEffect(() => {
        if (transaction) {
            setTempTransactionId(transaction?._id)
        }
    }, [transaction])


    const _renderPrevious = () => {
        if (transaction?.status === ACTIVITY_VALIDATION_METHOD_STATUS.PENDING.value) {
            let isDisable = true
            if (transaction?.previousTransaction && !isEmpty(transaction?.previousTransaction)) {
                isDisable = false
            }
            return <>
                {/* <Button type='link' onClick={() => fetchTransaction(transaction?.previousTransaction?._id)} disabled={isDisable}>
                    &#60; Previous
                </Button> */}
                <Button type='link'
                    href={`/share-with-community-transactions/info/${transaction?.previousTransaction?._id}`}
                    disabled={isDisable}>
                    &#60; Previous
                </Button>
            </>
        }

    }

    const _renderNext = () => {
        if (transaction?.status === ACTIVITY_VALIDATION_METHOD_STATUS.PENDING.value) {
            let isDisable = true
            if (transaction?.nextTransaction && !isEmpty(transaction?.nextTransaction)) {
                isDisable = false
            }
            return <>
                {/* <Button type='link' onClick={() => fetchTransaction(transaction?.nextTransaction?._id)} disabled={isDisable}>
                    Next &#62;
                </Button> */}
                <Button type='link'
                    href={`/share-with-community-transactions/info/${transaction?.nextTransaction?._id}`}
                    disabled={isDisable}>
                    Next &#62;
                </Button>
            </>
        }
    }

    const _renderTransactionCTA = () => {
        if (transaction?.userId?.status == 'DELETED') {
            return <></>
        }
        if (transaction?.status === ACTIVITY_VALIDATION_METHOD_STATUS.PENDING.value) {
            return <>
                <Button className='mx-2 text-black font-semibold' type='primary' danger onClick={handleModal}>
                    Reject
                </Button>
                <Button className='mx-2 bg-[#207868] text-white font-semibold' onClick={_handleApprovedTransaction}>
                    Approve
                </Button>
            </>
        } else {
            return <></>
        }
    }

    return (
        <div className='bg-white p-5'>
            <Descriptions
                title='Transaction Info'
                column={1}
                labelStyle={{
                    width: '20%'
                }}
            >
                <Descriptions.Item label='Transaction ID'>
                    <CopyToClipboard text={transformTransactionIdFormat(transaction, RUNNING_TRANSACTION_TYPE.TRANSACTION.value)}>
                        <p className='cursor-pointer text-blue-400 hover:text-blue-800 hover:underline' onClick={() => message.success('Copied to Clipboard')}>{transformTransactionIdFormat(transaction, RUNNING_TRANSACTION_TYPE.TRANSACTION.value)}</p>
                    </CopyToClipboard>
                </Descriptions.Item>
                <Descriptions.Item label='Submitted Date'>
                    {transaction?.createdAt && (moment(transaction?.createdAt).format(BE_FULL_DATE_FORMAT))}
                </Descriptions.Item>                
                <Descriptions.Item label='Community'>
                    {transaction?.communityId ? `${transaction?.communityId?.name} (${transaction?.communityId?._id})` : 'n/a'}
                </Descriptions.Item>
                <Descriptions.Item label='Team'>
                    {transaction?.teamId ? `${transaction?.teamId?.name} (${transaction?.teamId?._id})` : 'n/a'}
                </Descriptions.Item>
                <Descriptions.Item label='Challenge'>
                    {transaction?.challengeId ? `${transaction?.challengeId?.name} (${transaction?.challengeId?._id})` : 'n/a'}
                </Descriptions.Item>
                <Descriptions.Item label='Challenge Instance Id'>
                    {transaction?.challengeInstanceId ? `${transaction?.challengeInstanceId?._id}` : 'n/a'}
                </Descriptions.Item>
                <Descriptions.Item label='Activity'>
                    {transaction?.activityId?.name}
                    <CopyToClipboard text={transaction?.activityId?._id}>
                        <p className='cursor-pointer text-blue-400 hover:text-blue-800 hover:underline' onClick={() => message.success('Copied to Clipboard')}>&nbsp;({transaction?.activityId?._id})</p>
                    </CopyToClipboard>
                </Descriptions.Item>
                <Descriptions.Item label='Transaction Method'>
                    {_transformValidationMethod(transaction?.activityId?.validationMethod)}
                </Descriptions.Item>
                <Descriptions.Item label='Habits'>
                    {transaction?.activityId?.habitIds?.map(item => item.name).join(", ")}
                </Descriptions.Item>
                <Descriptions.Item label='Points'>
                    {transaction?.points}
                </Descriptions.Item>
                <Descriptions.Item label='Full Name'>
                    {transaction?.userId?.name} ({transformUserIdFormat(transaction?.userId)} - {transaction?.userId?.email}- {transaction?.userId?._id})
                </Descriptions.Item>
                <Descriptions.Item label='Content Uploaded'>
                    {_renderDataSubmitted()}
                </Descriptions.Item>
                <Descriptions.Item label='User Comment'>
                    <p className='flex'>{transaction?.verifyData?.comment}</p>
                </Descriptions.Item>
                <Descriptions.Item label='Status'>
                    <Tag color={transaction?.status == 'COMPLETED' ? 'green' : 'red'}>{transaction?.status}</Tag>
                </Descriptions.Item>
                <Descriptions.Item label='Remarks by Admin'>
                    <p className='flex'>{transaction?.status !== 'PENDING' ? transaction?.remarks : ''}</p>
                </Descriptions.Item>
            </Descriptions>

            <div className='flex justify-center mt-5'>
                {_renderPrevious()}
                <Button type='link' onClick={_goBack}>
                    Go Back
                </Button>
                {_renderTransactionCTA()}
                {_renderNext()}
                {/* {
                    !(transaction?.status === 'COMPLETED') ? <>
                        <Button className='mx-2 text-black font-semibold' type='primary' danger onClick={handleModal}>
                            Reject
                        </Button>
                        <Button className='mx-2 bg-[#207868] text-white font-semibold'>
                            Approve
                        </Button>
                    </> : ''
                } */}
            </div>

            {/* Transaction Reject Modal */}
            <Modal
                open={modalVisible}
                className='text-center'
                footer=''
                onCancel={() => setModalVisible(false)}
            >
                <Typography.Title level={3}>Denial Reason</Typography.Title>
                <Typography>Let your User know why you reject this transaction</Typography>
                <Input.TextArea
                    className='mt-2'
                    placeholder='Explain your reason here'
                    rows={5}
                    maxLength={80}
                    showCount
                    onChange={(e) => setRejectReason(e.currentTarget.value)}
                />
                <Button
                    className='font-semibold mt-4'
                    type='primary'
                    danger
                    // htmlType='submit'
                    onClick={_handleRejectTransaction}
                >
                    Reject Transaction
                </Button>
            </Modal>

        </div>
    )
}
