import { GET_ALL_SDGS } from "./types"

const initailState = {
    sdgs: []
}

const sdgsReducer = (state = initailState, action) => {
    switch (action.type) {
        case GET_ALL_SDGS:
            return { ...state, sdgs: action.payload }
        default:
            return state
    }
}

export default sdgsReducer