import React, { useEffect, useState } from 'react'
// import { PageHeader } from '@ant-design/pro-layout'
import { Col, Row, Button, Table, Tag, Descriptions, Typography, Tooltip, Statistic, Space } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, EyeOutlined } from '@ant-design/icons'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { adminFetchUserDetailById, fetchLastHeroProgress, fetchUserMyChallenges, fetchUserMyQuests, userChallengeTransactionsExportCsvAll } from '../../../store/users/action'
import PreviewImage from '../../../components/image/PreviewImage'
// import { userDetailsMyChallenges, userDetailsMyActivity } from './Users.columns'
// import { fetchAllChallenges } from '../../../store/challenges/actions'
import moment from 'moment'
// import CopyToClipboard from 'react-copy-to-clipboard'
import { BE_FULL_DATE_FORMAT, BE_SHORT_DATE_FORMAT, DEFAULT_PAGE_SIZE, showTotalRecords } from '../../../const/utils'
import { UserLastHeroProgressColumns, UserLastMyChallengesColumns, UserLastMyQuestsColumns } from './Users.columns'


export const UserInfo = () => {

  const dispatch = useDispatch()
  const { userId } = useParams()
  const location = useLocation()
  const userDetail = useSelector(state => state.users.userDetail)
  const lastHeroProgress = useSelector(state => state.users.lastHeroProgress)
  const lastMyChallenges = useSelector(state => state.users.lastMyChallenges)
  const lastMyQuests = useSelector(state => state.users.lastMyQuests)
  // const activities = useSelector(state => state.users.myActivities)
  useEffect(() => {
    dispatch(adminFetchUserDetailById(userId))
    dispatch(fetchLastHeroProgress(userId))
    dispatch(fetchUserMyChallenges(userId))
    dispatch(fetchUserMyQuests(userId))
    // dispatch(fetchMyActivity())
  }, [location])

  const _renderChallengeDuration = (challengeInfo) => {
    if (challengeInfo?.windowMode == 'TIME_PERIOD') {
      return <>
        <span>{moment(challengeInfo?.timePeriod?.from).format(BE_SHORT_DATE_FORMAT)}</span><br />
        <span>{moment(challengeInfo?.timePeriod?.to).format(BE_SHORT_DATE_FORMAT)}</span>
      </>
    } else {
      return <>
        <span>{challengeInfo?.completeWithin} days</span>
      </>
    }
  }

  /**
   * #45328 - Under User >User Details: Ability to download the "My Challenges" record.
   */
  const _handleChallengeTransactionsExportCsvAll = () => {
    const params = {
      userId
    }
    dispatch(userChallengeTransactionsExportCsvAll(params))
  }

  return (
    <div className='bg-white p-5 overflow-auto'>
      {/* MY PROFILE */}
      <Descriptions
        title='User Details'
        className='bg-white mb-10'
        labelCol={{ span: 4 }}
        bordered
      >
        <Descriptions.Item label='Name'
          labelStyle={{ fontWeight: 'bold' }}
        >
          {userDetail?.userProfile?.name}
        </Descriptions.Item>


        <Descriptions.Item label='Avatar'
          labelStyle={{ fontWeight: 'bold' }}
        >
          <PreviewImage src={userDetail?.userProfile?.avatar}></PreviewImage>
        </Descriptions.Item>

        <Descriptions.Item label='Email'
          labelStyle={{ fontWeight: 'bold' }}
        >
          {userDetail?.userProfile?.email}
        </Descriptions.Item>

        <Descriptions.Item label='Account Source'
          labelStyle={{ fontWeight: 'bold' }}
        >
          {userDetail?.userProfile?.sign_in_method}
        </Descriptions.Item>

        <Descriptions.Item label='Status'
          labelStyle={{ fontWeight: 'bold' }}
        >
          <Tag color={userDetail?.userProfile?.status == 'ACTIVE' && userDetail?.userProfile?.is_email_verified ? 'green' : !(userDetail?.userProfile?.is_email_verified) ? 'gold' : 'red'}>
            {userDetail?.userProfile?.is_email_verified ? userDetail?.userProfile?.status : "PENDING"}
          </Tag>
        </Descriptions.Item>

        <Descriptions.Item label='Role'
          labelStyle={{ fontWeight: 'bold' }}
        >
          {userDetail?.userProfile?.role}
        </Descriptions.Item>

        <Descriptions.Item label='Verified'
          labelStyle={{ fontWeight: 'bold' }}
        >
          {userDetail?.userProfile?.is_email_verified && <CheckCircleOutlined className='text-green-500' />}
          {!userDetail?.userProfile?.is_email_verified &&
            <>
              <CloseCircleOutlined className='text-red-500' /> (OTP: {userDetail?.userProfile?.otp})
            </>}
        </Descriptions.Item>

        <Descriptions.Item label='City'
          labelStyle={{ fontWeight: 'bold' }}
        >
          {userDetail?.userProfile?.location?.name}
        </Descriptions.Item>

        <Descriptions.Item label='Community'
          labelStyle={{ fontWeight: 'bold' }}
        >
          {userDetail?.userProfile?.teamJoiningStatus?.length > 0
            &&
            <>
              <p>My Community:</p>
              <p>
                {userDetail?.userProfile?.community?.map(i => <Tag key={i?.name}>{i?.name}</Tag>)}
              </p>
              <p>My Team:</p>
              <p>
                {userDetail?.userProfile?.teamJoiningStatus?.map(i => <Tag key={i?.teamId?.name}>{i?.teamId?.name}-{i?.status}</Tag>)}
              </p>
            </>
          }

        </Descriptions.Item>

        <Descriptions.Item label='Push Notifications'
          labelStyle={{ fontWeight: 'bold' }}
        >
          {userDetail?.userProfile?.settings?.pushNotifications ? <CheckCircleOutlined className='text-green-500' /> : <CloseCircleOutlined className='text-red-500' />}
        </Descriptions.Item>

        <Descriptions.Item label='Newsletter'
          labelStyle={{ fontWeight: 'bold' }}
        >
          {userDetail?.userProfile?.settings?.newsletter ? <CheckCircleOutlined className='text-green-500' /> : <CloseCircleOutlined className='text-red-500' />}
        </Descriptions.Item>

        <Descriptions.Item label='Timezone'
          labelStyle={{ fontWeight: 'bold' }}
        >
          {userDetail?.userProfile?.timezone}
        </Descriptions.Item>
      </Descriptions>


      {/* MY IMPACT */}
      <Descriptions
        title='User Impact'
        className='bg-white mb-10'
        labelCol={{ span: 4 }}
        bordered
      >
        {/* <Descriptions.Item span={6}> */}
        <div>
          <Row gutter={16}>
            <Col className="gutter-row" span={6}><Statistic title="Current Points" value={userDetail?.userProfile?.points} /></Col>
            <Col className="gutter-row" span={6}><Statistic title="Trash removed" value={userDetail?.userRewards?.trash_remove || 0} /></Col>
            <Col className="gutter-row" span={6}><Statistic title="Trees planted" value={userDetail?.userRewards?.tree || 0} /></Col>
            <Col className="gutter-row" span={6}><Statistic title="Meals donated" value={userDetail?.userRewards?.meal || 0} /></Col>
          </Row>
          {/* </Descriptions.Item> */}
        </div>
        {/* <Descriptions.Item
          label='Current Points'
          labelStyle={{ fontWeight: 'bold' }}
          span={3}
        >
          {userDetail?.userProfile?.points}
        </Descriptions.Item>


        <Descriptions.Item label='Trash removed'
          labelStyle={{ fontWeight: 'bold' }}
        >
          {userDetail?.userRewards?.trash_remove || 0}
        </Descriptions.Item>

        <Descriptions.Item label='Trees planted'
          labelStyle={{ fontWeight: 'bold' }}
        >
          {userDetail?.userRewards?.tree || 0}
        </Descriptions.Item>

        <Descriptions.Item label='Meals donated'
          labelStyle={{ fontWeight: 'bold' }}
        >
          {userDetail?.userRewards?.meal || 0}
        </Descriptions.Item> */}
      </Descriptions>

      <Descriptions
        title='Last Hero Progress'
        className='bg-white mb-10'
        labelCol={{ span: 4 }}
        bordered
      >
        <div>
          <Table
            className='relative w-full overflow-auto'
            columns={UserLastHeroProgressColumns}
            // rowSelection={rowSelection}
            dataSource={
              lastHeroProgress?.hero_progress?.map((i, it) => ({
                srno: (it + 1),
                key: i._id,
                green: i.goal1Impact?.level,
                blue: i.goal2Impact?.level,
                red: i.goal3Impact?.level,
                updatedAt: i.updatedAt && (moment(i.updatedAt).format(BE_FULL_DATE_FORMAT))
              }))
            }
          ></Table>
        </div>
      </Descriptions>


      <Descriptions
        title='My Challenges'
        className='bg-white mb-10'
        labelCol={{ span: 4 }}
        bordered
        extra={<Button className='mx-2 bg-[#207868]' type='primary' disabled={!(lastMyChallenges?.userChallenges?.length > 0)} onClick={_handleChallengeTransactionsExportCsvAll}>
          {`Export All`}
        </Button>}
      >
        <div>
          <Table
            className='relative w-full overflow-auto'
            columns={UserLastMyChallengesColumns}
            // rowSelection={rowSelection}
            dataSource={
              lastMyChallenges?.userChallenges?.map((i, it) => ({
                srno: (it + 1),
                key: i._id,
                challengeName: `${i?.challengeId?.name} - (Instance Id: ${i._id})`,
                challengeType: i?.type,
                challengeStatus: i?.status,
                challengeProgress: <><span>{i?.points}/{i?.targetPoints}</span></>,
                challengeDuration: _renderChallengeDuration(i),
                challengeCompletionMode: i?.windowMode,
                acceptedAt: i.createdAt && (moment(i.createdAt).format(BE_FULL_DATE_FORMAT)),
                updatedAt: i.updatedAt && (moment(i.updatedAt).format(BE_FULL_DATE_FORMAT))
              }))
            }
            pagination={{
              showTotal: showTotalRecords,
              defaultPageSize: DEFAULT_PAGE_SIZE,
              showSizeChanger: true
            }}
          ></Table>
        </div>
      </Descriptions>

      <Descriptions
        title='My Quests'
        className='bg-white mb-10'
        labelCol={{ span: 4 }}
        bordered
      // extra={<Button className='mx-2 bg-[#207868]' type='primary' disabled={!(lastMyChallenges?.userChallenges?.length > 0)} onClick={_handleChallengeTransactionsExportCsvAll}>
      //   {`Export All`}
      // </Button>}
      >
        <div>
          <Table
            className='relative w-full overflow-auto'
            columns={UserLastMyQuestsColumns}
            // rowSelection={rowSelection}
            dataSource={
              lastMyQuests?.userQuests?.map((i, it) => ({
                srno: (it + 1),
                key: i._id,
                questName: `${i?.questId?.name} - (Instance Id: ${i._id})`,
                questType: i?.type,
                questStatus: i?.status,
                questProgress: <><span>{i?.points}/{i?.targetPoints}</span></>,
                questDuration: _renderChallengeDuration(i),
                questCompletionMode: i?.windowMode,
                acceptedAt: i.createdAt && (moment(i.createdAt).format(BE_FULL_DATE_FORMAT)),
                updatedAt: i.updatedAt && (moment(i.updatedAt).format(BE_FULL_DATE_FORMAT))
              }))
            }
            pagination={{
              showTotal: showTotalRecords,
              defaultPageSize: DEFAULT_PAGE_SIZE,
              showSizeChanger: true
            }}
          ></Table>
        </div>
      </Descriptions>

      {/* Last Active At */}
      <Descriptions
        title='Last Active'
        className='mb-10'
        labelCol={{ span: 2 }}
      // bordered
      >
        <Descriptions.Item
          label='Last Active At'
          labelStyle={{ fontWeight: 'bold' }}
        // span={6}
        >
          {moment(userDetail?.userProfile?.lastActiveAt).format(BE_FULL_DATE_FORMAT)}
        </Descriptions.Item>

        <Descriptions.Item
          label='Last X-Headers'
          labelStyle={{ fontWeight: 'bold' }}
        // span={6}
        >
          {userDetail?.userProfile?.xheaders_meta &&
            JSON.stringify(userDetail?.userProfile?.xheaders_meta)
          }
          {!userDetail?.userProfile?.xheaders_meta && userDetail?.userProfile?.xheaders}
        </Descriptions.Item>

      </Descriptions>

      {/* <Typography.Title level={4} className='pl-5 mt-10'>My Challenges</Typography.Title>
      <Table
        className='bg-[#e6faf6] !px-0 !mb-16'
        columns={userDetailsMyChallenges}
        dataSource={challengesData?.result?.map((i, it) => ({
          srno: ++it,
          name: i.name,
          goal: i.goal,
          community: i.communityIds[0]?.name,
          timePeriod: `${moment(i.timePeriod.from).format(BE_SHORT_DATE_FORMAT)} to ${moment(i.timePeriod.to).format(BE_SHORT_DATE_FORMAT)}`,
          totalActivities: `${i.activityIds.length}`,
          point: i.points,
          reward: i.rewardType,
          validation: i.validationMethod,
          status: <Tag color={i.publish === true ? 'green' : 'red'}>{i.publish ? 'Published' : 'Not published'}</Tag>,
          shareUrl: i.shareUrl && (
            <CopyToClipboard text={i.shareUrl}>
              <p className='cursor-pointer text-blue-400 hover:text-blue-800 hover:underline' onClick={() => message.success('Link copied to Clipboard')}>Share Link</p>
            </CopyToClipboard>
          ),
          action:
            <Tooltip title="Edit">
              <Button style={{ marginRight: "8px" }} size="small" type="ghost" shape="circle" icon={<EyeOutlined />} />
            </Tooltip>

        }))}
      /> */}

      {/* <Typography.Title level={4} className='pl-5 mt-5'>My Activities</Typography.Title>
      <Table
        columns={userDetailsMyActivity}
        dataSource={activities?.result?.map((i, it) => ({
          srno: ++it,
          name: i.name,
          community: i.communities?.name,
          goal: i.goalId?.name,
          // habit: i.goalId?.habits?.map(j => <Tag className='my-0.5' color="blue">{j.name}</Tag>),
          point: i.points,
          validation: i.validationMethod,
          status: <Tag color={i.publish === true ? 'green' : 'red'}>{i.publish ? 'Published' : 'Not published'}</Tag>,
          link: i.shareUrl && (
            <>
              <CopyToClipboard text={i.shareUrl}>
                <p className='cursor-pointer text-blue-400 hover:text-blue-800 hover:underline' onClick={() => message.success('Link copied to Clipboard')}>Share Link</p>
              </CopyToClipboard>
            </>
          ),
          action:
            <div style={{ minWidth: '65px' }}>
              <Tooltip title="Edit">
                <Button style={{ marginRight: "8px" }} size="small" type="ghost" shape="circle" icon={<EyeOutlined />} />
              </Tooltip>
            </div>
        }))}
      /> */}

    </div>
  )
}