import { FETCH_MIAANIMATIONS_ANIMATION_CONFIG_LIST, FETCH_MIAANIMATIONS_CHALLENGES_LIST, FETCH_MIAANIMATIONS_USERS_LIST } from "./types"

const initialState = {
    animationConfig: [],
    challenges: [],
    users: []
}

const miaAnimationsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MIAANIMATIONS_CHALLENGES_LIST:
            return { ...state, challenges: action.payload }
        case FETCH_MIAANIMATIONS_ANIMATION_CONFIG_LIST:
            return { ...state, animationConfig: action.payload }
        case FETCH_MIAANIMATIONS_USERS_LIST:
            return { ...state, users: action.payload }
        default:
            return state
    }
}

export default miaAnimationsReducer