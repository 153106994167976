import { FETCH_COMMON_CONFIGURATION, NETWORK_LOADING } from "./types"

const initialState = {
    loading: false,
    data: null,
    networkLoading: false
}

// /**
//  * 
//  * @param {Object} state - The initial state
//  * @param {any} action - The data passed from action
//  * @returns 
//  */
const commonReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_COMMON_CONFIGURATION:
            return { ...state, data: action.payload }
      case NETWORK_LOADING:
        return {
          ...state,
          networkLoading: action.payload
        }
      default:
        return state
    }
}
  
export default commonReducer
  