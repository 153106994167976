import React from 'react'
import {Modal, Form, Input, Button, Typography} from 'antd'
import { forgotPassword } from '../../../store/auth/actions'
import { useDispatch, useSelector } from 'react-redux'


const ForgotPasswordModal = (props) => {
    const [modalForm] = Form.useForm()
    const dispatch = useDispatch()

    const networkLoading = useSelector(state => state.common.networkLoading)

    const resetModal = () => {
        props.refresh()
        props.setVisible(false)
    }

    const resetModalAfterSuccess = () => {
        modalForm.setFieldsValue({ email: null })
        props.refresh()
        props.setVisible(false)
    }

    const handleSubmitForgotPassword = (values) => {
        dispatch(forgotPassword(values, resetModalAfterSuccess))
    }


    return (
        <Modal
            visible={props.visible}
            onCancel={() => resetModal()}
            footer={""}
        >
            <div className='text-center mb-6'>
                <Typography className='text-xl font-bold'>Forgot password?</Typography>
            </div>
             <Form
                form={modalForm}
                name='ForgotPasswordForm'
                labelCol={{ span: 4 }}
                onFinish={(values) => handleSubmitForgotPassword(values)}
                autoComplete='off'
            >
                <Form.Item
                    label='Email'
                    labelCol={{
                        span: 4
                    }}
                    labelAlign='left'
                    name='email'
                    rules={[{ required: true, message: 'Please enter Email' }]}
                >
                    <Input type='email' placeholder='Enter your email' className='br-6'/>
                </Form.Item>   
                <div className="text-center pt-2 pb-4">
                    <Button className="bg-[#207868] px-8 br-6" size="large" type="primary" htmlType="submit" loading={networkLoading}>
                        Reset Password
                    </Button>
                </div>

            </Form>
        </Modal>
    )
}

export default ForgotPasswordModal