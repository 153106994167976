import { FETCH_ALL_DOMAIN_WHITELISTED } from "./types"

const initialState = {
    domains: []
}

const domainsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_DOMAIN_WHITELISTED:
            return { ...state, domains: action.payload }
        default:
            return state
    }
}

export default domainsReducer