export const ImpactByActivitiesTableColumns = [
  {
    title: 'No',
    dataIndex: 'srno',
    key: 'srno',
    notAllowToSearch: true
  },
  {
    title: 'Activity Name',
    dataIndex: 'activityName',
    key: 'activityName'
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'total'
  },
  {
    title: 'Quantity per Unit',
    dataIndex: 'qty_per_unit',
    key: 'qty_per_unit'
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit'
  },
  {
    title: 'Total Quantity',
    dataIndex: 'total_qty',
    key: 'total_qty'
  },
  {
    title: 'Carbon per Unit',
    dataIndex: 'carbon_per_unit',
    key: 'carbon_per_unit'
  },
  {
    title: 'Total Carbon',
    dataIndex: 'total_carbon',
    key: 'total_carbon'
  }
]
