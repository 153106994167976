import React from 'react'
import { cyan, generate, green, presetPalettes, red } from '@ant-design/colors'
import { Col, ColorPicker, Divider, Row, Space, theme } from 'antd'

const genPresets = (presets = presetPalettes) => {
  return Object.entries(presets).map(([label, colors]) => ({
    label,
    colors
  }))
}

const CustomColorPicker = (props) => {
  const { token } = theme.useToken()
  const presets = genPresets({
    primary: generate(token.colorPrimary),
    red,
    green,
    cyan
  })

  const customPanelRender = (_, { components: { Picker, Presets } }) => (
    <Row justify="space-between" wrap={false}>
      <Col span={12}>
        <Presets />
      </Col>
      <Divider
        type="vertical"
        style={{
          height: 'auto'
        }}
      />
      <Col flex="auto">
        <Picker />
      </Col>
    </Row>
  )

  return (
    <ColorPicker
      defaultValue={token.colorPrimary}
      styles={{
        popupOverlayInner: {
          width: 480
        }
      }}
      presets={presets}
      panelRender={customPanelRender}
      {...props}
    />)
}

export default CustomColorPicker