export const VoucherActionsTableColumns = [
  {
    title: 'No',
    dataIndex: 'srno',
    key: 'srno',
    notAllowToSearch: true
  },
  {
    title: 'Challenge Name',
    dataIndex: 'challengeName',
    key: 'challengeName',
    notAllowToSearch: true
  },
  {
    title: 'User ID',
    dataIndex: 'userId',
    key: 'userId',
    notAllowToSearch: true
  },
  {
    title: 'Full Name',
    dataIndex: 'name',
    key: 'name',
    notAllowToSearch: true
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    notAllowToSearch: true
  },
  {
    title: 'Voucher Code',
    dataIndex: 'voucher_code',
    key: 'voucher_code',
    notAllowToSearch: true
  },
  {
    title: 'Claim Email',
    dataIndex: 'claim_email',
    key: 'claim_email',
    notAllowToSearch: true
  },
  {
    title: 'Claim Status',
    dataIndex: 'claim_status',
    key: 'claim_status',
    notAllowToSearch: true
  },
  {
    title: 'Created Date',
    dataIndex: 'createdDate',
    key: 'createdDate',
    notAllowToSearch: true
  },
  {
    title: 'Updated Date',
    dataIndex: 'updatedDate',
    key: 'updatedDate',
    notAllowToSearch: true
  }
]