import { Collapse, Button, DatePicker, Radio, Select, Form, Input, Upload, message, InputNumber } from 'antd'
import { UploadOutlined, CopyOutlined } from '@ant-design/icons'
import { CgArrowsV } from 'react-icons/cg'
import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link, useParams } from 'react-router-dom'
import { fetchChallengeById, updateChallenge } from '../../../store/challenges/actions'
import { fetchAllActivitiesForCreateEditChallenge } from '../../../store/activity/actions'
// import { fetchAllGoals } from '../../../store/goal/actions'
import { fetchAllCommunity } from '../../../store/community/actions'
import { fetchAllRewads } from '../../../store/rewards/actions'
import ServerApi from '../../../utility/ServerApi'
// import moment from 'moment'
import * as dayjs from 'dayjs'
// import { SDGS } from '../../../const/sdgs.const'
import PreviewImage from '../../../components/image/PreviewImage'
import CopyToClipboard from 'react-copy-to-clipboard'
import { fetchAllSDGS } from '../../../store/sdgs/actions'
import { fetchPublishedLocations } from '../../../store/locations/actions'
import { isSupportedFileFormatForGeneric, SUPPORTED_FILE_FORMAT_EXTRA, transformValidationMethod } from '../../../const/utils'
import { CHALLENGE_WINDOW_MODE, transformVoucherCodeInput } from './Challenges.const'
import { fetchCommonConfiguration } from '../../../store/common/actions'
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertFromHTML, ContentState, convertToRaw } from "draft-js"
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'


const { Panel } = Collapse
const { RangePicker } = DatePicker

export const EditChallenge = () => {

    const [form] = Form.useForm()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { challengeId } = useParams()

    const challengesData = useSelector(state => state.challenges.challenge)
    const activityData = useSelector(state => state.activity.activitiesForEditCreateChallenge)
    const communityData = useSelector(state => state.community.communities)
    const rewards = useSelector(state => state.rewards.rewards)
    const sdgs = useSelector(state => state.sdgs.sdgs)
    const locations = useSelector(state => state.locations.locations)
    const [claimLimitOption, setClaimLimitOption] = useState("")
    const [rewardOption, setRewardOption] = useState("")
    const [fileList, setFileList] = useState([])
    const [thumbnailFileList, setThumbnailFileList] = useState([])
    // const [voucherCodes, setVoucherCodes] = useState(challengesData?.rewardDetails?.voucher?.voucher_codes)
    const [selectedCommunity, setSelectedCommunity] = useState(challengesData?.communityIds?.[0]?._id || null)
    const [selectedCity, setSelectedCity] = useState(challengesData?.cities || null)
    const [completionWindowMode, setCompletionWindowMode] = useState(CHALLENGE_WINDOW_MODE.TIME_PERIOD.value)

    const [editorDescriptioDetailState, setEditorDescriptioDetailState] = useState(EditorState.createEmpty())
    const editorDescriptionDetailText = useSelector(state => state?.challenges?.challenge?.description)
    const [descriptionDetail, setDescriptionDetail] = useState()

    const [emailContentEditorState, setEmailContentEditorState] = useState(EditorState.createEmpty())
    const [emailContent, setEmailContent] = useState('')
    const editorEmailContentText = useSelector(state => state?.challenges?.challenge?.rewardDetails?.voucher?.email_content)
    const [emailLogo, setEmailLogo] = useState([])
    const [numberVoucherEntered, setNumberVoucherEntered] = useState(0)
    const claimLimitOptions = useSelector(state => state.common?.data?.claimLimitOptions)
    useEffect(() => {
        setTimeout(() => {
            // const blocksFromHTML = convertFromHTML(editorDescriptionDetailText)
            // const state = ContentState.createFromBlockArray(
            //     blocksFromHTML.contentBlocks,
            //     blocksFromHTML.entityMap
            // )
            // setEditorDescriptioDetailState(EditorState.createWithContent(state))
            // setDescriptionDetail(editorDescriptionDetailText)


            setDescriptionDetail(editorDescriptionDetailText)
            const contentBlock = htmlToDraft(editorDescriptionDetailText)
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
                const editorState = EditorState.createWithContent(contentState)
                setEditorDescriptioDetailState(editorState)
            }

        }, 1000)
    }, [editorDescriptionDetailText])

    useEffect(() => {
        setTimeout(() => {
            // const blocksFromHTML = convertFromHTML(editorEmailContentText)
            // const state = ContentState.createFromBlockArray(
            //     blocksFromHTML.contentBlocks,
            //     blocksFromHTML.entityMap
            // )
            // setEmailContentEditorState(EditorState.createWithContent(state))
            // setEmailContent(editorEmailContentText)

            setEmailContent(editorEmailContentText)
            const contentBlock = htmlToDraft(editorEmailContentText)
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
                const editorState = EditorState.createWithContent(contentState)
                setEmailContentEditorState(editorState)
            }
        }, 1000)
    }, [editorEmailContentText])

    const onEditorDescriptionDetailStateChange = (editorState) => {
        setEditorDescriptioDetailState(editorState)
    }

    const onEditorEmailStateChange = (editorState) => {
        setEmailContentEditorState(editorState)
    }

    useEffect(() => {
        dispatch(fetchCommonConfiguration())
        dispatch(fetchChallengeById(challengeId))
        dispatch(fetchAllCommunity())
        dispatch(fetchAllRewads())
        dispatch(fetchAllSDGS())
        dispatch(fetchPublishedLocations())
    }, [])

    useEffect(() => {
        //teams options based on selected community
        // dispatch(fetchAllActivitiesForCreateEditChallenge({ communityId: selectedCommunity, city: selectedCity }))
        // setSelectedCity(selectedCommunity.cities)
        // force reset team selected
        // form.setFieldsValue('activityIds', undefined)
    }, [selectedCommunity])

    useEffect(() => {
        //teams options based on selected community
        dispatch(fetchAllActivitiesForCreateEditChallenge({ communityId: selectedCommunity, city: selectedCity }))
        // force reset team selected
        form.setFieldsValue({ activityIds: undefined })
    }, [selectedCity, selectedCommunity])

    useEffect(() => {
        form.setFieldsValue({
            name: challengesData?.name,
            shareUrl: challengesData?.qrCode?.share_url,
            communityIds: challengesData?.communityIds?.[0]?._id,
            cities: challengesData?.cities,
            timePeriod: [dayjs(challengesData?.timePeriod?.from || new Date), dayjs(challengesData?.timePeriod?.to || new Date)],
            completeWithin: challengesData?.completeWithin,
            windowMode: challengesData?.windowMode || CHALLENGE_WINDOW_MODE.TIME_PERIOD.value,
            points: challengesData?.points,
            activityIds: challengesData?.activityIds?.map(i => i._id),
            habit: challengesData?.habit,
            rewardId: { label: challengesData?.rewardId?.name, value: challengesData?.rewardId?._id },
            claimLimit: challengesData?.rewardDetails?.claimLimit,
            extraPoint: challengesData?.rewardDetails?.extraPoint,
            voucher_codes: challengesData?.rewardDetails?.voucher?.voucher_codes,
            email_content: challengesData?.rewardDetails?.voucher?.email_content,
            email_logo: challengesData?.rewardDetails?.voucher?.email_logo,
            plantTree: challengesData?.rewardDetails?.tree,
            mealForChildren: challengesData?.rewardDetails?.meal,
            removeTrash: challengesData?.rewardDetails?.trash,
            description: challengesData?.description,
            instructions: challengesData?.instructions,
            sdgs: challengesData?.sdgs?.map(i => i?._id),
            publish: challengesData?.publish
        })
        setCompletionWindowMode(challengesData?.windowMode || CHALLENGE_WINDOW_MODE.TIME_PERIOD.value)
        setRewardOption(rewards.find(i => i._id === challengesData?.rewardId?._id)?.rewardId)
        dispatch(fetchAllActivitiesForCreateEditChallenge({ city: challengesData?.cities, communityId: challengesData?.communityIds?.[0]?._id }))
        setNumberVoucherEntered(challengesData?.rewardDetails?.voucher?.voucher_codes?.split(',').length)
        setClaimLimitOption(challengesData?.rewardDetails?.claimLimit)
        // setSelectedCity(challengesData?.cities)
        // setSelectedCommunity(challengesData?.communityIds?.[0]?._id)
    }, [challengesData])

    const successCallback = () => {
        navigate('/challenges')
    }

    const updateNewChallenge = (values) => {
        // console.log(values)
        const _rewardId = typeof values.rewardId === 'string' ? values.rewardId : values.rewardId?._value
        const _voucher_codes = transformVoucherCodeInput(values.voucher_codes)
        values.description = descriptionDetail
        const data = {
            name: values.name,
            communityIds: values.communityIds || null,
            cities: values.cities,
            windowMode: completionWindowMode,
            completeWithin: values.completeWithin,
            timePeriod: {
                from: values.timePeriod ? values.timePeriod[0] : '',
                to: values.timePeriod ? values.timePeriod[1] : ''
            },
            totalnumber: values.totalnumber,
            activityIds: values.activityIds,
            habit: values.habit,
            points: values.points,
            rewardId: _rewardId,
            rewardDetails: {
                extraPoint: values.extraPoint,
                voucher: _voucher_codes.trim() !== '' ? {
                    voucher_codes: transformVoucherCodeInput(values.voucher_codes),
                    email_content: emailContent,
                    email_logo: emailLogo[0]?.image || challengesData?.rewardDetails?.voucher?.email_logo
                } : null,
                tree: values.plantTree,
                meal: values.mealForChildren,
                trash: values.removeTrash,
                claimLimit: claimLimitOption
            },
            description: values.description,
            instructions: values.instructions,
            sdgs: values.sdgs,
            publish: values.publish
        }
        if (fileList[0]) data.image = fileList[0].image
        if (thumbnailFileList[0]) data.thumbnail = thumbnailFileList[0].icon
        // if (voucherFileList[0]) values.thumbUrl = voucherFileList[0].image
        dispatch(updateChallenge(challengeId, data, successCallback))
    }

    const handleChange = async ({ file }) => {
        if (!isSupportedFileFormatForGeneric(file.type)) {
            message.error('You can only upload JPG/PNG file!')
            return
        }
        setFileList(
            [{ uid: file.uid, name: '', status: 'uploading' }]
        )
        const formData = new FormData()
        formData.append('file', file)
        formData.append('folder', 'challenges')

        ServerApi().post('media/admin-create-media', formData)
            .then(res => {
                setFileList([
                    {
                        uid: file.uid,
                        name: res.data.name,
                        image: res.data.file,
                        status: 'done',
                        url: `${res.data.path}`,
                        thumbUrl: `${res.data.path}`
                    }
                ])
                return true
            })
            .catch(e => { console.log(e); return false })
    }

    const handleThumbnail = async ({ file }) => {
        if (!isSupportedFileFormatForGeneric(file.type)) {
            message.error('You can only upload JPG/PNG file!')
            return
        }
        setThumbnailFileList(
            [{ uid: file.uid, name: '', status: 'uploading' }]
        )
        const formData = new FormData()
        formData.append('file', file)
        formData.append('folder', 'activities')

        ServerApi().post('media/admin-create-media', formData)
            .then(res => {
                setThumbnailFileList([
                    {
                        uid: file.uid,
                        name: res.data.name,
                        icon: res.data.file,
                        status: 'done',
                        url: `${res.data.path}`,
                        thumbUrl: `${res.data.path}`
                    }
                ])
                return true
            })
            .catch(e => { console.log(e); return false })
    }

    const handleEmailLogo = async ({ file }) => {
        if (!isSupportedFileFormatForGeneric(file.type)) {
            message.error('You can only upload JPG/PNG file!')
            return
        }
        setEmailLogo(
            [{ uid: file.uid, name: '', status: 'uploading' }]
        )
        const formData = new FormData()
        formData.append('file', file)
        formData.append('folder', 'challenges')

        ServerApi().post('media/admin-create-media', formData)
            .then(res => {
                setEmailLogo([
                    {
                        uid: file.uid,
                        name: res.data.name,
                        image: res.data.file,
                        status: 'done',
                        url: `${res.data.path}`,
                        thumbUrl: `${res.data.path}`
                    }
                ])
                return true
            })
            .catch(e => { console.log(e); return false })
    }

    // const handleVoucher = async ({ file }) => {
    //     if (!isSupportedFileFormatForGeneric(file.type)) {
    //         message.error('You can only upload JPG/PNG file!')
    //         return
    //     }
    //     setVoucherFileList(
    //         [{ uid: file.uid, name: '', status: 'uploading' }]
    //     )
    //     const formData = new FormData()
    //     formData.append('file', file)
    //     formData.append('folder', 'reward')

    //     ServerApi().post('media/admin-create-media', formData)
    //         .then(res => {
    //             setVoucherFileList([
    //                 {
    //                     uid: file.uid,
    //                     name: res.data.name,
    //                     image: res.data.file,
    //                     status: 'done',
    //                     url: `${res.data.path}`,
    //                     thumbUrl: `${res.data.path}`
    //                 }
    //             ])
    //             return true
    //         })
    // }

    const props = {
        customRequest: handleChange,
        listType: 'picture',
        onRemove: () => setFileList([]),
        fileList
    }

    const thumbnailProps = {
        customRequest: handleThumbnail,
        listType: 'picture',
        onRemove: () => setFileList([]),
        fileList
    }

    // Email Logo
    const emailLogoProps = {
        customRequest: handleEmailLogo,
        listType: 'picture',
        onRemove: () => setEmailLogo([]),
        emailLogo
    }

    // const voucherProps = {
    //     customRequest: handleVoucher,
    //     listType: 'picture',
    //     onRemove: () => setVoucherFileList([]),
    //     voucherFileList
    // }

    /**
     * #43616 - CHALLENGE - time period logic update
     * @param {*} e 
     */
    const handleWindowModeChange = (e) => {
        setCompletionWindowMode(e.target.value)
    }

    const handleCountVoucher = (e) => {
        try {
            if (e?.target?.value !== '') {
                const splittedVoucher = e.target.value.split(',')
                //check for empty element
                const newArr = splittedVoucher.filter(element => {
                    if (element === "") {
                        return false
                    } else {
                        return true
                    }
                })
                setNumberVoucherEntered(newArr.length)
            } else {
                setNumberVoucherEntered(0)
            }
        } catch (e) {

        }
    }

    return (
        <>
            <div className="shadow bg-white">

                <Form
                    name="EditChallenges"
                    form={form}
                    labelCol={{
                        span: 4
                    }}
                    onFinish={(values) => updateNewChallenge(values)}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Collapse className="border-none" expandIconPosition={'end'} defaultActiveKey={['1', '2', '3', '4']}>
                        <Panel header={<p className='font-bold text-lg'>General Info</p>} key="1" className="bg-textbackgroundcolor">
                            <Form.Item
                                label="Challenge Name"
                                name="name"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please enter Challenge name!' }]}
                                hasFeedback
                            >
                                <Input />
                            </Form.Item>
                            <div className='flex justify-between'>
                                <Form.Item
                                    label="Share Link"
                                    name="shareUrl"
                                    labelAlign="left"
                                    labelCol={{ span: 4 }}
                                    className='w-full ml-2'
                                    colon={false}
                                    hasFeedback
                                >
                                    <Input disabled />
                                </Form.Item>
                                <CopyToClipboard text={challengesData?.shareUrl} title='copy'>
                                    <Button icon={<CopyOutlined />} onClick={() => (challengesData?.shareUrl ? message.success('Link copied to clipboard') : message.warn('Link not available'))} />
                                </CopyToClipboard>
                            </div>
                            <Form.Item
                                labelAlign="left"
                                colon={false}
                                label="Challenge Thumbnail"
                                extra={SUPPORTED_FILE_FORMAT_EXTRA.CHALLENGE_THUMB}
                            >
                                <div className='flex justify-between'>
                                    <div className='self-center'>
                                        <p className='flex'>Currently : <PreviewImage src={challengesData.thumbnail} width={120} height={69} /></p>
                                    </div>
                                    <Upload className='flex space-x-4' {...thumbnailProps} fileList={thumbnailFileList}>
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </div>
                            </Form.Item>
                            <Form.Item
                                labelAlign="left"
                                colon={false}
                                label="Challenge Image"
                                extra={SUPPORTED_FILE_FORMAT_EXTRA.CHALLENGE_MAIN}
                            >
                                <div className='flex justify-between'>
                                    <div className='self-center'>
                                        <p className='flex'>Currently: <PreviewImage src={challengesData.image} width={105} height={69} /></p>
                                    </div>
                                    <Upload className='flex space-x-4' {...props} fileList={fileList}>
                                        <Button icon={<UploadOutlined />}>Upload</Button>
                                    </Upload>
                                </div>
                            </Form.Item>
                            <Form.Item
                                label="Community"
                                name="communityIds"
                                labelAlign="left"
                                colon={false}
                                extra="Leave blank if global"
                            // rules={[{ required: true, message: 'Please select community!' }]}
                            // hasFeedback
                            >
                                <Select
                                    showSearch
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Select community"
                                    optionFilterProp='label'
                                    onChange={(h) => { setSelectedCommunity(h) }}
                                    options={communityData.result?.map(i => ({ label: i.name, value: i._id }))}
                                    suffixIcon={<CgArrowsV />}
                                >
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Cities"
                                name="cities"
                                labelAlign="left"
                                colon={false}
                                // rules={[{ required: true, message: 'Please select cities!' }]}
                                hasFeedback
                                extra={'Leave blank if global'}
                            >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    allowClear
                                    style={{ width: '100%' }}
                                    optionFilterProp="label"
                                    placeholder="Select City"
                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    onChange={(h) => { setSelectedCity(h) }}
                                    suffixIcon={<CgArrowsV />}
                                    options={locations?.map(i => ({ label: i?.name, value: i?._id }))}
                                >
                                </Select>
                            </Form.Item>
                            <Form.Item name="windowMode"
                                colon={false} labelAlign="left"
                                label="Completion window mode"
                                rules={[{ required: true, message: 'Please completion mode' }]}
                                onChange={handleWindowModeChange}
                            >
                                <Radio.Group className="text-left">
                                    <Radio value={CHALLENGE_WINDOW_MODE.TIME_PERIOD.value}>{CHALLENGE_WINDOW_MODE.TIME_PERIOD.label}</Radio>
                                    <Radio value={CHALLENGE_WINDOW_MODE.COMPLETE_WITHIN.value}>{CHALLENGE_WINDOW_MODE.COMPLETE_WITHIN.label}</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {completionWindowMode == CHALLENGE_WINDOW_MODE.TIME_PERIOD.value &&
                                <div className="grid grid-cols-7 gap-3">
                                    <Form.Item
                                        label="From / To Date"
                                        name="timePeriod"
                                        labelAlign="left"
                                        colon={false}
                                        rules={[{ required: true, message: 'Please enter duration!' }]}
                                        labelCol={{
                                            span: 7
                                        }}
                                        className="col-span-4"
                                        extra='The challenge will be available from date and to date only'
                                    >
                                        <RangePicker style={{ width: "100%" }} />
                                    </Form.Item>
                                </div>
                            }
                            {completionWindowMode == CHALLENGE_WINDOW_MODE.COMPLETE_WITHIN.value &&
                                <>
                                    <Form.Item
                                        label="Complete Within"
                                        name="completeWithin"
                                        labelAlign="left"
                                        colon={false}
                                        rules={[
                                            { required: true, message: 'Please enter number of days!' },
                                            { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only! Value from 1..180.' }
                                        ]}
                                        hasFeedback
                                        extra='Number of days user should complete this challenge since accepted. Value from 1..180.'
                                    >
                                        <InputNumber placeholder='Days' min={1} max={180} />
                                    </Form.Item>
                                </>
                            }
                        </Panel>
                        <Panel header={<p className='font-bold text-lg py-0'>Activities</p>} key="2" className="bg-textbackgroundcolor">
                            {/* <Form.Item
                                label="Total number"
                                name="totalnumber"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please enter Total number!' }]}
                                hasFeedback
                            >
                                <Input />
                            </Form.Item> */}
                            <Form.Item
                                label="Activities"
                                name="activityIds"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please select activities!' }]}
                                hasFeedback
                                extra={'Only activities in same community or same city or global available here'}
                            >
                                <Select
                                    mode='multiple'
                                    showSearch
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Select activity"
                                    optionFilterProp='label'
                                    options={activityData?.result?.map(i => ({ label: `${i.name} (${transformValidationMethod(i.validationMethod)}, ${i.points}pts) (${i.cities && i.cities.length > 0 ? i.cities?.map(k => k.name).join(", ") : '-'})`, value: i._id }))}
                                >
                                </Select>
                            </Form.Item>
                        </Panel>
                        <Panel header={<p className='font-bold text-lg py-0'>Reward</p>} key="3" className="bg-textbackgroundcolor">
                            <Form.Item
                                label="Target Points"
                                name="points"
                                labelAlign="left"
                                colon={false}
                                rules={[
                                    { required: true, message: 'Please enter maximum points!' },
                                    { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                ]}
                                hasFeedback
                                extra='Target points to complete this challenge. Value from 1..999999'
                            >
                                <InputNumber placeholder='Points' min={1} max={999999} />
                            </Form.Item>
                            <Form.Item
                                label="Reward"
                                name="rewardId"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please select SDGs!' }]}
                                hasFeedback
                                extra='Award item when user complete this challenge.'
                            >
                                <Select
                                    showSearch
                                    placeholder="Select Reward"
                                    optionFilterProp="label"
                                    onSelect={(e, s) => setRewardOption(s.rewardId)}
                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    options={rewards?.map((r, it) => ({ label: r?.name, value: r?._id, rewardId: r?.rewardId }))}
                                    suffixIcon={<CgArrowsV />}
                                >
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Claim Limit"
                                name="claimLimit"
                                labelAlign="left"
                                colon={false}
                                extra={`No Limit: same as current user can redo the challenge how many times they want.
                                Once per User: user can only do the challenge once. They cannot redo the challenge after completed.`}
                                rules={[{ required: false, message: 'Please select frequency!' }]}
                                hasFeedback
                            >
                                <Select
                                    showSearch
                                    placeholder="Select claim limit"
                                    optionFilterProp="label"
                                    onSelect={setClaimLimitOption}
                                    // filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    options={claimLimitOptions ? claimLimitOptions.map(i => ({ label: i.label, value: i.value })) : []}
                                    suffixIcon={<CgArrowsV />}
                                >
                                </Select>
                            </Form.Item>
                            {((rewardOption || challengesData?.rewardId?.rewardId) == 'tree') && (
                                <Form.Item
                                    label="Number of tree"
                                    name="plantTree"
                                    labelAlign="left"
                                    colon={false}
                                    rules={[
                                        { required: true, message: 'Please enter extra point!' },
                                        { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                    ]}
                                    hasFeedback
                                >
                                    <InputNumber placeholder='Trees' min={1} max={100000} />
                                </Form.Item>
                            )}
                            {(rewardOption || challengesData?.rewardId?.rewardId) == 'meal' && (
                                <Form.Item
                                    label="Number of meal"
                                    name="mealForChildren"
                                    labelAlign="left"
                                    colon={false}
                                    rules={[
                                        { required: true, message: 'Please enter extra point!' },
                                        { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                    ]}
                                    hasFeedback
                                >
                                    <InputNumber placeholder='Meal' min={1} max={100000} />
                                </Form.Item>
                            )}
                            {(rewardOption || challengesData?.rewardId?.rewardId) == 'trash_remove' && (
                                <Form.Item
                                    label="Number of grams of trash"
                                    name="removeTrash"
                                    labelAlign="left"
                                    colon={false}
                                    rules={[
                                        { required: true, message: 'Please enter grams of trash!' },
                                        { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                    ]}
                                    hasFeedback
                                >
                                    <InputNumber placeholder='Trash' min={1} max={100000} />
                                </Form.Item>
                            )}
                            {(rewardOption || challengesData?.rewardId?.rewardId) == 'xpoints' && (
                                <Form.Item
                                    label="Number of Points"
                                    name="extraPoint"
                                    labelAlign="left"
                                    colon={false}
                                    rules={[
                                        { required: true, message: 'Please enter extra point!' },
                                        { pattern: new RegExp(/^-?\d*(\.\d*)?$/), message: 'Number format only!' }
                                    ]}
                                    hasFeedback
                                >
                                    <InputNumber placeholder='Points' min={1} max={100000} />
                                </Form.Item>
                            )}
                            {(rewardOption || challengesData?.rewardId?.rewardId) == 'voucher' && (
                                <Form.Item
                                    name='voucher_codes'
                                    label="Voucher Code"
                                    labelAlign="left"
                                    colon={false}
                                    extra={
                                        <>
                                            <p className='text-sm text-blue-500 pt-2 font-bold'>Total codes available {numberVoucherEntered}.</p>
                                            <p className='text-sm py-2'>Please enter all code can be used seperated by comma</p>
                                        </>
                                    }
                                >
                                    <Input.TextArea rows={4} onChange={(e) => handleCountVoucher(e)} />
                                </Form.Item>
                            )}
                            {(rewardOption || challengesData?.rewardId?.rewardId) == 'voucher' && (
                                <Form.Item
                                    label="Email Content"
                                    name="email_content"
                                    labelAlign="left"
                                    colon={false}
                                    hasFeedback
                                    extra={
                                        <>
                                            <p className='text-sm py-2'>Template variable available: {`{{voucher_code}}, {{challenge_name}}, {{email_logo}}`}.</p>
                                        </>
                                    }
                                >
                                    <Editor
                                        toolbar={{
                                            fontFamily: {
                                                options: ["Poppins"]
                                            }
                                        }}
                                        editorState={emailContentEditorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorEmailStateChange}
                                        onChange={() => {
                                            const tempData = (emailContentEditorState && draftToHtml(convertToRaw(emailContentEditorState.getCurrentContent())))
                                            setEmailContent(tempData)
                                        }

                                        }
                                    />
                                </Form.Item>
                            )}
                            {(rewardOption || challengesData?.rewardId?.rewardId) == 'voucher' && (
                                <Form.Item
                                    labelAlign="left"
                                    colon={false}
                                    label="Email Logo"
                                    extra={
                                        <>
                                            <p className='py-2'>{SUPPORTED_FILE_FORMAT_EXTRA.CHALLENGE_THUMB}</p>
                                            <p>This logo if uploaded will be used for the template variable {`{{email_logo}}`} when the system sending email to user</p>
                                        </>
                                    }
                                >
                                    <div className='flex justify-between'>
                                        <div className='self-center'>
                                            <p className='flex items-center'>Currently : <PreviewImage src={challengesData?.rewardDetails?.voucher?.email_logo} width={120} height={69} /></p>
                                        </div>
                                        <Upload className='flex space-x-4' {...emailLogoProps} fileList={emailLogo}>
                                            <Button icon={<UploadOutlined />}>Upload</Button>
                                        </Upload>
                                    </div>
                                </Form.Item>
                            )}
                        </Panel>
                        <Panel header={<p className='font-bold text-lg py-0'>Details</p>} key="4" className="bg-textbackgroundcolor" style={{ borderBottom: "none" }}>
                            <Form.Item
                                label="Description"
                                name="description"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please enter description!' }]}
                                hasFeedback
                            >
                                {/* <Input.TextArea showCount rows={3} /> */}
                                <Editor
                                    toolbar={{
                                        fontFamily: {
                                            options: ["Poppins"]
                                        }
                                    }}
                                    editorState={editorDescriptioDetailState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={onEditorDescriptionDetailStateChange}
                                    // plugins={plugins}
                                    onChange={() => {
                                        const tempData = (editorDescriptioDetailState && draftToHtml(convertToRaw(editorDescriptioDetailState.getCurrentContent())))
                                        setDescriptionDetail(tempData)
                                    }

                                    }
                                />
                            </Form.Item>
                            <Form.Item
                                label="Instruction"
                                name="instructions"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please enter Instruction!' }]}
                                hasFeedback
                            >
                                <Input.TextArea showCount rows={3} />
                            </Form.Item>
                            <Form.Item
                                label="SDGs"
                                name="sdgs"
                                labelAlign="left"
                                colon={false}
                                rules={[{ required: true, message: 'Please select SDGs!' }]}
                                hasFeedback
                            >
                                <Select
                                    mode='multiple'
                                    showSearch
                                    placeholder="Multiple Selection"
                                    optionFilterProp="children"
                                    // onChange={onChange}
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                // options={sdgs?.map(i => ({ label: i.name, value: i._id }))}
                                >
                                    {sdgs.map(i => (
                                        <Select.Option value={i._id}>{i.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Publish on listing"
                                name="publish"
                                colon={false}
                                labelAlign="left"
                                rules={[{ required: true, message: 'Publish is not selected!' }]}
                            >
                                <Radio.Group className="text-left">
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Panel>
                    </Collapse>
                    <div className="text-center pt-2 pb-4">
                        <Button className="bg-[#207868]" size="large" type="primary" htmlType="submit">
                            Save Changes
                        </Button>
                    </div>
                </Form>
            </div>

        </>
    )
}