export const StepsTrackingLogsTableColumns = [
  {
    title: 'No',
    dataIndex: 'srno',
    key: 'srno',
    notAllowToSearch: true
  },
  {
    title: 'Full Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Challenge',
    dataIndex: 'challengeName',
    key: 'challengeName'
  },
  {
    title: 'Activity',
    dataIndex: 'activityName',
    key: 'activityName'
  },
  {
    title: 'Target Steps',
    dataIndex: 'targetSteps',
    key: 'targetSteps',
    notAllowToSearch: true
  },
  {
    title: 'Progress',
    dataIndex: 'progress',
    key: 'progress',
    notAllowToSearch: true
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    notAllowToSearch: true
  },
  {
    title: 'Created Date',
    dataIndex: 'createdDate',
    key: 'createdDate',
    notAllowToSearch: true
  },
  {
    title: 'Updated Date',
    dataIndex: 'updatedDate',
    key: 'updatedDate',
    notAllowToSearch: true
  }
]