import React, { useRef, useEffect, useState } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Table, Space, Input, Popover } from 'antd'
import { SearchOutlined, DeleteOutlined, QuestionCircleOutlined, EyeOutlined, PlusOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchDeletedUsers, deleteUser, mobileUsersDeletedExportCsvAll } from '../../../store/users/action'
// import { fetchAllCommunity } from '../../../store/community/actions'
// import PreviewImage from '../../../components/image/PreviewImage'
import AddEditUsers from './AddEditUser'
// import { CSVLink } from 'react-csv'
import moment from 'moment'
import { ACCOUNT_STATUS, exportData, findUserPlatformFromMeta } from './Users.const'
import { DeletedUserColums } from './Users.columns'
import { BE_FULL_DATE_FORMAT, BE_SHORT_DATE_FORMAT, DEFAULT_PAGE_SIZE } from '../../../const/utils'
import { transformUserIdFormat } from '../Transactions/Transactions.const'

export const DeletedUsers = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const users = useSelector(state => state.users.users) || [{ result: [] }]
    const communities = useSelector(state => state.community.communities)

    const searchInput = useRef(null)
    const [prepairDeletedUserColumns, setPrepairDeletedUserColumns] = useState(null)
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')

    const handleColumnSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = (clearFilters) => {
        clearFilters()
        setSearchText('')
    }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block'
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        className='mx-2 bg-[#207868]'
                        onClick={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined
                }}
            />
        ),
        onFilter: (value, record) => {
            if (record[dataIndex]) {
                if (dataIndex === 'type') {
                    return record[dataIndex]?.props?.children.toLowerCase().includes(value.toLowerCase())
                } else {
                    return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                }
            } else {
                return false
            }

        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        }
    })

    useEffect(() => {
        dispatch(fetchDeletedUsers())
        // dispatch(fetchAllCommunity())
        const newDeletedUserColumns = DeletedUserColums.map((item) => {
            if (item.notAllowToSearch === true) {
                return item
            } else {
                return {
                    ...item,
                    ...getColumnSearchProps(item['dataIndex'])
                }
            }
        })
        setPrepairDeletedUserColumns(newDeletedUserColumns)
    }, [])

    const [visible, setVisible] = useState(false)
    const [wordEntered, setWordEntered] = useState()
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [selectedRows, setSelectedRows] = useState([])

    const handleSearch = (event) => {
        const searchWord = event.target.value
        setWordEntered(searchWord)

        if (searchWord === '') {
            dispatch(fetchDeletedUsers())

        }
        dispatch(fetchDeletedUsers({ search: searchWord.toLowerCase() }))
    }

    // const handleVerfiyFilter = (value) => {
    //     if (value === 'all' || value === undefined) {
    //         dispatch(fetchDeletedUsers())
    //     } else {
    //         dispatch(fetchDeletedUsers({ verified: JSON.parse(value.toLowerCase()) }))
    //     }
    // }

    // const showModal = () => {
    //     setVisible(true)
    // }

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys)
        setSelectedRows(newSelectedRowKeys.map(i => users?.result[i]))
    }

    const rowSelection = {
        ...selectedRowKeys,
        ...selectedRows,
        onChange: onSelectChange
    }

    // const handleCommunityFilter = (id) => {
    //     dispatch(fetchDeletedUsers({ communityId: id }))
    // }

    const handleTableChange = (pagination) => {
        dispatch(fetchDeletedUsers(pagination))
    }

    // const deleteThisUser = (id) => {
    //     dispatch(deleteUser(id))
    //     return new Promise(resolve => {
    //         setTimeout(() => resolve(), 2000)
    //     })
    // }

    // const _userVerifiedStatus = (_user) => {
    //     const _status = Object.values(ACCOUNT_STATUS).find(i => i.value == _user.status)
    //     if (_status == ACCOUNT_STATUS.ACTIVE && _user.is_email_verified) {
    //         return <CheckCircleOutlined className='text-green-500' />
    //     }
    //     return <CloseCircleOutlined className='text-red-500' />
    // }

    /**
   * #43769 - Mobile Users - Export selected and Export All is missing
   * Export all CSV
   */
    const _handlemobileUsersDeletedExportCsvAll = () => {
        dispatch(mobileUsersDeletedExportCsvAll())
    }

    /**
     * #43769 - Mobile Users - Export selected and Export All is missing
     * Export selected records
     */
    const _handleMobileUsersExportCsvSelected = () => {
        if (selectedRowKeys.length > 0) {
            dispatch(mobileUsersDeletedExportCsvAll(selectedRowKeys))
        } else {
            //export from current result
            if (users?.result.length > 0) {
                const filterIds = users?.result.map(e => e._id)
                dispatch(mobileUsersDeletedExportCsvAll(filterIds))
            }
        }
    }

    const hasSelected = selectedRowKeys.length > 0 || (users && users?.result?.length > 0)


    return (
        <div className='bg-white shadow'>
            <PageHeader
                ghost={false}
                title="Account Deleted Users"></PageHeader>

            <div className='relative flex items-center justify-between mr-5 mb-5'>
                <div className='flex'>
                    <Input
                        className='w-[220px] h-[40px] ml-4 mr-2'
                        placeholder='Search'
                        value={wordEntered}
                        onChange={handleSearch}
                        prefix={<SearchOutlined />}
                    />
                    {/* <Button className='py-5 mx-2 flex items-center' onClick={handleValidationSort}>Community</Button> */}
                    {/* <Select
                        bordered={false}
                        className='w-[220px] h-[40px] ml-4 mb-5 border-[1px] border-mainThemeColorBorder'
                        placeholder='Community'
                        optionFilterProp='label'
                        options={communities?.result?.map(c => ({ label: c.name, value: c._id }))}
                        onChange={handleCommunityFilter}
                        allowClear
                    />
                    <Select
                        bordered={false}
                        className='w-[280px] h-[40px] ml-4 mb-5 border-[1px] border-mainThemeColorBorder'
                        placeholder='Filter (all, verified and not verified)'
                        optionFilterProp='label'
                        onChange={handleVerfiyFilter}
                        allowClear
                    >
                        <Select.Option key='all' value='all'>All</Select.Option>
                        <Select.Option key='true' value='true'>Verified</Select.Option>
                        <Select.Option key='false' value='false'>Not Verified</Select.Option>
                    </Select> */}
                    {/* <Button className='py-5 mx-2 flex items-center'>Filter (all, verified and not verified)</Button> */}
                </div>
                <div>
                    <Button className='mx-2 border-[#207868] text-[#207868]' disabled={!hasSelected} onClick={_handleMobileUsersExportCsvSelected}>
                        {`Export Selected`}
                    </Button>
                    <Button className='mx-2 bg-[#207868]' type='primary' disabled={!(users?.result)} onClick={_handlemobileUsersDeletedExportCsvAll}>
                        {`Export All`}
                    </Button>
                </div>
            </div>

            <Table
                className='z-[0] relative w-full overflow-auto'
                columns={prepairDeletedUserColumns}
                rowSelection={rowSelection}
                dataSource={
                    users?.result?.map((i, it) => ({
                        key: i._id,
                        userId: transformUserIdFormat(i),
                        name: i.name,
                        platform: findUserPlatformFromMeta(i?.x_app_platforms),
                        email: `${i.email} / ${i?.remarks?.deleted_email || '-'}`,
                        // community: i?.community?.map(i => i?.name),
                        // teamName: i?.teams?.map(t => t?.name),
                        // verified: _userVerifiedStatus(i),
                        remarks:
                            <Popover content={i?.remarks?.notes} title="Reason">
                                <span type="primary">View reason</span>
                            </Popover>,
                        createdAt: i.createdAt && (moment(i.createdAt).format(BE_SHORT_DATE_FORMAT)),
                        deletedAt: i.updatedAt && (moment(i.updatedAt).format(BE_FULL_DATE_FORMAT)),
                        lastActiveAt: i.lastActiveAt && (moment(i.lastActiveAt).format(BE_FULL_DATE_FORMAT))
                        // type: i.community.length > 0 ? <Tag color="cyan">Community</Tag> : <Tag color="geekblue">Individual</Tag>,
                        // status: <Tag color={i.status == 'ACTIVE' && i.is_email_verified ? 'green' : !(i.is_email_verified) ? 'gold' : 'red'}>
                        // {i.is_email_verified ? i.status : "PENDING"}
                        // </Tag>,
                        // action:
                        //     <div style={{ minWidth: '65px' }}><Tooltip title="Info">
                        //         <Button style={{ marginRight: "8px" }} onClick={() => { navigate((`/users/info/${i._id}`)) }} size="small" type="ghost" shape="circle" icon={<EyeOutlined />} />
                        //     </Tooltip>
                        //         <Popconfirm
                        //             title="Are you sure？"
                        //             icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                        //             onConfirm={() => deleteThisUser(i._id)}
                        //             onCancel={() => null}
                        //             okText="Yes"
                        //             okType="danger"
                        //             placement="topLeft"
                        //             cancelText="Cancel"
                        //         >
                        //             <Button size="small" type="ghost" danger shape="circle" icon={<DeleteOutlined />} />
                        //         </Popconfirm>
                        //     </div>
                    }))
                }
                pagination={{
                    defaultPageSize: DEFAULT_PAGE_SIZE,
                    showSizeChanger: true,
                    hideOnSinglePage: true,
                    position: ["none", "bottomRight"],
                    total: users?.totalCount
                }}
                onChange={handleTableChange}
            ></Table>

            {/* <AddEditUsers
                visible={visible}
                setVisible={setVisible}
            /> */}
        </div>
    )
}