import axios from 'axios'

const getLoggedInUser = () => {
  // const user = localStorage.getItem('userData')
  const user = sessionStorage.getItem('userData')
  if (user) return JSON.parse(user)
  return null
}

function ServerApiCSV(props) {
  let token = ''
  try {
    token = getLoggedInUser().accessToken
  } catch (e) {
    token = ''
  }
  return (axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/api/v1`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "x-app-platform": "admin-dashboard"
    },
    responseType: 'blob', // important
    redirect: 'follow',
    // transformResponse: [
    //   (data) => {

    //     //manage session timeouts
    //     if (data === '{"statusCode":401,"message":"Unauthorized"}') {
    //       console.log('Not authorized ---- ')
    //       // localStorage.removeItem('userData')
    //       window.location = '/not-authorized'
    //     }

    //     return JSON.parse(data)
    //   }
    // ],
    ...props
  }))
}
export default ServerApiCSV