import { FETCH_ALL_COMMUNITIES, FETCH_COMMUNITY_BY_ID, FETCH_MY_CORPORATE_INFORMATION } from "./types"

const initialState = {
    communities: [],
    community: {},
    myCorporateProfile: {}
}

const communityReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_COMMUNITIES:
            return { ...state, communities: action.payload }

        case FETCH_COMMUNITY_BY_ID:
            return { ...state, community: action.payload }

        case FETCH_MY_CORPORATE_INFORMATION:
            return { ...state, myCorporateProfile: action.payload }

        default:
            return state
    }
}

export default communityReducer