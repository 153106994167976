import { FETCH_BROADCASTINGS_CHALLENGES_LIST, FETCH_BROADCASTINGS_COMMUNITIES_LIST, FETCH_BROADCASTINGS_DATA_LIST, POST_CREATE_NEW_BROADCASTING } from "./types"

const initialState = {
    communities: [],
    challenges: [],
    data: []
}

const broadcastingReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BROADCASTINGS_CHALLENGES_LIST:
            return { ...state, challenges: action.payload }
        case FETCH_BROADCASTINGS_COMMUNITIES_LIST:
            return { ...state, communities: action.payload }
        case POST_CREATE_NEW_BROADCASTING:
            return { ...state, result: action.payload }
        case FETCH_BROADCASTINGS_DATA_LIST:
            return { ...state, data: action.payload }
        default:
            return state
    }
}

export default broadcastingReducer